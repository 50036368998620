<template>
  <div>
    <el-breadcrumb separator="/"
                   class="mbx">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>账户信息</el-breadcrumb-item>
      <el-breadcrumb-item>电子账簿管理</el-breadcrumb-item>
      <el-breadcrumb-item>汇款验证</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="bank_card">
      <div class="title">小款验证</div>
      <div class="content_bot">
        <div class="jinggao">
          <div>
            <img src="../../../../assets/img/hk_01@2x.png"
                 alt="">
            <span class="yz_top">我们已向您尾号({{$route.query.bankAccountNumber}})的银行账号转入验证小款</span>

          </div>

          <div class="yz_span">请尽快查收验证！</div>
        </div>
        <div class="yz_input">
          <span class="je">验证金额:</span>
          <!-- oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
 -->
          <el-input v-model="amt"
                    oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"></el-input><span>元</span>
        </div>
        <el-button class=" btn_sub"
                   @click="submit">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      amt: "", //金额
    };
  },
  created() {
    console.log(this.$route.query.bankAccountNumber);
  },
  methods: {
    ChangeAmt(value) {
      var reg = /^\d+$|^\d*\.\d+$/g;
      if (!reg.test(value)) {
        this.$message.warning("只能输入数字");
        this.amt = "";
      }
    },
    async submit() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/zxlrOpenAccount/smallValidation?amt=" + this.amt
      );
      if (res.code == 200) {
        this.$message.success(res.data);
      } else {
        this.$message.warning(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mbx {
  height: 56px;
  background-color: #fff;
  line-height: 56px;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.aside_right {
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    .bank_card {
      flex: 1;
    }
  }
}
.bank_card {
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .title {
    padding: 16px 20px 15px;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f3ef;
  }
  .content_bot {
    padding-top: 50px;

    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    .jinggao {
      display: flex;
      flex-direction: column;
      padding: 10px;
      box-sizing: border-box;
      background: #fffbe6;
      box-shadow: 0px 2px 31px 0px rgba(234, 234, 234, 0.5);
      border: 1px solid #fee58f;
      > div {
        display: flex;
        align-items: center;
        > img {
          width: 20px;
          margin-right: 10px;
        }
        .yz_top {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          letter-spacing: 1px;
        }
      }
      .yz_span {
        margin-top: 5px;
        padding-left: 30px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        letter-spacing: 1px;
      }
    }
    .yz_input {
      margin-top: 20px;
      display: flex;
      align-items: center;
      .je {
      }
      .el-input {
        width: 200px;
        margin: 0 5px;
      }
    }
    .btn_sub {
      width: 160px;
      height: 48px;
      background: #c70009;
      border-radius: 4px;

      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      margin-top: 60px;
    }
  }
}
</style>