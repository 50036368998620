<template>
  <div>
    <el-breadcrumb separator="/"
                   class="mbx">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>账户信息</el-breadcrumb-item>
      <el-breadcrumb-item>银行卡绑定</el-breadcrumb-item>
      <el-breadcrumb-item>详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="bank_card">
      <el-form class="cent_form"
               ref="bankFormDataRef"
               :model="bankFormData"
               label-width="84px">
        <el-form-item label="账户名称："
                      prop="name">
          <el-input v-model="bankFormData.name"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="银行账号：">
          <el-input disabled
                    v-model="bankFormData.bankNumber"></el-input>
        </el-form-item>
        <el-form-item label="银行名称："
                      class="dis_form">
          <el-select disabled
                     class="cheng_bank"
                     v-model="bankFormData.bankName">
            <el-option label="区域一"
                       value="shanghai"></el-option>
            <el-option label="区域二"
                       value="beijing"></el-option>
          </el-select>
          <el-select disabled
                     class="shi_bank"
                     v-model="bankFormData.bankBranch">
            <el-option label="区域一"
                       value="shanghai"></el-option>
            <el-option label="区域二"
                       value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在城市："
                      class="dis_form">
          <el-select disabled
                     class="chengshi"
                     v-model="bankFormData.provinceId">
            <el-option label="区域一"
                       value="shanghai"></el-option>
            <el-option label="区域二"
                       value="beijing"></el-option>
          </el-select>
          <el-select disabled
                     class="chengshi"
                     v-model="bankFormData.cityId">
            <el-option label="区域一"
                       value="shanghai"></el-option>
            <el-option label="区域二"
                       value="beijing"></el-option>
          </el-select>
          <el-select disabled
                     class="chengshi"
                     v-model="bankFormData.areaId">
            <el-option label="区域一"
                       value="shanghai"></el-option>
            <el-option label="区域二"
                       value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联行号：">
          <el-input disabled
                    v-model="bankFormData.clearingNumber"></el-input>
        </el-form-item>

      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bankFormData: {
        name: "",
        provinceId: "",
        cityId: "",
        areaId: "",
        bankNumber: "",
        bankName: "",
        bankBranch: "",
        clearingNumber: "",
      },
    };
  },
  created() {
    this.getName(); //获取企业名称
    this.getDetail(); //获取详情信息
  },
  methods: {
    async getName() {
      const { data: res } = await this.$auth.get(
        "/cpiaoju-auth/lxgw/front/company/get"
      );
      this.bankFormData.name = res.data.companyName;
    },
    async getDetail() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/lxgw/front/bank-account/selectEditInfo?id=" +
          this.$route.query.id
      );
      this.bankFormData.bankNumber = res.data.bankNumber;
      this.bankFormData.bankName = res.data.bankName;
      this.bankFormData.bankBranch = res.data.bankBranch;
      this.bankFormData.provinceId = res.data.provinceName;
      this.bankFormData.cityId = res.data.cityName;
      this.bankFormData.areaId = res.data.areaName;
      this.bankFormData.clearingNumber = res.data.clearingNumber;
    },
  },
};
</script>

<style lang="less" scoped>
.aside_right {
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
.mbx {
  height: 56px;
  background-color: #fff;
  line-height: 56px;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.bank_card {
  flex: 1;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .cent_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .dis_form {
      .cheng_bank {
        width: 180px;
        /deep/ .el-input {
          width: 100%;
        }
      }
      .shi_bank {
        width: 260px;
        /deep/ .el-input {
          width: 100%;
        }
      }
      .chengshi {
        width: 32%;
        /deep/ .el-input {
          width: 100%;
        }
      }
      /deep/ .el-form-item__content {
        width: 450px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /deep/ .el-select {
        }
      }
    }
    .addSubmit {
      width: 160px;
      height: 48px;
      background: #c70009;
      border-radius: 4px;
      border: none;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      margin-top: 18px;
    }
  }
  /deep/.el-form-item__content::after,
  /deep/.el-form-item__content::before {
    content: none;
  }
  /deep/.el-input {
    width: 450px;
  }
}
</style>