<template>
	<el-menu background-color="#545c64"
					 text-color="#fff"
					 active-text-color="#ffd04b"
	         router
					 :default-active="activeName"
					 :default-openeds="defaultOpeneds">
		<el-submenu index="0">
			<template slot="title">
				<i class="el-icon-location"></i>
				<span>首页</span>
			</template>
			<el-menu-item index="/ticket/ticketIndex">首页</el-menu-item>
		</el-submenu>
		<el-submenu v-for="item in menuList" :index="item.id + ''" :key="item.id">
			<template slot="title">
				<i class="el-icon-location"></i>
				<span>{{item.title}}</span>
			</template>
			<el-menu-item v-for="item1 in item.children"
			              :key="item1.id"
			              :index="item1.route">
				{{item1.title}}
			</el-menu-item>
		</el-submenu>
	</el-menu>
</template>

<script>
  export default {
    name: "Aside",
	  data(){
      return {
        // 左侧菜单数据
        menuList: [],
        // 被激活的链接地址
        activeName: '',
	      // 当前打开的 sub-menu 的 index 的数组
        defaultOpeneds: []
      }
	  },
	  mounted() {
      // 获取左侧菜单数据
      this.getAside()
		  
    },
	  methods: {
      // 获取左侧菜单数据
      async getAside(){
				const {data: res} = await this.$auth.get('/cpiaoju-auth/lxgw/front/getRouters');
				if(res.code !== 200) return this.$message.error(res.msg)
        this.menuList = res.data.routes
        // 默认打开的一级菜单
        /*this.menuList.forEach(item => {
          this.defaultOpeneds.push(item.id + '')
        })*/
      },
			
	  },
    watch:{
      '$route':{
        immediate: true,
        handler(){
          this.activeName = this.$route.path;
        }
      }
    },
  }
</script>

<style scoped>
.el-menu{
	height: 100%;
	border-right: none !important;
}
</style>