<template>
  <div class="footer">
    <div class="center ">
      <div class="footFlex">
        <ul>
          <li>
            <h1>联系我们</h1>
          </li>
          <li><span>联系电话：400-628-7087</span></li>
          <li><span>网址：www.lxdt.com</span></li>
          <li><span>邮箱：service@lxdt.com</span></li>
          <li><span>地址：北京市朝阳区东四环中路82号金长安大厦C座5层</span></li>
        </ul>
        <ul class="link">
          <li>
            <h1>快速导航</h1>
          </li>
          <li><span>
              <router-link to="/work">业务介绍</router-link>
            </span><span>
              <router-link to="/aboutus">关于乐享</router-link>
            </span>
          </li>
          <li><span>
              <router-link to="/solution">解决方案</router-link>
            </span><span>
              <router-link to="/contactus">联系我们</router-link>
            </span></li>
          <li><span>
              <router-link to="/case">经典案例</router-link>
            </span></li>
          <li><span>
              <router-link to="/news">新闻中心</router-link>
            </span></li>
        </ul>
        <ul class="qr">
          <li>
            <h1>关注我们</h1>
          </li>
          <li class="ewm">
            <div>
              <img width="80" height="80" style="margin-right: 30px" src="@/assets/img/lxskKf.jpg" alt="">
              <p>乐享数科订阅号</p>
            </div>
            <div>
              <img width="80" height="80" style="margin-right: 30px" src="@/assets/img/lxskLogo.jpg" alt="">
              <p>乐享数科客服</p>
            </div>
            <div>
              <img width="80" height="80" src="@/assets/img/ewmApp.png" alt="">
              <p>下载乐享App</p>
            </div>
          </li>
        </ul>
      </div>
      <p class="footBtm">
        Copyright 2014-{{new Date().getFullYear()}}(www.lxdt.com) | 乐享数科有限公司 版权所有
      </p>
      <p class="footBtm">
        <a class="num" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">
          京ICP备14062166号-6
        </a>
        <a class="num" target="_blank" href="https://tsm.miit.gov.cn/dxxzsp">
          增值电信业务经营许可证 京B2-20182078
        </a>
        <img width="12px" height="14px" style="vertical-align: -2px" src="@/assets/img/foot3.png" alt="">
        <a class="num" target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502049120">京公网安备 11010502051955号</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  height: 326px;
  background: #141b1e;
  padding: 30px 0;
  box-sizing: border-box;

  h1 {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 18px;
  }

  span {
    font-size: 14px;
    color: #999999;
    font-weight: 400;
    line-height: 32px;
  }

  .footFlex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 24px;
    box-sizing: border-box;
    border-bottom: 1px solid #515151;
    margin-bottom: 18px;
  }
}

.footBtm {
  text-align: center;
  color: #999999;
  margin-bottom: 10px;

  font-size: 14px;

  .num {
    color: #999999;
    margin-right: 20px;
  }
}

.link {
  li a {
    color: #999999;
    margin-right: 15px;
  }
}

.link li a:hover {
  color: #fff;
}

.qr {
  li.ewm {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;

    p {
      color: #999999;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
</style>