<template>
  <div class="center_content">
    <img class="bg_img"
         src="../../assets/img/loginlxdt5@2x.png"
         alt="">
    <div class="center">

      <div class="text_left">
        <p class="first">服务商业信用 助力产业发展</p>
        <p class="second">全方位的供应链金融科技平台</p>
        <!-- <p class="second three">金融科技平台</p> -->
        <p class="four"></p>
      </div>
      <img class="gif_img"
           src="../../assets/img/dl.gif"
           alt="">
      <div class="login_box"
           v-if="pageId == 1">
        <el-tabs v-model="activeName"
                 @tab-click="handleClick">
          <el-tab-pane label="账号登录"
                       name="first">
            <el-form style="margin-top:15px"
                     ref="form"
                     :rules="formRule"
                     :model="formLogin">
              <el-form-item prop="userPhone">
                <el-input v-model="formLogin.userPhone"
                          oninput="value=value.replace(/[^\d]/g, '')"
                          maxlength="11"
                          placeholder="请输入手机号码"></el-input>
              </el-form-item>
              <el-form-item prop="userPass">
                <el-input show-password
                          v-model="formLogin.userPass"
                          placeholder="密码"></el-input>
              </el-form-item>

              <el-form-item style="margin-top:30px;margin-bottom:15px">
                <el-button class="btn_login"
                           @click="Login">登 录</el-button>
              </el-form-item>
              <el-form-item prop="xyChecked"
                            class="el_it_check">
                <el-checkbox-group v-model="formLogin.xyChecked"
                                   class="checkPassword">
                  <el-checkbox name="xyChecked"
                               class="checked_left">我已阅读并同意<span class="co_co"
                          @click="dialogVisible1=true">《门户注册协议》</span>及<span class="co_co"
                          @click="dialogVisible2=true">《隐私政策》</span></el-checkbox>
                </el-checkbox-group>
              </el-form-item>

            </el-form>
            <!-- <div class="checkPassword">
              <el-checkbox fill='#999999'
                           v-model="checked">记住密码</el-checkbox>

              <el-button type="text"
                         @click="forgetPassword">忘记密码?</el-button>
            </div> -->
            <!-- <el-button class="btn_login"
                       @click="Login">登 录</el-button> -->
            <!-- <div style="text-align:center"
                 class="zczh">
              <span>没有账号?
                <el-button type="text"
                           @click="registerAccount">注册账户</el-button>

              </span>
            </div> -->
          </el-tab-pane>
          <el-tab-pane label="快捷登录"
                       name="second">
            <el-form style="margin-top:15px"
                     ref="formKj"
                     :rules="KjRule"
                     :model="formLoginKj">
              <el-form-item prop="userPhone"
                            :error="error">
                <el-input v-model="formLoginKj.userPhone"
                          oninput="value=value.replace(/[^\d]/g, '')"
                          maxlength="11"
                          placeholder="请输入手机号码"></el-input>
              </el-form-item>
              <el-form-item prop="verify"
                            class="yzm">
                <el-input style="width:62.5%"
                          v-model="formLoginKj.verify"
                          placeholder="验证码"></el-input>
                <el-button @click="KjAuthCode"
                           class="code_bg"
                           :class="{'co_bg':disabledKj}"
                           :disabled='disabledKj'>{{AuthCodeKj}}</el-button>
              </el-form-item>
              <el-form-item style="margin-top:30px;margin-bottom:15px">
                <el-button class="btn_login"
                           @click="LoginKj">登 录</el-button>
              </el-form-item>
              <el-form-item prop="xyChecked"
                            class="el_it_check">
                <el-checkbox-group v-model="formLoginKj.xyChecked"
                                   class="checkPassword">
                  <el-checkbox name="xyChecked"
                               class="checked_left">我已阅读并同意<span class="co_co"
                          @click="dialogVisible1=true">《门户注册协议》</span>及<span class="co_co"
                          @click="dialogVisible2=true">《隐私政策》</span></el-checkbox>
                </el-checkbox-group>
              </el-form-item>

            </el-form>
            <!-- <div class="checkPassword"
                 style="float:right">
              <el-checkbox fill='#999999'
                          >记住密码</el-checkbox>
              <el-button type="text"
                         @click="forgetPassword">忘记密码?</el-button>
            </div> -->

            <!-- <div style="text-align:center"
                 class="zczh">
              <span>没有账号?
                <el-button type="text"
                           @click="registerAccount">注册账户</el-button>

              </span>
            </div> -->
          </el-tab-pane>
        </el-tabs>

      </div>

    </div>
    <el-dialog title="乐享数科用户注册协议"
               :visible.sync="dialogVisible1"
               class="abow_dialog"
               width="62%">
      <!-- <iframe src="/static/1.pdf#toolbar=0"
              frameborder="0"
              style="width: 100%; height: 100%"></iframe> -->
      <Zcxy></Zcxy>

    </el-dialog>
    <el-dialog title="乐享数科隐私政策"
               :visible.sync="dialogVisible2"
               class="abow_dialog"
               width="62%">
      <Yszc></Yszc>
      <!-- <iframe src="/static/2.pdf"
              frameborder="0"
              style="width: 100%; height: 100%"></iframe> -->

    </el-dialog>
  </div>
</template>

<script>
import Zcxy from "./Zcxy.vue";
import Yszc from "./Yszc.vue";
export default {
  name: "Login",
  components: {
    Zcxy,
    Yszc,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      let reg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/;
      if (value.length > 20 || value.length < 6) {
        callback(new Error("6-20个字符"));
      } else if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("必须为数字和字母组合"));
      }
    };
    return {
      dialogVisible1: false,
      dialogVisible2: false,
      disabledCode: false,
      disabledKj: false,
      disabledFor: false,
      checked: false, //是否记住密码
      pageId: 1, //显示登陆或者注册
      activeName: "first",
      activeName1: "first1",
      formLogin: {
        useName: "",
        passWord: "",
        xyChecked: [],
      },
      delayTimeKJ: 60,
      AuthCode: "获取验证码",
      AuthCodeKj: "获取验证码",
      AuthCodeForget: "获取验证码",
      formLoginKj: { xyChecked: [], userPhone: "", verify: "" }, //快捷登录表单

      KjRule: {
        userPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        verify: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        xyChecked: [
          { required: true, message: "请勾选协议", trigger: "change" },
        ],
      },
      formRule: {
        userPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        userPass: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        xyChecked: [
          { required: true, message: "请勾选协议", trigger: "change" },
        ],
      },
      imgSrc: "",
      error: "", //修改错误提示
      type: "", //产品类型
    };
  },
  created() {
    if (this.$route.params.type) {
      localStorage.setItem("type", this.$route.params.type);
    } else {
      localStorage.removeItem("type");
    }
    this.type = localStorage.getItem("type");
    if (localStorage.getItem("userPhone")) {
      this.formLogin.userPhone = localStorage.getItem("userPhone");
      this.formLogin.userPass = localStorage.getItem("userPass");
    }
  },
  methods: {
    handleClick() {
      this.$refs["form"].resetFields();
      this.$refs["formKj"].resetFields();
    },
    switchLogin() {
      this.activeName = "first";
      this.pageId = 1;
    },
    // 快捷登录获取验证码
    KjAuthCode() {
      this.$refs["formKj"].validateField("userPhone", async (errMsg) => {
        if (errMsg) {
          this.error = "请输入正确的手机号,否则无法接收验证码";
          return false;
        } else {
          // 验证过mobile是没问题的，在这里做对应的操作，比如请求短信验证码接口
          this.disabledKj = true;
          let timer = setInterval(() => {
            this.delayTimeKJ = this.delayTimeKJ - 1;
            this.AuthCodeKj = this.delayTimeKJ + "s重新发送";
            if (this.delayTimeKJ < 0) {
              clearInterval(timer);
              this.AuthCodeKj = "获取验证码";
              this.disabledKj = false;
              this.delayTimeKJ = 60;
            }
          }, 1000);
          let { data: res } = await this.$auth.get(
            "/cpiaoju-auth/login/setPhoneCode?tel=" + this.formLoginKj.userPhone
          );
          if (res.code == 200) {
            this.$message.success(res.data);
          } else {
            this.$message.warning(res.msg);
            clearInterval(timer);
            this.AuthCodeKj = "获取验证码";
            this.disabledKj = false;
            this.delayTimeKJ = 120;
          }
        }
      });
    },
    // 快捷登录
    LoginKj() {
      window.sessionStorage.clear();
      this.$refs["formKj"].validate(async (v) => {
        if (!v) return;
        localStorage.setItem("userPhoneKJ", this.formLoginKj.userPhone);
        let { data: res } = await this.$auth.post(
          "/cpiaoju-auth/login/otherphoneLogin",
          {
            userPhone: this.formLoginKj.userPhone,
            verify: this.formLoginKj.verify,
          }
        );
        if (res.code !== 200) {
          this.$message.warning(res.msg);
        } else {
          sessionStorage.setItem("token", res.token);
          this.$store.commit("getNum", this.formLogin.userPhone); //用户
          if (res.companyStatus !== 3 || res.bankStatus !== 1) {
            this.$router.push("/auth");
          } else if (this.type !== "cyl") {
            // 判断是否产品类型是供票信息
            this.$router.push("/auth");
          } else {
            this.$router.push("/ticket/ticketIndex");
          }
        }
      });
    },
    // 登录
    Login() {
      window.sessionStorage.clear();
      this.$refs["form"].validate(async (v) => {
        if (!v) return;
        if (this.checked) {
          localStorage.setItem("userPhone", this.formLogin.userPhone);
          localStorage.setItem("userPass", this.formLogin.userPass);
        } else {
          localStorage.removeItem("userPhone");
          localStorage.removeItem("userPass");
        }
        let { data: res } = await this.$auth.post(
          "/cpiaoju-auth/login/otherLogin",
          this.$qs.stringify({
            userPhone: this.formLogin.userPhone,
            userPass: this.formLogin.userPass,
          })
        );
        if (res.code !== 200) {
          this.$message.warning(res.msg);
        } else {
          sessionStorage.setItem("token", res.token);
          this.$store.commit("getNum", this.formLogin.userPhone); //用户
          // this.$store.commit("getbankStatus", res.bankStatus);//银行状态--0 处理中 1 审核成功 2 审核失败 3 待验证 4未添加
          // this.$store.commit("getcompanyStatus", res.companyStatus);//登录后企业审核状态--0.保存 1.待认证 2.认证失败 3.认证通过4未认证
          // this.$store.state.bankStatus = res.bankStatus
          // this.$store.state.companyStatus = res.companyStatus
          // window.sessionStorage.setItem('businessRole', res.businessRole)//登录后0小微企业 1核心企业 2保理公司 3担保公司 4律所
          if (res.companyStatus !== 3 || res.bankStatus !== 1) {
            this.$router.push("/auth");
          } else if (this.type !== "cyl") {
            // 判断是否产品类型是供票信息
            this.$router.push("/auth");
          } else {
            this.$router.push("/ticket/ticketIndex");
          }
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
.el-button {
  font-weight: normal !important;
}
.co_co:hover {
  color: #f6000b !important;
}
.btn_login:hover {
  background: linear-gradient(#f6000b, #f6000b);
  // transition: background-color .3s;
}
.code_bg:hover {
  background: linear-gradient(#f6000b, #f6000b) !important;
}

/deep/.el-dialog__body {
  overflow: auto !important;
  padding: 20px !important;
}
.co_bg {
  background: #eeeeee !important;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #bbbbbb !important;
  line-height: 20px;
  letter-spacing: 1px;
}
.checked_left {
  /deep/.el-checkbox__label {
    padding-left: 5px;
  }
  span {
    color: #ac0008 !important;
  }
}

.zc_check {
  margin-bottom: 32px !important;
  /deep/.el-checkbox-group {
    justify-content: flex-start !important;
  }
}
/deep/.el-checkbox-group {
  justify-content: center !important;
}
/deep/.el-form-item {
  margin-bottom: 20px;
}
/deep/.el-input__inner {
  border-radius: 6px;
}
/deep/.el-input__inner:focus {
  // el-input输入时设置边框颜色
  box-shadow: inset 0px 1px 3px 0px #ff000c;

  border: 1px solid #c70009;
}
.center_content {
  position: relative;
  height: 682px; //calc(100% - 396px)
  background-image: url("../../assets/img/jgw1.png");
  .bg_img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -99;
  }
  .center {
    height: 682px; //calc(100% - 396px)

    position: relative;

    .text_left {
      position: absolute;
      top: 117px;
      left: 0;
      width: 531px;
      height: 336px;
      padding-left: 54px;
      padding-top: 21px;
      box-sizing: border-box;
      background-image: url("../../assets/img/jgw.png");
      background-position: -10px -10px;
      background-size: 100% 100%;
      > p {
        margin-bottom: 40px;
      }
      .first {
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 45px;
        letter-spacing: 2px;
      }
      .second {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 33px;
        letter-spacing: 2px;
      }
      .three {
        margin-bottom: 60px;
      }
      .four {
        width: 40px;
        height: 4px;
        margin-top: 53px;
        background: #ffffff;
      }
    }
    .gif_img {
      width: 360px;
      position: absolute;
      bottom: 97px;
      left: 370px;
    }
    .login_box {
      width: 400px;
      // height: 450px;
      background: #ffffff;
      box-shadow: 5px -5px 20px 0px rgba(172, 0, 8, 0.91);
      border-radius: 5px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      // top: 116px;
      right: 0;
      padding: 30px 40px;
      box-sizing: border-box;
      /deep/.el-tabs__nav {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #c70009;
        line-height: 25px;
        letter-spacing: 2px;
      }
      /deep/.el-tabs__item:hover {
        color: #c70009 !important;
      }
      /deep/.el-tabs__item.is-active {
        color: #c70009 !important;
      }
      /deep/.el-tabs__active-bar {
        background-color: #c70009 !important;
      }
      .checkPassword {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        letter-spacing: 1px;
        /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
          color: #666666;
        }
        /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: #fff;

          border-color: #999;
        }
        /deep/.el-checkbox__inner {
          border: 1px solid #c70009 !important; // 是改这里的颜色
        }
        /deep/ .el-checkbox__inner::after {
          height: 10px;
          left: 4px;
          top: -1px;
          width: 3px;
          border: 1px solid #c70009; // 是改这里的颜色
          border-left: 0;
          border-top: 0;
        }

        .el-button {
          color: #666666;
          padding: 0;
          font-family: Arial, Verdana, Sans-serif;
        }
      }
      .btn_login {
        border: none;
        width: 100%;
        // background: linear-gradient(180deg, #ff2630 0%, #c70009 100%);
        background: #f6000b;
        border-radius: 6px;
        letter-spacing: 1px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
        padding: 10px 20px;
        // margin-top: 30px;
        font-size: 14px;
      }
      .btn_login1 {
        margin-top: 103px;
      }
      .zczh {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        letter-spacing: 1px;
        .el-button {
          color: #ac0008;
          padding: 0;
        }
      }
      .yzm {
        /deep/.el-form-item__content {
          display: flex;
          justify-content: space-between;
        }
        /deep/.el-form-item__content::after,
        /deep/.el-form-item__content::before {
          content: none;
        }
        .el-button {
          background: linear-gradient(180deg, #ff2630 0%, #c70009 100%);
          border-radius: 6px;

          flex: 1;
          margin-left: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          letter-spacing: 1px;
          padding: 10px 11px;
          box-sizing: border-box;
          border: none;
        }
      }
    }
    .zc {
      /deep/.el-form {
        margin-top: 0 !important;
      }
      .zc_btn {
        width: 100%;
        background: linear-gradient(180deg, #ff2630 0%, #c70009 100%);
        border-radius: 4px;
        border: none;

        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
        padding: 10px;
      }
    }
  }
}
.continue_btn {
  color: #ffffff;
  background: #c70009;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.continue_btn:hover {
  color: #ffffff;
  background: #c70009;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.continue_btn:focus {
  color: #ffffff;
  background: #c70009;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}

.el-button {
  font-size: 14px !important;
}
/deep/ .el-tabs__item {
  font-size: 18px !important;
}
/deep/input::-webkit-input-placeholder {
  font-size: 14px;
}

/deep/input::-moz-input-placeholder {
  font-size: 14px;
}

/deep/input::-ms-input-placeholder {
  font-size: 14px;
}
.abow_dialog1 {
  display: flex;
  justify-content: center;
  align-items: Center;
  overflow: hidden;
  /deep/.el-dialog__body {
    height: 76%;
    flex: 1 !important;
  }
  /deep/.el-tabs__header {
    margin: 0 !important;
  }
  /deep/.el-dialog {
    margin: 0 auto !important;
    height: 80% !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /deep/.el-dialog__body {
      padding: 20px !important;
      // position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}
.abow_dialog {
  display: flex;
  justify-content: center;
  align-items: Center;
  overflow: hidden;
  /deep/.el-dialog__body {
    flex: 1 !important;
  }
  /deep/.el-tabs__header {
    margin: 0 !important;
  }
  /deep/.el-dialog {
    margin: 0 auto !important;
    height: 80% !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /deep/.el-dialog__body {
      position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}
/deep/.el-tabs__active-bar {
  background-color: #fff;
}
.el_it_check {
  margin-bottom: 18px;
  // margin-top: 30px;
  /deep/.el-form-item__content {
    line-height: normal;
  }
  /deep/.el-checkbox__label {
    font-size: 12px;
  }
  span {
    color: #409eff;
  }
}
.zczh_title {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 25px;
  letter-spacing: 1.93px;
  text-align: center;
  margin-bottom: 20px;
}
</style>
<style lang="less" >
.el-tabs__nav-wrap::after {
  content: none !important;
}
</style>