<template>
  <div>
    <el-breadcrumb separator="/"
                   class="mbx">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>账户信息</el-breadcrumb-item>
      <el-breadcrumb-item>电子账簿管理</el-breadcrumb-item>
      <el-breadcrumb-item>虚户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="bank_card">
      <el-button class="retur_router return_btn"
                 @click="$router.go(-1);">返回</el-button>

      <el-button class="retur_router"
                 @click="addXh">添加虚户</el-button>

      <el-table :data="recordData"
                style="width: 100%">
        <el-table-column type="index"
                         label="序号"
                         width="50">
        </el-table-column>
        <el-table-column prop="fictitiousAccount"
                         width="120"
                         label="付款虚户ID">
        </el-table-column>
        <el-table-column prop="source"
                         label="产品类型"
                         width="110">
          <template slot-scope="scope">
            {{scope.row.source==1?'微掌柜':scope.row.source==2?'微账户':'-'}}
          </template>
        </el-table-column>
        <el-table-column prop="useStatus"
                         label="账户类型"
                         width="110">
          <template slot-scope="scope">
            {{scope.row.useStatus==1?'放款账户':scope.row.useStatus==2?'还款账户':'-'}}
          </template>
        </el-table-column>
        <el-table-column prop="imtAmt"
                         :formatter="thousandth"
                         label="可提现余额(元)">
        </el-table-column>
        <el-table-column prop="useAmt"
                         :formatter="thousandth"
                         label="使用中余额(元)">
        </el-table-column>
        <el-table-column prop="refuseAmt"
                         :formatter="thousandth"
                         label="不可用余额(元)">
        </el-table-column>
        <el-table-column align="right"
                         label="操作"
                         width="115">
          <template slot-scope="scope">
            <!-- v-if="scope.row.useStatus==1" -->
            <el-button type="text"
                       @click="TxSubmit(scope.row)"
                       size="small">提现</el-button>
            <!-- <span v-else>-</span> -->
          </template>
        </el-table-column>

      </el-table>
      <!-- 分页 -->
      <el-pagination style="margin-top:10px"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="page"
                     :page-sizes="[10, 20, 30, 40]"
                     :page-size="limit"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </div>
    <el-dialog :visible.sync="addxhDialog"
               class="tjxh"
               width="20%">
      <div slot="title"
           class="header-title">
        <img style="margin-right:10px"
             src="../../../../assets/img/hk_01@2x.png"
             alt="">
        <span class="title-age">添加虚户提示</span>
      </div>
      <div>请确认是否需要再次开通虚户？</div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="addxhDialog = false"
                   class="btn_right">取消</el-button>
        <el-button class="btn_right btn_sub"
                   @click="ktxhSubmit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提现"
               @close='reset'
               :visible.sync="dialogVisible"
               width="40%">
      <span class="ab_time">提现时间为工作日9:00-17:00</span>
      <el-form :model="TxDataFrom"
               label-width="120px"
               :rules="rules"
               ref="TxDataFrom"
               class="demo-ruleForm">
        <el-form-item prop="fictitiousAccount"
                      label="付款账号：">
          <el-input disabled
                    v-model="TxDataFrom.fictitiousAccount"
                    placeholder="请输入虚拟账户ID"></el-input>
        </el-form-item>
        <el-form-item class="err"
                      prop="cashOutAmount"
                      style="margin-bottom:0px"
                      label="提现金额：">
          <el-input v-model="TxDataFrom.cashOutAmount"
                    placeholder="请输入提现金额"></el-input>
          <div style="margin-top:-10px">
            {{"当前账户可提现余额为"+thousandth1(cashOutAmount)+"元"}}
          </div>
        </el-form-item>

        <el-form-item style="margin-top:10px"
                      prop="accountNo"
                      label="收款账户：">
          <el-select v-model="TxDataFrom.accountNo"
                     @change="skzhSlectChange"
                     placeholder="请选择收款账号">
            <el-option v-for="(item,index) in skzh"
                       :label="item.bankYhName"
                       :value="item.bankNumber"
                       :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="lxh"
                      label="联行号：">
          <el-input disabled
                    v-model="TxDataFrom.lxh"
                    placeholder="请输入联行号"></el-input>
        </el-form-item>
        <el-form-item prop="farenName"
                      label="法人姓名：">
          <el-input disabled
                    v-model="TxDataFrom.farenName"
                    placeholder="请输入法人姓名"></el-input>
        </el-form-item>
        <el-form-item prop="legalTel"
                      label="法人手机号：">
          <el-input disabled
                    v-model="TxDataFrom.legalTel"
                    placeholder="请输入法人手机号"></el-input>
        </el-form-item>
        <el-form-item prop="msgCode"
                      class="dxyzm"
                      label="短信验证码：">
          <el-input v-model="TxDataFrom.msgCode"
                    placeholder="请填写验证码"></el-input>
          <el-button type="primary"
                     style="margin-left:15px;width:90px"
                     size="mini"
                     :disabled='disabledCode'
                     @click="verificationCode">{{AuthCode}}</el-button>

        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false"
                   class="btn_right">取消</el-button>
        <el-button class="btn_right btn_sub"
                   @click="submitTx">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (Number(value) < 0 || Number(value) == 0) {
        callback(new Error("输入金额必须大于0"));
      } else if (!Number(value)) {
        callback(new Error("请输入数字值"));
      } else if (
        value !== "" &&
        parseInt(value) > parseInt(this.cashOutAmount)
      ) {
        callback(new Error("输入金额必须小于提现金额"));
      }
      callback();
    };
    return {
      addxhDialog: false, //添加虚户
      disable: false,
      limit: 10,
      page: 1,
      total: 0,
      TxDataFrom: {
        legalTel: "",
      },
      dialogVisible: false,
      activeName: "first",
      skzh: [], //收款账号
      recordData: [],
      rules: {
        cashOutAmount: [
          { required: true, message: "请输入提现金额", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        fictitiousAccount: [
          { required: true, message: "请输入付款账户", trigger: "blur" },
        ],
        accountNo: [
          { required: true, message: "请选择收款账号", trigger: "change" },
        ],
        lxh: [{ required: true, message: "请输入联行号", trigger: "blur" }],
        farenName: [
          { required: true, message: "请输入法人姓名", trigger: "blur" },
        ],
        legalTel: [
          { required: true, message: "请输入法人手机号", trigger: "blur" },
        ],
        msgCode: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
      },
      disabledCode: false,
      AuthCode: "获取验证码",
      delayTime: 60,
      phone: "",
      cashOutAmount: "", //可提现金额
    };
  },
  created() {
    this.getVirList();
  },
  methods: {
    // 清除表单
    reset() {
      this.$refs["TxDataFrom"].resetFields();
    },
    // 获取收款账号下拉
    async khhSelect() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/zxlrFictitiousAccount/selectBankAccount"
      );
      this.TxDataFrom.farenName = res.data.company.legalName.slice(0, 1) + "**";
      this.phone = res.data.company.legalTel;
      if (res.data.company.legalTel) {
        const mb = String(res.data.company.legalTel);
        const reg = /^(\d{3})\d{4}(\d{4})$/;
        this.TxDataFrom.legalTel = mb.replace(reg, "$1****$2");
      }
      this.skzh = res.data.bankAccount;
    },
    // 获取虚户列表数据
    async getVirList() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/zxlrFictitiousAccount/listByPage",
        this.$qs.stringify({
          page: this.page,
          limit: this.limit,
        })
      );
      if (res.code !== 200) return this.$message.warning(res.msg);
      this.total = res.data.total;
      this.recordData = res.data.records;
    },
    //监听 页面条数 改变的事件
    handleSizeChange(newSize) {
      this.limit = newSize;
      this.getVirList();
    },
    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.page = newPage;
      this.getVirList();
    },
    handleClick() {},
    // 验证码
    verificationCode() {
      this.$refs["TxDataFrom"].validateField("legalTel", async (errMsg) => {
        if (errMsg) {
          return false;
        } else {
          this.disabledCode = true;
          let timer = setInterval(() => {
            this.delayTime = this.delayTime - 1;
            this.AuthCode = this.delayTime + "s";
            if (this.delayTime < 0) {
              clearInterval(timer);
              this.AuthCode = "发送验证码";
              this.disabledCode = false;
              this.delayTime = 60;
            }
          }, 1000);
          let { data: res } = await this.$auth.get(
            "/cpiaoju-auth/login/setPhoneCode?tel=" + this.phone
          );
          if (res.code == 200) {
            this.$message.success(res.data);
          }
        }
      });
    },
    //列表数据-千分位-过滤
    thousandth(row, column) {
      const property = column["property"];
      if (row[property] && row[property] !== "-") {
        return Number(row[property])
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      } else if (
        row[property] === "-" ||
        row[property] === "" ||
        row[property] === null
      ) {
        return "-";
      }
    },
    //千分位
    thousandth1: function (value) {
      if (value) {
        return Number(value)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      } else {
        return "";
      }
    },
    // 提现
    TxSubmit(row) {
      // 获取最新余额
      // const { data: res } = await this.$auth.post(
      //   "/cpiaoju-auth/zxlrFictitiousAccount/selctAmount?id=" + row.id
      // );
      // if (res.code == 200) {
      //   this.cashOutAmount = res.imtAmt;
      // } else {
      //   this.cashOutAmount = row.imtAmt;
      // }
      this.dialogVisible = true;
      this.cashOutAmount = row.imtAmt;
      this.khhSelect();
      this.TxDataFrom.fictitiousAccount = row.fictitiousAccount;
    },
    // 更改收款账户
    skzhSlectChange(val) {
      this.skzh.forEach((item) => {
        if (item.bankNumber == val) {
          this.TxDataFrom.lxh = item.clearingNumber;
        }
      });
    },
    // 添加虚户
    addXh() {
      this.addxhDialog = true;
    },
    // 添加虚户
    async ktxhSubmit() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/zxlrFictitiousAccount/addAccount"
      );
      if (res.code == 200) {
        this.$message.success(res.msg);
        this.addxhDialog = false;
        this.getVirList();
      } else {
        this.$message.warning(res.msg);
        this.addxhDialog = false;
        this.getVirList();
      }
    },
    // 提现
    submitTx() {
      this.$refs["TxDataFrom"].validate(async (v) => {
        if (!v) return;
        const { data: res } = await this.$auth.post(
          "/cpiaoju-auth/zxlrCashOutLog/saveCashOutLog",
          this.$qs.stringify({
            fictitiousAccount: this.TxDataFrom.fictitiousAccount,
            cashOutAmount: this.TxDataFrom.cashOutAmount,
            accountNo: this.TxDataFrom.accountNo,
            msgCode: this.TxDataFrom.msgCode,
            legalTel: this.phone,
          })
        );
        if (res.code == 0) {
          this.$message.success(res.data);
          this.dialogVisible = false;
          this.page = 1;
          this.getVirList();
        } else if (res.code == -1) {
          this.$message.warning(res.msg);
        } else {
          this.$message.warning(res.data);
          this.dialogVisible = false;
          this.page = 1;
          this.getVirList();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.mbx {
  height: 56px;
  background-color: #fff;
  line-height: 56px;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.aside_right {
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    .bank_card {
      flex: 1;
    }
  }
}
.bank_card {
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px 20px 23px;
  /deep/.el-tabs__nav {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #c70009;
    line-height: 25px;
    letter-spacing: 2px;
  }
  /deep/.el-tabs__item:hover {
    color: #c70009 !important;
  }
  /deep/.el-tabs__item.is-active {
    color: #c70009 !important;
  }
  /deep/.el-tabs__active-bar {
    background-color: #c70009 !important;
  }

  .retur_router {
    float: right;
    width: 86px;
    height: 34px;
    background: #c70009;
    border-radius: 4px;
    padding: 7px 15px;
    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid #c70009;

    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
  }
  .return_btn {
    padding: 7px 29px;
    border: 1px solid #c70009;
    background-color: #fff;
    color: #c70009;
    margin-left: 10px;
    box-sizing: border-box;
  }
  /deep/.el-table {
    margin-top: 54px;
  }
  /deep/.el-table thead {
    height: 54px;
    padding-top: 17px;
    padding-bottom: 15px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    letter-spacing: 1px;
  }
  /deep/.el-table .el-table__cell {
    padding: 0;
  }
  /deep/.el-table .cell {
    padding: 0 5px;
  }
  /deep/.el-table th.el-table__cell > .cell {
    padding: 0 5px;
  }
  /deep/.el-table th.el-table__cell {
    background: #fafafa;
  }
  .el-button--text {
    color: #c70009;
  }
}
// 弹框
/deep/.el-dialog__header {
  padding: 15px 20px;
  border-bottom: 1px solid #f0f3ef;
  /deep/.el-dialog__title {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 25px;
    letter-spacing: 1px;
  }
}
/deep/.el-input {
  // width: 90%;
}
/deep/.el-select {
  width: 100%;
}
/deep/ .el-dialog__body {
  padding-top: 20px;
  padding-right: 40px;
  position: relative;
  .ab_time {
    position: absolute;
    top: -38px;
    left: 72px;

    font-size: 16px;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
    letter-spacing: 1px;
  }
}
.dxyzm {
  /deep/.el-input {
    width: none;
  }
  /deep/.el-form-item__content {
    display: flex;
  }
  .el-button {
    background: #c70009;
    border-radius: 4px;
    border: none;
  }
}
.dialog-footer {
  .btn_right {
    padding: 0;
    width: 86px;
    height: 34px;
    border-radius: 4px;
    border: 1px solid #c70009;

    font-size: 14px;
    font-weight: 400;
    color: #c70009;
    line-height: 20px;
  }
  .btn_sub {
    background: #c70009;

    color: #ffffff;
  }
}
.tjxh {
  /deep/.header-title {
    display: flex;
    align-items: center;
  }
  /deep/.el-dialog__body {
    padding: 20px 49px;
    color: #c70009;
  }
}
.err {
  /deep/.el-form-item__error {
    margin-top: -10px;
  }
}
</style>