<template>
  <div class="risk">
    <div class="block bannerImg">
      <img width="100%"
           height="600"
           src="@/assets/img/yw4.png"
           alt="">
      <div class="bannerText">
        <h1>关于智能风控系统</h1>
        <p>智能风控解决方案将帮助银行建立以数据为基础，风控模型为核心，风险策略为决策依据的智能风控体系。我们将嵌入业务场景和流程设计制定风控策略和模型，进而制定更全面的智能风控方案。在不断优化、完善风险管理模式的过程中，不仅提供先进的业务理念，同时融合大数据、人工智能等金融科技技术，切实帮助银行在智能化转型中获取强大的竞争优势。</p>
        <!-- <p class="mt20"
           style="display: block">
          <!==          <router-link to="/login">==>
          <el-button class="submit btn_jrcp"
                     @click="jump"
                     round>进 入 产 品</el-button>
          <!==          </router-link>==>
        </p> -->
      </div>
    </div>

    <div class="mainBox">
      <div class="cardBg">
        <div class="center">
          <div class="oneTitle">
            <h1>系统功能</h1>
          </div>
          <div class="flex3 risk mt20">
            <div class="flex3Item">
              <div class="top">
                企业风险检测（大数据征信）
              </div>
              <p>
                通过企业全息画像系统，从信用评级、对外担保、法律诉讼、承信风险等6大维度150项，细分标签描绘企业画像。
              </p>
            </div>
            <div class="flex3Item">
              <div class="top">
                CFCA电子签章
              </div>
              <p>
                平台通过CFCA认可的安全环境下进行融资交易，为企业提供身份认证电子签章授权确认，CFCA数字证书是由国家认可，具有权威性、可信性、和公正性。
              </p>
            </div>
            <div class="flex3Item">
              <div class="top">
                企业账户核验
              </div>
              <p>
                企业账户真实性验证，系统自动调取征信信息数据， 核对企业账户信息，确保交易资金安全。
              </p>
            </div>
            <div class="flex3Item">
              <div class="top">
                工商信息数据
              </div>
              <p>
                24小时企业舆情监控，支持自定义监控方案，同时将通过短息、邮件、APP等多种方式进行实时风险提示。
              </p>
            </div>
            <div class="flex3Item">
              <div class="top">
                发票真伪核验
              </div>
              <p>
                对接政府工商税务系统，实时捕获企业发票状态信息，系统同步验证发票真伪。
              </p>
            </div>
            <div class="flex3Item">
              <div class="top">
                税务数据查询
              </div>
              <p>
                企业税务数据检测，对接政府税务系统、依据企业进销项相关数据进行企业经营分析。
              </p>
            </div>
            <div class="flex3Item">
              <div class="top">
                AI智能影像识别
              </div>
              <p>
                对接百度、公安系统，人脸核身方案，应用活体检测、证件识别、人脸对比等多种技术能力，捕获当前用户照片并与公民身份照片进行比对，实现在线用户身份验证。
              </p>
            </div>
            <div class="flex3Item">
              <div class="top">
                OCR和NLP技术
              </div>
              <p>
                通过OCR和NLP技术，对合同发票、物流单据等材料进行有效信息提取。
              </p>
            </div>
            <div class="flex3Item">
              <div class="top">
                票据交易见证
              </div>
              <p>
                结合“互联网+”与“电票”的优势，与多家银行合作专设银行汇票资金存管户全程资金监管，专款专用保障交易资金安全。
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="cardBg">
        <div class="center">
          <div class="oneTitle">
            <h1>业务场景</h1>
          </div>
          <div class="flex5 risk mt40">
            <div class="flex5Item">
              <img src="@/assets/img/znfk_02.png"
                   alt="">
              <p>交易反欺诈</p>
            </div>
            <div class="flex5Item">
              <img src="@/assets/img/znfk_03.png"
                   alt="">
              <p>申请反欺诈</p>
            </div>
            <div class="flex5Item">
              <img src="@/assets/img/znfk_04.png"
                   alt="">
              <p>智能催收</p>
            </div>
            <div class="flex5Item">
              <img src="@/assets/img/znfk_05.png"
                   alt="">
              <p>逾期预警</p>
            </div>
            <div class="flex5Item">
              <img src="@/assets/img/znfk_06.png"
                   alt="">
              <p>异常登录识别</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="apply">
      <h1>专属客服1v1服务，为您的需求保驾护航</h1>
      <router-link :to="{path:'/contactus',query:{type:'6'}}">
        <el-button>立即联系</el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkRisk",
  data() {
    return {};
  },
  methods: {
    jump() {
      let token = sessionStorage.getItem("token");
      if (token) {
        this.$router.push("/auth");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.flex3.risk {
  flex-wrap: wrap;

  .flex3Item {
    width: 32.2%;
    height: 220px;
    margin-top: 20px;

    .top {
      height: 60px;
      background: url("~@/assets/img/znfk_01.png") no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 60px;
      letter-spacing: 2px;
      text-align: center;
    }

    p {
      height: 160px;
      color: #999999;
      line-height: 22px;
      letter-spacing: 2px;
      padding: 20px;
      box-sizing: border-box;
      background: #ffffff;
      text-align: justify;
    }
  }
}

.flex5.risk {
  justify-content: space-between;
  flex-wrap: wrap;

  .flex5Item {
    width: 19%;
    height: 206px;
    background: #f4f5f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > img {
      margin-bottom: 20px;
    }

    > p {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 1px;
    }
  }
}
.apply {
  height: 200px;
  text-align: center;
  padding: 50px 20px 40px;
  box-sizing: border-box;
  background: url("~@/assets/img/jjfa_12@2x.png") no-repeat;

  h1 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 30px;
  }

  .el-button {
    width: 180px;
    height: 48px;
    border: 1px solid #ffffff;
    color: #ffffff;
    background: none;
  }

  .el-button:hover {
    background: #ffffff;
    color: #c70009;
  }
}
</style>