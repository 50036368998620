<template>
  <div class="auth bgColor">
    <div class="center">
      <div class="authTop">
        <div class="topL">
          <div class="imgPos">
            <img src="@/assets/img/auth/sy_061.png"
                 alt="">
            <!--            <div class="userTip">-->
            <!--              {{formList.productRoleList}}-->
            <!--            </div>-->
          </div>
          <h1 v-if="formList.userPhone">
            {{ formList.userPhone.substr(0, 3) + '****' + formList.userPhone.substr(7) }}</h1>
          <p>上次登录：{{ formList.lastLoginTime }}</p>
        </div>
        <div class="topR">
          <div class="title">
            <span v-if="companyRole=='0'">
              <em v-if="companyStatus=='1'">您的企业正在审核中，请耐心等待审核结果…</em>
              <em v-if="companyStatus=='2'">企业认证失败，请修改后重新提交</em>
              <em v-if="companyStatus=='3'||companyStatus=='6'">{{ companyName | ellipsis }}</em>
              <em v-if="companyStatus=='4'||companyStatus=='0'">
                请点击“<a href="javascript:;"
                   style="color: #F4CA3B"
                   @click="openCompany">企业信息</a>”完成企业认证
              </em>
              <i v-if="companyStatus=='1'"
                 style="margin-left: 10px"
                 class="companyState">审核中</i>
              <i v-else-if="companyStatus=='2'"
                 style="margin-left: 10px"
                 class="companyState">认证失败</i>
              <i v-else-if="companyStatus=='3'"
                 style="margin-left: 10px"
                 class="companyState">认证通过</i>
              <i v-else-if="companyStatus=='4'||companyStatus=='0'"
                 style="margin-left: 10px"
                 class="companyState">未认证</i>
              <i v-else-if="companyStatus=='6'"
                 style="margin-left: 10px"
                 class="companyState">待补充</i>
            </span>
            <span v-if="companyRole != '0'">
              <em>{{ companyName | ellipsis }}</em>
            </span>
            <div style="display: inline-block"
                 class="fr"
                 v-if="((formList.channel=='2'||formList.channel=='3')&&formList.state=='3'&&companyRole=='0')||companyStatus=='6'">
              <em>账户信息缺失，请前往认证信息中进行补充</em>
              <router-link :to="{path:'/aside/companyinfo'}">
                <el-button class=""
                           round>立即前往</el-button>
              </router-link>
            </div>
          </div>
          <div class="cardBox flex4">
            <div class="flex4Item img1">
              <div class="name">
                <span>企业信息</span>
                <a href="javascript:;"
                   @click="jumpCompany(formList.userType,'1')"><i><img src="@/assets/img/auth/sy_03.png"
                         alt=""></i></a>
              </div>
              <div v-if="companyRole=='0'">
                <p v-if="companyStatus=='1'">审核中</p>
                <p v-else-if="companyStatus=='2'">认证失败</p>
                <p v-else-if="companyStatus=='3'">认证通过</p>
                <p v-else-if="companyStatus=='4'||companyStatus=='0'">未认证</p>
                <p v-else-if="companyStatus=='6'">待补充</p>
              </div>
            </div>
            <div class="flex4Item img2"
                 v-if="companyRole == '0'">
              <div class="name">
                <span>银行卡信息</span>
                <a href="javascript:;"
                   @click="jumpCompany(formList.userType,'2')"><i><img src="@/assets/img/auth/sy_04.png"
                         alt=""></i>
                </a>
              </div>
              <p v-if="bankStatus==1">已添加</p>
              <p v-else>未验证</p>
            </div>
            <div class="flex4Item img3">
              <div class="name">
                <span>账号安全</span>
                <router-link to="/aside/password"><i><img src="@/assets/img/auth/sy_03.png"
                         alt=""></i></router-link>
              </div>
            </div>
            <div class="flex4Item img4">
              <div class="name">
                <span>操作员管理</span>
                <!--                <a href="javascript:;" @click="jumpOperate"><i><img src="@/assets/img/auth/sy_04.png" alt=""></i></a>-->
                <a href="javascript:;"
                   @click="jumpCompany(formList.userType,'3')"><i><img src="@/assets/img/auth/sy_04.png"
                         alt=""></i></a>
              </div>
            </div>
          </div>
          <!-- 新手指导1.3 -->
          <div class="dialog_1"
               v-if="FirstBz">
            <div class="xx_kk"></div>
            <img class="img_dialog"
                 src="@/assets/img/jiantou.png"
                 alt="">
            <div class="submit_dialog">
              <div>第一步:</div>
              <div>点击“企业信息”完成企业认证。</div>
              <div class="btn_dialog">
                <el-button class="btn__1"
                           type="text"
                           @click="gbFirst">关闭</el-button>
                <el-button class="btn__1"
                           type="text"
                           @click="nextSecond">下一步</el-button>
              </div>
            </div>
          </div>
          <div class="dialog_2"
               v-if="SecondBz">
            <div class="xx_kk"></div>
            <img class="img_dialog"
                 src="@/assets/img/jiantou.png"
                 alt="">
            <div class="submit_dialog"
                 style="width:245px">
              <div>第二步:</div>
              <div>点击“银行卡信息”绑定对公账户。</div>
              <div class="btn_dialog">
                <el-button class="btn__1"
                           type="text"
                           @click="gbFirst">关闭</el-button>
                <el-button class="btn__1"
                           type="text"
                           @click="nextThird">下一步</el-button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div style="display: flex;justify-content: space-between;">
        <div class="authMain"
             style="width:57%">
          <p>待办事项</p>
          <el-collapse v-model="activeNames"
                       @change="handleChange"
                       style="padding:20px 19px">
            <el-collapse-item name="1"
                              v-if="tableList.length!=0">
              <template slot="title">
                供应链票据<i class="titleIcon">{{ tableList.length }}</i>
              </template>
              <div v-for="(item,index) in tableList"
                   :key="index">
                <p>您有一条编号为“<em>{{ item.operNo }}</em>”的订单需处理 <a href="javascript:;"
                     @click="jumpDetail"
                     class="red fr">操作</a></p>
              </div>
            </el-collapse-item>
          </el-collapse>
          <div class="tableNull"
               style="margin-top:79px;margin-bottom:35px"
               v-if="tableList.length=='0'">
            <img style="width:200px;margin-bottom:20px"
                 src="@/assets/img/auth/zwsj_01.png"
                 alt="">
            <p style="line-height:1">暂无数据</p>
          </div>
        </div>
        <div class="authMain"
             style="width:42%">
          <!-- 新手指导第三步1.3 -->
          <div class="dialog_3"
               v-if="ThirdBz">
            <div class="submit_dialog"
                 style="width:245px">
              <div>第三步:</div>
              <div>点击“产品管理”前往列表内选择要操作的产品。</div>
              <div class="btn_dialog">
                <el-button class="btn__1"
                           type="text"
                           @click="gbFirst">知道啦</el-button>
              </div>
            </div>
            <img class="img_dialog"
                 src="@/assets/img/jiantou.png"
                 alt="">
            <div class="xx_kk"></div>

          </div>
          <!-- 我的产品开通记录1.3增加 -->
          <p style="border-bottom:1px solid #F0F3EF">我的产品</p>
          <div class="pro_list"
               v-if="productDtoList.length!=0">
            <div v-for='item,index in productDtoList'
                 :key="index">
              <!-- <div v-if="item.status!=0">
                <div>
                  <div class="name">{{item.type==1?'供应链票据':''}}</div>
                  <div class="status">{{item.status==0?'未开通':item.status==1?'待修改':item.status==2?'待审核':'已开通'}}</div>
                </div>

                <el-button type="text"
                           class="btn_view"
                           @click="view(item.type)">查看</el-button>
              </div> -->
              <div @click="view(item.type,companyRole,companyStatus,bankStatus)">
                <div class="cpName">{{item.type==1?'供应链票据':''}}</div>
                <div class="type">{{item.status==0?'未开通':item.status==1?'待修改':item.status==2?'待审核':'已开通'}}</div>
                <!-- <img style="width:18px;height:10px"
                     src="@/assets/img/nextstep.png"
                     alt=""> -->
                <div class="bg_img"></div>
              </div>
              <img src="@/assets/img/gzt_01.png"
                   alt="">

            </div>
          </div>
          <div v-else
               class="tableNull"
               style="line-height:22px;color:#666">
            <img style="width:200px;height:171px;margin-top:68px;margin-bottom:20px"
                 src="@/assets/img/auth/zwsj_01.png"
                 alt="">
            <p>暂未开通产品</p>
            <p>点击“<a href="/workbench/product"
                 style="color:#C70009">产品管理</a>”进行开通</p>
          </div>
        </div>
      </div>

    </div>
    <div id="pop"
         v-if="pop">
    </div>

    <!--gyl/cpiaoju信息授权带回-->
    <el-dialog :show-close="false"
               :visible.sync="FromVisible"
               :close-on-click-modal="false"
               :destroy-on-close="true"
               width="410px">
      <div class="contentTip flex5">
        <img class="tipIcon"
             src="@/assets/img/auth/tishi1.png"
             alt="">
        <div>
          <h1>提示</h1>
          <p>已检测您的账号在云票据平台上存在企业信息，是否需要将数据带回？</p>
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer formBtn">
        <el-button class="btn normal"
                   @click="FromVisibleCancal()">取 消</el-button>
        <el-button class="btn submit"
                   type="primary"
                   @click="FromeVisibleSave()">授 权 带 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import {mapState} from "src/store/index"
import { mapState } from "vuex";

export default {
  name: "Auth",
  data() {
    return {
      pop: false, //遮罩层
      FirstBz: false, //步骤一
      SecondBz: false, //步骤二
      ThirdBz: false, //步骤三
      activeNames: ["1"], //下拉
      formList: {}, //列表
      tableList: [], //待办事项
      stateSet: "", //状态-编辑
      // businessRole: ''//企业角色
      // isuserType:''//操作员2/管理员1
      FromVisible: false, //gyl来源弹窗
      // flagVisible:false,//授权弹窗
      productDtoList: [], //我的产品
      routerPass: "",
    };
  },
  computed: {
    ...mapState([
      "getUser",
      "companyStatus",
      "bankStatus",
      "companyRole",
      "backStatus",
      "companyName",
    ]),
  },
  filters: {
    //文件名称超出20个字...隐藏
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 20) {
        return value.slice(0, 20) + "... ";
      }
      return value;
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path == "/login" || from.path == "/loginQt") {
        console.log(vm);
        vm.pop = true;
        vm.FirstBz = true;
      }
    });
  },

  created() {
    // if (this.routerPass == "/login" || this.routerPass == "/loginQt") {
    //   this.pop = true;
    //   this.FirstBz = true;
    // }
    window.addEventListener("setItem", () => {
      this.getPhone = sessionStorage.getItem("getUserPhone");
    });
    this.getRole();
  },
  methods: {
    //登录账户来源-gyl/cpiaoju-数据带回
    async FromeVisibleSave() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/login/updateCompany",
        this.$qs.stringify({
          // userId: this.updateId,
          backStatus: 2,
        })
      );
      if (res.code == 200) {
        this.FromVisible = false;
        this.$store.state.backStatus = 2;
        this.$store.commit("getbackStatus", 2);
        this.$store.state.companyStatus = 6;
        this.$store.commit("getcompanyStatus", 6);
        sessionStorage.setItem("getStatus", 6);
        this.getRole();
        // this.flagVisible = true;
      } else {
        this.FromVisible = false;
        // this.flagVisible = false;
        this.$message.error(res.msg);
      }
    },
    async FromVisibleCancal() {
      this.FromVisible = false;
      this.$router.push("/aside/companyinfo");
    },
    //查询资料
    async getRole() {
      // console.log(sessionStorage.getItem('getUserPhone'), '获取')
      const { data: res } = await this.$auth.post("/cpiaoju-auth/index/home");
      if (res.code == 200) {
        this.formList = res.date;
        this.tableList = res.agent;
        this.productDtoList = res.productDtoList;
        if (res.date.status == "4") {
          this.stateSet = "未认证";
        } else if (res.date.status == "3") {
          this.stateSet = "认证通过";
        } else if (res.date.status == "2") {
          this.stateSet = "认证失败";
        } else if (res.date.status == "1") {
          this.stateSet = "审核中";
        } else if (res.date.status == "0") {
          this.stateSet = "未认证";
        } else if (res.date.status == "6") {
          this.stateSet = "待补充";
        }
        this.$store.commit("getuserType", res.date.userType); //用户角色-操作员|管理员
        this.$store.state.userType = res.date.userType; //用户角色-操作员|管理员
        window.sessionStorage.setItem("userType", res.date.userType); //用户角色-操作员|管理员
        this.$store.commit("getcompanyStatus", res.date.status); //登录后企业审核状态--0.保存 1.待认证 2.认证失败 3.认证通过4未认证
        this.$store.state.companyStatus = res.date.status;
        window.sessionStorage.setItem("getStatus", res.date.status); //登录后企业审核状态--0.保存 1.待认证 2.认证失败 3.认证通过4未认证
        window.sessionStorage.setItem("companyName", res.date.companyName); //公司
        this.$store.commit("getcompanyName", res.date.companyName); //

        this.$store.commit("getcompanyRole", res.date.businessRole); //银行状态--0 处理中 1 审核成功 2 审核失败 3 待验证 4未添加
        window.sessionStorage.setItem("businessRole", res.date.businessRole); //登录后0小微企业 1核心企业 2保理公司 3担保公司 4律所

        this.$store.commit("getbankStatus", res.date.bankStatus); //银行状态--0 处理中 1 审核成功 2 审核失败 3 待验证 4未添加
        // this.$store.state.bankStatus = res.date.bankStatus;
        window.sessionStorage.setItem("bankStatus", res.date.bankStatus); //缓存银行卡状态
      }
    },
    //跳转企业信息
    jumpCompany(val, i) {
      if (val == "1" && i == "1") {
        if (this.backStatus == "1") return (this.FromVisible = true); //如果backStatus==1授权带回
        this.$router.push({ path: "/aside/companyinfo" }); //跳转企业信息
      } else if (val == "1" && i == "2") {
        this.$router.push({ path: "/aside/bankCardBind" }); //跳转银行绑定
      } else if (val == "1" && i == "3") {
        this.$router.push({ path: "/aside/operator" }); //跳转操作员管理
      } else {
        this.$message.error("该账户暂无权限");
      }
    },
    openCompany() {
      if (this.backStatus == "1") {
        this.FromVisible = true;
      } else {
        this.$router.push("/aside/companyinfo");
      }
    },
    //待办-详情
    jumpDetail() {
      this.$router.push({ name: "taskWaitList" });
    },
    //表格下拉
    handleChange(val) {
      console.log(val);
    },
    gbFirst() {
      this.pop = false;
      this.FirstBz = false;
      this.SecondBz = false;
      this.ThirdBz = false;
    },
    nextSecond() {
      this.FirstBz = false;
      this.SecondBz = true;
    },
    nextThird() {
      this.SecondBz = false;
      this.ThirdBz = true;

      // document.documentElement.scrollTop = document.body.scrollTop = 400;
    },
    openBz() {
      this.pop = true;
      this.FirstBz = true;
    },
    view(type, role, val, bank) {
      if (role != "0") return this.$message.error("该账户暂无权限");
      if (role == "0" && val != "3")
        return this.$message.error("企业未完成认证");
      if (val == "3" && bank != "1")
        return this.$message.error("银行卡验证未通过");
      if (type == 1) {
        this.$router.push("/ticket");
      }
    },
  },
  watch: {
    // ...mapState([
    //   'getPhone'
    //
    // ]),
    // data:function(val){
    //   console.log(val,'监听')
    //   console.log(this.getPhone,'-')
    // },
  },
};
</script>

<style lang="less" scoped>
.auth {
  .authTop {
    display: flex;
    justify-content: space-between;

    .topL {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 220px;
      height: 240px;
      background: #ffffff;
      border-radius: 8px;
      margin-right: 20px;

      .imgPos {
        position: relative;
        margin-bottom: 15px;

        img {
          width: 90px;
          height: 90px;
        }

        .userTip {
          position: absolute;
          left: calc(50% - 25px);
          bottom: 0;
          width: 50px;
          height: 18px;
          background: linear-gradient(135deg, #e4443d 0%, #c70009 100%);
          border-radius: 10px;
          line-height: 18px;
          text-align: center;
          color: #ffffff;
          font-size: 10px;
        }
      }

      h1 {
        font-size: 16px;
        color: #212f51;
        margin-bottom: 30px;
      }

      p {
        font-size: 12px;
        color: #666666;
      }
    }

    .topR {
      flex: 1;
      background: #ffffff;
      position: relative;

      .title {
        height: 56px;
        background: linear-gradient(135deg, #e4443d 0%, #d6000f 100%);
        border-radius: 8px 8px 0 0;
        line-height: 56px;
        padding: 0 20px;
        box-sizing: border-box;

        span {
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
        }

        .fr {
          em {
            font-size: 16px;
            color: #d8d8d8;
            margin-right: 20px;
          }

          .el-button.is-round {
            padding: 7px 9px;
            color: #d8d8d8;
            background: none;
            border: 1px solid #d8d8d8;
          }
        }
      }
    }

    .cardBox.flex4 {
      display: flex;
      /*justify-content: space-between;*/
      justify-content: flex-start;
      padding: 30px 20px;
      box-sizing: border-box;
      width: 100%;

      .flex4Item {
        width: 24%;
        height: 120px;
        padding: 20px;
        box-sizing: border-box;
        margin-right: 20px;

        .name {
          display: flex;
          justify-content: space-between;

          span {
            font-size: 18px;
            font-weight: 600;
            color: #ffffff;
          }

          i {
            display: inline-block;
            width: 24px;
            height: 24px;
            background: #ffffff;
            border-radius: 6px;
            text-align: center;
            line-height: 24px;
          }
        }

        p {
          margin-top: 8px;
          color: #ffffff;
        }
      }

      .flex4Item:last-of-type {
        margin-right: 0;
      }

      .img1 {
        background: url("~@/assets/img/auth/sy_01.png") no-repeat;
        background-size: 100% 100%;
      }

      .img2 {
        background: url("~@/assets/img/auth/sy_02.png") no-repeat;
        background-size: 100% 100%;
      }

      .img3 {
        background: url("~@/assets/img/auth/sy_06.png") no-repeat;
        background-size: 100% 100%;
      }

      .img4 {
        background: url("~@/assets/img/auth/sy_07.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .authMain {
    background: #ffffff;
    margin-top: 20px;
    min-height: calc(100vh - 696px);
    position: relative;
    /*height: calc(100vh - 596px);*/

    > p {
      height: 56px;
      line-height: 56px;
      padding: 0 20px;
      font-size: 18px;
      letter-spacing: 1px;
    }

    .el-collapse {
      // padding: 20px;
      border-bottom: none;
      box-sizing: border-box;

      /deep/ .el-collapse-item__wrap {
        border-bottom: none;
        /*height: 100px;*/
        height: 190px;
        min-height: 190px;
        max-height: 190px;
        overflow-y: auto;
      }

      /deep/ .el-collapse-item__wrap::-webkit-scrollbar {
        width: 4px;
        height: 16px;
        background-color: #f5f5f5;
      }

      /*定义滚动条轨道 内阴影+圆角*/

      /deep/ .el-collapse-item__wrap::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(54, 52, 52, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }

      /*定义滑块 内阴影+圆角*/

      /deep/ .el-collapse-item__wrap::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(99, 97, 97, 0.3);
        background-color: #b4afaf;
      }

      /deep/ .el-collapse-item__header {
        font-size: 16px;
        border-bottom: 1px solid #ebeef5;
        padding: 0 20px;
        box-sizing: border-box;
      }

      /deep/ .el-collapse-item__header:hover {
        background: #fafafa;
      }

      /deep/ .el-collapse-item__content {
        padding-bottom: 0;
        font-size: 14px;
      }

      .titleIcon {
        width: auto;
        height: 14px;
        background: #c70009;
        color: #ffffff;
        border-radius: 20px;
        margin-left: 12px;
        text-align: center;
        line-height: 14px;
        padding: 0 4px;
        font-size: 8px;
      }

      p {
        height: 52px;
        line-height: 52px;
        color: #666666;
        border-bottom: 1px solid #ebeef5;
        padding: 0 20px;
        box-sizing: border-box;

        em {
          color: #333333;
        }

        a {
          height: auto;
          margin-right: 10px;
        }
      }
    }
  }

  .tableNull {
    text-align: center;
    line-height: 40px;
    padding: 20px 0 64px;
    box-sizing: border-box;
  }

  .companyState {
    width: 42px;
    height: 20px;
    font-size: 14px;
    font-weight: normal;
    border-radius: 4px;
    border: 1px solid #ffffff;
    padding: 0 4px;
    box-sizing: border-box;
  }
}

.contentTip {
  .tipIcon {
    width: 46px;
    height: 46px;
    margin-right: 20px;
  }

  h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    word-break: break-all;
    color: #666666;
  }
}
// 我的产品样式
.pro_list {
  padding: 20px;
  box-sizing: border-box;
  // height: 345px;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    // border-top: 1px solid #e8e8e8;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 16px 20px 20px 20px;
    width: 210px;
    height: 110px;
    background: #fafafa;
    cursor: pointer;
    border-radius: 8px;
    > div {
      // display: flex;
      // align-items: center;
      .cpName {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        letter-spacing: 1px;
        margin-bottom: 10px;
      }
      .type {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-bottom: 17px;
      }
      .bg_img {
        width: 18px;
        height: 10px;
        background: url("../../../assets/img/nextstep.png") no-repeat;
        background-size: 100% 100%;
      }
      .name {
        margin-right: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        letter-spacing: 1px;
      }
      .status {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #f59a23;
        line-height: 22px;
      }
    }

    .btn_view {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #c70009;
      line-height: 22px;
    }
  }
  > div:hover .cpName {
    color: #c70009;
  }
  > div:hover .bg_img {
    background: url("../../../assets/img/nextstep1.png") no-repeat;
    background-size: 100% 100%;
  }
  > div:last-child {
    // border-bottom: 1px solid #e8e8e8;
  }
}
// 、、、、、、、、、我的产品样式ending、、、、、、、、、、、、、、、、、、、、、、
// 新手指导 start
/* 遮罩层 */
#pop {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.dialog_1 {
  position: absolute;
  top: 78px;
  left: 12px;
  z-index: 2;
}
.xx_kk {
  width: 225px;
  height: 130px;
  border: 2px dashed #fff;
  border-radius: 4px;
}
.img_dialog {
  height: 150px;
  // transform: rotate(-48deg);
  transform: translateX(135px) rotate(-40deg);
}
.submit_dialog {
  transform: translate(98px, -12px);
  width: 230px;
  height: 110px;
  border: 2px dashed #fff;
  border-radius: 4px;
  padding: 5px 8px;
  background-color: #dbdbdb;
  box-sizing: border-box;
  > div {
    line-height: 30px;
    color: #75818d;
  }
  .btn_dialog {
    text-align: right;
    margin-top: 4px;
    .btn__1 {
      color: #fff;
      border: 1px solid #fff;
      line-height: 20px;
      padding: 2px 8px;
    }
  }
}
.dialog_2 {
  position: absolute;
  top: 78px;
  left: 248px;
  z-index: 2;
}
.dialog_3 {
  position: absolute;
  top: -302px;
  left: -8px;
  z-index: 2;
  .submit_dialog {
    height: 140px;
    transform: translate(-407px, 143px);
  }
  img {
    transform: translate(-122px, 173px) rotate(-50deg);
  }
  .xx_kk {
    width: 518px;
    height: 528px;
  }
}
// 、、、、、、、、、新手指导结束、、、、、、、、、、、、、、、、
/deep/.el-collapse-item__header {
  background: #fafafa;
  border-radius: 8px 8px 0px 0px;
}
</style>