<template>

  <div>
    <h3>乐享数科用户注册协议</h3>
    <p>欢迎访问乐享数科供应链金融科技服务平台！</p>
    <p>
      乐享数科供应链金融科技服务平台（网址：http://www.lxdigits.com，及其已经开
      发及将来不时开发的移动终端软件与微信公众账号，以下称“本平台”）是乐享数
      科有限公司开发运营的为用户提供信息浏览、金融服务等各项服务的金融服务平台，
      其所有权和运作权归属于“乐享数科”所有。本平台提供的服务将完全按照其发布
      的《乐享数科用户注册协议》（以下简称“本协议”）中服务条款和操作规则严格
      执行。为了更好地提供服务，本平台在此特别提示用户关注涉及免除或限制责任的
      条款、权利许可和信息使用的条款、法律适用和争议解决条款等。<strong>其中，免除或者
        限制责任条款等重要内容将以加粗形式提示用户注意，请用户重点阅读。用户应审
        慎阅读并选择接受或不接受本协议，一经点击“同意”、“接受”、“确认”的行
        为即视为用户已充分理解本协议各条款内容，并接受本协议的所有条款且完成签署
        本协议，同意作为本协议的一方当事人接受本协议的法律约束力。</strong></p>
    <p class="title">
      <strong> 1.服务确认和接纳</strong>
    </p>
    <p>
      1.1 本平台的用户包括一般用户和认证用户。如无特别说明，本协议中“用户”是
      指一般用户及认证用户。</p>
    <p> 1.2 在使用本平台服务前，用户必须先在本平台进行注册，成为一般用户。一旦成
      功注册成为一般用户，即可通过本平台的用户账户（包括子账户，下同）及密码
      （包括用户设置的账户密码、Ukey、手机动态短信验证码，下同）进入本平台查看
      本平台的产品和服务。一般用户注册后可申请身份认证，并在认证通过后通过本平台
      申请或使用本平台金融服务产品。</p>
    <p>
      1.3 本协议内容包括本协议全部条款及本平台已经发布的或将来可能发布的各类业
      务规则和指引。所有业务规则和指引为本协议不可分割的一部分，与本协议正文具
      有同等法律效力。
    </p>
    <p>
      1.4 本协议的效力范围及于本平台的一切产品和服务，用户在享受乐享数科提供的
      任何单项或多项服务时，应当接受本协议的约束。
    </p>
    <p>
      1.5 用户可能因使用本平台的产品和服务而与本平台、其他用户达成其他协议或合
      意。如该协议或合意的内容与本协议的内容存在冲突的，以该协议或合意约定的内
      容为准。
    </p>
    <p class="title">
      <strong> 2. 本平台服务</strong>
    </p>
    <p>
      2.1 乐享数科通过本平台为用户提供相关服务，包括但不限于:用户查看金融服务
      产品信息、向客户提供技术支持和解决方案，具体详情以本平台当时提供的服务内
      容为准。本平台服务的部分内容需要用户根据本平台要求完成身份认证，未进行身
      份认证的一般用户将无法使用该部分服务。因未能完成身份认证而无法享受本平台
      服务造成的损失，本平台不承担任何责任。</p>
    <p>
      2.2 一般用户可以浏览相关产品功能项目、查看产品说明、使用本平台金融服务说
      明或本协议约定的其他服务等，经过身份认证的用户可以使用本平台提供的金融服
      务产品。
    </p>
    <p>
      2.3 用户认证申请
    </p>
    <p>
      (1)认证申请人注册成为本平台一般用户后，可申请身份认证，并在认证通过后成
      为认证用户。本平台提供的服务中，如要求实名操作或涉及交易风险的服务，仅有
      认证用户可使用。
    </p>
    <p>
      (2)一般用户根据本平台的业务规则和指引，填写完毕用户认证申请资料，上传完
      毕全部用户认证申请文件。
    </p>
    <p>
      (3)一般用户在本平台上成功提交认证申请信息后，应根据本平台届时公示的认证
      要求，在指定的时间内，根据具体情况，将有关纸质资料送达本平台或在线上传电
      子版影像资料。一般用户提交纸质材料或电子版影像资料后，本平台仍有权根据独
      立判断要求用户补充资料。
    </p>
    <p>
      2.4 用户认证审核
    </p>
    <p>
      本平台对一般用户提交的认证申请进行审核。本平台的审核包括对一般用户在本平
      台上提交的用户认证申请资料及一般用户另行提交的纸质材料或电子版影像资料进
      行审核。不论用户认证是否通过，本平台均无义务对用户提交的纸质材料或电子版
      影像资料退还。
    </p>
    <p>
      2.5 用户认证成功或失败
    </p>
    <p>
      一般用户可以登录本平台后，查询用户认证申请审核结果。审核不通过的认证申请，
      即认证失败。用户有权再次申请认证。
    </p>
    <p>
      2.6 本平台将对用户在平台上的所有操作进行记录，不论该操作之目的最终是否实
      现。用户可以通过用户账户查询用户账户名下的交易记录。用户理解并同意如因本
      协议约定的不可抗力免责等原因导致用户通过本平台查询的信息与实际交易结果与
      本平台记录存在任何不一致，应以本平台根据实际交易结果提供的书面记录为准。
    </p>
    <p class="title">
      <strong> 3. 用户的陈述和保证</strong>
    </p>
    <p>在本协议签署之日,用户做出如下陈述和保证，且下述陈述和保证在本协议有效期
      内始终有效。</p>
    <p>3.1 用户为机构时适用：用户承诺，其为合法成立并有效存续的主体，能够独立承
      担法律责任，已为签署本协议履行了必要的项目批准、核准或备案手续，并能够履
      行其在本协议项下的义务。</p>
    <p>3.2 用户为自然人时适用：用户承诺，其具有完全民事行为能力,可以独立实施民事
      法律行为和承担法律责任。</p>
    <p>3.3 用户向本平台提供的必须是真实、最新、有效及完整的资料（包括真实的身份
      证明材料及必要信息），并且同意授予本平台基于提供金融服务产品的目的，对其
      提供的资料及数据信息拥有免费的使用权利。</p>
    <p>3.4 用户承诺，将维持并更新注册的用户资料，确保其为真实、最新、有效及完整
      的资料。如因未及时更新基本资料，导致本平台无法提供或提供时发生任何错误的
      后果应由客户自行承担，本平台亦不承担任何责任。</p>
    <p>3.5 用户承诺并同意，若提供任何错误、虚假、过时或不完整的资料，或者本平台
      依其独立判断怀疑资料为错误、虚假、过时或不完整的，本平台有权暂停或终止用
      户在本平台的注册账号，并拒绝提供平台服务的部分或全部功能。在此情况下，本
      平台不承担任何责任，用户同意承担因此所产生的直接或间接的任何支出或损失。</p>
    <p>3.6 用户承诺绝不为任何非法目的或以任何非法方式使用本平台，并承诺遵守中国
      相关法律、法规及一切使用互联网之国际惯例，遵守所有与本平台金融服务产品有
      关的网络协议、规则和程序。</p>
    <p>3.7 用户承诺，由于用户违反本协议，或违反通过援引并入本协议并成为本协议一
      部分的文件，或由于用户使用本平台服务违反了任何法律或侵犯了第三方的合法权
      利而导致任何第三方向本平台提出任何补偿申请或要求（包括律师费），用户应对
      本平台给予全额补偿并使之不受损害。</p>
    <p>3.8 用户同意并保证不得利用本平台的服务从事侵害他人权益或违法之行为，若有
      违反者应负所有法律责任。上述行为包括但不限于：</p>
    <p>(a)反对宪法所确定的基本原则，危害国家安全、泄漏国家秘密、颠覆国家政权、破
      坏国家统一的；</p>
    <p>(b)侵害他人名誉、隐私权、商业秘密、商标权、著作权、专利权、其他知识产权及
      其他权益；</p>
    <p>(c)违反依法律或合约所应负之保密义务；</p>
    <p>(d)冒用他人名义使用本平台服务；</p>
    <p>(e)从事任何不法交易行为，如贩卖枪支、毒品、禁药、盗版软件或其他违禁物；</p>
    <p>(f)提供赌博资讯或以任何方式引诱他人参与赌博；</p>
    <p>(g)涉嫌洗钱、套现或进行传销活动的；</p>
    <p>(h)从事任何可能含有电脑病毒或是可能侵害本平台服务系統、资料等行为；</p>
    <p>(i)利用本平台服务系统进行可能对互联网或移动网正常运转造成不利影响之行为；</p>
    <p>(j)侵犯本平台的商业利益，包括但不限于发布非经本平台许可的商业广告；</p>
    <p>(k)利用本平台服务上传、展示或传播虚假的、骚扰性的、中伤他人的、辱骂性的、
      恐吓性的、庸俗淫秽的或其他任何非法的信息资料；</p>
    <p>(l)使用本平台提供的服务或其他电子邮件转发服务发出垃圾邮件或其他可能违反本
      协议的内容；</p>
    <p>(m)制造虚假身份、发布虚假信息等误导、欺骗他人，或违背本平台平台页面公布之
      活动规则进行虚假交易;
    </p>
    <p>(n)对本平台上的任何数据作商业性利用，以复制、传播等任何方式使用本平台平台
      上展示的资料；</p>
    <p>(o)其他本平台有正当理由认为不适当之行为。</p>
    <p class="title">
      <strong>4. 用户的权利和义务</strong>
    </p>
    <p>4.1 用户有权根据本平台的业务规则和指引，申请用户身份认证。</p>
    <p>4.2 用户有权使用其在本平台的账户，登录本平台，并根据本平台金融服务产品的
      业务规则和指引，使用本平台的服务。</p>
    <p>4.3 用户有权根据自身独立判断，停止使用本平台的服务，并根据本平台服务业务
      规则和指引，注销用户账户。</p>
    <p>4.4 用户应不时关注并遵守本平台不时公布或修改的各类合法规则规定。</p>
    <p class="title">
      <strong>5. 本平台的权利和义务</strong>
    </p>
    <p>5.1 用户须遵守法律法规或本协议约定的，否则，本平台有权作出独立判断并采取
      暂停或关闭用户账户等措施。</p>
    <p>5.2 本平台服务内容可能涉及某些服务由第三方所有、控制和运营的其他平台（以
      下称“第三方平台”），含有访问第三方平台的链接进行提供，本平台有权与其他
      第三方机构合作，对本平台的服务内容和服务模式进行调整，并在本平台上公告，
      无需另行单独通知用户。</p>
    <p><strong>5.3 本平台可能会通过与第三方进行合作来为用户提供或推荐相关服务，本平台对
        所有第三方平台的隐私保护措施和平台上信息的真实性和有效性不负任何责任。用
        户应按照第三方平台的相关用户协议与规则使用第三方平台，而不是按照本协议。
        第三方平台的内容、产品、广告和其他任何信息均由用户自行判断真实性和可靠性，
        并承担风险，而与本平台无关。</strong></p>
    <p class="title">
      <strong>6. 账户安全与管理</strong>
    </p>
    <p>6.1 用户了解并同意，确保其在本平台上的用户账户及密码的安全性与保密性是用
      户的责任。用户将对利用该用户账户及密码所进行的一切操作行为及言论承担全部
      法律责任，并同意以下事项：
    </p>
    <p>(1)用户在任何情况下不对其他人透露账户及密码，亦不可使用其他任何人的账户或
      密码。因黑客、病毒或用户的保管疏忽等非本平台原因导致用户的账户或密码遭他
      人非法使用的，本平台不承担任何责任。</p>
    <p>(2)本平台通过用户的账户及密码来识别用户的指令，用户确认，使用用户账户和密
      码、账户和验证码或扫码等方式登录后在本平台的一切行为（包括但不限于使用本
      平台的服务或产品功能所作出的一切操作行为）均代表用户本人。用户账户操作所
      产生的电子信息记录均为用户行为的有效凭据，并由用户本人承担由此产生的全部
      责任。</p>
    <p><strong>(3)冒用他人账户及密码的，本平台及其合法授权主体保留追究实际使用人连带责任
        的权利。</strong></p>
    <p><strong>6.2 用户账户和密码仅限于用户本人使用，用户不得以任何形式赠与、出借、出租、
        售卖或以其他方式许可他人使用，如果发现或有合理理由认为使用者并非账户初始
        注册人或同一账户和密码在同一时间内被多人同时登录使用，本平台有权暂停、终
        止或限制该用户访问直至取消该用户的用户资格，并不予任何赔偿。</strong></p>
    <p>6.3 用户丢失账号或遗忘密码、密码被盗，可遵照本平台的相关要求找回账号或密
      码。用户理解并认可，密码找回必须提供与系统记录资料一致的完全、正确的注册
      信息，本平台无法识别申诉人是否系账号真正有权使用者，否则本平台有权本着为
      用户保密的原则不予告知。</p>
    <p>6.4 用户如发现有第三人冒用或盗用用户账户及密码，或其他任何未经合法授权的
      情形，应立即以有效方式通知本平台，要求本平台暂停相关服务，否则由此产生的
      一切责任由用户自身承担。同时，用户理解本平台对用户的请求采取行动需要合理
      期限，在此之前，本平台对第三人使用该服务所导致的损失不承担任何责任。</p>
    <p>6.5 用户决定不再使用用户账户时，仅能向本平台申请注销本人的账户，经本平台
      审核同意后可正式注销用户账户。注销成功后，账号记录、功能等无法恢复或提供。</p>
    <p>6.6 本平台有权基于单方独立判断，在其认为可能发生危害交易安全等情形时，不
      经通知而先行暂停、中断或终止向用户提供本协议项下的全部或部分用户服务，且
      无需对用户或任何第三方承担任何责任。用户同意，用户账户的暂停、中断或终止
      不代表用户责任的终止，用户仍应对使用本平台服务期间的行为承担可能的违约或
      损害赔偿责任，同时本平台仍可保有用户的相关信息。</p>
    <p class="title">
      <strong>7. 责任限制及免责条款</strong>
    </p>
    <p><strong>7.1 本平台不保证平台用户上传信息的真实性、充分性、可靠性、准确性、完整性
        和有效性，用户依赖于其独立判断进行交易，并对其做出的判断承担全部责任。</strong></p>
    <p>7.2 基于互联网的特殊性，本平台不保证本平台服务不会中断，也不保证本平台服
      务的及时性和安全性。系统因相关状况无法正常运作，使用户无法使用任何本平台
      服务或使用任何本平台服务时受到任何影响时，本平台对用户或第三方不负任何责
      任，前述状况包括但不限于：</p>
    <p>(1)本平台或本平台系统停机维护期间。</p>
    <p>(2)电信设备出现故障不能进行数据传输的。</p>
    <p>(3)由于黑客攻击、网络供应商技术调整或故障、平台升级、银行方面、政府有权
      机构的问题等原因而造成的本平台服务中断或延迟。</p>
    <p>(4) 因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成
      本平台系统障碍不能执行业务的。</p>
    <p class="title">
      <strong>8. 费用承担</strong>
    </p>
    <p>用户在本平台进行交易而与其他方签订协议的，其他方有权按照协议的约定向用户
      收取相关费用。</p>
    <p class="title">
      <strong>9. 违约责任</strong>
    </p>
    <p>9.1 在本协议有效期内，如用户违反法律、法规或本协议中的任何内容，侵犯了其
      他用户或其他第三方合法权利或造成上述主体的损失的，该用户应当依法承担赔偿
      责任。</p>
    <p><strong>9.2 在本协议有效期内，如用户违反法律、法规或本协议中的任何内容，本平台有
        权根据该用户的违约情况，对其采取限制或暂停使用本平台服务、暂停或关闭用户
        账户等措施，有权向有关监管机构汇报该用户的违约情况。本平台采取上述措施，
        并不排除本平台依法追究该用户违约责任的权利。</strong></p>
    <p class="title">
      <strong>10. 保密及知识产权</strong>
    </p>
    <p>10.1 用户应当对因履行本协议或在本协议期间以任何方式获得的或知悉的关于本网
      站商务、财务、技术、产品的信息、其他用户资料或其他标明保密的文件或信息的
      内容（以下简称“保密资料”）保守秘密，未经本平台、其他用户事先书面同意，
      不得向本协议以外的任何第三方披露。用户可仅为本协议目的向其确有知悉必要的
      雇员披露对方提供的保密资料，但同时须指示并确保其雇员遵守本条规定的保密及
      不披露义务。双方应仅为本协议目的而复制和使用保密资料。</p>
    <p>10.2 本平台上所有内容，包括但不限于著作、图片、档案、资讯、资料、平台架构、
      平台画面的安排、网页设计，均由本平台或其他权利人依法拥有其知识产权，包括
      但不限于商标权、专利权、著作权、商业秘密等。</p>
    <p>10.3 非经本平台或其他权利人书面同意，任何人不得擅自使用、修改、复制、公开
      传播、改变、散布、发行或公开发表本平台程序或内容。
    </p>
    <p class="title">
      <strong>11. 个人信息保护和授权</strong>
    </p>
    <p>11.1 本平台对于用户提供的、本平台经用户同意后自行收集的、经认证的以下用户
      信息（以下统称“用户信息”）旨在保障用户顺利、有效、安全地进行使用平台服
      务的目的。</p>
    <p>11.2 除用户向本平台提供的资料外，用户同意本平台以下列方式收集并核对用户的
      信息：</p>
    <p><strong>(a)本平台根据用户在平台上的行为自动追踪关于用户的相关资料。本平台将合理
        利用该相关资料进行本平台用户的人数统计及行为的内部研究，以更好地了用户及
        本平台的其他用户，从而提供更好、更完善的服务。</strong></p>
    <p><strong>(b)其他用户或第三方向本公司发出关于用户在本平台上活动或登录事项的信息，
        本公司可以将这些资料收集、整理在用户的专门档案中。</strong></p>
    <p>11.3 本平台将按照本协议予以保护、使用或者披露以下用户信息：</p>
    <p>⑴<strong>身份信息</strong>，包括但不限于姓名/名称、证件号码、统一社会信用代码、证件类型、
      住所地、电话号码、电子邮箱地址、生物特征、亲戚朋友、联系人以及其他身份信
      息；</p>
    <p>⑵<strong>信用信息</strong>，包括但不限于用户的征信记录和信用报告；</p>
    <p>⑶<strong>财产信息</strong>，包括但不限于用户企业经营状况、财税信息、房产信息、车辆信息、
      基金、保险、股票、信托、债券等投资理财信息和负债信息等；</p>
    <p>⑷<strong>行政机关、司法机关留存的任何信息</strong>，包括但不限于户籍信息、用户作为法定代
      表人或实际控制人的企业工商信息、诉讼信息、执行信息和违法犯罪信息等；</p>
    <p>⑸<strong>用户访问本平台及其相关网站时，或使用本平台服务时的操作日志</strong>，包括但不限于
      用户计算机 IP 地址、设备标识符、硬件型号、操作系统版本、用户位置以及与本网
      站服务相关的日志信息；
    </p>
    <p>⑹<strong>用户在申请、使用本平台服务时所提供、形成的任何数据和信息</strong>，包括但不限于用
      户及其提供的关联方的数据和信息。</p>
    <p><strong>11.4 用户特别授权及同意，出于研究和开发平台新功能及本平台整体给用户提供更
        便捷的服务及运营安全的目的，本平台有权将用户信息在本平台成员（指本平台及其
        控股 51%以上的子公司，以下简称“子公司”；本平台、子公司单独或者共同持股
        20%以上的公司，或者持股不足 20%但处于最大股东地位的公司；本平台、子公司
        下属的事业单位法人或者社会团体法人）范围内共享。</strong></p>
    <p>11.5 用户特别授权及同意，本平台有权向本平台成员转让与本平台有关的全部或部
      分权利和义务。未经本平台事先书面同意，用户不得转让其在本协议项下的任何权
      利和义务。</p>
    <p><strong>11.6 为更好的向用户提供本平台服务，用户授权并且同意本平台通过合法方式从任
        何第三方收集用户的额外信息，且同意本平台将查询收集的用户信息进行保存、整
        理、加工，用于如下用途：</strong></p>
    <p><strong>⑴评价用户信用情况、核实用户信息的真实性，以更好地掌握用户情况；</strong></p>
    <p><strong>⑵创建数据分析模型、分析平台的使用率、改善平台的内容和产品推广形式，并使
        内容、设计和服务更能符合用户的要求，为用户度身订造本平台服务，对用户个人
        信息的合理使用能使用户在使用本平台服务时得到更为顺利、有效、安全的体验；</strong></p>
    <p><strong>⑶为解决用户与本平台或该用户在本平台平台发生关系的其他用户之间的争议，根
        据有关法律要求向司法机关和政府部门提供用户的个人信息，必要时，满足相关的
        法律程序时，可以提供给法律顾问、催收公司等机构；</strong></p>
    <p><strong>(4)为向用户传递与用户相关的信息而使用用户的联系方式与用户联络，该信息包
        括但不限于行政管理方面的通知、产品提供、有关用户使用本平台的服务、通讯以
        及针对性的广告；</strong>
    </p>
    <p><strong>(5)预防或组织非法活动，确保用户在本平台进行安全交易；</strong></p>
    <p><strong>(6)用户无权要求本平台提供其他用户的个人信息，除非司法机关或政府部门根据
        法律法规要求本公司提供。</strong></p>
    <p><strong>(7)其他经用户同意的用途。</strong></p>
    <p>11.7 根据有关监管规定，用户同意本平台将用户及用户在本平台服务有关的信息向
      监管部门、行业自律组织和监管信息系统报送，供监管部门使用和用户授权查询使用。</p>
    <p>11.8 本平台可能会通过与第三方进行合作来为用户提供或推荐相关服务，在此情况
      下，用户特别授权及同意，如该第三方同意承担与本平台同等的保护用户隐私的责
      任，本平台可将用户的个人信息和资料提供给该第三方。</p>

    <p class="title">
      <strong>12. 其他条款</strong>
    </p>
    <p>12.1 协议的生效</p>
    <p>本协议自用户以勾选或网上签署并成功注册成为本平台一般用户之日起生效，除非
      本平台终止本服务协议或者用户丧失本平台用户资格，否则本服务协议始终有效。
      本服务协议终止并不免除用户根据本服务协议或其他有关协议、规则所应承担的义
      务和责任。</p>
    <p>12.2 协议变更</p>
    <p>⑴根据国家法律法规、政策调整、技术条件、产品功能变化及平台运营需要，本网
      站有权对本协议条款不时地进行修订，修订内容构成本协议的组成部分。本协议更
      新后，本平台会发布更新版本，一旦公示即生效，并自动代替原来的协议，无须另
      行单独通知用户。</p>
    <p>⑵用户可随时登录查阅最新协议；用户有义务不时关注并阅读最新版的协议及平台
      公告。如用户不同意更新后的协议，可以且应立即停止接受本平台依据本协议提供
      的服务或向本平台提起注销账户的申请；如用户继续使用本平台提供的服务的，即
      视为用户同意且认可更新后的协议。本平台建议用户在使用本平台金融服务之前阅
      读本协议及本平台的公告。如果本协议中任何一条被视为废止、无效或因任何理由
      不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。</p>
    <p>12.3 合同解除</p>
    <p>(1)除本协议另有规定，本协议在以下情形终止：</p>
    <p>(a)平台用户注销或被关闭用户账户之日起本协议终止；</p>
    <p>(b)平台用户被依法受理破产申请或出现解散事由之日起，本协议终止。</p>
    <p>(2)协议终止不得影响各方在协议终止前享有的权利和承担的义务。</p>
    <p>(3)本协议第 10 条、第 11 条不因协议的终止而无效。</p>
    <p>12.4 通知</p>
    <p>本平台对于用户的通知及任何其他的协议、告示或其他关于用户使用用户账户及服
      务的通知，用户同意本平台可通过本平台公告、电子邮件、手机短信、无线通讯装
      置等电子方式或常规的信件传递等方式进行，该等通知于发送之日视为已送达收件
      人。因信息传输或用户向本平台提供的是错误、虚假、过时或不完整的资料等原因
      导致用户未在前述通知发出当日收到该等通知的，本平台不承担责任。</p>
    <p>12.5 争议解决</p>
    <p>本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用
      之有效法律（但不包括其冲突法规则）。如发生本协议与适用之法律相抵触时，则
      这些条款将完全按法律规定重新解释，而其它有效条款继续有效。如缔约方就本协
      议内容或其执行发生任何争议，双方应尽力友好协商解决；<strong>协商不成时，任何一方
        均应向石家庄仲裁委员会申请仲裁，各方均同意仲裁庭适用简易程序审理此案。</strong></p>
    <p>12.6 本服务协议部分条款被认定无效时，不影响本服务协议其他条款的效力。
    </p>
    <p>12.7 本协议版本于 2022 年 5 月 16 日发布。</p>
    <p>12.8 乐享数科有限公司对本服务协议享有最终的解释权。</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang='less' scoped>
h3 {
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
}

.abow_dialog,.abow_dialog1 {
  letter-spacing: 1px;

  p {
    font-size: 14px;
    text-indent: 2em;
    line-height: 30px;
    text-align: justify;
  }
  .title {
    // font-size: 18px;
    font-weight: bold;
    margin-top: 30px;
  }
}
</style>