import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {//定义数据
    getUser: '',//用户登录手机号
    companyStatus: '',//企业认证状态
    bankStatus:'',//银行状态
    companyName:'',//公司名称
    companyRole:'',//企業角色
    userType:'',//用户角色
    backStatus:''//gyl/cpiaoju
  },
  mutations: {//定义方法-全局同步方法, 调用方法,this.$store.commit("xxx")
    getNum(state,val) {//存登录手机号
      state.getUser = val;
      window.sessionStorage.setItem('getUser', JSON.parse(val));
    },
    getcompanyStatus(state, val) {//存企业状态
      state.companyStatus = val;
      window.sessionStorage.setItem('companyStatus', JSON.stringify(val));
    },
    getcompanyName(state, val) {//存公司名称
      state.companyName = val;
      window.sessionStorage.setItem('companyName', JSON.stringify(val));
    },
    getbankStatus(state, val) {//存银行状态
      state.bankStatus = val;
      window.sessionStorage.setItem('bankStatus', JSON.stringify(val));
    },
    getcompanyRole(state, val) {//存公司角色
      state.companyRole = val;
      window.sessionStorage.setItem('companyRole', JSON.stringify(val));
    },
    getuserType(state, val) {//存操作员/管理员
      state.userType = val;
      window.sessionStorage.setItem('userType', JSON.stringify(val));
    },
    getbackStatus(state, val) {//存gyl/cpiaoju
      state.backStatus = val;
      window.sessionStorage.setItem('backStatus', JSON.stringify(val));
    },
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  actions: {// 异步方法 调用方法,this.$store.dispatch("xxx")

  },
  getters: {// Vuex属性计算,在视图里面当变量使用

  },
  modules: {// 模块化注册
  }
})
