<template>
  <div>
    <h3>隐私政策</h3>
    <p style="text-indent: none;"><strong>导言</strong></p>
    <p>乐享数科有限公司（以下也称“我们”）深知个人信息对您的重要性，因此我们非常重视保护您的隐私和个人信息，我们亦将本
      《乐享数科隐私政策》中的内容以高度审慎的义务对待和处理。本政策与您所使用的我们的产品与/或服务息息相关，您在下载、安装、
      启动、浏览、注册、登录、使用、卸载、退出我们的产品与/或服务（以下统称“使用我们的产品与/或服务”或“使用产品与/或服务”）
      时，我们将按照本政策的约定处理和保护您的个人信息，因此我们希望您能够仔细阅读、充分理解本政策的全文，并在需要时，按照本
      政策的指引，作出您认为适当的选择。本政策之中涉及的相关技术词汇，我们尽量以通俗易懂的表述向您解释，以便于您理解。<strong> 您应当
        在仔细阅读、充分理解本《乐享数科隐私政策》后选择是否同意本政策的内容以及是否同意使用我们的产品与/或服务，如果您不同意本
        政策的内容，将可能导致我们的产品与/或服务无法正常运行，或者无法达到我们期待的服务效果，或者无法达到您期待的个人信息保护
        状态，对此，您应立即停止访问/使用我们的产品与/或服务。您使用或继续使用我们提供的产品与/或服务的行为，或者您在使用或继续
        使用我们提供的产品与/或服务的行为之前没有以任何明示的方式向我们表达异议，都表示您充分理解和同意本《乐享数科隐私政策》（包
        括更新版本）的全部内容。</strong></p>
    <p>
      乐享数科有限公司已经以字体加粗或其他合理方式提示您重点阅读本政策中与您的权益（可能）存在重大关系的条款，请您留意
      重点查阅。您应当在充分阅读、明确理解本政策前述加粗条款并同意本政策全部条款的有效性的前提下使用我们的产品与/或服务，您不
      会以乐享数科未尽到合理提示义务为由而声称本政策中的条款非法或无效。
    </p>
    <p>如您在阅读本《乐享数科隐私政策》过程中有任何疑惑或其他相关事宜的，我们为您提供了多种反馈渠道，具体请见本政策“如
      何联系我们”章节，我们会尽快为您作出解答。</p>
    <p class="title">
      <strong> 一、本《乐享数科隐私政策》适用范围、相关词语涵义</strong>
    </p>
    <p class="title">
      <strong> (一)本《乐享数科隐私政策》适用范围</strong>
    </p>
    <p>
      <strong>本《乐享数科隐私政策》适用于乐享数科有限公司提供的网站、APP、SDKs、插件、组件、工具以及不断创新研发的产品及服务,</strong>
      当您使用我们的任何产品与/或服务时，本《乐享数科隐私政策》即适用，但如该产品与/或服务单独设置了隐私条款/隐私协议的，单独
      的隐私条款/隐私协议优先适用；单独的隐私条款/隐私协议未提及的内容，适用本政策。但请您注意，本《乐享数科隐私政策》不适用
      于以下情况：
    </p>
    <p><strong>• 本《乐享数科隐私政策》仅适用于我们所收集的您的个人信息，并不适用于任何第三方对您的个人信息的收集，以及任何第三
        方提供的服务或第三方的信息使用规则，我们对任何第三方收集、储存和使用的您个人信息的行为在法律允许的范围内亦不承担任何责
        任；</strong></p>
    <p><strong>• 其他非乐享数科向您提供的产品与/或服务内容。</strong></p>
    <p><strong>（二）相关词语涵义</strong></p>
    <p>• 我们的产品与/或服务：包括但不限于我们提供的软件、网站、服务以及包含的相关产品或服务功能。 我们需要特别提醒您的
      是：由于我们的产品和服务较多，为您提供的产品和服务内容也有所不同，本《乐享数科隐私政策》为乐享数科有限公司统一适用的一
      般性隐私条款，本政策约定的用户权利和我们提供的相关信息安全保护措施均适用于我们的所有产品和服务；本政策所述之“我们的产
      品与/或服务”以及我们收集的您个人信息的类型和对应的使用、处理规则等可能会因您使用的具体的产品/服务（包括客户端类型、软
      件版本等）而有所不同，具体以您实际使用的产品/服务的实际情况为准。针对我们的某些特定产品/服务，我们还将制定特定隐私条款
      或隐私协议，以便更具体地向您阐明我们的隐私规则和保护措施等内容。</p>
    <p>• 个人信息（出自于 GB/T 35273-2020《信息安全技术 个人信息安全规范》）：指以电子或者其他方式记录的能够单独或者与
      其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。我们实际具体收集的个人信息种类以下文描述为准。</p>
    <p>• 个人敏感信息（出自于 GB/T 35273-2020《信息安全技术 个人信息安全规范》）：指一旦泄露、非法提供或滥用可能危害人
      身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。我们实际具体收集的个人敏感信息种类以下文描述
      为准。相比其他个人信息，个人敏感信息可能对您的个人权益影响更大。我们在此善意的提醒您需在谨慎考虑后再向我们提供您的个人
      敏感信息。</p>
    <p>• 授权同意：是指您对您的个人信息进行特定处理做出明确授权的行为，包括通过积极的行为作出授权（即明示同意），也包括
      通过消极的不作为而作出授权。（出自于 GB/T35273-2020 《信息安全技术 个人信息安全规范》）请您知悉，“授权同意”在本政策
      中有时亦表述为“授权”、“同意”。</p>
    <p>• 匿名化：指通过对个人信息的技术处理，使得个人信息主体无法被识别或者被关联到，且处理后的信息不能被复原的过程。（出
      自于 GB/T 35273-2020《信息安全技术 个人信息安全规范》）</p>
    <p>• 去标识化：指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别或关联到个人信息主体的过程。（出自
      于 GB/T 35273-2020《信息安全技术 个人信息安全规范》）</p>
    <p><strong>二、我们如何收集和使用您的个人信息</strong></p>
    <p>您在使用我们的产品与/或服务时，我们需要/可能需要收集和使用您的一些个人信息，<strong>我们收集和使用的您的个人信息类型包括
        两种：第一种：我们产品与/或服务的核心业务功能所必需的信息：此类信息为产品与/或服务正常运行的必备信息，您须授权我们收集。
        如您拒绝提供，您将无法正常使用我们的产品与/或服务；第二种：我们产品与/或服务的附加业务功能可能需要收集的信息：此信息为
        非核心业务功能所需的信息，您可以选择是否授权我们收集。如您拒绝提供，将导致附加业务功能无法实现或无法达到我们拟达到的效
        果，但不影响您对核心业务功能的正常使用。</strong></p>
    <p>我们需要特别提醒您的是：由于我们的产品和服务较多，为您提供的内容也不同，因此核心业务功能（包括其收集的您的个人信
      息类型）也会因产品/服务的内容不同而有所区别，具体以产品/服务实际提供为准。除此之外，您理解并同意，我们希望提供给您的产
      品和服务是完善的，所以我们会不断改进我们的产品和服务，包括技术，这意味着随着产品和服务的迭代、拓展、升级等，我们可能会
      经常推出新的业务功能或重新划分业务功能，并可能需要收集新的个人信息或变更个人信息使用目的或方式。如果某一需要收集您的个
      人信息的功能或产品/服务未能在本《乐享数科隐私政策》中予以说明的，我们会通过更新本政策、启动或浏览界面提示、弹窗、网站公
      告、站内信、短信等一种或多种方式另行向您说明该信息收集的目的、内容、使用方式和范围，并为您提供自主选择同意的方式，且在
      征得您同意后收集。在此过程中，如果您对相关事宜有任何疑惑的，可以通过文末提供的方式联系我们，我们会尽快为您作出解答。 您
      需授权我们收集和使用您的个人信息的功能/场景包括：</p>
    <p><strong>(一)服务内容展示/浏览/播放功能</strong></p>
    <p>我们的产品与/或服务为您提供音频、即时通信等服务内容的展示、浏览、播放功能，在此过程中，我们会根据您使用我们产品与
      /或服务的具体操作收集您的一些信息，包括如下个人信息：</p>
    <p>（1）设备信息：包括设备 MAC 地址、唯一设备识别码、登录 IP 地址、设备型号、设备名称、设备标识、浏览器类型和设置、
      语言设置、操作系统和应用程序版本、接入网络的方式、网络质量数据、移动网络信息（包括运营商名称）、产品版本号、设备所在位
      置相关信息（包括您授权我们获取的地理位置信息）。为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限并根
      据您的授权获取相关信息。</p>
    <p>（2）日志信息：当您使用我们的产品与/或服务时，我们会自动收集您的个人上网记录，并作为有关操作日志、服务日志保存，
      包括您的浏览记录、点赞/评论/互动记录、收藏/关注、播放记录、播放时长、访问日期和时间。 我们收集这些信息是为了向您提供我们
      最核心的服务内容展示/播放/下载服务，如您拒绝提供上述信息和/或权限将可能导致您无法使用我们的产品与服务。请您知悉，单独的
      设备信息、日志信息等无法识别您的身份信息。</p>
    <p><strong>(二) 账号注册/登录功能</strong></p>
    <p>当您使用账号注册功能时，我们会收集由您主动提供给我们的一些单独或者结合识别您实名身份的信息，包括：手机号码、验证
      码匹配结果，并创建密码。 您的密码将以加密形式进行自动存储、传输、验证，我们不会以明文方式存储、传输、验证您的密码。您在
      保管、输入、使用您的密码时，应当对物理环境、电子环境审慎评估，以防密码外泄。 我们收集这些信息是用以完成注册程序、为您持
      续稳定提供专属于注册用户的产品与/或服务，并保护您的账号安全。您应知悉，手机号码和验证码匹配结果属于您的个人敏感信息，我
      们收集该类信息是为了满足相关法律法规的要求，如您拒绝提供可能导致您无法使用我们的此功能，请您谨慎考虑后再提供。同时，您
      也可以在注册时或后续使用过程中填写或补充您的昵称、头像，如您未填写或后续删除、变更此类信息的，可能会影响（包括无法获取
      到）我们基于为您提供优质、便捷、个性化的服务，但不会影响注册及会员功能（如开通）的正常使用。</p>
    <p>我们收集这些信息是用于为您提供账号登录服务以及保障您的账号安全，防范安全风险。如您拒绝授权此类信息的，您将无法使
      用第三方平台的账号登录我们平台，但不影响我们为您提供的其他产品和服务的正常使用。</p>
    <p><strong>(三) 身份认证功能</strong></p>
    <p>当您使用我们的产品与/或服务中的身份认证功能或服务时，为满足相关法律规定及监管要求、确保用户身份真实性、实现反欺诈
      等风险控制、保障系统和服务安全或提供服务之需要，我们将需要收集您的真实身份信息（可能包括姓名、身份证相关信息、面部特征
      信息（静态或动态）、手机号码）（个人敏感信息）以完成实名认证。为实现实名认证的目的，您同意我们可以自行或委托第三方向有
      关机构（如个人征信机构、政府机构等）提供、查询、核对您的前述身份信息。在您实名认证成功后，如无正当事由，实名信息将无法
      修改，如确需修改，请您与我们联系解决。</p>
    <p>同时，为协助您快速完成实名认证、风控核验等，对于您曾经在我们的产品与/或服务中提供的相关实名认证信息（包括姓名、身
      份证相关信息、手机号码）（个人敏感信息），您同意我们可以将前述信息的全部或部分自动预填于其他需要您实名认证的页面，您知
      悉并认可，我们仅协助填写，并未直接传输，只有在您点击下一步操作后才由您自行提供至对应服务系统。</p>
    <p><strong>(四) 信息制作、发布、交流互动功能</strong></p>
    <p>当您在我们的部分产品与/或服务中使用上传、发布、论坛发帖、平台内交流互动、点赞、评论等服务/功能时，除注册登录账户
      之外，您可能会主动提供相关图文、互动信息（包括但不限于帖子、点赞/评论/交流互动信息）。我们会自动收集您的上述信息，并展
      示您的昵称、头像、发布的信息内容。请您知悉，您发布的信息中可能包含他人的个人信息，请您务必取得他人的合法授权，避免非法
      泄露他人的个人信息。如您不同意我们收集上述信息，您将无法使用我们的信息发布功能，但不影响您使用我们为您提供的其他产品和
      服务。 另外，乐享数科有限公司会与您之间会通过消息推送、站内信、短信等方式进行互动。您知悉并同意，对于您在使用产品与/或
      服务的过程中提供的您的一种或多种联系方式（例如：电子邮箱、联系电话、联系地址），我们在运营中可能会向其中的一种或多种发
      送多类通知，用于用户消息告知、身份验证、安全验证等用途。</p>
    <p><strong>(五) 客服、其他用户响应功能</strong></p>
    <p>当您与我们的客服互动时或使用其他用户响应功能时（包括：在线提交意见反馈、与在线/人工客服沟通、提出我们的产品与/或
      服务的售后申请、行使您的相关个人信息控制权、其他客户投诉和需求），为了您的账号与系统安全，我们可能需要您先行提供账号信
      息，并与您之前的个人信息相匹配以验证您的用户身份。在您使用客服或其他用户响应功能时，我们可能还会需要收集您的如下个人敏
      感信息：联系方式（您与我们联系时使用的电话号码/电子邮箱或您向我们主动提供的其他联系方式）、您与我们的沟通信息（包括文字
      /图片/音视频/通话记录形式）、与您需求相关联的其他必要信息。我们收集这些信息是为了调查事实与帮助您解决问题，如您拒绝提供
      可能导致您无法使用我们的客服等用户响应机制。</p>
    <p><strong>(六) 产品安全保障功能</strong></p>
    <p>我们需要收集您的一些信息来保障您使用我们的产品与/或服务时的账号与系统安全，并协助提升我们的产品与/服务的安全性和
      可靠性，以防产生任何危害用户、乐享数科有限公司、社会的行为，包括您的如下个人信息：账号登录地、个人常用设备信息（例如：
      硬件型号、设备 MAC 地址、IMEI、IMSI）、登录 IP 地址、产品版本号、语言模式、浏览记录、网络使用习惯、服务故障信息，以及个
      人敏感信息：会员实名认证信息。我们会根据上述信息来综合判断您账号、账户及交易风险、进行身份验证、客户服务、检测及防范安
      全事件、诈骗监测、存档和备份用途，并依法采取必要的记录、审计、分析、处置措施，一旦我们检测出存在或疑似存在账号安全风险
      时，我们会使用相关信息进行安全验证与风险排除，确保我们向您提供的产品和服务的安全性，以用来保障您的权益不受侵害。同时，
      当发生账号或系统安全问题时，我们会收集这些信息来优化我们的产品和服务。</p>
    <p>此外，为确保您设备操作环境的安全以及提供我们的产品与/或服务所必需，防止恶意程序和反作弊，我们会在您同意本《乐享数
      科隐私政策》后获取您设备上已安装或正在运行的必要的应用/软件列表信息（包括应用/软件来源、应用/软件总体运行情况、崩溃情况、
      使用频率等）。请您知悉，单独的应用/软件列表信息无法识别您的特定身份。</p>
    <p><strong>您可自主选择提供个人信息的情形包括：</strong></p>
    <p><strong>(一) 基于设备相册权限的附加业务功能</strong></p>
    <p>当您上传/更新账号头像、图文/发布、评论、发帖、意见反馈、视频拍摄、扫码登录、人脸识别等业务功能时，我们将需要获取
      您的设备相册权限，并收集您提供的图片内容信息（个人信息）。如您拒绝提供的仅会使您无法使用该功能，但并不影响您正常使用产
      品与/或服务的其他功能。相册权限是您设备上的一项设置，您可以通过设备设置页面进行管理。同时，您也可以随时通过手机上的相关
      功能设置开启/取消该权限，例如：“手机端乐享数科 App”的路径为：系统设置-更多应用-权限-应用权限管理-乐享数科权限。您开启
      该权限即视为您授权我们可以访问、获取、收集、使用您的该个人信息；当您取消该授权后，我们将不再收集该信息，也无法再为您提
      供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的个人
      信息的处理、存储。</p>
    <p><strong>(二) 基于设备相机权限的附加业务功能
      </strong></p>
    <p>当您拍摄功能、上传/更新账号头像、扫一扫（用于授权设备登录、扫码下载）、图文/发布、评论等业务功能时，我们将需要获
      取您的设备相机权限，并收集您提供的图片内容信息（个人信息）。如您拒绝提供的仅会使您无法使用该功能，但并不影响您正常使用
      产品与/或服务的其他功能。相机权限是您设备上的一项设置，您可以通过设备设置页面进行管理。同时，您也可以随时通过手机上的相
      关功能设置开启/取消该权限，例如：“手机端乐享数科 App”的路径为：系统设置-更多应用-权限-应用权限管理-乐享数科权限。您开
      启该权限即视为您授权我们可以访问、获取、收集、使用您的该个人信息；当您取消该授权后，我们将不再收集该信息，也无法再为您
      提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的个
      人信息的处理、存储。</p>
    <p><strong>(三) 基于设备麦克风权限的附加业务功能</strong></p>
    <p>当您使用即时通信聊天与在线客服沟通等业务功能时，我们将需要获取您的设备麦克风权限，并收集您的语音信息、语音交互信
      息（个人信息）。如您拒绝提供的仅会使您无法使用该功能，但并不影响您正常使用产品与/或服务的其他功能。麦克风权限是您设备上
      的一项设置，您可以通过设备设置页面进行管理。同时，您也可以随时通过手机上的相关功能设置开启/取消该权限，例如：“手机端乐
      享数科 App”的路径为：系统设置-更多应用-权限-应用权限管理-乐享数科权限。您开启该权限即视为您授权我们可以访问、获取、收
      集、使用您的该个人信息；但当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律
      的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的个人信息的处理、存储。</p>
    <p><strong>(四) 基于设备通讯录权限的附加业务功能</strong></p>
    <p>当您使用短信邀请业务中向您展示通讯录好友、邀请您的好友发送短信使用我们的产品与/或服务、向您认识的人分享内容/产品
      等业务功能时，我们将需要获取您的设备通讯录权限，并收集相关通讯录信息（包括联系人姓名及对应的联系方式、通讯录好友列表）。
      通讯录信息属于个人敏感信息，请您谨慎开启通讯录权限，我们亦会高度对待和谨慎处理您的通讯录信息（包括高强度加密保护等）。
      如您拒绝提供的仅会使您无法使用该功能，但并不影响您正常使用产品与/或服务的其他功能。通讯录权限是您设备上的一项设置，您可
      以通过设备设置页面进行管理。同时，您也可以随时通过手机上的相关功能设置开启/取消该权限，例如：“手机端乐享数科 App”的路
      径为：系统设置-更多应用-权限-应用权限管理-乐享数科权限。您开启该权限即视为您授权我们可以访问、获取、收集、使用您的该个人
      信息；但当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个
      人信息，否则您的取消行为不会影响我们基于您之前的授权进行的个人信息的处理、存储。</p>
    <p><strong>(五) 基于位置权限的附加业务功能</strong></p>
    <p>在我们的特定服务中，我们需要申请获取您的位置权限，并在您授权后收集您的位置信息（我们仅收集您当时所处的位置信息，
      但不会将您各时段的位置信息进行结合以判断您的行踪轨迹）来提供服务，前述特定服务场景包括：在获取天气信息和聊天中共享您的
      位置,并且使用收藏功能服务内容等。位置权限是您设备上的一项设置，您可以通过设备设置页面进行管理。同时，您也可以随时通过手
      机上的相关功能设置开启/取消该权限，例如：“手机端乐享数科 App”的路径为：系统设置-更多应用-权限-应用权限管理-乐享数科权
      限。您开启该权限即视为您授权我们可以访问、获取、收集、使用您的该个人信息；但当您取消该授权后，我们将不再收集该信息，也
      无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授
      权进行的个人信息的处理、存储。</p>
    <p><strong>(六) 其他</strong></p>
    <p>如上文所述，如果某一需要收集您的个人信息的功能或产品/服务未能在本《乐享数科隐私政策》中予以说明的，或者我们超出了
      与收集您的个人信息时所声称的目的及具有直接或合理关联范围的，我们将在收集和使用您的个人信息前，通过更新本《乐享数科隐私
      政策》、页面提示、弹窗、消息通知、网站公告或其他便于您获知的方式另行向您说明，并为您提供自主选择同意的方式，且在征得您
      明示同意后收集和使用。请您知悉，如信息无法单独或结合其他信息识别或关联到您的个人身份的，其不属于法律意义上您的个人信息；
      当您的信息可以单独或结合其他信息识别到您的个人身份时、我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合
      使用时，这些信息在结合使用期间，将作为您的个人信息按照本政策处理与保护。</p>
    <p><strong>例外情形</strong></p>
    <p><strong>另外，您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意：</strong></p>
    <p>• 与我们履行法律法规规定的义务相关的；</p>
    <p>• 与国家安全、国防安全直接相关的；</p>
    <p>• 与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p>• 与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
    <p>• 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p>
    <p>• 所收集的信息是您自行向社会公开的或者是从合法公开的渠道（如合法的新闻报道、政府信息公开等渠道）中收集到的；</p>
    <p>• 根据与您签订和履行相关协议或其他书面文件所必需的；</p>
    <p>• 用于维护我们的产品与/或服务的安全稳定运行所必需的，例如：发现、处置产品与/或服务的故障；</p>
    <p>• 有权机关的要求、法律法规等规定的其他情形。</p>
    <p><strong>三、我们如何使用 Cookie 和同类技术</strong></p>
    <p><strong>（一）关于 Cookie 和同类技术</strong></p>
    <p>Cookie 是包含字符串的小文件，在您登入和使用网站或其他网络内容时发送、存放在您的计算机、移动设备或其他装置内（通常
      经过加密）。Cookie 同类技术是可用于与 Cookie 类似用途的其他技术，例如：Web Beacon、Proxy、嵌入式脚本等。</p>
    <p>目前，我们主要使用 Cookie 收集您的个人信息。您知悉并同意，随着技术的发展和我们产品和服务的进一步完善，我们也可能会
      使用 Cookie 同类技术。</p>
    <p><strong>（二）我们如何使用 Cookie 和同类技术</strong></p>
    <p><strong>在您使用我们的产品与/或服务时，我们可能会使用 Cookie 和同类技术收集您的一些个人信息，包括：您访问网站的习惯、您的
        浏览信息、您的登录信息，Cookie 和同类技术收集该类信息是为了您使用我们的产品与/或服务的必要、简化您重复操作的步骤（如注
        册、登录）、便于您查看使用历史（如视频观看历史）、向您提供更切合您个人需要的服务内容和您可能更感兴趣的内容、保护您的信
        息和账号安全性、改善我们的产品和服务等。我们承诺仅在本《乐享数科隐私政策》所述目的范围内使用 Cookie 和同类技术。</strong></p>
    <p>如果您的浏览器允许，您可以通过您的浏览器的设置以管理、（部分/全部）拒绝 Cookie 与/或同类技术；或删除已经储存在您的
      计算机、移动设备或其他装置内的 Cookie 与/或同类技术，从而实现我们无法全部或部分追踪您的个人信息。您如需详细了解如何更改
      浏览器设置，请具体查看您使用的浏览器的相关设置页面。您理解并知悉：我们的某些产品/服务只能通过使用 Cookie 或同类技术才可
      得到实现，如您拒绝使用或删除的，您可能将无法正常使用我们的相关产品与/或服务或无法通过我们的产品与/或服务获得最佳的服务
      体验，同时也可能会对您的信息保护和账号安全性造成一定的影响。</p>
    <p><strong>四、我们如何共享、转让、公开披露您的个人信息</strong></p>
    <p><strong>（一）共享</strong></p>
    <p><strong>我们非常重视保护您的个人信息。目前，未经您的授权同意，我们不会与平台以外的第三方公司、组织和个人共享您的个人信息，除非
        存在以下一种或多种情形：</strong></p>
    <p>1.您自行分享或主动提出共享要求或事先已征得您的授权同意而进行的共享： 我们会应您的合法合理的共享要求或在事先征得您
      授权同意后而共享您的信息，您也可主动基于乐享数科的产品与服务的分享功能与第三人（包括不特定对象）共享您的个人信息或其他
      信息，但因您的共享行为而导致的信息泄露、被使用等情况，与平台无关，您需要自行承担相应的法律责任。
    </p>
    <p>2.为实现我们的服务/功能所必需进行的共享： 您知悉并同意，在某些情况下，为向您提供我们的服务或实现我们的产品与/或服
      务的业务功能或基于合理商业习惯，我们必须共享您的个人信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务。</p>
    <p><strong>3.与乐享数科有限公司内部的必要、合理共享；包括：</strong></p>
    <p><strong>（1）您知悉并同意，为便于我们基于平台统一的账号体系向您提供一致化服务、个性化推荐、识别账号异常、保护平台或用户或
        公众的人身财产安全免遭侵害以及便于您进行统一管理，您知悉并同意，您的相关个人信息可能会在平台的产品内部进行必要共享，但
        请您放心，我们只会在必要和合理范围内共享信息且仅在前述目的范围内进行处理，如果我们需要共享您的个人敏感信息或关联公司需
        要改变个人信息的使用或处理目的时，我们会将再次征求您的授权同意；</strong></p>
    <p><strong>（2）您理解并同意，为了使您在我们的产品与/或服务体验，您知悉并同意，乐享数科注册账号信息、设备信息、相关数据等反
        映您观影习惯和爱好的信息可能会与我们的关联公司共享。我们会努力确保关联公司采取不低于本政策所承诺的安全保障措施的程度以
        及遵守我们提出的其他安全保护要求来保障您的个人信息安全。</strong></p>
    <p>4.与部分业务合作伙伴的必要共享: 您理解并同意，为了必要/合理的业务的顺利开展、满足您的要求、履行我们在相关用户协议
      或本隐私政策中的义务和行使我们的权利或遵守法律规定等目的，我们可能需要向部分业务合作伙伴共享您的部分信息。但我们承诺我
      们只会共享您的必要个人信息，如果我们共享您的个人敏感信息或改变个人信息的使用及处理目的，我们会将再次征求您的授权同意。
      我们的业务合作伙伴包括：</p>
    <p>（1）为我们的产品与/或服务提供功能支持的服务提供商：包括：提供支付服务的支付机构、提供一键登录服务的运营商、第三
      方配送公司和其他服务提供商，我们共享信息的目的是可以实现我们产品与/或服务的功能，让您可以正常使用；</p>
    <p>（2）我们平台的第三方：为实现您购买产品或服务的需求（包括享受咨询服务），我们会将您的订单信息与交易相关的信息共享
      给我们平台的第三方；</p>
    <p>（3）提供数据服务（包括数据统计、数据分析）的合作伙伴：为维护/改进我们的产品/服务、为您提供更好的内容，我们可能会
      与提供该服务的指定合作伙伴共享您的相关信息（包括：终端型号、设备信息、您所在的城市及其他相关信息），为了您的信息安全，
      我们目前仅与已签署严格数据安全保密协议的合作伙伴进行合作，且这些公司必须遵守我们的数据隐私和安全要求。除非得到您的同意，
      我们不会与其共享您的个人身份信息；</p>
    <p>（4）第三方 SDK 类服务商：我们产品中可能会包含第三方 SDK 或其他类似的应用程序，如您在我们平台上使用这类由第三方提
      供的服务时，您同意将由其直接收集和处理您的信息（如以嵌入代码、插件等形式），前述服务商收集和处理信息等行为遵守其自身的
      隐私条款，而不适用于本政策。但我们也会努力审查该第三方的业务准入资质并努力要求该服务商的合法合规性与安全性。为了最大程
      度保障您的信息安全，我们强烈建议您在使用任何第三方 SDK 类服务前先行查看其隐私条款。为保障您的合法权益，如您发现这等 SDK
      或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与我们取得联系；</p>
    <p>5.其他情形：</p>
    <p><strong>（1）共享的是去标识化的信息，且接受该信息共享的第三方无法重新识别信息主体身份；</strong></p>
    <p><strong>（2）为了保护您、我们的其他用户或员工、平台或社会公共利益、财产或安全（例如：欺诈或信用风险等）免遭损害而与第三方
        的共享；</strong></p>
    <p><strong>（3）某些情况下，只有共享您的个人信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务，或处理您与他人的
        纠纷或争议；</strong></p>
    <p><strong>（4）依据您与我们签署的相关协议（例如：在线协议、平台规则等）或法律文件而共享的；</strong></p>
    <p><strong>（5）符合您与其他第三人之间的有关约定的；</strong></p>
    <p><strong>（6）基于合理商业习惯而共享的，例如：与第三方共享联合营销活动中的中奖/获胜者等信息，以便其能及时向您发放奖品/礼品
        等；我们接受尽调时等；</strong></p>
    <p><strong>（7） 基于学术研究而使用，例如：以学术研究为目的而与学术机构共享我们使用您的相关信息集合形成的间接用户画像、去标
        识化或匿名化处理后的分析/统计类信息（未经您的同意，我们不会与其共享可以识别您身份的个人信息）；学术研究机构基于公共利益
        开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行匿名化处理的；</strong></p>
    <p><strong>（8）有权机关的要求、诉讼争议需要、法律法规等规定的其他需要共享的情形。
      </strong></p>
    <p><strong>您可以基于平台与第三人（包括不特定对象）共享您的个人信息或其他信息，但因您的共享行为而导致的信息泄露、被使用等情
        况，与平台无关，您需要自行承担相应的法律责任。</strong></p>
    <p>我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享必要的个人信息。在我们与业务合作伙伴
      共享信息时，我们会以多种方式保护您的个人信息，包括但不限于：（1）如具备上述合理事由且需要您授权同意的，我们会在共享前向
      您告知共享的信息的目的、信息接收方的类型以及可能产生的后果（如涉及共享、转让您的个人敏感信息的，我们还会向您告知涉及的
      个人敏感信息类型、信息接收方的身份和数据安全能力）并在取得您授权同意后再共享；（2）在合作协议层面，我们会严格要求合作伙
      伴的信息保护义务与责任，为此，我们的法律部门要求业务合作伙伴在合作前需与平台签署关于数据安全的保护协议，协议严格约定了
      业务合作伙伴的用户信息保密义务，包括信息的保管、使用和流转等均应满足我们的管控要求，接受我们的审核、监督等，一旦有任何
      违反，我们将立即要求业务合作伙伴停止相关行为，并采取或要求业务合作伙伴采取相应补救措施控制或消除您的风险，必要时业务合
      作伙伴将须承担相应法律责任；（3）此外，我们将对个人信息的共享、转让活动事先进行个人信息安全影响评估，并通过输出形式、流
      转、使用等采取有效的技术保护措施等。请您知悉，即使已经取得您的授权同意，我们也仅会出于合法、正当、必要、特定、明确的目
      的共享您的个人信息，并尽量对共享内容中的个人信息进行匿名化处理。您理解并知悉，匿名化处理后的信息无法识别或关联到您，已
      不属于法律意义上的个人信息，其处理、使用、共享、转让无需征得您的授权同意。</p>
    <p><strong>（二）转让</strong></p>
    <p>转让是指将个人信息控制权向其他公司、组织或个人转移的过程。原则上我们不会将您的个人信息转让，但以下情况除外：</p>
    <p>• 您自行提出要求的；</p>
    <p>• 事先已征得您的明确授权同意；</p>
    <p>• 如我们进行兼并、收购、重组、分立、破产、资产转让或类似的交易，而您的个人信息有可能作为此类交易的一部分而被转移，
      我们会要求新持有人继续遵守和履行该《乐享数科隐私政策》的全部内容（包括使用目的、使用规则、安全保护措施等），否则我们将
      要求其重新获取您的明示授权同意；</p>
    <p>• 法律法规等规定的其他情形。</p>
    <p>如具备上述事由确需转让的，我们会在转让前向您告知转让的信息的目的、类型（如涉及您的个人敏感信息的，我们还会向您告
      知涉及的敏感信息的内容），并在征得您的授权同意后再转让，但法律法规另有规定的或本政策另有约定的除外。</p>
    <p><strong>（三）公开披露</strong></p>
    <p>公开披露是指向社会或不特定人群发布信息的行为。除了因需要对违规账号、欺诈行为等进行处罚公告、公布中奖/获胜者等名单
      时脱敏展示相关信息等必要事宜而进行的必要披露外，我们不会对您的个人信息进行公开披露，如因经法律授权或具备合理事由确需公
      开披露的，我们会在公开披露前向您告知公开披露的信息的目的、类型（如涉及您的个人敏感信息的，我们还会向您告知涉及的敏感信
      息的内容），并在征得您的明示同意后再公开披露，但法律法规另有规定的或本政策另有约定的除外。</p>
    <p>对于公开披露的您的个人信息，我们会充分重视风险，在收到公开披露申请后第一时间且审慎审查其正当性、合理性、合法性，
      并在公开披露时和公开披露后采取不低于本《乐享数科隐私政策》约定的个人信息安全保护措施和手段的程度对其进行保护。</p>
    <p>请您知悉，即使已经取得您的授权同意，我们也仅会出于合法、正当、必要、特定、明确的目的公开披露您的个人信息，并尽量
      对公开披露内容中的个人信息进行匿名化处理。</p>
    <p><strong>（四）委托处理</strong></p>
    <p>为了提升信息处理效率，降低信息处理成本或提高信息处理准确性，我们可能会委托有能力的我们的关联公司或其他专业机构代
      表我们来处理用户信息，但我们会通过书面协议、现场审计等方式要求受托公司遵守严格的保密义务及采取有效的保密措施，禁止其将
      这些信息用于未经用户授权的用途。委托关系解除后，受托公司将不再保存您的个人信息。</p>
    <p>例外情形</p>
    <p>根据法律法规等规定，在下述情况下，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</p>
    <p>• 与我们履行法律法规规定的义务相关的；</p>
    <p>• 与国家安全、国防安全直接相关的；</p>
    <p>• 与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p>• 与犯罪侦查、起诉、审判和判决执行等直接相关的；或根据法律法规的要求、行政机关或公检法等有权机关的要求的；</p>
    <p>• 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p>
    <p>• 个人信息是您自行向社会公开的或者是我们从合法公开披露的渠道（如合法的新闻报道、政府信息公开等渠道）中收集到的；</p>
    <p>• 根据与您签订和履行相关协议或其他书面文件所必需的；</p>
    <p>• 法律法规等规定的其他情形。</p>
    <p><strong>（五）共享、转让、公开披露的相关责任</strong></p>
    <p>我们将严格按照本隐私政策的约定及相关法律法规的要求执行对个人信息的共享、转让、公开披露的操作，如因我们的过错原因
      导致您的合法权益遭受损害的，我们愿意就我们的过错在法律规定的范围内向您承担相应的损害赔偿责任。如我们违反了法律法规或与
      您的约定向第三方共享、转让或公开披露个人信息的，您可以要求我们删除，我们将在收到您的请求后立即停止共享、转让、公开披露
      的信息，并通知第三方及时删除</p>
    <p><strong>五、您对个人信息享有的控制权</strong></p>
    <p><strong>您对我们产品与/或服务中的您的个人信息享有多种方式的控制权，包括：您可以访问、更正/修改、删除您的个人信息，也可以
        撤回之前作出的对您个人信息的同意，同时您也可以注销您的乐享数科账号。为便于您行使您的上述控制权，我们在产品的相关功能页
        面为您提供了操作指引和操作设置，您可以自行进行操作，如您在操作过程中有疑惑或困难的可以通过文末的方式联系我们来进行控制，
        我们会及时为您处理。</strong></p>
    <p><strong>（一）访问权</strong></p>
    <p><strong>您可以在我们的产品与/或服务中查询或访问您的相关个人信息，包括：</strong></p>
    <p><strong>· 账号信息：您可以通过相关产品页面随时登录您的个人账号，随时查询或访问您的账号中的个人资料信息，包括：头像、昵称
        等，例如：“头像/昵称”信息在“手机端乐享数科 App”中的访问路径为：我的—个人中心；</strong></p>
    <p><strong>· 其他信息：如您在此访问过程中遇到操作问题的或如需获取其他前述无法获知的个人信息内容，您可通过文末提供的方式联系
        我们，我们将在核实您的身份后在合理期限内向您提供，但法律法规另有规定的或本政策另有约定的除外。</strong></p>
    <p><strong>（二）更正/修改权</strong></p>
    <p><strong>您可以在我们的产品与/或服务中更正/修改您的相关个人信息。为便于您行使您的上述权利，我们为您提供了在线自行更正/修改
        和向我们提出更正/修改申请两种方式。 对于您的部分个人信息，我们在产品的相关功能页面为您提供了操作指引和操作设置，您可以
        直接进行更正/修改，例如：“头像/昵称”信息在“手机端乐享数科 App”中的访问路径为：我的—个人中心；</strong></p>
    <p><strong>对于您在行使上述权利过程中遇到的困难，或其他可能未/无法向您提供在线自行更正/修改权限的， 经对您的身份进行验证，且
        更正不影响信息的客观性和准确性的情况下，您有权对错误或不完整的信息作出更正或修改，或在特定情况下，尤其是数据错误时，通
        过我们公布的反馈与报错等措施将您的更正/修改申请提交给我们，要求我们更正或修改您的数据，但法律法规另有规定的除外。但出于
        安全性和身份识别的考虑，您可能无法修改注册时提交的某些初始注册信息。</strong></p>
    <p><strong>(三) 个人信息副本获取权</strong></p>
    <p><strong>如您需要您的个人信息的副本，您可以通过本《乐享数科隐私政策》文末提供的方式联系我们，在核实您的身份后，我们将向您
        提供您在我们的服务中的个人信息副本（包括基本资料、身份信息），但法律法规另有规定的或本政策另有约定的除外。</strong></p>
    <p><strong>（四）提前获知产品与/或服务停止运营权</strong></p>
    <p><strong>我们将持续为您提供优质服务，若因特殊原因导致我们的部分或全部产品与/或服务被迫停止运营，我们将提前在显著位置或向您
        发送推送消息或以其他方式通知您，并将停止对您个人信息的收集，同时在超出法律法规规定的必需且最短期限后，我们将会对所持有
        的您的个人信息进行删除或匿名化处理。</strong></p>
    <p><strong>（五）帮助反馈权</strong></p>
    <p><strong>我们为您提供了多种反馈渠道，具体请见本政策“如何联系我们”章节。</strong></p>
    <p><strong>例外情形</strong></p>
    <p><strong>请您理解并知悉，根据法律法规等规定，在下述情况下，我们可能不会响应您的关于查询权、更正/修改权、个人信息副本获取权
        等的请求：
      </strong></p>
    <p><strong>· 与我们履行法律法规规定的义务相关的；</strong></p>
    <p><strong>· 与国家安全、国防安全直接相关的；</strong></p>
    <p><strong>· 与公共安全、公共卫生、重大公共利益直接相关的；</strong></p>
    <p><strong>· 与刑事侦查、起诉、审判和执行判决等直接相关的；</strong></p>
    <p><strong>· 我们有充分证据表明您存在主观恶意或滥用权利的；</strong></p>
    <p><strong>· 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</strong></p>
    <p><strong>· 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</strong></p>
    <p><strong>· 涉及商业秘密的；</strong></p>
    <p>• 法律法规等规定的其他情形。</p>
    <p><strong>六、我们如何存储和保护您的个人信息</strong></p>
    <p><strong>（一）个人信息的存储</strong></p>
    <p>• 存储地点：我们依照法律法规的规定，将您的个人信息存储于中华人民共和国境内。目前我们不存在跨境存储您的个人信息或
      向境外提供个人信息的场景。如需跨境存储或向境外提供个人信息的，我们会单独向您明确告知（包括出境的目的、接收方、使用方式
      与范围、使用内容、安全保障措施、安全风险等）并再次征得您的授权同意，并严格要求接收方按照本《乐享数科隐私政策》以及法律
      法规相关要求来处理您的个人信息；</p>
    <p>• 存储期限：我们在为提供我们的产品和服务之目的所必需且最短的期间内保留您的个人信息，例如：当您使用我们的注册登录
      及会员功能时，我们需要收集您的手机号码，且在您提供后并在您使用该功能期间，我们需要持续为您保留您的手机号码，以向您正常
      提供该功能、保障您的账号和系统安全。在超出上述存储期限后，我们会对您的个人信息进行删除或匿名化处理。</p>
    <p><strong>(二) 个人信息的保护措施</strong></p>
    <p>我们一直都极为重视保护用户的个人信息安全，为此我们采用了符合行业标准的安全技术措施及组织和管理措施等保护措施以最
      大程度降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。包括：</p>
    <p>• 数据安全技术措施</p>
    <p>（1）数据安全收集方面，通过个人信息安全影响评估确认数据收集的合法性、正当性和必要性，识别并以清晰、准确的方式告知
      用户以征得同意，同时对用户的授权同意采集行为进行日志记录；采用敏感识别工具对收集的用户数据进行分类分级，针对不同级别的
      数据设置不同等级的安全策略；采用技术措施对收集或产生数据的来源方进行身份识别，确保数据来源的准确性和抗抵赖性；</p>
    <p>（2）数据安全传输方面，使用安全通道传输个人信息，并通过合适的加密算法进行安全加密、脱敏处理，确保数据传输过程中个
      人信息的秘密性和完整性；</p>
    <p>（3）数据安全存储方面，采用数据分类分级管理制度，针对数据分类分级结果采取不同的信息存储策略；个人敏感信息需加密存
      储，确保数据安全使用规范能够落实到位；针对存储有个人信息的数据库加强权限控制与安全审计；定期对个人信息进行备份与恢复，
      确保个人信息在存储使用过程中的完整性；</p>
    <p>（4）数据安全处理方面，依照使用场景和安全需求对个人信息进行脱敏处理，例如：在前端显示个人敏感信息之前需在服务端完
      成脱敏处理；开发、测试环境严禁使用真实用户信息；实施严格的数据权限控制机制，采取多重身份认证、网络/数据隔离等技术措施，
      确保能够对处理个人信息的行为进行有效监控，避免数据被违规访问和未授权使用；</p>
    <p>（5）数据安全销毁方面，根据法律法规要求和业务实际需求设定个人信息存储的最小可用期限，对到期的数据通过安全删除技术
      进行处理，确保已销毁数据不可恢复，技术手段包括但不限于数据彻底清除方案、磁盘销毁、物理销毁等；</p>
    <p>（6）建立完整的审计机制，对数据生命周期的全流程进行监控与审计，防止您的个人信息遭遇未经授权的访问、公开披露、使用、
      修改、人为或意外的损坏或丢失；</p>
    <p>（7）其他实现数据安全保护的措施。</p>
    <p>• 数据安全组织和管理措施</p>
    <p>（1）成立专门的个人信息保护责任部门，建立相关的内控管理流程，对可能接触到您的信息的工作人员采取最小化权限原则；
    </p>
    <p>（2）建立数据分类分级制度、业务数据安全使用规范、数据合作规范等管理体系，保障您的信息在收集、传输、使用、存储、转
      移、销毁等环节的处置满足法律法规相关规范和安全要求；</p>
    <p>（3）定期组织员工参加安全与隐私保护相关培训并要求完成规定的考核，加强员工对于保护个人信息重要性的认知；</p>
    <p>（4）严格执行等级保护各项制度，通过持续迭代优化安全架构、全面提升合规框架下的安全技术支撑能力，我们的互联网业务系
      统、大数据平台、私有云平台、后台支撑系统等信息系统全部通过国家公安部信息安全等级测评备案，并获得了由公安部颁发的“国家
      信息安全等级保护三级认证”；</p>
    <p>（5）信息安全管理和用户隐私保护能力达到国际权威认证标准的要求，并已获得了相应的认证，包括 ISO/IEC 27001:2013(信息
      安全管理体系)和 ISO/IEC 29151:2017（个人身份信息保护实践指南）认证，构建了完善的 ISMS 信息安全管理体系，并基于 ISO 29151
      扩展了个人身份信息保护的最佳实践；</p>
    <p>（6）其他可行的安全组织和管理措施。</p>
    <p>• 合作协议条款保证</p>
    <p>（1）在我们从第三方间接收集您的个人信息前，我们会明确以书面形式（如合作协议、承诺书）要求该第三方在已经取得您明示
      同意后收集以及处理（如共享等）个人信息，在书面协议层面要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反
      行为的，我们会明确要求该第三方承担相应法律责任；</p>
    <p>（2）在我们向业务合作伙伴共享您的个人信息前，我们会严格要求合作伙伴的信息保护义务与责任，并要求业务合作伙伴在合作
      前签署关于数据安全的保护协议，一旦业务合作伙伴有任何违反协议的行为，将须承担相应法律责任；</p>
    <p>（3）其他合作协议中明确约定的内容。</p>
    <p>（4）如不幸发生个人信息安全事件的，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采
      取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、电话、短信、推
      送通知、信件等一种或多种方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照
      监管部门要求，主动上报个人信息安全事件的处置情况；</p>
    <p>（5）请您知悉并理解，互联网并非绝对安全的环境，我们强烈建议您通过安全方式、使用复杂密码、定期修改密码、不将自己的
      账号密码及相关个人信息披露给他人等方式，协助我们保证您的账号安全。如您发现自己的个人信息泄密，尤其是您的账户或密码发生
      泄露，请您立即根据本隐私政策文末中提供的联系方式联络我们，以便我们采取相应措施来保护您的信息安全。</p>
    <p><strong>七、本《乐享数科隐私政策》的更新</strong></p>
    <p><strong>我们鼓励您在每次使用我们的产品或服务时都查阅我们的《乐享数科隐私政策》。为了给您提供更好的服务，我们会根据产品的更新情
        况及法律法规的相关要求更新本《乐享数科隐私政策》的条款，该更新构成本《乐享数科隐私政策》的一部分。如该更新造成您在本《乐
        享数科隐私政策》下权利的实质减少或重大变更，我们将在本政策生效前通过在显著位置提示或向您发送推送消息或以其他方式通知您，
        若您继续使用我们的服务，即表示您充分阅读、理解并同意受经修订的《乐享数科隐私政策》的约束。为保障您的合法权益，我们建议
        您可以定期在我们平台的设置页面中查看本政策。</strong></p>
    <p><strong>上述的“重大变更”包括但不限于：</strong></p>
    <p>• 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息的类型、个人信息的使用方式等；</p>
    <p>• 我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
    <p>• 个人信息共享、转让或公开披露的主要对象发生变化；</p>
    <p>• 您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
    <p>• 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
    <p>• 个人信息安全影响评估报告表明存在高风险时；</p>
    <p>• 其他重要的或可能严重影响您的个人权益的情况发生时。</p>
    <p><strong>九、如何联系我们</strong></p>
    <p><strong>（一）如您对本《乐享数科隐私政策》的执行或使用我们的服务时遇到的与隐私保护相关的事宜有任何问题（包括问题咨询、投诉等），
        我们专门为您提供了多种反馈通道，希望为您提供满意的解决方案：
      </strong></p>
    <p>• 在线客服/其他在线意见反馈通道：您可与我们平台上产品功能页面的在线客服联系或者在线提交意见反馈；</p>
    <p>• 人工客服通道：您可以拨打我们的任何一部客服电话与我们联系；</p>
    <p><strong>(二) 我们会在收到您的意见及建议后，并在验证您的用户身份后的 15 个工作日内或法律法规规定的期限内尽快向您回复，一般情况下，
        我们不会因此对您收取服务费。但是，在以下情形下，您理解并知悉，我们将无法响应您的请求：</strong></p>
    <p>• 与我们履行法律法规规定的义务相关的；</p>
    <p>• 与国家安全、国防安全直接相关的；</p>
    <p>• 与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p>• 与犯罪侦查、起诉和审判等有关的</p>
    <p>• 有充分证据表明您存在主观恶意或滥用权利的；</p>
    <p>• 出于维护您或其他个人的生命、财产等重大权益但又难得到本人授权同意的；</p>
    <p>• 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
    <p>• 涉及商业秘密的；</p>
    <p>• 法律法规等规定的其他情形。</p>
    <p><strong>（三）如果您对我们的回复不满意，或我们对您个人信息的处理方式侵害了您的合法权益，且在您向我们反馈后我们仍拒绝改进的，您
        可以向工商、公安等监管部门进行投诉、举报。</strong></p>
    <p><strong>十、其他</strong></p>
    <p><strong>(一) 本《乐享数科隐私政策》的解释及争议解决均应适用中华人民共和国大陆地区法律。如就本政策的签订、履行等发生任何争议的，
        双方应尽量友好协商解决；协商不成时，任何一方均可向被告住所地享有管辖权的人民法院提起诉讼。</strong></p>
    <p><strong>(二）本《乐享数科隐私政策》的标题仅为方便及阅读而设，并不影响正文其中任何规定的含义或解释。</strong></p>
  </div>
</template>

<script>
export default {};
</script>

<style lang='less' scoped>
h3 {
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
}

.abow_dialog,.abow_dialog1{
  letter-spacing: 1px;

  p {
    font-size: 14px;
    text-indent: 2em;
    line-height: 30px;
    text-align: justify;
  }
  .title {
    // font-size: 18px;
    font-weight: bold;
  }
}
</style>