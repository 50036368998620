import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Work from "../views/Work";
import Solution from "../views/Solution";
import Case from "../views/Case";
import CaseDetail from "../views/CaseDetail";
import News from "../views/News";
import NewsDetail from "../views/NewsDetail";
import AboutUs from "../views/AboutUs";
import ContactUs from "../views/ContactUs";
import WorkLXCX from "../views/WorkLXCX";
import WorkABS from "../views/WorkABS";
import WorkWZG from "../views/WorkWZG";
import WorkRisk from "../views/WorkRisk";
import WorkZTT from "../views/WorkZTT";
import WorkKJ from "../views/WorkKJ";
import WorkYBL from "../views/WorkYBL";
import WorkCSY from "../views/WorkCSY";
import Login from "../views/MSME/Login";
import LoginQt from "../views/MSME/LoginQt";
import Auth from "../views/MSME/auth/Auth";
import Product from "../views/WorkBench/Product";
import MicroCreditLoans from "../views/WorkBench/MicroCreditLoans";
import Ticket from "../views/Ticket/Ticket"
import BankCardBind from "../views/MSME/AccountInfo/bankCard/BankCardBind"
import BankCardAdd from "../views/MSME/AccountInfo/bankCard/BankCardAdd"
import BankCardEdit from "../views/MSME/AccountInfo/bankCard/BankCardEdit"
import BankCardDetail from "../views/MSME/AccountInfo/bankCard/BankCardDetail"
import InOutGoldList from "../views/MSME/AccountInfo/inOutGold/InOutGoldList"
import VirtualBnakList from "../views/MSME/AccountInfo/virtualmanagment/VirtualBnakList"
import VirtualList from "../views/MSME/AccountInfo/virtualmanagment/VirtualList"
import Remittance from "../views/MSME/AccountInfo/virtualmanagment/Remittance"
import OpenVirtual from "../views/MSME/AccountInfo/virtualmanagment/OpenVirtual"
import OpenDetail from "../views/MSME/AccountInfo/virtualmanagment/OpenDetail"
import ClientList from "../views/MSME/AccountInfo/virtualmanagment/ClientList"
// 侧导航
import Aside from "../views/MSME/Aside"
import fi from "element-ui/src/locale/lang/fi";
import el from "element-ui/src/locale/lang/el";

Vue.use(VueRouter)
// const _import = file => () => import('@/views/' + file + '/index.vue')
const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      title: '乐享数科_全方位的供应链金融科技平台',
      content: {
        keywords: '乐享承信,资产证券化,智能风控系统,金融科技解决方案,供应链金融解决方案,乐享数科',
        description: '乐享数科依托云计算、大数据、区块链等先进技术，致力于打造官、产、学、研、金为一体的供应链金融科技平台，是国内领先的供应链金融科技解决方案提供商。'
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: "登录",
    }
  },
  {
    path: '/loginQt',
    name: 'LoginQt',
    component: LoginQt,
    meta: {
      title: "登录",
    }
  },
  {
    path: '/work',
    name: 'Work',
    component: Work,
    meta: {
      title: "乐享承信_乐享云贷_资产证券化_乐享数科业务介绍",
      content: {
        keywords: '乐享承信,资产证券化,智能风控系统,乐享云贷,票据保理,厂商银,金融科技解决方案,供应链金融解决方案',
        description: '乐享数科供应链金融科技平台提供乐享承信、乐享云贷、资产证券、智能风控、票据保理、厂商银等科技服务，为政府、企业、金融机构等提供数字化金融科技解决方案。'
      }
    }
  },
  {
    path: '/solution',
    name: 'Solution',
    component: Solution,
    meta: {
      title: "金融科技解决方案_供应链金融解决方案_乐享数科",
      content: {
        keywords: '供应链金融,互联网+供应链金融,供应链融资,供应链管理,产业链金融,数字供应链金融',
        description: '乐享数科供应链金融科技平台为小微企业、核心机构、政府平台、银行、金融机构提供金融科技解决方案、供应链金融解决方案，推动传统金融与数字科技深度融合。'
      }
    }
  },
  {
    path: '/case',
    name: 'Case',
    component: Case,
    meta: {
      title: "医疗行业供应链融资案例_核心企业供应链融资案例_乐享数科",
      content: {
        keywords: '小微企业供应链融资案例,核心企业融资案例,医疗行业供应链融资案例',
        description: '乐享数科供应链金融科技平台为小微企业、核心机构、政府平台、银行、金融机构提供供应链融资决绝方案的案例介绍。'
      }
    },
  },
  {
    path: '/case/casedetail',
    name: 'CaseDetail',
    component: CaseDetail,
    meta: { title: "经典案例_乐享数科" },
    ChildrenActiveMenu: '/case',
    GuidePath: true,
    activeMenu: '/case'
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      title: "公司动态_金融科技行业最新政策法规_乐享数科新闻中心",
      content: {
        keywords: '乐享数科有限公司动态,金融科技行业最新政策法规,乐享数科新闻中心',
        description: '乐享数科新闻中心为您提供金融科技行业最新政策法规，企业融资最新政策、以及乐享数科有限公司最新动态的资讯内容。'
      }
    }
  },
  {
    path: '/news/newsdetail',
    name: 'NewsDetail',
    component: NewsDetail,
    meta: {
      title: "",
      content: {
        keywords: '',
        description: '11，企业融资最新政策、以及乐享数科有限公司最新动态的资讯内容。'
      }
    }
  },
  {
    path: '/aboutus',
    name: 'AboutUs',
    component: AboutUs,
    meta: {
      title: "乐享数科有限公司简介_乐享数科发展历程_乐享数科企业荣誉",
      content: {
        keywords: '乐享数科有限公司简介,乐享数科',
        description: '乐享数科有限公司2014年成立于北京，为用户提供全方位的供应链金融科技服务方案，是国内领先的供应链金融科技解决方案提供商。'
      }
    }
  },
  {
    path: '/contactus',
    name: 'ContactUs',
    component: ContactUs,
    meta: {
      title: "乐享数科联系方式400-628-7087_乐享数科有限公司地址电话",
      content: {
        keywords: '关于乐享数科,乐享数科联系电话400-628-7087',
        description: '乐享数科有限公司地址：北京市朝阳区东四环中路82号金长安大厦C座5层，联系电话400-628-7087。'
      }
    }
  },
  //业务介绍子页面
  {
    path: '/work/worklxcx',
    name: 'WorkLXCX',
    component: WorkLXCX,
    meta: {
      title: "核心企业融资_全供应链融资_乐享承信",
      content: {
        keywords: '核心企业融资,全供应链融资,供应商线上融资',
        description: '乐享数科供应链金融科技平台，推出乐享承信产品，以电子签名的方式为核心企业提供全供应链融资，为中小企业提供高效便捷的融资新渠道。'
      },
      activeMenu: '/work'
    }
  },
  {
    path: '/work/workabs',
    name: 'WorkABS',
    component: WorkABS,
    meta: {
      title: "票据ABS_ABS供应链融资_企业资产支持证券_乐享ABS",
      content: {
        keywords: '票据ABS,企业资产支持证券,ABS供应链融资',
        description: '乐享数科供应链金融科技平台，推出乐享ABS产品,整合多家原始权益人和多家债务人，提供企业资产支持证券、ABS供应链融资，为持票中小企业实现快速的低成本融资。'
      },
      activeMenu: '/work'
    }
  },
  {
    path: '/work/workwzg',
    name: 'WorkWZG',
    component: WorkWZG,
    meta: {
      title: "微掌柜官网_微掌柜优势_乐享数科微掌柜",
      content: {
        keywords: '微掌柜官网,微掌柜融资,微掌柜优势,乐享数科微掌柜',
        description: '乐享数科微掌柜是为帮助产业链上中小企业解决账期长、回款慢、融资渠道单一、融资门槛高等问题提供的全新解决方案。'
      },
      activeMenu: '/work'
    }
  },
  {
    path: '/work/workrisk',
    name: 'WorkRisk',
    component: WorkRisk,
    meta: {
      title: "银行智能风控系统_银行风控平台_乐享数科",
      content: {
        keywords: '银行智能风控系统,银行风控平台,智能风控解决方案',
        description: '乐享数科供应链金融科技平台，智能风控解决方案将帮助银行建立以数据为基础，风控模型为核心 ，风险策略为决策依据的智能风控体系。'
      },
      activeMenu: '/work'
    }
  },
  {
    path: '/work/workztt',
    name: 'WorkZTT',
    component: WorkZTT,
    meta: {
      title: "银行承兑汇票贴现_电子商票快速贴现_乐享直贴通",
      content: {
        keywords: '银行承兑汇票贴现,电子商票快速贴现,乐享直贴通',
        description: '乐享直贴通提供银行承兑汇票贴现、电子商票快速贴现，为持有核心企业商票的各类中小微企业提供商业承兑汇票解决方案。'
      },
      activeMenu: '/work'
    }
  },
  {
    path: '/work/workybl',
    name: 'WorkYBL',
    component: WorkYBL,
    meta: {
      title: "票据保理融资_应收账款融资_乐享云保理",
      content: {
        keywords: '票据保理融资,应收账款融资,乐享云保理',
        description: '乐享云保理为核心企业提供基于应收账款的融资服务，支持保理业务全流程管控，包含贷前、贷中、贷后的各种业务信息管理及额度、业务、放款、还款等流程审批。'
      },
      activeMenu: '/work'
    }
  },
  {
    path: '/work/workkj',
    name: 'WorkKJ',
    component: WorkKJ,
    meta: {
      title: "票据跨行贴现_银行票据快速贴现_极速贴_乐享数科",
      content: {
        keywords: '票据跨行贴现,银行票据快速贴现,极速贴',
        description: '乐享数科供应链金融科技平台，推出极速贴（银企直贴）为持票企业、财务公司、银行等金融机构提供票据跨行贴现、银行票据快速贴现等票据快速贴现服务。'
      },
      activeMenu: '/work'
    }
  },
  {
    path: '/work/workcsy',
    name: 'WorkCSY',
    component: WorkCSY,
    meta: {
      title: "应收账款票据化_应收账款票据融资_乐享数科",
      content: {
        keywords: '应收账款票据化,应收账款票据融资',
        description: '乐享数科应收账款票据化，规范企业商业信用，为企业体用应收账款票据融资，提高链上资金周转效率，缓解供应链“长尾”端小微企业融资难、融资贵问题。'
      },
      activeMenu: '/work'
    }
  },
  //二期业务-小微企业
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    meta: { title: "乐享数科-个人中心", requireAuth: true }
  },
  //工作台
  {
    path: '/workbench',
    name: 'WorkBench',
    component: () => import('@/views/WorkBench/Aside'),
    meta: { title: "工作台", requireAuth: true },
    children: [
      {
        path: 'product',
        name: 'Product',
        component: Product,
        meta: { title: "产品列表", requireAuth: true }
      },

    ]
  },
  // 产品详情
  {
    path: '/productDetails/microCreditLoans',
    name: 'microCreditLoans',
    component: MicroCreditLoans,
    meta: { title: "小微企业信用贷", requireAuth: true }
  },

  // 侧导航
  {
    path: '/aside',
    name: 'Aside',
    component: Aside,
    meta: { title: "", requireAuth: true },
    children: [
      {
        path: 'bankCardBind',
        name: 'BankCardBind',
        component: BankCardBind,
        meta: { title: "银行卡绑定", requireAuth: true }
      },
      {
        path: 'bankCardBind/bankCardAdd',
        name: 'BankCardAdd',
        component: BankCardAdd,//添加银行卡
        meta: { title: "银行卡绑定", requireAuth: true, ChildrenActiveMenu: '/aside/bankCardBind', GuidePath: true }
      },
      {
        path: 'bankCardBind/bankCardEdit',
        name: 'BankCardEdit',
        component: BankCardEdit,//编辑银行卡
        meta: { title: "银行卡绑定", requireAuth: true, ChildrenActiveMenu: '/aside/bankCardBind', GuidePath: true }
      },
      {
        path: 'bankCardBind/bankCardDetail',
        name: 'BankCardDetail',
        component: BankCardDetail,//银行卡详情
        meta: { title: "银行卡绑定", requireAuth: true, ChildrenActiveMenu: '/aside/bankCardBind', GuidePath: true }
      },
      {
        path: 'inOutGoldList',
        name: 'InOutGoldList',
        component: InOutGoldList, //出入金管理
        meta: { title: "出入金管理", requireAuth: true }
      },
      {
        path: 'virtualBnakList',
        name: 'VirtualBnakList',
        component: VirtualBnakList, //虚户管理
        meta: { title: "虚户管理", requireAuth: true }
      },
      {
        path: 'virtualBnakList/virtualList',
        name: 'VirtualList',
        component: VirtualList, //虚户管理 -- 虚户列表
        meta: { title: "虚户管理", requireAuth: true, ChildrenActiveMenu: '/aside/virtualBnakList', GuidePath: true }
      },
      {
        path: 'virtualBnakList/clientList',
        name: 'ClientList',
        component: ClientList, //虚户管理 -- 实户列表
        meta: { title: "虚户管理", requireAuth: true, ChildrenActiveMenu: '/aside/virtualBnakList', GuidePath: true }
      },
      {
        path: 'virtualBnakList/remittance',
        name: 'Remittance',
        component: Remittance, //虚户管理 -- 汇款验证
        meta: { title: "虚户管理", requireAuth: true, ChildrenActiveMenu: '/aside/virtualBnakList', GuidePath: true }
      },
      {
        path: 'virtualBnakList/openVirtual',
        name: 'OpenVirtual',
        component: OpenVirtual, //虚户管理 -- 开通虚户
        meta: { title: "虚户管理", requireAuth: true, ChildrenActiveMenu: '/aside/virtualBnakList', GuidePath: true }
      },
      {
        path: 'virtualBnakList/openDetail',
        name: 'OpenDetail',
        component: OpenDetail, //虚户管理 -- 开户详情+编辑
        meta: { title: "虚户管理", requireAuth: true, ChildrenActiveMenu: '/aside/virtualBnakList', GuidePath: true }
      },
      {
        path: 'companyinfo',//个人中心-认证管理-企业信息
        meta: { title: "个人中心-认证管理-企业信息", requireAuth: true },
        component: () => import('@/views/MSME/auth/authManage/CompanyInfo')
      },
      {
        path: 'subcompany',//个人中心-用户管理-子公司管理
        meta: { title: "子公司管理", requireAuth: true },
        component: () => import('@/views/MSME/auth/userManage/Subcompany')
      },
      {
        path: 'subcompany/detail',//个人中心-用户管理-子公司管理-详情
        name: 'Detail',
        component: () => import('@/views/MSME/auth/userManage/subchildren/Detail'),
        meta: { title: "子公司管理", requireAuth: true, ChildrenActiveMenu: '/aside/subcompany', GuidePath: true }
      },
      {
        path: 'operator',//个人中心-用户管理-操作员管理
        meta: { title: "操作员管理", requireAuth: true },
        component: () => import('@/views/MSME/auth/userManage/Operator')
      },
      {
        path: 'operator/detail',//个人中心-用户管理-操作员管理-详情
        name: 'Detail1',
        component: () => import('@/views/MSME/auth/userManage/opechildren/Detail'),
        meta: { title: "操作员管理", requireAuth: true, ChildrenActiveMenu: '/aside/operator', GuidePath: true }
      },
      {
        path: 'operator/update',//个人中心-用户管理-操作员管理-变更
        name: 'Update1',
        component: () => import('@/views/MSME/auth/userManage/opechildren/Update'),
        meta: { title: "操作员管理", requireAuth: true, ChildrenActiveMenu: '/aside/operator', GuidePath: true }
      },
      {
        path: 'password',//个人中心-账号安全-密码管理
        meta: { title: "密码管理", requireAuth: true },
        component: () => import('@/views/MSME/auth/accountSafe/Password')
      }
    ]
  },
  //供票
  {
    path: '/ticket',
    name: 'ticker',
    redirect: '/ticket/ticketIndex',
    component: Ticket,
    meta: { title: "供票" },
    children: [
      {
        path: 'ticketIndex',
        component: () => import('@/views/Ticket/routerCpn/TicketIndex'),
        meta: { title: "首页", requireAuth: true }
      },
      {
        path: 'access',
        // 企业准入
        component: () => import('@/views/Ticket/routerCpn/access/companyAccess/Access'),
        meta: { title: "企业准入", requireAuth: true }
      },
      {
        path: 'accessDetail',
        name: 'accessDetail',
        // 企业准入 - 详情（待运营审核/待简单汇审核/完成）
        component: () => import('@/views/Ticket/routerCpn/access/companyAccess/AccessDetail'),
        meta: { title: "企业准入", requireAuth: true }
      },
      {
        path: 'identityVerify',
        name: 'identityVerify',
        // 企业准入 - 企业身份验证
        component: () => import('@/views/Ticket/routerCpn/access/companyVerify/companyVerify'),
        meta: { title: "企业身份验证", requireAuth: true }
      },
      {
        path: 'companyUpdate',
        // 企业信息更新列表
        component: () => import('@/views/Ticket/routerCpn/access/companyUpdate/List'),
        meta: { title: "企业信息更新", requireAuth: true }
      },
      {
        path: 'companyAccount',
        name: 'companyAccount',
        // 更新账户信息
        component: () => import('@/views/Ticket/routerCpn/access/companyUpdate/UpdateAccount'),
        meta: { title: "企业信息更新", requireAuth: true }
      },
      {
        path: 'companyAccountDetail',
        name: 'companyAccountDetail',
        // 更新账户信息 - 详情
        component: () => import('@/views/Ticket/routerCpn/access/companyUpdate/UpdateAccountDetail'),
        meta: { title: "企业信息更新", requireAuth: true }
      },
      {
        path: 'companyOperator',
        name: 'companyOperator',
        // 更新操作员信息
        component: () => import('@/views/Ticket/routerCpn/access/companyUpdate/UpdateOperator'),
        meta: { title: "企业信息更新", requireAuth: true }
      },
      {
        path: 'companyOperatorDetail',
        name: 'companyOperatorDetail',
        // 更新账户信息 - 操作员信息详情
        component: () => import('@/views/Ticket/routerCpn/access/companyUpdate/UpdateOperatorDetail'),
        meta: { title: "企业信息更新", requireAuth: true }
      },
      {
        path: 'taskWaitList',
        name: 'taskWaitList',
        // 工作台 - 待办任务列表
        component: () => import('@/views/Ticket/routerCpn/workbench/taskWait/TaskWaitList'),
        meta: { title: "待办任务", requireAuth: true }
      },
      {
        path: 'taskWaitDetail',
        name: 'taskWaitDetail',
        // 工作台 - 待办任务列表 - 详情
        component: () => import('@/views/Ticket/routerCpn/workbench/taskWait/TaskWaitDetail'),
        meta: { title: "待办任务", requireAuth: true }
      },
      {
        path: 'taskQueryList',
        name: 'taskQueryList',
        // 工作台 - 任务查询列表
        component: () => import('@/views/Ticket/routerCpn/workbench/taskQuery/TaskQueryList'),
        meta: { title: "任务查询", requireAuth: true }
      },
      {
        path: 'taskQueryDetail',
        name: 'taskQueryDetail',
        // 工作台 - 任务查询列表 - 详情
        component: () => import('@/views/Ticket/routerCpn/workbench/taskQuery/TaskQueryDetail'),
        meta: { title: "任务查询", requireAuth: true }
      },
      {
        path: 'holdBill',
        name: 'receivable',
        // 票据查询 - 应收票据
        component: () => import('@/views/Ticket/routerCpn/billQuery/receivable/Receivable'),
        meta: { title: "应收票据", requireAuth: true }
      },
      {
        path: 'payBill',
        name: 'payBill',
        // 票据查询 - 应付票据列表
        component: () => import('@/views/Ticket/routerCpn/billQuery/pay/PayBill'),
        meta: { title: "应付票据", requireAuth: true }
      },
      {
        path: 'flowPath',
        name: 'flowPath',
        component: () => import('@/views/Ticket/routerCpn/billQuery/pay/FlowPath'),
        meta: { title: "流转轨迹", requireAuth: true }
      },
      {
        path: 'queryBill',
        name: 'queryBill',
        // 票据查询 - 操作查询列表
        component: () => import('@/views/Ticket/routerCpn/billQuery/query/queryBill'),
        meta: { title: "操作查询", requireAuth: true }
      },
      {
        path: 'drawBillApply',
        name: 'drawBillApply',
        // 出票管理 - 出票登记申请
        component: () => import('@/views/Ticket/routerCpn/billOut/BillOutApply/BillOutApply'),
        meta: { title: "出票登记申请", requireAuth: true }
      },
      {
        path: 'billOutDraft',
        name: 'billOutDraft',
        // 出票管理 - 出票登记申请 - 草稿箱
        component: () => import('@/views/Ticket/routerCpn/billOut/BillOutApply/BillOutDraft'),
        meta: { title: "草稿箱", requireAuth: true }
      },
      {
        path: 'hintAcceptApply/:placeholder',
        name: 'hintAcceptApply/:placeholder',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/billOut/BillOutOther'),
        meta: { title: "乐享数科", requireAuth: true }
      },
      //  背书管理 - 背书申请列表
      {
        path: 'endorse/apply',
        name: 'endorseApplyList',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/endorse/endorseApply/EndorseApplyList'),
        meta: { title: "背书申请", requireAuth: true }
      },
      //  背书管理 - 背书申请详情
      {
        path: 'endorse/detail',
        name: 'endorseApplyDetail',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/endorse/endorseApply/EndorseApplyStep'),
        meta: { title: "背书申请", requireAuth: true }
      },
      //  背书管理 - 背书撤回
      {
        path: 'endorse/recall',
        name: 'endorseRecall',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/endorse/endorseRecall/EndorseRecallList'),
        meta: { title: "背书撤回", requireAuth: true }
      },
      {
        path: 'endorse/recallDetail',
        name: 'endorseRecallDetail',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/endorse/endorseRecall/EndorseRecallStep'),
        meta: { title: '背书撤回', requireAuth: true }
      },
      //  贴现管理 - 贴现申请
      {
        path: 'discount/apply',
        name: 'discountApply',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/discount/DiscountApply'),
        meta: { title: "贴现申请", requireAuth: true }
      },
      //  贴现管理 - 贴现申请详情
      {
        path: 'discount/detail',
        name: 'discountDetail',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/discount/DiscountStep'),
        meta: { title: "贴现申请", requireAuth: true }
      },
      //  保证管理 -- 保证申请 -- 列表
      {
        path: 'guarantee/apply',
        name: 'guaranteeApplyList',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/guarantee/apply/GuaranteeApplyList'),
        meta: { title: "保证申请", requireAuth: true }
      },
      //  保证管理 -- 保证申请 -- 详情
      {
        path: 'guarantee/apply/detail',
        name: 'guaranteeApplyDetail',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/guarantee/apply/GuaranteeApplyDetail'),
        meta: { title: "保证申请", requireAuth: true }
      },
      //  保证管理 -- 保证撤回 -- 列表
      {
        path: 'guarantee/recall',
        name: 'guaranteeRecallList',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/guarantee/recall/GuaranteeRecallList'),
        meta: { title: "保证撤回", requireAuth: true }
      },
      //  保证管理 -- 保证撤回 -- 列表
      {
        path: 'guarantee/recall/detail',
        name: 'guaranteeRecallDetail',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/guarantee/recall/GuaranteeRecallDetail'),
        meta: { title: "保证撤回", requireAuth: true }
      },
      //  质押管理 -- 质押申请
      {
        path: 'pledge/apply',
        name: 'pledgeApplyList',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/pledge/apply/PledgeApplyList'),
        meta: { title: "质押申请", requireAuth: true }
      },
      //  质押管理 -- 质押申请 -- 详情
      {
        path: 'pledge/apply/detail',
        name: 'pledgeApplyStep',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/pledge/apply/PledgeApplyStep'),
        meta: { title: "质押申请详情", requireAuth: true }
      },
      //  质押管理 -- 质押撤回 -- 列表
      {
        path: 'pledge/recall',
        name: 'pledgeRecallList',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/pledge/recall/PledgeRecallList'),
        meta: { title: "质押撤销", requireAuth: true }
      },
      //  质押管理 -- 质押撤回 -- 申请撤销
      {
        path: 'pledge/recall/detail',
        name: 'PledgeApplyRecall',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/pledge/recall/PledgeApplyRecall'),
        meta: { title: "质押撤销", requireAuth: true }
      },
      //  质押管理 -- 质押解除申请 -- 列表
      {
        path: 'pledge/remove/apply',
        name: 'ApplyRemoveList',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/pledge/applyRemove/ApplyRemoveList'),
        meta: { title: "质押解除申请", requireAuth: true }
      },
      //  质押管理 -- 质押解除申请 -- 详情
      {
        path: 'pledge/remove/apply/detail',
        name: 'PledgeApplyRemoveStep',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/pledge/applyRemove/PledgeApplyRemoveStep'),
        meta: { title: "质押解除申请", requireAuth: true }
      },
      //  质押管理 -- 质押解除撤回 -- 列表
      {
        path: 'pledge/remove/recall',
        name: 'recallRemoveList',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/pledge/recallRemove/recallRemoveList'),
        meta: { title: "质押解除撤回", requireAuth: true }
      },
      //  质押管理 -- 质押解除撤回 -- 详情
      {
        path: 'pledge/remove/recall/detail',
        name: 'recallRemoveDetail',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/pledge/recallRemove/recallRemoveDetail'),
        meta: { title: "质押解除撤回", requireAuth: true }
      },
      //  提示付款管理 - 提示付款申请
      {
        path: 'remind/payment/apply',
        name: 'paymentApplyList',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/payment/apply/PaymentApplyList'),
        meta: { title: "提示付款申请", requireAuth: true }
      },
      //  提示付款管理 - 提示付款申请详情
      {
        path: 'remind/payment/apply/detail',
        name: 'paymentApplyDetail',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/payment/apply/PaymentApplyStep'),
        meta: { title: "提示付款申请详情", requireAuth: true }
      },
      //  提示付款管理 - 提示付款撤回
      {
        path: 'remind/payment/recall',
        name: 'paymentRecallList',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/payment/recall/PaymentRecallList'),
        meta: { title: "提示付款撤回", requireAuth: true }
      },
      //  提示付款管理 - 提示付款撤回详情
      {
        path: 'remind/payment/recall/detail',
        name: 'paymentRecallDetail',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/payment/recall/PaymentRecallStep'),
        meta: { title: "提示付款申请详情", requireAuth: true }
      },
      //  综合管理 - 信批验证出票
      {
        path: 'credit/verify/ticket',
        name: 'creditVerifyTicket',
        props: true,
        component: () => import('@/views/Ticket/routerCpn/letter/CreditVerifyTicket'),
        meta: { title: "信批验证出票", requireAuth: true }
      }
    ]
  },
]


const router = new VueRouter({
  mode: 'history',
  routes,
})
//跳转页面回到顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
//解决路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  if (to.meta.content) {
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next()
  const isToken = window.sessionStorage.getItem('token')
  if (to.path === "/login") return next();
  if (to.meta.requireAuth == true) {
    if (!isToken) {
      next({ path: '/login' })
    } else {
      return next()
    }
  } else {
    return next()
  }
});
export default router
