var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution"},[_vm._m(0),_c('div',{staticClass:"mainBox"},[_c('div',{staticClass:"cardBg"},[_c('div',{staticClass:"center"},[_vm._m(1),_c('div',{staticClass:"soluteBox"},[_vm._l((_vm.caseArr),function(item,index){return _c('div',{key:index,class:_vm.activeIndex===index? 'isActive': 'hide'},[_c('div',{staticClass:"titleRed"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"text text1"},[_vm._v(" "+_vm._s(item.text)+" ")])])}),_c('div',{staticClass:"flex5"},_vm._l((_vm.caseAll),function(item,index){return _c('div',{key:index,staticClass:"flex5Item item1",on:{"mouseenter":function($event){return _vm.isShow(index)}}},[_c('img',{attrs:{"src":item.url,"alt":""}}),_c('p',[_vm._v(_vm._s(item.title))])])}),0)],2)])]),_c('div',{staticClass:"cardBg"},[_c('div',{staticClass:"center"},[_vm._m(2),_c('div',{staticClass:"container"},_vm._l((_vm.project),function(item,index){return _c('div',{key:index,staticClass:"list",class:_vm.currentIndex===index? 'eleactive': '',on:{"mouseenter":function($event){return _vm.handlerEnter(index)}}},[_c('div',{staticClass:"flexBox"},[_c('img',{attrs:{"src":item.url,"alt":""}}),_c('p',[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"hideBox"},[_vm._v(" "+_vm._s(item.text)+" ")])]),_c('div',{staticClass:"hideEl"})])}),0)])]),_c('div',{staticClass:"cardBg jdal"},[_c('div',{staticClass:"center"},[_vm._m(3),_c('div',{staticClass:"flex2"},[_c('div',{staticClass:"flex2Item jdalL"},[_c('div',{staticClass:"itemBox"},[_c('h1',[_vm._v("国安大数据")]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('router-link',{attrs:{"to":"/case"}},[_c('span',{staticClass:"goDetail"},[_vm._v("了解更多 →")])])],1)]),_vm._m(8)])])]),_c('div',{staticClass:"apply"},[_c('h1',[_vm._v("专属客服1v1服务，为您的需求保驾护航")]),_c('router-link',{attrs:{"to":"/contactus"}},[_c('el-button',[_vm._v("立即申请")])],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block bannerImg"},[_c('img',{attrs:{"width":"100%","height":"600","src":require("@/assets/img/solution.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"oneTitle"},[_c('h1',[_vm._v("方案介绍")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"oneTitle"},[_c('h1',[_vm._v("方案优势")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"oneTitle"},[_c('h1',[_vm._v("经典案例")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("客户名称：")]),_c('em',[_vm._v("鄂尔多斯市伊泰投资控股有限责任公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("合作类型：")]),_c('em',[_vm._v("合资公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("解决方案：")]),_c('em',[_vm._v("2018年8月，为了顺应煤炭交易的庞大体量与能源化工的飞速发展，助力核心企业建立票据生态体系，解决中小供应商企业融资难题。乐享数科携手鄂尔多斯市伊泰投资控股有限责任公司、内蒙古天眼股权投资中心共同设立了国安大数据科技有限公司。历经2年多的布局开发与深耕合作，针对伊泰集团与链属企业的核心需求，设计全流程线上化服务，打造国安供应链金融服务平台，为企业用户提供一站式金融服务体系；目前平台已实现与平安、建设等多家银行的系统对接及多元服务，助力核心企业创新融合、布局未来。平台的票据服务数据从2018年底的8.6亿元，发展至2021年底的71.1亿元；截止2021年底，平台共计合作的核心企业15家，注册的企业会员近600家，合作的金融机构20余家，累计服务各类供应链金融业务共计约123亿元。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("实现价值：")]),_c('em',[_vm._v("平台先后运营了资产服务中心与商业保理公司，为了更好的服务中小融资客户，不断提升伊泰商票的市场认可度与融资竞争力，打破利率壁垒，助力流转融通；综合贴现利率已从2019年的年化8%，逐步降低至2021年的年化3.58%，在增加业务签发的同时不断降低利率。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex2Item jdalR"},[_c('img',{staticClass:"img1",attrs:{"src":require("@/assets/img/jdal_01.png"),"alt":""}})])
}]

export { render, staticRenderFns }