<template>
  <!--  联系我们-->
  <div class="contactus">
    <div class="center">
      <div class="flex2 pad120">
        <div class="flex2Item">
          <div class="itemL">
            <h1>了解更多产品介绍
              <br>请拨打400-628-7087
              <br>或添加客服微信
            </h1>
            <h2>专属客服1v1服务，为您的需求保驾护航</h2>
            <!-- <div class="line"></div> -->
            <img src="@/assets/img/lxskKf.jpg"
                 alt="">
          </div>
        </div>
        <div class="flex2Item itemR">
          <div class="top">
            <h1>联系我们<br></h1>
            <h2>完善信息后我们会第一时间跟您联系</h2>
          </div>
          <el-form :model="ruleForm"
                   :rules="rules"
                   ref="ruleForm"
                   class="contactForm">
            <el-form-item label="联系人"
                          prop="name">
              <el-input v-model="ruleForm.name"
                        placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="手机号"
                          prop="phone">
              <el-input v-model="ruleForm.phone"
                        placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="机构类型"
                          prop="source">
              <el-select v-model="ruleForm.source"
                         placeholder="请选择机构类型">
                <el-option label="融资企业"
                           value="0"></el-option>
                <el-option label="核心企业"
                           value="1"></el-option>
                <el-option label="保理公司"
                           value="2"></el-option>
                <el-option label="担保公司"
                           value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="企业名称"
                          prop="companyName">
              <el-input v-model="ruleForm.companyName"
                        placeholder="请输入企业名称"></el-input>
            </el-form-item>
            <el-form-item label="咨询问题"
                          prop="content">
              <el-input type="textarea"
                        v-model="ruleForm.content"
                        placeholder="请输入咨询问题"></el-input>
            </el-form-item>
            <el-form-item class="btnRow">
              <el-button class="reset"
                         @click="resetForm('ruleForm')"
                         round>重 置</el-button>
              <el-button type="primary"
                         class="submit"
                         @click="submitForm('ruleForm')"
                         round>提 交</el-button>

            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      type:this.$route.query.type, // 来源
      ruleForm: {
        phone: "", //手机号
        name: "", //联系人
        source: "", //公司类型
        companyName: "", //企业名称
        content: "", //咨询内容
      },
      rules: {
        name: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号" },
          {
            type: "number",
            message: "手机号格式不正确",
            trigger: "blur",
            transform(value) {
              var phoneReg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
              if (!phoneReg.test(value)) {
                return false;
              } else {
                return Number(value);
              }
            },
          },
        ],
        source: [
          { required: true, message: "请选择机构类型", trigger: "change" },
        ],
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请输入咨询问题", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate(async (valid) => {
        if (valid) {
          this.ruleForm.type = this.type ? this.type : '1'
          const { data: res } = await this.$http.post(
            "/insertCustomer",
            this.$qs.stringify(this.ruleForm)
          );
          if (res.code == 200) {
            this.$message.success(
              "提交成功！我们将在2个工作日内联系您，请保持通讯通畅。"
            );
            this.$refs.ruleForm.resetFields();
          } else {
            this.$refs.ruleForm.resetFields();
            return this.$message.error(res.msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.contactus {
  // height: 1000px;
  height: 683px;
  background: url("~@/assets/img/bj@2x.png") no-repeat;
  background-size: 100% 100%;

  .pad120 {
    padding-top: 96px;
  }

  .flex2Item {
    width: 50%;
    height: 738px;

    .itemL {
      // padding: 20px 60px 20px 0;
      box-sizing: border-box;

      h1 {
        font-size: 42px;
        font-weight: 500;
        color: #c70009;
        line-height: 71px;
        letter-spacing: 3px;
      }

      h2 {
        font-weight: 400;
        font-size: 24px;
        color: #999999;
        line-height: 33px;
        letter-spacing: 1px;
        margin: 50px 0;
      }

      .line {
        width: 180px;
        height: 4px;
        background: #c70009;
        margin-bottom: 40px;
      }

      > img {
        width: 180px;
        height: 180px;
      }
    }
  }

  .itemR {
    background: #ffffff;
    box-shadow: 2px 2px 4px 0px #dedede;
    padding: 39px 60px 40px 46px;
    box-sizing: border-box;

    width: 504px;
    height: 536px;
    border-radius: 4px;
    /deep/.el-textarea__inner {
      height: 100% !important;
    }

    .el-input {
      width: 297px;
    }
    .top {
      h1 {
        font-size: 18px;
        letter-spacing: 1px;
      }

      h2 {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        margin: 10px 0 20px;
      }
    }
  }

  .contactForm {
    /deep/ .el-form-item__label {
      width: 100px;
      text-align: left;
    }
    .el-textarea {
      width: 297px;
      height: 80px;
    }
    .el-select {
      width: 297px;
    }

    .el-textarea__inner {
      height: 100% !important;
    }

    .el-form-item {
      margin-bottom: 14px;
    }

    .el-form-item.btnRow {
      margin-top: 30px;
      float: right;

      .el-form-item__content {
        height: 80px;
        .el-button.is-round {
          width: 116px;
          height: 36px;
          border-radius: 4px;
          padding: 10px 23px;
        }

        .el-button + .el-button {
          margin-left: 20px;
        }

        .submit {
          background: #c70009;
          border: 1px solid #c70009;
        }

        .reset {
          background: #ffffff;
          color: #c70009;
          border: 1px solid #c70009;
        }
      }
    }
  }
  /deep/.el-form-item__error{
    line-height: 5px;
    position: absolute;
    top: 100%;
    left: 100px;
  }
}
</style>