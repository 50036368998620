<template>
  <div class="center_content">
    <!-- <img class="bg_img"
         src="../../assets/img/jgw1.png"
         alt=""> -->
    <div class="center">

      <div class="text_left">
        <p class="first">服务商业信用 助力产业发展</p>
        <p class="second">全方位的供应链金融科技平台</p>
        <!-- <p class="second three">金融科技平台</p> -->
        <p class="four "></p>
      </div>
      <img class="gif_img"
           src="../../assets/img/dl.gif"
           alt="">
      <div class="login_box"
           v-show="pageId == 1">
        <el-tabs v-model="activeName"
                 @tab-click="handleClick">
          <el-tab-pane label="账号登录"
                       name="first">
            <el-form style="margin-top:15px"
                     ref="form"
                     :rules="formRule"
                     :model="formLogin">
              <el-form-item prop="userPhone">
                <el-input v-model.trim="formLogin.userPhone"
                          oninput="value=value.replace(/[^\d]/g, '')"
                          maxlength="11"
                          placeholder="请输入手机号码"></el-input>
              </el-form-item>
              <el-form-item prop="userPass">
                <el-input show-password
                          v-model="formLogin.userPass"
                          placeholder="密码"></el-input>
              </el-form-item>
              <el-form-item>
                <div class="checkPassword">
                  <!-- <el-checkbox fill='#999999'
                               v-model="checked">记住密码</el-checkbox> -->
                  <el-button type="text"
                             class="co_co"
                             @click="forgetPassword">忘记密码?</el-button>
                  <el-button type="text"
                             style="color:#C70009"
                             class="co_co"
                             @click="registerAccount">注册账户</el-button>
                </div>
              </el-form-item>
              <el-form-item style="margin-top:30px;margin-bottom:15px">
                <el-button class="btn_login"
                           @click="Login">登 录</el-button>
              </el-form-item>
              <el-form-item prop="xyChecked"
                            class="el_it_check">
                <el-checkbox-group v-model="formLogin.xyChecked"
                                   class="checkPassword">
                  <el-checkbox name="xyChecked"
                               class="checked_left">我已阅读并同意<span class="co_co"
                          @click="dialogVisible1=true">《门户注册协议》</span>及<span class="co_co"
                          @click="dialogVisible2=true">《隐私政策》</span></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-form>

            <!-- <div style="text-align:center"
                 class="zczh">
              <span>没有账号?

              </span>
            </div> -->
          </el-tab-pane>
          <el-tab-pane label="快捷登录"
                       name="second">
            <el-form style="margin-top:15px"
                     ref="formKj"
                     :rules="KjRule"
                     :model="formLoginKj">
              <el-form-item prop="userPhone"
                            :error="error">
                <el-input maxlength="11"
                          oninput="value=value.replace(/[^\d]/g, '')"
                          v-model.trim="formLoginKj.userPhone"
                          placeholder="请输入手机号码"></el-input>
              </el-form-item>
              <el-form-item prop="verify"
                            class="yzm">
                <el-input style="width:62.5%"
                          v-model="formLoginKj.verify"
                          placeholder="验证码"></el-input>
                <el-button @click="KjAuthCode"
                           class="code_bg"
                           :class="{'co_bg':disabledKj}"
                           :disabled='disabledKj'>{{AuthCodeKj}}</el-button>
              </el-form-item>
              <el-form-item>
                <div class="checkPassword">
                  <!-- <el-checkbox fill='#999999'
                          >记住密码</el-checkbox> -->
                  <el-button type="text"
                             class="co_co"
                             @click="forgetPassword">忘记密码?</el-button>
                  <el-button type="text"
                             style="color:#C70009"
                             class="co_co"
                             @click="registerAccount">注册账户</el-button>
                </div>
              </el-form-item>
              <el-form-item style="margin-top:30px;margin-bottom:15px">
                <el-button class="btn_login"
                           @click="LoginKj">登 录</el-button>
              </el-form-item>
              <el-form-item prop="xyChecked"
                            class="el_it_check">
                <el-checkbox-group v-model="formLoginKj.xyChecked"
                                   class="checkPassword">
                  <el-checkbox name="xyChecked"
                               class="checked_left">我已阅读并同意<span class="co_co"
                          @click="dialogVisible1=true">《门户注册协议》</span>及<span class="co_co"
                          @click="dialogVisible2=true">《隐私政策》</span></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-form>

            <!-- <el-button class="btn_login "
                       @click="LoginKj">登 录</el-button> -->
            <!-- <div style="text-align:center"
                 class="zczh">
              <span>没有账号?
                <el-button type="text"
                           @click="registerAccount">注册账户</el-button>

              </span>
            </div> -->
          </el-tab-pane>
        </el-tabs>

      </div>
      <div class="login_box zc"
           v-show="pageId == 2">
        <!-- <el-tabs v-model="activeName1"> -->
        <!-- <el-tab-pane label="注册账户" -->
        <!-- name="first1"> -->
        <div class="zczh_title">注册账户</div>
        <el-form style="margin-top:15px"
                 ref="formZC"
                 :rules="formLoginZcRule"
                 :model="formLoginZc">
          <el-form-item prop="tel"
                        :error="error1">
            <el-input maxlength="11"
                      oninput="value=value.replace(/[^\d]/g, '')"
                      v-model.trim="formLoginZc.tel"
                      placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input show-password
                      v-model="formLoginZc.password"
                      placeholder="6-20位密码,字母数字组合"></el-input>
          </el-form-item>
          <el-form-item prop="passWordAgain">
            <el-input show-password
                      v-model="formLoginZc.passWordAgain"
                      placeholder="确认密码"></el-input>
          </el-form-item>
          <el-form-item prop="phoneCode"
                        class="yzm">
            <el-input style="width:62.5%"
                      v-model="formLoginZc.phoneCode"
                      placeholder="验证码"></el-input>
            <el-button @click="getZcAuthCode"
                       class="code_bg"
                       :class="{'co_bg':disabledCode}"
                       :disabled='disabledCode'>{{AuthCode}}</el-button>
          </el-form-item>
          <el-form-item prop="xyChecked"
                        class="el_it_check zc_check">
            <el-checkbox-group v-model="formLoginZc.xyChecked"
                               class="checkPassword">
              <el-checkbox name="xyChecked"
                           class="checked_left">我已阅读并同意<span class="co_co"
                      @click="dialogVisible1=true">《门户注册协议》</span>及<span class="co_co"
                      @click="dialogVisible2=true">《隐私政策》</span></el-checkbox>
            </el-checkbox-group>
          </el-form-item>

        </el-form>
        <el-button class="zc_btn"
                   @click="ZcClick">注 册</el-button>
        <div style="text-align:center;margin-top:15px"
             class="zczh">
          <el-button type="text"
                     @click="switchLogin">使用已有账号登录</el-button>

        </div>
        <!-- </el-tab-pane> -->
        <!-- </el-tabs> -->

      </div>
      <div class="login_box zc"
           v-show="pageId == 3">
        <div class="zczh_title">忘记密码</div>
        <el-form style="margin-top:15px"
                 ref="formForget"
                 :rules="formForgetRule"
                 :model="formLoginForget">
          <el-form-item prop="userPhone"
                        :error="error2">
            <el-input maxlength="11"
                      oninput="value=value.replace(/[^\d]/g, '')"
                      v-model.trim="formLoginForget.userPhone"
                      placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item prop="userPass">
            <el-input show-password
                      v-model="formLoginForget.userPass"
                      placeholder="6-20位密码,字母数字组合"></el-input>
          </el-form-item>
          <el-form-item prop="passWordAgain">
            <el-input show-password
                      v-model="formLoginForget.passWordAgain"
                      placeholder="确认密码"></el-input>
          </el-form-item>
          <el-form-item prop="verify"
                        class="yzm">
            <el-input style="width:62.5%"
                      v-model="formLoginForget.verify"
                      placeholder="图片验证码"></el-input>
            <img style="cursor:pointer"
                 :src="imgSrc"
                 @click="getImgUrl"
                 alt="">
          </el-form-item>
          <el-form-item prop="phoneCode"
                        class="yzm">
            <el-input style="width:62.5%"
                      v-model="formLoginForget.phoneCode"
                      placeholder="验证码"></el-input>
            <el-button @click="getForCode"
                       class="code_bg"
                       :class="{'co_bg':disabledFor}"
                       :disabled='disabledFor'>{{AuthCodeForget}}</el-button>
          </el-form-item>
          <el-form-item prop="xyChecked"
                        class="el_it_check zc_check">
            <el-checkbox-group v-model="formLoginForget.xyChecked"
                               class="checkPassword">
              <el-checkbox name="xyChecked"
                           class="checked_left">我已阅读并同意<span class="co_co"
                      @click="dialogVisible1=true">《门户注册协议》</span>及<span class="co_co"
                      @click="dialogVisible2=true">《隐私政策》</span></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-button class="zc_btn"
                     @click="ForgetZC">确 定</el-button>
          <div style="text-align:center;margin-top:15px"
               class="zczh">
            <el-button type="text"
                       @click="switchLogin">使用已有账号登录</el-button>

          </div>
        </el-form>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible"
               class="abow_dialog1"
               width="62.5%">
      <!--  :fullscreen="true" -->
      <!-- <span>门户注册协议内容</span> -->
      <div slot="title"
           class="dialog-title ">
        <el-tabs v-model="activeNameDialog">
          <el-tab-pane label="乐享数科用户注册协议"
                       name="first"></el-tab-pane>
          <el-tab-pane label="乐享数科隐私政策"
                       name="second"></el-tab-pane>
        </el-tabs>
      </div>
      <Zcxy v-if="activeNameDialog=='first'"></Zcxy>
      <Yszc v-if="activeNameDialog=='second'"></Yszc>

      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button class="continue_btn"
                   @click="continueZc">同意并继续</el-button>
      </span>
    </el-dialog>
    <el-dialog title="乐享数科用户注册协议"
               :visible.sync="dialogVisible1"
               class="abow_dialog"
               width="62%">
      <!-- <iframe src="/static/1.pdf#toolbar=0"
              frameborder="0"
              style="width: 100%; height: 100%"></iframe> -->
      <Zcxy></Zcxy>

    </el-dialog>
    <el-dialog title="乐享数科隐私政策"
               :visible.sync="dialogVisible2"
               class="abow_dialog"
               width="62%">
      <Yszc></Yszc>
      <!-- <iframe src="/static/2.pdf"
              frameborder="0"
              style="width: 100%; height: 100%"></iframe> -->

    </el-dialog>
  </div>
</template>

<script>
import Zcxy from "./Zcxy.vue";
import Yszc from "./Yszc.vue";
export default {
  name: "Login",
  components: {
    Zcxy,
    Yszc,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      let reg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/;
      if (value.length > 20 || value.length < 6) {
        callback(new Error("6-20个字符"));
      } else if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("必须为数字和字母组合"));
      }
    };
    let validator3 = (rule, value, callback) => {
      let reg = /^[1]([3-9])[0-9]{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      activeNameDialog: "first",
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      disabledCode: false,
      disabledKj: false,
      disabledFor: false,
      checked: false, //是否记住密码
      pageId: 1, //显示登陆或者注册
      activeName: "first",
      activeName1: "first1",
      delayTime: 60,
      delayTimeKJ: 60,
      delayTimeForget: 60,
      AuthCode: "获取验证码",
      AuthCodeKj: "获取验证码",
      AuthCodeForget: "获取验证码",
      formLogin: {
        userPhone: "",
        userPass: "",
        xyChecked: [], //协议通过
      },
      formLoginZc: {
        tel: "",
        password: "",
        passWordAgain: "",
        phoneCode: "",
        xyChecked: [], //协议通过
      }, //注册表单
      formLoginKj: {
        userPhone: "",
        verify: "",
        xyChecked: [], //协议通过
      }, //快捷登录表单
      formLoginForget: {
        userPhone: "",
        userPass: "",
        passWordAgain: "",
        verify: "",
        phoneCode: "",
        xyChecked: [], //协议通过
      }, //忘记密码表单
      formRule: {
        userPhone: [
          { required: true, message: "手机号输入不能为空", trigger: "blur" },
          { required: true, message: "请输入正确的手机号", trigger: "blur" },
          { required: true, validator: validator3, trigger: "blur" },
        ],
        userPass: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        xyChecked: [
          { required: true, message: "请勾选协议", trigger: "change" },
        ],
      },
      formLoginZcRule: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        passWordAgain: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        tel: [
          { required: true, message: "手机号输入不能为空", tsrigger: "blur" },
          { required: true, message: "请输入正确的手机号", trigger: "blur" },
          { required: true, validator: validator3, trigger: "blur" },
        ],

        phoneCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        xyChecked: [
          { required: true, message: "请勾选协议", trigger: "change" },
        ],
      },
      KjRule: {
        xyChecked: [
          { required: true, message: "请勾选协议", trigger: "change" },
        ],
        userPhone: [
          { required: true, message: "手机号输入不能为空", trigger: "blur" },
          { required: true, message: "请输入正确的手机号", trigger: "blur" },
          { required: true, validator: validator3, trigger: "blur" },
        ],
        verify: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      formForgetRule: {
        userPhone: [
          { required: true, message: "手机号输入不能为空", trigger: "blur" },
          { required: true, message: "请输入正确的手机号", trigger: "blur" },
          { required: true, validator: validator3, trigger: "blur" },
        ],
        userPass: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        passWordAgain: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        verify: [
          { required: true, message: "请输入图片验证码", trigger: "blur" },
        ],
        phoneCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        xyChecked: [
          { required: true, message: "请勾选协议", trigger: "change" },
        ],
      },
      imgSrc: "",
      error: "", //修改错误提示
      error1: "", //修改错误提示
      error2: "", //修改错误提示
      type: "", //产品类型
      // 判断验证码是否获取过
      KjCodeAgain: false,
      ZcCodeAgain: false,
      ForgetCodeAgain: false,
    };
  },
  created() {
    if (this.$route.params.type) {
      localStorage.setItem("type", this.$route.params.type);
    } else {
      localStorage.removeItem("type");
    }
    this.type = localStorage.getItem("type");

    if (localStorage.getItem("userPhone")) {
      this.formLogin.userPhone = localStorage.getItem("userPhone");
      this.formLogin.userPass = localStorage.getItem("userPass");
    }
  },
  methods: {
    handleClick() {
      this.$refs["form"].resetFields();
      this.$refs["formKj"].resetFields();
    },
    switchLogin() {
      if (this.pageId == 2) {
        this.$refs["formZC"].resetFields();
      }
      if (this.pageId == 3) {
        this.$refs["formForget"].resetFields();
      }
      this.$refs["form"].resetFields();
      this.$refs["formKj"].resetFields();
      this.activeName = "first";
      this.pageId = 1;
    },
    registerAccount() {
      // this.pageId = 2;
      this.dialogVisible = true;
    },
    // 忘记密码
    forgetPassword() {
      this.pageId = 3;
      this.getImgUrl();
    },
    // 注册发送验证码
    getZcAuthCode() {
      this.ZcCodeAgain = true;
      this.$refs["formZC"].validateField("tel", async (errMsg) => {
        if (errMsg) {
          this.error1 = "请输入正确的手机号,否则无法接收验证码";
          return false;
        } else {
          this.disabledCode = true;
          let timer = setInterval(() => {
            this.delayTime = this.delayTime - 1;
            this.AuthCode = this.delayTime + "s重新发送";
            if (this.delayTime < 0) {
              clearInterval(timer);
              this.AuthCode = "发送验证码";
              this.disabledCode = false;
              this.delayTime = 60;
            }
          }, 1000);
          let { data: res } = await this.$auth.get(
            "/cpiaoju-auth/login/setPhoneCode?tel=" +
              this.formLoginZc.tel +
              "&type=3"
          );
          if (res.code == 200) {
            this.$message.success(res.data);
          } else {
            this.$message.warning(res.msg);
          }
        }
      });
    },
    // 点击注册
    ZcClick() {
      this.$refs["formZC"].validate(async (v) => {
        if (!v) return;
        if (this.formLoginZc.password != this.formLoginZc.passWordAgain) {
          return this.$message.warning("请确认两次输入密码一致");
        }
        if (this.ZcCodeAgain) {
          let { data: res } = await this.$auth.post(
            "/cpiaoju-auth/login/register",
            {
              userPhone: this.formLoginZc.tel,
              password: this.formLoginZc.password,
              phoneCode: this.formLoginZc.phoneCode,
            }
          );
          if (res.code == 200) {
            this.$message.success(res.data);
            this.pageId = 1;
            this.dialogVisible = false;
          } else {
            this.$message.warning(res.msg);
            this.dialogVisible = false;
          }
        } else {
          this.$message.warning("请获取验证码");
        }
      });
    },
    // 继续注册
    continueZc() {
      this.dialogVisible = false;
      this.pageId = 2;
      this.formLoginZc.xyChecked = true;

      
      this.disabledCode = false;

      this.AuthCode = "发送验证码";
      this.delayTime = 60;
    },
    // 快捷登录获取验证码
    KjAuthCode() {
      this.KjCodeAgain = true;
      this.$refs["formKj"].validateField("userPhone", async (errMsg) => {
        if (errMsg) {
          this.error = "请输入正确的手机号,否则无法接收验证码";
          return false;
        } else {
          // 验证过mobile是没问题的，在这里做对应的操作，比如请求短信验证码接口
          this.disabledKj = true;
          let timer = setInterval(() => {
            this.delayTimeKJ = this.delayTimeKJ - 1;
            this.AuthCodeKj = this.delayTimeKJ + "s重新发送";
            if (this.delayTimeKJ < 0) {
              clearInterval(timer);
              this.AuthCodeKj = "获取验证码";
              this.disabledKj = false;
              this.delayTimeKJ = 60;
            }
          }, 1000);
          let { data: res } = await this.$auth.get(
            "/cpiaoju-auth/login/setPhoneCode?tel=" +
              this.formLoginKj.userPhone +
              "&type=1"
          );
          if (res.code == 200) {
            this.$message.success(res.data);
          } else {
            this.$message.warning(res.msg);
            clearInterval(timer);
            this.AuthCodeKj = "获取验证码";
            this.disabledKj = false;
            this.delayTimeKJ = 60;
          }
        }
      });
    },
    // 快捷登录
    LoginKj() {
      this.$refs["formKj"].validate(async (v) => {
        if (!v) return;
        localStorage.setItem("userPhoneKJ", this.formLoginKj.userPhone);
        if (this.KjCodeAgain) {
          let { data: res } = await this.$auth.post(
            "/cpiaoju-auth/login/phoneLogin",
            {
              userPhone: this.formLoginKj.userPhone,
              verify: this.formLoginKj.verify,
            }
          );
          if (res.code !== 200) {
            this.$message.warning(res.msg);
          } else {
            sessionStorage.setItem("token", res.token);
            this.$store.commit("getNum", this.formLoginKj.userPhone);
            this.$store.commit("getbackStatus", res.backStatus); //登录后企业来源--1.未授权 2.授权带回 3.不带回
            this.$store.state.backStatus = res.backStatus;
            window.sessionStorage.setItem("backStatus", res.backStatus);
            if (res.companyStatus !== 3 || res.bankStatus !== 1) {
              this.$router.push("/auth");
            } else if (this.type !== "cyl") {
              // 判断是否产品类型是供票信息
              this.$router.push("/auth");
            } else {
              this.$router.push("/ticket/ticketIndex");
            }
          }
        } else {
          this.$message.warning("请获取验证码");
        }
      });
    },
    // 登录
    Login() {
      this.$refs["form"].validate(async (v) => {
        if (!v) return;
        console.log(this.checked);
        if (this.checked) {
          localStorage.setItem("userPhone", this.formLogin.userPhone);
          localStorage.setItem("userPass", this.formLogin.userPass);
        } else {
          localStorage.removeItem("userPhone");
          localStorage.removeItem("userPass");
        }
        let { data: res } = await this.$auth.post(
          "/cpiaoju-auth/login/passLogin",
          this.$qs.stringify({
            userPhone: this.formLogin.userPhone,
            userPass: this.formLogin.userPass,
          })
        );
        if (res.code !== 200) {
          this.$message.warning(res.msg);
        } else {
          sessionStorage.setItem("token", res.token);
          this.$store.commit("getNum", this.formLogin.userPhone); //用户
          this.$store.commit("getbankStatus", res.bankStatus); //银行状态--0 处理中 1 审核成功 2 审核失败 3 待验证 4未添加
          this.$store.commit("getcompanyStatus", res.companyStatus); //登录后企业审核状态--0.保存 1.待认证 2.认证失败 3.认证通过4未认证
          this.$store.commit("getbackStatus", res.backStatus); //登录后企业来源--1.未授权 2.授权带回 3.不带回
          this.$store.state.bankStatus = res.bankStatus;
          this.$store.state.companyStatus = res.companyStatus;
          this.$store.state.backStatus = res.backStatus;
          window.sessionStorage.setItem("businessRole", res.businessRole); //登录后0小微企业 1核心企业 2保理公司 3担保公司 4律所
          window.sessionStorage.setItem("backStatus", res.backStatus); //公司

          window.sessionStorage.setItem("bankStatus", res.bankStatus); //缓存银行卡状态

          if (res.companyStatus !== 3 || res.bankStatus !== 1) {
            this.$store.commit("getNum", this.formLogin.userPhone);

            this.$router.push("/auth");
          } else if (this.type !== "cyl") {
            // 判断是否产品类型是供票信息
            this.$router.push("/auth");
          } else {
            this.$router.push("/ticket/ticketIndex");
          }
        }
      });
    },
    // 图片验证码
    getImgUrl() {
      this.$auth
        .get("/cpiaoju-auth/login/validateCode", { responseType: "blob" })
        .then((response) => {
          console.log(response.data);
          this.imgSrc = window.URL.createObjectURL(response.data);
          console.log(this.imgSrc);
        });
    },
    // 获取忘记密码页的手机验证码
    getForCode() {
      this.ForgetCodeAgain = true;
      this.$refs["formForget"].validateField("userPhone", async (errMsg) => {
        if (errMsg) {
          this.error2 = "请输入正确的手机号,否则无法接收验证码";
          return false;
        } else {
          // 验证过mobile是没问题的，在这里做对应的操作，比如请求短信验证码接口
          this.disabledFor = true;
          let timer = setInterval(() => {
            this.delayTimeForget = this.delayTimeForget - 1;
            this.AuthCodeForget = this.delayTimeForget + "s重新发送";
            if (this.delayTimeForget < 0) {
              clearInterval(timer);
              this.AuthCodeForget = "获取验证码";
              this.disabledFor = false;
              this.delayTimeForget = 120;
            }
          }, 1000);
          let { data: res } = await this.$auth.get(
            "/cpiaoju-auth/login/setPhoneCode?tel=" +
              this.formLoginForget.userPhone +
              "&type=2"
          );
          if (res.code == 200) {
            this.$message.success(res.data);
          } else {
            this.$message.warning(res.msg);
          }
        }
      });
    },
    // 忘记密码注册
    ForgetZC() {
      this.$refs["formForget"].validate(async (v) => {
        if (!v) return;
        if (
          this.formLoginForget.userPass != this.formLoginForget.passWordAgain
        ) {
          return this.$message.warning("请确认两次输入密码一致");
        }
        if (this.ForgetCodeAgain) {
          let { data: res } = await this.$auth.post(
            "/cpiaoju-auth/login/updatePassWord",
            {
              userPhone: this.formLoginForget.userPhone,
              password: this.formLoginForget.userPass,
              phoneCode: this.formLoginForget.phoneCode,
              verify: this.formLoginForget.verify,
            }
          );
          if (res.code == 200) {
            this.$message.success(res.data);
            this.pageId = 1;
          } else {
            this.$message.warning(res.msg);
          }
        } else {
          this.$message.warning("请获取验证码");
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
.el-button {
  font-weight: normal !important;
}
/deep/.el-dialog__body {
  overflow: auto !important;
  padding: 20px !important;
}
.co_bg {
  background: #eeeeee !important;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #bbbbbb !important;
  line-height: 20px;
  letter-spacing: 1px;
}
.checked_left {
  /deep/.el-checkbox__label {
    padding-left: 5px;
  }
  span {
    color: #ac0008 !important;
  }
}

.zc_check {
  margin-bottom: 32px !important;
  /deep/.el-checkbox-group {
    justify-content: flex-start !important;
  }
}
/deep/.el-checkbox-group {
  justify-content: center !important;
}
/deep/.el-form-item {
  margin-bottom: 20px;
}
/deep/.el-input__inner {
  border-radius: 6px;
}
/deep/.el-input__inner:focus {
  // el-input输入时设置边框颜色
  box-shadow: inset 0px 1px 3px 0px #ff000c;

  border: 1px solid #c70009;
}

.code_bg:hover {
  background: linear-gradient(#f6000b, #f6000b) !important;
}
.co_co:hover {
  color: #f6000b !important;
}
.center_content {
  position: relative;
  height: 682px; //calc(100% - 396px)
  background-image: url("../../assets/img/jgw1.png");
  .bg_img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -99;
  }
  .center {
    height: 682px; //calc(100% - 396px)

    position: relative;

    .text_left {
      position: absolute;
      top: 117px;
      left: 0;
      width: 531px;
      height: 336px;
      padding-left: 54px;
      padding-top: 21px;
      box-sizing: border-box;
      background-image: url("../../assets/img/jgw.png");
      background-position: -10px -10px;
      background-size: 100% 100%;
      > p {
        margin-bottom: 40px;
      }
      .first {
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 45px;
        letter-spacing: 2px;
      }
      .second {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 33px;
        letter-spacing: 2px;
      }
      .three {
        margin-bottom: 60px;
      }
      .four {
        width: 40px;
        height: 4px;
        margin-top: 53px;
        background: #ffffff;
      }
    }
    .gif_img {
      width: 360px;
      position: absolute;
      bottom: 97px;
      left: 370px;
    }
    .login_box {
      width: 400px;
      // height: 450px;
      background: #ffffff;
      box-shadow: 5px -5px 20px 0px rgba(172, 0, 8, 0.91);
      border-radius: 5px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      // top: 116px;
      right: 0;
      padding: 30px 40px;
      box-sizing: border-box;
      /deep/.el-tabs__nav {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #c70009;
        line-height: 25px;
        letter-spacing: 2px;
      }
      /deep/.el-tabs__item:hover {
        color: #c70009 !important;
      }
      /deep/.el-tabs__item.is-active {
        color: #c70009 !important;
      }
      /deep/.el-tabs__active-bar {
        background-color: #c70009 !important;
      }
      .checkPassword {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        letter-spacing: 1px;
        /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
          color: #666666;
        }
        /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: #fff;

          border-color: #999;
        }
        /deep/.el-checkbox__inner {
          border: 1px solid #c70009 !important; // 是改这里的颜色
        }
        /deep/ .el-checkbox__inner::after {
          height: 10px;
          left: 4px;
          top: -1px;
          width: 3px;
          border: 1px solid #c70009; // 是改这里的颜色
          border-left: 0;
          border-top: 0;
        }

        .el-button {
          // font-family: Arial, Verdana, Sans-serif!important;
          font-weight: normal !important;
          color: #666666;
          padding: 0;
          font-family: Arial, Verdana, Sans-serif;
        }
      }
      .btn_login {
        border: none;
        width: 100%;
        background: linear-gradient(180deg, #ff2630 0%, #c70009 100%);
        border-radius: 6px;
        letter-spacing: 1px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
        padding: 10px 20px;
        // margin-top: 30px;
        font-size: 14px;
      }
      .btn_login:hover {
        background: linear-gradient(#f6000b, #f6000b);
        // transition: background-color .3s;
      }
      .zc_btn:hover {
        background: linear-gradient(#f6000b, #f6000b);
        // transition: background-color .3s;
      }
      .btn_login1 {
        margin-top: 103px;
      }
      .zczh {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        letter-spacing: 1px;
        .el-button {
          color: #ac0008;
          padding: 0;
        }
      }
      .yzm {
        /deep/.el-form-item__content {
          display: flex;
          justify-content: space-between;
        }
        /deep/.el-form-item__content::after,
        /deep/.el-form-item__content::before {
          content: none;
        }
        .el-button {
          background: linear-gradient(180deg, #ff2630 0%, #c70009 100%);
          border-radius: 6px;

          flex: 1;
          margin-left: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          letter-spacing: 1px;
          padding: 10px 11px;
          box-sizing: border-box;
          border: none;
        }
      }
    }
    .zc {
      /deep/.el-form {
        margin-top: 0 !important;
      }
      .zc_btn {
        width: 100%;
        background: linear-gradient(180deg, #ff2630 0%, #c70009 100%);
        border-radius: 4px;
        border: none;

        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
        padding: 10px;
      }
    }
  }
}
.continue_btn {
  color: #ffffff;
  background: #c70009;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.continue_btn:hover {
  color: #ffffff;
  background: #c70009;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.continue_btn:focus {
  color: #ffffff;
  background: #c70009;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}

.el-button {
  font-size: 14px !important;
}
/deep/ .el-tabs__item {
  font-size: 18px !important;
}
/deep/input::-webkit-input-placeholder {
  font-size: 14px;
}

/deep/input::-moz-input-placeholder {
  font-size: 14px;
}

/deep/input::-ms-input-placeholder {
  font-size: 14px;
}
.abow_dialog1 {
  display: flex;
  justify-content: center;
  align-items: Center;
  overflow: hidden;
  /deep/.el-dialog__body {
    height: 76%;
    flex: 1 !important;
  }
  /deep/.el-tabs__header {
    margin: 0 !important;
  }
  /deep/.el-dialog {
    margin: 0 auto !important;
    height: 80% !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /deep/.el-dialog__body {
      padding: 20px !important;
      // position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
    }
  }
  /deep/.el-tabs__item.is-active {
    color: #c70009 !important;
  }
  /deep/.el-tabs__item:hover {
    color: #c70009 !important;
  }
}
.abow_dialog {
  display: flex;
  justify-content: center;
  align-items: Center;
  overflow: hidden;
  /deep/.el-dialog__body {
    flex: 1 !important;
  }
  /deep/.el-tabs__header {
    margin: 0 !important;
  }
  /deep/.el-dialog {
    margin: 0 auto !important;
    height: 80% !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /deep/.el-dialog__body {
      position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}
/deep/.el-tabs__active-bar {
  background-color: #fff;
}
.el_it_check {
  margin-bottom: 18px;
  // margin-top: 30px;
  /deep/.el-form-item__content {
    line-height: normal;
  }
  /deep/.el-checkbox__label {
    font-size: 12px;
  }
  span {
    color: #409eff;
  }
}
.zczh_title {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 25px;
  letter-spacing: 1.93px;
  text-align: center;
  margin-bottom: 20px;
}
</style>
<style lang="less" >
.el-tabs__nav-wrap::after {
  content: none !important;
}
</style>