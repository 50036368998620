<template>
  <div class="ztt">
    <div class="bannerImg">
      <img width="100%"
           height="600"
           src="@/assets/img/yw5.png"
           alt="">
      <div class="bannerText">
        <h1>关于乐享直贴通</h1>
        <p>乐享直贴通为持有核心企业商票的各类中小微企业提供商业承兑汇票解决方案，具有多渠道、低成本、高效率等特点。</p>
        <!-- <p class="mt20"
           style="display: block">
          <!==          <router-link to="/login">==>
          <el-button class="submit btn_jrcp"
                     @click="jump"
                     round>进 入 产 品</el-button>
          <!==          </router-link>==>
        </p> -->
      </div>
    </div>

    <div class="mainBox">
      <div class="cardBg">
        <div class="center">
          <div class="oneTitle">
            <h1>产品优势</h1>
          </div>
          <div class="flex ztt mt40">
            <div class="flexItem">
              <img src="@/assets/img/ztt_01.png"
                   alt="">
              <p>数百家央国企上市公司等核心企业商票白名单授信</p>
            </div>
            <div class="flexItem">
              <img src="@/assets/img/ztt_02.png"
                   alt="">
              <p>纯线上操作，简化流程，提供效率</p>
            </div>
            <div class="flexItem">
              <img src="@/assets/img/ztt_03.png"
                   alt="">
              <p>极速放款，银行签约审核通过后T+0日放款</p>
            </div>
            <div class="flexItem">
              <img src="@/assets/img/ztt_04.png"
                   alt="">
              <p>与银行等各类金融机构战略合作，提供多元化融资渠道</p>
            </div>
            <div class="flexItem">
              <img src="@/assets/img/ztt_05.png"
                   alt="">
              <p>白名单智能匹配、票据核验，保证融资安全可靠</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="apply">
      <h1>专属客服1v1服务，为您的需求保驾护航</h1>
      <router-link :to="{path:'/contactus',query:{type:'7'}}">
        <el-button>立即联系</el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkZTT",
  data() {
    return {};
  },
  methods: {
    jump() {
      let token = sessionStorage.getItem("token");
      if (token) {
        this.$router.push("/auth");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.flex.ztt {
  .flexItem {
    background: #ffffff;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-top: 20px;

    > img {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }

    > p {
      font-size: 18px;
      letter-spacing: 2px;
    }
  }
}
.apply {
  height: 200px;
  text-align: center;
  padding: 50px 20px 40px;
  box-sizing: border-box;
  background: url("~@/assets/img/jjfa_12@2x.png") no-repeat;

  h1 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 30px;
  }

  .el-button {
    width: 180px;
    height: 48px;
    border: 1px solid #ffffff;
    color: #ffffff;
    background: none;
  }

  .el-button:hover {
    background: #ffffff;
    color: #c70009;
  }
}
</style>