<template>
  <div class="workABS">
    <div class="block bannerImg">
      <img width="100%"
           height="600"
           src="@/assets/img/yw1.png"
           alt="">
      <div class="bannerText"
           v-if="activeName=='first'">
        <h1>关于乐享承信</h1>
        <p>
          乐享承信是核心企业在乐享数科平台以电子签名的方式，给供应商开具的可以体现交易双方基础合同之间债权债务关系的应收账款债权凭证。承信为核心企业产业链上广大企业提供全新的结算方式，具有高信用、可拆分、可转让、可融资等特性，使用灵活便捷、风险可控。承信支持供应商进行线上融资，为中小企业提供高效便捷的融资新渠道。</p>
        <!-- <p class="mt20"
           style="display: block">
           <!==          <router-link to="/login">==>
          <el-button class="submit btn_jrcp"
                     @click="jump1"
                     round>进 入 产 品</el-button>
                     <!==          </router-link>==>
        </p> -->
      </div>
      <div class="bannerText"
      style="width:32%"
           v-else>
        <h1>关于供应链票据系统</h1>
        <p>
          供应链票据系统是依托于票交所建设运营的票据系统，由具有资质的供应链金融平台为企业提供的供应链票据签发、承兑、背书、融资、到期处理、信息服务全周期、一站式管理服务系统。供应链票据系统解决了传统票据无法实现拆分流转的难题，实现了核心企业信用的多级传导。供应链票据系统实现票据1分起开，自带贸易背景，金额可自由组合，能有效降低企业融资成本。</p>
        <p class="mt20"
           style="display: block">
          <!--          <router-link :to="{name:'Login',params:{type:'cyl'}}">-->
          <el-button class="submit btn_jrcp"
                     @click="jump"
                     round>进 入 产 品</el-button>
          <!--          </router-link>-->
        </p>
      </div>
    </div>
    <el-tabs class="newsTab"
             v-model="activeName"
             @tab-click="handleClick">
      <el-tab-pane label="乐享承信"
                   name="first">
        <div class="mainBox ">
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>产品优势</h1>
              </div>
              <img width="100%"
                   height="706"
                   class="mt40"
                   src="@/assets/img/lxcx_01.png"
                   alt="">
            </div>
          </div>
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>产品流程</h1>
              </div>
              <img width="100%"
                   height="665"
                   class="mt40"
                   src="@/assets/img/lxcx_02.png"
                   alt="">
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="供应链票据系统"
                   name="second">
        <div class="mainBox">
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>产品优势</h1>
              </div>
              <div class="flex3 lxcx mt40">
                <div class="flex3Item">
                  <img src="@/assets/img/gylpj_01.png"
                       alt="">
                  <p>可等分化开具、背书、
                    贴现，极大便利企业灵
                    活使用票据。</p>
                </div>
                <div class="flex3Item">
                  <img src="@/assets/img/gylpj_02.png"
                       alt="">
                  <p>从供应链平台签发，将
                    票据签发、背书嵌入付
                    款场景。</p>
                </div>
                <div class="flex3Item">
                  <img src="@/assets/img/gylpj_03.png"
                       alt="">
                  <p>票据签发环节审核贸易
                    背景，增强票据流通性
                    与融资可得性。</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>优势对比</h1>
              </div>
              <img width="100%"
                   height="706"
                   class="mt40"
                   src="@/assets/img/gylpj_04.png"
                   alt="">
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="apply" v-if="activeName=='first'">
      <h1>专属客服1v1服务，为您的需求保驾护航</h1>
      <router-link :to="{path:'/contactus',query:{type:'2'}}">
        <el-button>立即联系</el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkLXCX",
  data() {
    return {
      activeName: "first", //切签
    };
  },
  created() {
    this.tab();
  },
  methods: {
    tab() {
      let active = this.$route.query.activeName; //切签
      if (active) {
        this.activeName = active;
      } else {
        this.activeName = "first";
      }
    },
    handleClick(tab, event) {
      //切签
      console.log(tab, event);
    },
    jump() {
      //  this.$router.push({ name: "Login", params: { type: "gyl" } });
      let token = sessionStorage.getItem("token");
      if (token) {
        this.$router.push("/auth");
      } else {
        this.$router.push({ name: "Login", params: { type: "gyl" } });
      }
    },
    jump1() {
      let token = sessionStorage.getItem("token");
      if (token) {
        this.$router.push("/auth");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.lxcx {
  .flex3Item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 380px;
    height: 284px;
    background: #ffffff;
    line-height: 28px;
    text-align: center;
    font-size: 16px;
    padding: 60px 106px;
    box-sizing: border-box;

    > img {
      margin-bottom: 20px;
    }
  }
}
.apply {
  height: 200px;
  text-align: center;
  padding: 50px 20px 40px;
  box-sizing: border-box;
  background: url("~@/assets/img/jjfa_12@2x.png") no-repeat;

  h1 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 30px;
  }

  .el-button {
    width: 180px;
    height: 48px;
    border: 1px solid #ffffff;
    color: #ffffff;
    background: none;
  }

  .el-button:hover {
    background: #ffffff;
    color: #c70009;
  }
}
</style>