<template>
  <div class="caseDetail">
    <div class="bannerImg position">
      <img v-if="detailIndex == 0" width="100%" height="600" src="@/assets/img/nm_01.png" alt="">
      <img v-if="detailIndex == 1" width="100%" height="600" src="@/assets/img/nm_2.png" alt="">
      <div class="bannerTip" v-if="detailIndex == 0" style="width: 480px">
        <img src="@/assets/img/yitai_logo.png" alt="">
        <p class="mt40"><span>行业类型</span><em>煤炭</em></p>
        <p><span>企业类型</span><em>股份有限公司</em></p>
        <p><span>注册资本</span><em>1000000万元人民币</em></p>
      </div>
      <div class="bannerTip" v-if="detailIndex == 1" style="width: 480px">
        <img src="@/assets/img/nm_3.png" alt="">
        <p class="mt40"><span>行业类型</span><em>医疗</em></p>
        <p><span>企业类型</span><em>股份有限公司</em></p>
        <p><span>注册资本</span><em>1000000万元人民币</em></p>
      </div>
    </div>
    <!--    <div class="bannerImg position" >-->
    <!--      <img  width="100%" height="600" src="@/assets/img/nm_2.png" alt="">-->
    <!--      <div class="bannerTip">-->
    <!--        <img src="@/assets/img/nm_3.png" alt="">-->
    <!--        <p class="mt40"><span>行业类型2</span><em>医疗</em></p>-->
    <!--        <p><span>企业类型</span><em>股份有限公司</em></p>-->
    <!--        <p><span>注册资本</span><em>1000000万元人民币</em></p>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="center detail">
      <h1>客户名称</h1>
      <h4>{{caseDetail.case[detailIndex].title}}</h4>
      <h1>合作类型</h1>
      <h4>{{caseDetail.case[detailIndex].type}}</h4>
      <h1>解决方案</h1>
      <h4><span>{{caseDetail.case[detailIndex].solution}}</span></h4>
      <h1 v-text="detailIndex==0?'实现价值':'实现流程'"></h1>
      <h4>
        <span>{{caseDetail.case[detailIndex].value}}</span>
      </h4>
      <img class="mt60" :src="caseDetail.case[detailIndex].img1" alt="">
      <img class="mt60" :src="caseDetail.case[detailIndex].img2" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    name: "CaseDetail",
    data() {
      return {
        detailIndex: this.$route.query.detailIndex,
        caseDetail: {
          case: [
            {
              title: '鄂尔多斯市伊泰投资控股有限责任公司',
              type: '合资公司',
              solution: '2018年8月，为了顺应煤炭交易的庞大体量与能源化工的飞速发展，助力核心企业建立票据生态体系，解决中小供应商企业融资难题。乐享数科携手\n' +
                  '鄂尔多斯市伊泰投资控股有限责任公司、内蒙古天眼股权投资中心共同设立了国安大数据科技有限公司。\n' +
                  '\n' +
                  '历经2年多的布局开发与深耕合作，针对伊泰集团与链属企业的核心需求，设计全流程线上化服务，打造国安供应链金融服务平台，为企业用户提供一\n' +
                  '站式金融服务体系；目前平台已实现与平安、建设等多家银行的系统对接及多元服务，助力核心企业创新融合、布局未来。平台的票据服务数据从201\n' +
                  '8年底的8.6亿元，发展至2021年底的71.1亿元；截止2021年底，平台共计合作的核心企业15家，注册的企业会员近600家，合作的金融机构20余家，\n' +
                  '累计服务各类供应链金融业务共计约123亿元。',
              value: '同时，平台先后运营了资产服务中心与商业保理公司，为了更好的服务中小融资客户，不断提升伊泰商票的市场认可度与融资竞争力，打破利率壁垒，\n' +
                  '助力流转融通；综合贴现利率已从2019年的年化8%，逐步降低至2021年的年化3.58%，在增加业务签发的同时不断降低利率。\n' +
                  '\n' +
                  '未来，我们将助力推动区域“煤炭结算票据化、融资服务便捷化、票据流转信用化”，打造区域的卓越品牌；从而让合作的核心企业实现降低财务成本、\n' +
                  '促进信用流转、提高融资效率、稳固生态模式的发展理念。',
              img1: require('@/assets/img/echarts1.png'),
              img2: require('@/assets/img/echarts2.png'),
            },
            {
              title: '安徽中技国医医疗科技有限公司',
              type: '合资公司',
              solution: "近年来医用耗材市场以每年20%的速度高速增长，但医院医用耗材整体管理水平不高，目前仍处于进销存管理、线下采购、纸质对账的半自动化管理\n" +
                  "阶段。同时，“两票制”政策的全面执行，也使得流通企业的资金压力越来越大。在此背景下，SPD+供应链金融的服务模式应运而生。\n" +
                  "\n" +
                  "SPD+供应链金融是基于供应商与医院之间真实贸易背景，以应收账款作为参考的金融创新模式。借助先进的大数据、区块链、云计算、智能风控等技\n" +
                  "术，为供应链各环节客户提供全方位可定制的金融科技解决方案，将有效解决链上成员的融资难题，提高供应商资金周转速度；并且链上企业无需提供\n" +
                  "抵押和保证担保，即可享有轻松快捷的线上融资。",
              value: "1.银行为医院批复总额度，在总额度项下，医院向银行推荐优质耗材供应商；" + "\n" +
                  "2.银行为单个耗材供应商批复直接授信额度；" + "\n" +
                  "3.基于SPD系统的结算单、医院、供应商三方进行平台在线确认；" + "\n" +
                  "4.供应商通过服务平台、提供结算单、明细单、发票提交融资申请；" + "\n" +
                  "5.银行对结算单、发票进行核验、进行放款；" + "\n" +
                  "6.到期后医院进行还款，银行锁定还款账户、到期自动扣划；",
              img1: require('@/assets/img/echarts3.png'),
            }
          ],
        },
      }
    }
  }
</script>

<style lang="less" scoped>
  .caseDetail {
    width: 100%;
  }

  .bannerImg.position {
    position: relative;

    .bannerTip {
      position: absolute;
      top: 100px;
      /*right: 370px;*/
      right: 10%;
      background: #ffffff;
      width: 480px;
      height: 400px;
      padding: 60px 30px;
      box-sizing: border-box;

      p {
        font-size: 16px;
        line-height: 70px;

        span {
          display: inline-block;
          width: 100px;
          color: #999999;
        }
      }
    }
  }

  .detail {
    padding-bottom: 60px;
    box-sizing: border-box;

    h1 {
      font-size: 32px;
      margin-top: 55px;
    }

    h4 {
      font-size: 16px;
      color: #818181;
      font-weight: 400;
      margin-top: 30px;

      span {
        display: block;
        /*margin-top: 22px;*/
        white-space: pre;
        line-height: 24px;
      }
    }
  }

</style>