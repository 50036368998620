<template>
  <div class="kd">
    <!-- 微掌柜-->
    <div class="block bannerImg">
      <img width="100%"
           height="600"
           src="@/assets/img/yw3.png"
           alt="">
      <div class="bannerText"
           v-if="activeName=='first'">
        <h1>关于微掌柜</h1>
        <p>微掌柜是为帮助产业链上中小企业解决账期长、回款慢、融资渠道单一、融资门槛高等问题提供的全新解决方案。具有简单便捷、高效灵活的特点。</p>
        <p class="mt20"
           style="display: block">
          <!--          <router-link to="/login">-->
          <el-button class="submit btn_jrcp"
                     @click="jump"
                     round>进 入 产 品</el-button>
          <!--          </router-link>-->
        </p>
      </div>
      <div class="bannerText"
           v-if="activeName=='second'">
        <h1>兴惠贷</h1>
        <p>“兴惠贷”产品是服务小微企业发展，为小微企业提供纯线上的融资服务。该产品利用大数据技术对客户进行风险画像，通过风险量化模型核定贷款额度，借款流程全部在线完成，全程无纸化操作，为企业提供快速便捷的流动资金贷款。</p>
        <!-- <p class="mt20"
           style="display: block">
          <!==          <router-link to="/login">==>
          <el-button class="submit btn_jrcp"
                     @click="jump"
                     round>进 入 产 品</el-button>
          <!==          </router-link>==>
        </p> -->
      </div>
      <div class="bannerText"
           v-if="activeName=='third'">
        <h1>关于小微企业信用贷</h1>
        <p>为中小微企业打造数字化融资服务中心，助力企业经营发展，降低融资门槛，解决小微企业融资难的瓶颈。乐享数科响应国家号召打造经营场景+数据增信+服务中小微企业的数字化融资平台</p>
        <p class="mt20"
           style="display: block">
          <!--          <router-link to="/login">-->
          <el-button class="submit btn_jrcp"
                     @click="jump"
                     round>进 入 产 品</el-button>
          <!--          </router-link>-->
        </p>
      </div>
    </div>

    <el-tabs class="newsTab"
             v-model="activeName"
             @tab-click="handleClick">
      <el-tab-pane label="微掌柜"
                   name="first">
        <div class="mainBox">
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>产品优势</h1>
              </div>
              <div class="flex4 product mt40">
                <div class="flex4Item">
                  <img src="@/assets/img/wzg_01.png"
                       alt="">
                  <p>全流程线上操作，足不出户轻松融资</p>
                </div>
                <div class="flex4Item">
                  <img src="@/assets/img/wzg_02.png"
                       alt="">
                  <p>当日审批，审核通过后，最快10分钟即时放款</p>
                </div>
                <div class="flex4Item">
                  <img src="@/assets/img/wzg_03.png"
                       alt="">
                  <p>额度300万，循环使 用，后期可提高授信额度</p>
                </div>
                <div class="flex4Item">
                  <img src="@/assets/img/wzg_04.png"
                       alt="">
                  <p>直连银行系统，利率透明更安全</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>准入要求</h1>
              </div>
              <div class="flex2 wzgAsk">
                <div class="flex2Item">
                  <img src="@/assets/img/wzg_05.png"
                       alt="">
                  <p>企业成立时间超过1年</p>
                </div>
                <div class="flex2Item">
                  <img src="@/assets/img/wzg_06.png"
                       alt="">
                  <p>法定代表人为22-65岁中国公民</p>
                </div>
                <div class="flex2Item">
                  <img src="@/assets/img/wzg_07.png"
                       alt="">
                  <p>企业及法人征信记录良好，无不良记录</p>
                </div>
                <div class="flex2Item">
                  <img src="@/assets/img/wzg_08.png"
                       alt="">
                  <p>企业经营状态正常</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="兴惠贷"
                   name="second">
        <div class="mainBox">
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>产品优势</h1>
              </div>
              <div class="flex4 product mt40">
                <div class="flex4Item">
                  <img src="@/assets/img/hxh_01.png"
                       alt="">
                  <p>额度大 ，单户300万，最高10个亿</p>
                </div>
                <div class="flex4Item">
                  <img src="@/assets/img/hxh_02.png"
                       alt="">
                  <p>操作简单，全程线上操作，保姆式融资服务</p>
                </div>
                <div class="flex4Item">
                  <img src="@/assets/img/hxh_03.png"
                       alt="">
                  <p>利率低，利率是市场同类信用贷产品1/3</p>
                </div>
                <div class="flex4Item">
                  <img src="@/assets/img/hxh_04.png"
                       alt="">
                  <p>灵活还款，按日计息，额度可循环</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>准入条件</h1>
              </div>
            </div>
            <div class="admin">
              <div class="redBg"></div>
              <div class="center adminCon">
                <div class="pos">
                  <div class="left">
                    <h1>准入条件</h1>
                    <div class="text">
                      <p><i></i><span>成立时间不低于1年；</span></p>
                      <p><i></i><span>企业无重大涉诉及被执行情况，不涉及金融纠纷等相关诉讼情况；</span></p>
                      <p><i></i><span>禁入行业黑名单，包括：娱乐业、从事危险化学品生产经营的行业、赌博及赌博机具相关制造业及批发零售业、从事金融及金融衍生行业、违法经营行业。</span></p>
                    </div>
                  </div>
                  <img src="@/assets/img/hxh_05.png"
                       alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="小微企业信用贷"
                   name="third">
        <div class="mainBox">
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>产品优势</h1>
              </div>
              <div class="ys_context">
                <div>
                  <img src="@/assets/img/xw_01.png"
                       alt="">
                  <div>
                    <div class="title">额度高</div>
                    <div class="cont">单笔额度可达500万</div>
                  </div>
                </div>
                <div>
                  <img src="@/assets/img/xw_02.png"
                       alt="">
                  <div>
                    <div class="title">易申请</div>
                    <div class="cont">放款迅速，可实现当天放款，当天到账</div>
                  </div>
                </div>
                <div>
                  <img src="@/assets/img/xw_03.png"
                       alt="">
                  <div>
                    <div class="title">压力小</div>
                    <div class="cont">随借随还，循环授信，年化6%-15%</div>
                  </div>
                </div>
                <div>
                  <img src="@/assets/img/xw_04.png"
                       alt="">
                  <div>
                    <div class="title">区域广</div>
                    <div class="cont">除西藏、新疆地区外，全国适用</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle"
                   style="margin-bottom:40px">
                <h1>操作流程</h1>
              </div>
              <img src="@/assets/img/xw_05.png"
                   alt="">
            </div>

          </div>
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>准入条件</h1>
              </div>
              <div class="zr_content">
                <img src="@/assets/img/xw_06.png"
                     alt="">
                <div>
                  <h2>
                    准入条件
                  </h2>
                  <div>
                    <i class="ii"></i><span>企业营业执照满1年</span>
                  </div>
                  <div>
                    <i class="ii"></i><span>法人6个月内没有变更</span>
                  </div>
                  <div>
                    <i class="ii"></i><span>法人年龄20-60岁</span>
                  </div>
                  <div>
                    <i class="ii"></i><span>有12个月开票记录，金额在50万以上，最多断票不连续超过3个月</span>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="apply" v-if="activeName=='second'">
      <h1>专属客服1v1服务，为您的需求保驾护航</h1>
      <router-link :to="{path:'/contactus',query:{type:'5'}}">
        <el-button>立即联系</el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkWZG",
  data() {
    return {
      activeName: "first", //切签
    };
  },
  created() {
    this.tab();
  },
  methods: {
    tab() {
      let active = this.$route.query.activeName; //切签
      if (active) {
        this.activeName = active;
      } else {
        this.activeName = "first";
      }
    },
    handleClick(tab, event) {
      //切签
      console.log(tab, event);
    },
    jump() {
      let token = sessionStorage.getItem("token");
      if (token) {
        this.$router.push("/auth");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.flex4.product {
  display: flex;
  justify-content: space-between;

  .flex4Item {
    width: 24%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
    box-sizing: border-box;

    > img {
      width: 60px;
      height: 60px;
      margin-bottom: 20px;
    }

    > p {
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 1px;
      text-align: center;
    }
  }
}

.flex2.wzgAsk {
  flex-wrap: wrap;

  .flex2Item {
    width: 48%;
    display: flex;
    align-items: center;
    margin-top: 60px;

    > img {
      width: 50px;
      height: 57px;
      margin-right: 20px;
    }

    > p {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 2px;
    }
  }
}

.admin {
  position: relative;
  height: 438px;
  margin-top: 80px;

  .redBg {
    position: absolute;
    width: 1235px;
    height: 438px;
    background: linear-gradient(360deg, #c70009 0%, #e50015 100%);
    z-index: 1;
  }

  .adminCon {
    height: 300px;
    background: #42b983;
    z-index: 2;

    .pos {
      display: flex;
      width: 100%;
      position: relative;
      z-index: 3;

      .left {
        margin-top: 50px;

        > h1 {
          font-size: 18px;
          color: #ffffff;
          line-height: 25px;
          letter-spacing: 1px;
        }

        .text {
          width: 500px;
          height: 293px;
          background: rgba(255, 255, 255, 0.3);
          padding: 20px 30px;
          box-sizing: border-box;
          margin-top: 20px;

          p {
            display: flex;
            font-size: 16px;
            color: #ffffff;
            line-height: 26px;
            letter-spacing: 1px;
            align-items: flex-start;
            margin-bottom: 12px;

            > i {
              width: 4px;
              height: 4px;
              border-radius: 100%;
              background: #ffffff;
              margin-right: 10px;
              margin-top: 10px;
            }

            > span {
              flex: 1;
            }
          }
        }
      }

      img {
        position: absolute;
        top: -40px;
        right: 0;
        width: 660px;
        height: 440px;
        z-index: 3;
      }
    }
  }
}
.ys_context {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  > div {
    width: 49%;
    background-color: #fff;
    padding: 40px;
    box-sizing: border-box;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    > img {
      margin-right: 15px;
    }
    > div {
      .title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        letter-spacing: 2px;
        margin-bottom: 9px;
      }
      .cont {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        letter-spacing: 1px;
      }
    }
  }
}
.zr_content {
  margin-top: 40px;
  position: relative;
  display: flex;
  align-items: center;
  > div {
    width: 636px;
    height: 307px;
    background: #fff;
    position: absolute;
    z-index: 99;
    left: 544px;
    border-right: 20px solid #c70009;
    box-sizing: border-box;
    padding-top: 57px;
    padding-left: 40px;
    > h2 {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #262626;
      line-height: 25px;
      letter-spacing: 1px;
      margin-bottom: 20px;
    }
    > div {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .ii {
        width: 5px;
        height: 5px;
        background: #c70009;
        display: inline-block;
        margin-right: 8px;
      }
      > span {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #262626;
        line-height: 22px;
        letter-spacing: 1px;
      }
    }
  }
}
.apply {
  height: 200px;
  text-align: center;
  padding: 50px 20px 40px;
  box-sizing: border-box;
  background: url("~@/assets/img/jjfa_12@2x.png") no-repeat;

  h1 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 30px;
  }

  .el-button {
    width: 180px;
    height: 48px;
    border: 1px solid #ffffff;
    color: #ffffff;
    background: none;
  }

  .el-button:hover {
    background: #ffffff;
    color: #c70009;
  }
}
</style>