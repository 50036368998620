<template>
  <div>
    <el-breadcrumb separator="/"
                   class="mbx">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>账户信息</el-breadcrumb-item>
      <el-breadcrumb-item>电子账簿管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="bank_card">
      <el-tabs v-model="activeName"
               @tab-click="handleClick">
        <el-tab-pane :label="'已开通('+count1+')'"
                     name="first">
          <div class="bankCard_for"
               v-if="status==3">
            <div class="bindBank">
              <div class="yhk_img">
                <img src="../../../../assets/img/yh_logo/yh_19.png"
                     alt="">
              </div>
              <div class="bank_num">
                <span>仅适用于有关众邦银行业务</span>

              </div>
              <div class="bank_btn"
                   v-if=" status == 3">
                <el-button type="text"
                           @click="$router.push({path:'/aside/virtualBnakList/openDetail',query:{num:1}})">开户详情</el-button><span>|</span>
                <el-button type="text"
                           @click="virtualList"
                           class="cen_btn">虚户列表</el-button><span>|</span>
                <el-button type="text"
                           @click="$router.push('/aside/virtualBnakList/clientList')">实户列表</el-button>

              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="'未开通('+count2+')'"
                     name="second">
          <div class="bankCard_for"
               v-if="status==0">
            <div class="bindBank">
              <div class="yhk_img">
                <img src="../../../../assets/img/yh_logo/yh_19.png"
                     alt="">
              </div>
              <div class="bank_num">
                <span>仅适用于有关众邦银行业务</span>

              </div>

              <div class="bank_btn"
                   v-if=" status == 0">
                <el-button type="text"
                           @click="openXh">开通虚户</el-button>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="'待验证('+count3+')'"
                     name="three">
          <div class="bankCard_for"
               v-if="status==6">
            <div class="bindBank">
              <div class="yhk_img">
                <img src="../../../../assets/img/yh_logo/yh_19.png"
                     alt="">
              </div>
              <div class="bank_num">
                <span>仅适用于有关众邦银行业务</span>

              </div>

              <div class="bank_btn"
                   v-if=" status == 6">
                <el-button type="text"
                           @click="$router.push({path:'/aside/virtualBnakList/remittance',query:{bankAccountNumber}})">去验证</el-button>
                           <!-- $router.push({path:'/aside/virtualBnakList/remittance',query:{bankAccountNumber}}) -->
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="'审核中('+count4+')'"
                     name="four">
          <div class="bankCard_for"
               v-if="status==2||status==5">
            <div class="bindBank">
              <div class="yhk_img">
                <img src="../../../../assets/img/yh_logo/yh_19.png"
                     alt="">
              </div>
              <div class="bank_num">
                <span>仅适用于有关众邦银行业务</span>

              </div>

              <div class="bank_btn"
                   v-if="  status == 2 || status == 5">
                <el-button type="text"
                           @click="$router.push({path:'/aside/virtualBnakList/openDetail',query:{num:1}})">开户详情</el-button>
              </div>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane :label="'开户失败('+count5+')'"
                     name="five">
          <div class="bankCard_for"
               v-if="status==4||status==7">
            <div class="bindBank">
              <div class="yhk_img">
                <img src="../../../../assets/img/yh_logo/yh_19.png"
                     alt="">
              </div>
              <div class="bank_num">
                <span>仅适用于有关众邦银行业务</span>

              </div>

              <div class="bank_btn"
                   v-if="  status == 7 || status == 4">
                <el-button type="text"
                           @click="$router.push({path:'/aside/virtualBnakList/openDetail',query:{status}})">重新开通</el-button>
              </div>

            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      bankAccountNumber: "", //银行卡号后四位
      count1: 0,
      count2: 0,
      count3: 0,
      count4: 0,
      count5: 0,
      activeName: "first",
      BankList: [], //虚户
      status: "",
      bankStatus: "", //银行卡认证状态
      getStatus: "", //企业认证状态
    };
  },
  created() {
    this.bankStatus = sessionStorage.getItem("bankStatus");
    this.getStatus = sessionStorage.getItem("getStatus");
    this.getXhList();
  },
  methods: {
    handleClick(tab, event) {
      this.getXhList();
    },
    async getXhList() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/zxlrOpenAccount/accountManagePage"
      );
      if (res.code !== 200) return this.$message.warning(res.msg);
      this.BankList = res.data.zbAccount;
      if (res.data.zbAccount) {
        this.bankAccountNumber = res.data.zbAccount.bankAccountNumber.substr(
          res.data.zbAccount.bankAccountNumber.length - 4,
          4
        );
      }
      this.count1 = res.count1;
      this.count2 = res.count2;
      this.count3 = res.count3;
      this.count4 = res.count4;
      this.count5 = res.count5;
      if (res.data.status) {
        this.status = res.data.status;
      } else {
        this.status = res.data.zbAccount.status;
      }
    },
    // 跳转虚户列表
    virtualList() {
      this.$router.push("/aside/virtualBnakList/virtualList");
    },
    // 跳转开通虚户
    openXh() {
      if (this.getStatus != 3) {
        this.$message.error("企业未完成验证");
        // this.$router.push("/aside/companyinfo");
      } else if (this.bankStatus != 1) {
        this.$message.error("银行卡验证未通过");
        this.$router.push("/aside/virtualBnakList");
      } else {
        this.$router.push("/aside/virtualBnakList/openVirtual");
      }
    },
    // 去验证
    quyz(){
      
    }
  },
};
</script>

<style lang="less" scoped>
.mbx {
  height: 56px;
  background-color: #fff;
  line-height: 56px;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.bank_card {
  background-color: #fff;
  padding: 10px 20px 23px;
  /deep/.el-tabs__nav {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #c70009;
    line-height: 25px;
    letter-spacing: 2px;
  }
  /deep/.el-tabs__item:hover {
    color: #c70009 !important;
  }
  /deep/.el-tabs__item.is-active {
    color: #c70009 !important;
  }
  /deep/.el-tabs__active-bar {
    background-color: #c70009 !important;
  }

  .bankCard_for {
    padding: 0 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .bindBank {
      padding: 30px 20px;
      box-sizing: border-box;
      width: 400px;
      height: 240px;
      background: #fafafa;
      box-shadow: 0px 2px 4px 0px #dbdbdb;
      border-radius: 25px;
      position: relative;
      margin-bottom: 20px;
      .yhk_img {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > img {
          width: 220px;
        }
      }
      .bank_num {
        display: flex;
        justify-content: space-around;
        margin-top: 39px;
        span {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          letter-spacing: 1px;
        }
      }
      .bank_btn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 48px;
        background: rgba(207, 224, 255, 0.29);
        border-radius: 0px 0px 25px 25px;
        backdrop-filter: blur(9px);
        display: flex;
        justify-content: space-around;
        align-items: center;

        font-size: 16px;
        font-weight: 400;
        color: #acacac;
        line-height: 22px;
        .el-button {
          width: 33%;
          font-size: 16px;
          font-weight: 400;
          color: #acacac;
          line-height: 22px;
        }
      }
    }
  }
}
</style>