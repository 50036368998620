<template>
  <div>
    <el-breadcrumb separator="/"
                   class="mbx">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>账户信息</el-breadcrumb-item>
      <el-breadcrumb-item>电子账簿管理</el-breadcrumb-item>
      <el-breadcrumb-item>实户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="bank_card">
      <el-button class="retur_router return_btn"
                 @click="$router.go(-1);">返回</el-button>

      <el-button class="retur_router"
                 @click="addXh">添加帐户</el-button>

      <el-table :data="recordData"
                style="width: 100%">
        <el-table-column type="index"
                         label="序号"
                         width="50">
        </el-table-column>
        <el-table-column prop="bankNumber"
                         label="银行账号">
        </el-table-column>
        <el-table-column prop="bankName"
                         label="开户银行">

        </el-table-column>
        <el-table-column prop="bankBranch"
                         label="银行支行"
                         min-width="145">

        </el-table-column>
        <el-table-column prop="clearingNumber"
                         min-width="100"
                         label="清算联行号">
        </el-table-column>
        <el-table-column prop="createTime"
                         width="150"
                         label="添加时间">

        </el-table-column>
        <el-table-column prop="status"
                         width="70"
                         label="状态">
          <template slot-scope="scope">
            {{scope.row.status==0?'待验证':scope.row.status==1?'验证成功':scope.row.status==2?'验证失败':'验证锁定'}}
          </template>
        </el-table-column>
        <el-table-column align="right"
                         label="操作"
                         min-width="60">
          <!-- width="120" -->
          <template slot-scope="scope">
            <!-- <el-button type="text"
                       size="small"
                       @click="editXh(scope.row)">编辑</el-button> -->
            <!--   v-if="scope.row.status==2" -->
            <el-button type="text"
                       size="small"
                       @click="del(scope.row.id)">删除</el-button>
            <el-button v-if="scope.row.status==0||scope.row.status==3"
                       type="text"
                       @click="quyz(scope.row.id,scope.row.bankAccountId,scope.row.bankNumber)"
                       size="small">去验证</el-button>

          </template>
        </el-table-column>

      </el-table>
      <!-- 分页
      <el-pagination style="margin-top:10px"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="page"
                     :page-sizes="[10, 20, 30, 40]"
                     :page-size="limit"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination> -->
    </div>
    <el-dialog :visible.sync="addxhDialog"
    @close='reset'
               :title="title"
               class="tjxh"
               width="30%">

      <el-form :model="TxDataFrom"
               label-width="86px"
               :rules="rules"
               ref="TxDataFrom"
               class="demo-ruleForm">
        <el-form-item prop="fictitiousAccount"
                      label="付款账号：">
          <el-input disabled
                    v-model="TxDataFrom.fictitiousAccount"
                    placeholder="请输入虚拟账户ID"></el-input>
        </el-form-item>
        <el-form-item style="margin-top:10px"
                      prop="accountNo"
                      label="收款账户：">
          <el-select v-model="TxDataFrom.accountNo"
                     @change="skzhSlectChange"
                     placeholder="请选择收款账号">
            <el-option v-for="(item,index) in skzh"
                       :label="item.bankYhName"
                       :value="item.id"
                       :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="lxh"
                      label="联行号：">
          <el-input disabled
                    v-model="TxDataFrom.lxh"
                    placeholder="请输入联行号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="addxhDialog = false"
                   class="btn_right">取消</el-button>
        <el-button class="btn_right btn_sub"
                   @click="ktxhSubmit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog class="ncdialog"
               width="30%"
               title="小额打款验证"
               :visible.sync="innerVisible"
               append-to-body>
      <div>
        <div class="jinggao">
          <div>
            <img src="../../../../assets/img/hk_01@2x.png"
                 alt="">
            <span class="yz_top">我们已向您尾号({{bankNumber}})的银行账号转入验证小款</span>

          </div>

          <div class="yz_span">请尽快查收验证！</div>
        </div>
        <div class="yz_input">
          <div>
            <span class="je">验证金额:</span>
            <el-input v-model="amount"></el-input><span>元</span>
          </div>

        </div>
      </div>
      <div slot="footer"
           class="dialog-footer">
        <!-- <el-button size="mini"
                   @click="submitAgain">未收到小额，重新提交</el-button> -->
        <el-button type="primary"
                   size="mini"
                   @click="Submityz">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "添加账户",
      id: "",
      bankAccountId: "",
      amount: "",
      innerVisible: false,
      addxhDialog: false, //添加虚户
      disable: false,
      TxDataFrom: {},
      dialogVisible: false,
      activeName: "first",
      skzh: [],
      recordData: [], //实户列表
      rules: {},
      bankNumber: "", //银行卡后四位
    };
  },
  created() {
    this.getVirList();
    this.getBankSelect();
  },
  methods: {
    reset(){
      this.$refs['TxDataFrom'].resetFields()
    },
    async getBankSelect() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/zxlrFictitiousBankAccount/fictitiousBankAccount"
      );
      this.skzh = res.data.YhBankAccount;
    },
    // 获取实户列表
    async getVirList() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/zxlrFictitiousBankAccount/listByPage"
      );
      if (res.code !== 0) return this.$message.warning(res.msg);
      this.recordData = res.data.records;
    },
    //监听 页面条数 改变的事件
    handleSizeChange(newSize) {
      this.limit = newSize;
    },
    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.page = newPage;
    },
    handleClick() {},
    // 修改银行账户
    skzhSlectChange(val) {
      this.$forceUpdate();
      this.skzh.forEach((item) => {
        if (item.id == val) {
          this.TxDataFrom.fictitiousAccount = item.bankNumber;
          this.TxDataFrom.lxh = item.clearingNumber;
        }
      });
    },
    // 添加账户
    addXh() {
      this.title = "添加账户";
      this.addxhDialog = true;
    },
    // 编辑账户
    editXh(row) {
      this.TxDataFrom.fictitiousAccount = row.bankNumber;
      this.TxDataFrom.lxh = row.clearingNumber;
      this.TxDataFrom.accountNo = Number(row.bankAccountId);
      this.TxDataFrom.id = row.id;
      this.title = "编辑账户";
      this.addxhDialog = true;
    },
    // 添加账户+编辑账户
    async ktxhSubmit() {
      let url =
        this.title == "添加账户"
          ? "/cpiaoju-auth/zxlrFictitiousBankAccount/saveBankAccount"
          : "/cpiaoju-auth/zxlrFictitiousBankAccount/updateBankAccount";
      const { data: res } = await this.$auth.post(
        url,
        this.$qs.stringify({
          bankAccountId: this.TxDataFrom.accountNo,
          id: this.TxDataFrom.id ? this.TxDataFrom.id : undefined,
        })
      );
      if (res.code !== 0) return this.$message.warning(res.msg);
      this.getVirList();
      this.$message.success(res.msg);
      this.addxhDialog = false;
    },
    quyz(id, bankAccountId, bankNumber) {
      this.bankNumber = bankNumber.substr(bankNumber.length - 4, 4);
      this.innerVisible = true;
      this.id = id;
      this.bankAccountId = bankAccountId;
    },
    // 未收到小额,重新发送
    async submitAgain() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/zxlrFictitiousBankAccount/resSmallAuthentication",
        this.$qs.stringify({
          id: this.bankAccountId,
        })
      );
      if (res.code !== 200) return this.$message.warning(res.msg);
      this.$message.warning(res.data);
    },
    // 去验证
    async Submityz() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/zxlrFictitiousBankAccount/smallAuthentication",
        this.$qs.stringify({
          id: this.bankAccountId,
          amount: this.amount,
          fictitiousBankAccountId: this.id,
        })
      );
      if (res.code !== 200) return this.$message.warning(res.msg);
      this.$message.warning(res.data);
      this.innerVisible = false;
    },
    // 删除
    async del(id) {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/zxlrFictitiousBankAccount/updateStatus?id=" + id
      );
      if (res.data != 200) return this.$message.warning(res.msg);
      this.$message.success(res.msg);
      this.getVirList();
    },
  },
};
</script>
<style lang="less" scoped>
.mbx {
  height: 56px;
  background-color: #fff;
  line-height: 56px;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.aside_right {
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    .bank_card {
      flex: 1;
    }
  }
}
.bank_card {
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px 20px 23px;
  /deep/.el-tabs__nav {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #c70009;
    line-height: 25px;
    letter-spacing: 2px;
  }
  /deep/.el-tabs__item:hover {
    color: #c70009 !important;
  }
  /deep/.el-tabs__item.is-active {
    color: #c70009 !important;
  }
  /deep/.el-tabs__active-bar {
    background-color: #c70009 !important;
  }

  .retur_router {
    float: right;
    width: 86px;
    height: 34px;
    background: #c70009;
    border-radius: 4px;
    padding: 7px 15px;
    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid #c70009;

    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
  }
  .return_btn {
    padding: 7px 29px;
    border: 1px solid #c70009;
    background-color: #fff;
    color: #c70009;
    margin-left: 10px;
    box-sizing: border-box;
  }
  /deep/.el-table {
    margin-top: 54px;
  }
  /deep/.el-table thead {
    height: 54px;
    padding-top: 17px;
    padding-bottom: 15px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    letter-spacing: 1px;
  }
  /deep/.el-table .el-table__cell {
    padding: 0;
  }
  /deep/.el-table .cell {
    padding: 0 5px;
  }
  /deep/.el-table th.el-table__cell > .cell {
    padding: 0 5px;
  }
  /deep/.el-table th.el-table__cell {
    background: #fafafa;
  }
  .el-button--text {
    color: #c70009;
  }
}
// 弹框
/deep/.el-dialog__header {
  padding: 15px 20px;
  border-bottom: 1px solid #f0f3ef;
  /deep/.el-dialog__title {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 25px;
    letter-spacing: 1px;
  }
}
/deep/.el-input {
  // width: 90%;
}
/deep/.el-select {
  width: 100%;
}
/deep/ .el-dialog__body {
  padding-top: 20px;
  padding-right: 40px;
  position: relative;
  .ab_time {
    position: absolute;
    top: -38px;
    left: 72px;

    font-size: 16px;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
    letter-spacing: 1px;
  }
}
.dxyzm {
  /deep/.el-input {
    width: none;
  }
  /deep/.el-form-item__content {
    display: flex;
  }
  .el-button {
    background: #c70009;
    border-radius: 4px;
    border: none;
  }
}
.dialog-footer {
  .btn_right {
    padding: 0;
    width: 86px;
    height: 34px;
    border-radius: 4px;
    border: 1px solid #c70009;

    font-size: 14px;
    font-weight: 400;
    color: #c70009;
    line-height: 20px;
  }
  .btn_sub {
    background: #c70009;

    color: #ffffff;
  }
}
.tjxh {
  /deep/.header-title {
    display: flex;
    align-items: center;
  }
  /deep/.el-dialog__body {
    padding: 20px 49px;
    color: #c70009;
  }
}

.ncdialog {
  /deep/.el-dialog__header {
    padding: 17px 20px;
  }
  /deep/.el-dialog__body {
    padding: 6px 20px;
  }
  /deep/.el-dialog__footer {
    margin-top: 50px;
  }
}
.jinggao {
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  background: #fffbe6;
  box-shadow: 0px 2px 31px 0px rgba(234, 234, 234, 0.5);
  border: 1px solid #fee58f;
  > div {
    display: flex;
    align-items: center;
    > img {
      width: 20px;
      margin-right: 10px;
    }
    .yz_top {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      letter-spacing: 1px;
    }
  }
  .yz_span {
    margin-top: 5px;
    padding-left: 30px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    letter-spacing: 1px;
  }
}
.yz_input {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .je {
    // width: 100px;
  }
  .el-input {
    width: 220px;
    margin: 0 5px;
  }
}
</style>