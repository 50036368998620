import { render, staticRenderFns } from "./MicroCreditLoans.vue?vue&type=template&id=80031160&scoped=true&"
import script from "./MicroCreditLoans.vue?vue&type=script&lang=js&"
export * from "./MicroCreditLoans.vue?vue&type=script&lang=js&"
import style0 from "./MicroCreditLoans.vue?vue&type=style&index=0&id=80031160&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80031160",
  null
  
)

export default component.exports