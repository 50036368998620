<template>
  <div>
    <el-breadcrumb separator="/"
                   class="mbx">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>账户信息</el-breadcrumb-item>
      <el-breadcrumb-item>银行卡绑定</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="bank_card">
      <el-tabs v-model="activeName"
               @tab-click="handleClick">

        <el-tab-pane :label="'验证成功('+count1+')'"
                     name="first">
        </el-tab-pane>
        <el-tab-pane :label="'待验证('+count2+')'"
                     name="second"></el-tab-pane>
        <el-tab-pane :label="'未验证('+count3+')'"
                     name="third"></el-tab-pane>
        <el-tab-pane :label="'处理中('+count4+')'"
                     name="fourth"></el-tab-pane>
        <el-tab-pane :label="'验证失败('+count5+')'"
                     name="five"></el-tab-pane>
      </el-tabs>
      <div class="bankCard_for">
        <div v-if="activeName=='first'"
             class="bindBank wtj_bank"
             @click="BankAdd">
          <img src="../../../../assets/img/yhk_01@2x.png"
               alt="">
          <div>添加银行卡</div>
        </div>

        <div class="bindBank"
             v-for="item,idx in BankList"
             :key="idx">
          <div class="yhk_img">
            <img :src="getBankUrl(item.accountBank)"
                 alt="">
            <!-- <span>{{item.bankBranch}}</span> -->
          </div>
          <div class="bank_num">
            <span>{{item.bankNumber.substr(0,4)}}</span>
            <span>****</span>
            <span>****</span>
            <span>{{item.bankNumber.substr(item.bankNumber.length-4,4)}}</span>
          </div>
          <div class="bank_btn">
            <el-button type="text"
                       v-if="item.status!==2"
                       @click="getDetail(item.id)">详情</el-button><span v-if="item.status!==2">|</span>
            <el-button type="text"
                       v-if="item.status==0"
                       @click="$message.warning('查询中，请稍后...')">查询结果</el-button>
            <el-button type="text"
                       @click="delDialog=true;delId=item.id"
                       v-if="item.status==1">删除</el-button>
            <!-- deleteBank(item.id) -->
            <el-button type="text"
                       v-if="item.status==4||item.status==2"
                       @click="edit(item.id)">编辑</el-button><span v-if="item.status==4||item.status==2">|</span>
            <el-button type="text"
                       v-if="item.status==4||item.status==2||item.status==3"
                       @click="quyz(item.id,item.bankNumber,item.checkCount)">去验证</el-button>
          </div>
        </div>
      </div>

    </div>
    <el-dialog :visible.sync="delDialog"
               class="del_dialog"
               width="20%">
      <div slot="title"
           class="header-title">
        <i class="el-icon-error"
           style="color:red;font-size:18px;margin-right:10px"></i><span>删除提示</span>
      </div>

      <span>请确认是否删除?</span>
      <span slot="footer"
            class="dialog-footer formBtn">
        <el-button size="small" class="btn normal"
                   @click="delDialog = false">取 消</el-button>
        <el-button size="small" class="btn submit"
                   type="primary"
                   @click="deleteBank">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal='false'
               class="ncdialog"
               width="568px"
               @close='reset'
               title="小额打款验证"
               :visible.sync="innerVisible"
               append-to-body>
      <div>
        <div class="jinggao">
          <!-- <div> -->
          <!-- <img src="../../../../assets/img/hk_01@2x.png"
                 alt=""> -->
          <!-- class="yz_top" -->
          <span>我们已向您尾号({{bankNumber}})的银行账号转入验证小款,请尽快查收验证！</span>

          <!-- </div> -->

          <!-- <div class="yz_span">请尽快查收验证！</div> -->
        </div>
        <div class="yz_input">
          <el-form :model="ruleForm"
                   :rules="rules"
                   ref="ruleForm"
                   label-width="85px"
                   @submit.native.prevent
                   class="demo-ruleForm">
            <el-form-item label="验证金额:"
                          prop="amount">
              <el-input v-model="ruleForm.amount"
                        ref="saveTagInput">
                <i slot="suffix">元</i>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div slot="footer"
           class="dialog-footer">
        <span>*一张银行卡一天只能验证三次小额打款</span>
        <el-button size="mini"
                   :disabled='checkCount==3'
                   @click="reginXe">未收到小额，重新提交</el-button>
        <el-button type="primary"
                   size="mini"
                   @click="Submityz">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        {
          id: 6,
          url: require("../../../../assets/img/yh_logo/yh_01.png"),
        },
        {
          id: 9,
          url: require("../../../../assets/img/yh_logo/yh_02.png"),
        },
        {
          id: 10,
          url: require("../../../../assets/img/yh_logo/yh_03.png"),
        },
        {
          id: 17,
          url: require("../../../../assets/img/yh_logo/yh_04.png"),
        },
        {
          id: 14,
          url: require("../../../../assets/img/yh_logo/yh_05.png"),
        },
        {
          id: 15,
          url: require("../../../../assets/img/yh_logo/yh_06.png"),
        },
        {
          id: 16,
          url: require("../../../../assets/img/yh_logo/yh_07.png"),
        },
        {
          id: 18,
          url: require("../../../../assets/img/yh_logo/yh_08.png"),
        },
        {
          id: 54,
          url: require("../../../../assets/img/yh_logo/yh_09.png"),
        },
        {
          id: 50,
          url: require("../../../../assets/img/yh_logo/yh_10.png"),
        },
        {
          id: 19,
          url: require("../../../../assets/img/yh_logo/yh_11.png"),
        },
        {
          id: 5,
          url: require("../../../../assets/img/yh_logo/yh_12.png"),
        },
        {
          id: 566,
          url: require("../../../../assets/img/yh_logo/yh_13.png"),
        },
        {
          id: 198,
          url: require("../../../../assets/img/yh_logo/yh_14.png"),
        },
        {
          id: 59,
          url: require("../../../../assets/img/yh_logo/yh_15.png"),
        },
        {
          id: 564,
          url: require("../../../../assets/img/yh_logo/yh_16.png"),
        },
        {
          id: 12,
          url: require("../../../../assets/img/yh_logo/yh_17.png"),
        },
      ],
      checkCount: 0, //获取小额次数
      BankUrl: "",
      count1: 0,
      count2: 0,
      count3: 0,
      count4: 0,
      count5: 0,
      delId: "", //删除银行卡id
      ruleForm: {
        amount: "", //金额
      },
      innerVisible: false,
      delDialog: false, //删除确认框
      activeName: "first",
      BankList: [], //银行卡list
      status: 1, //银行卡传参
      id: "",
      getStatus: "", //企业认证状态
      bankNumber: "", //获取银行卡后四位
      rules: {
        amount: [
          { required: true, message: "请输入验证金额", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getRole().then(() => {
      this.getStatus = sessionStorage.getItem("getStatus");
    });
    this.getBankList();
  },
  methods: {
    // 获取企业认证状态
    async getRole() {
      const { data: res } = await this.$auth.post("/cpiaoju-auth/index/home");
      if (res.code == 200) {
        this.$store.state.companyStatus = res.date.status;
        window.sessionStorage.setItem("getStatus", res.date.status); //登录后企业审核状态--0.保存 1.待认证 2.认证失败 3.认证通过4未认证
      }
    },
    // // 银行图片展示
    getBankUrl(id) {
      return (
        this.images.find((item) => item.id == id)?.url ||
        require("../../../../assets/img/yh_logo/yh_20.png")
      );
    },
    // 清空验证金额文本框
    reset() {
      this.$refs["ruleForm"].resetFields();
    },
    // 修改状态传参
    handleClick(tab, event) {
      if (tab.index == 0) {
        this.status = 1;
        this.getBankList();
      } else if (tab.index == 1) {
        this.status = 3;
        this.getBankList();
      } else if (tab.index == 2) {
        this.status = 4;
        this.getBankList();
      } else if (tab.index == 3) {
        this.status = 0;
        this.getBankList();
      } else if (tab.index == 4) {
        this.status = 2;
        this.getBankList();
      }
    },
    // 获取银行卡列表
    async getBankList() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/lxgw/front/bank-account/listsPage?status=" + this.status
      );
      if (res.code == 200) {
        this.BankList = res.data.records;

        this.count1 = res.count1;
        this.count2 = res.count2;
        this.count3 = res.count3;
        this.count4 = res.count4;
        this.count5 = res.count5;
      }
    },
    // 添加银行卡
    BankAdd() {
      if (this.getStatus == 3) {
        this.$router.push("/aside/bankCardBind/bankCardAdd");
      } else {
        this.$message.error("企业未完成验证");
        // this.$router.push("/aside/companyinfo");
      }
    },
    // 详情
    getDetail(id) {
      this.$router.push({
        path: "/aside/bankCardBind/bankCardDetail",
        query: { id },
      });
    },
    // 编辑
    edit(id) {
      this.$router.push({
        path: "/aside/bankCardBind/bankCardEdit",
        query: { id },
      });
    },
    // 删除
    async deleteBank() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/lxgw/front/bank-account/deleteBankAccount?id=" +
          this.delId +
          "&status=" +
          1
      );
      if (res.code == 200) {
        this.$message.success(res.msg);
        this.delDialog = false;
        this.getBankList();
      } else {
        this.$message.error(res.msg);
        this.delDialog = false;
        this.getBankList();
      }
    },
    // 验证
    async quyz(id, bankNumber, checkCount) {
      // console.log(this.$refs["saveTagInput"]);
      this.checkCount = checkCount;
      this.bankNumber = bankNumber.substr(bankNumber.length - 4, 4);
      this.innerVisible = true;
      this.$nextTick(() => {
        this.$refs["saveTagInput"].focus(); //DOM渲染完毕后就能正常获取了
      });
      this.id = id;
      if (this.activeName != "second") {
        const { data: res } = await this.$auth.post(
          "/cpiaoju-auth/lxgw/front/bank-account/updateStatus",
          this.$qs.stringify({
            id: this.id,
            status: 3,
          })
        );
      }
      this.getBankList();
    },
    // 去验证
    Submityz() {
      this.$refs["ruleForm"].validate(async (v) => {
        if (!v) return;
        const { data: res } = await this.$auth.post(
          "/cpiaoju-auth/lxgw/front/bank-account/smallAuthentication",
          this.$qs.stringify({
            id: this.id,
            amount: this.ruleForm.amount,
          })
        );

        if (res.code == 200) {
          sessionStorage.setItem("bankStatus", 1);
          this.$store.commit("getbankStatus", 1);
          this.$message.success(res.msg);
          this.innerVisible = false;
          this.getBankList();
        } else {
          this.$message.warning(res.msg);
          this.innerVisible = false;
          this.getBankList();
        }
      });
    },
    // 重新获取小额
    async reginXe() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/lxgw/front/bank-account/resSmallAuthentication",
        this.$qs.stringify({
          id: this.id,
        })
      );
      if (res.code == 200) {
        this.checkCount = res.checkCount;
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mbx {
  height: 56px;
  background-color: #fff;
  line-height: 56px;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.bank_card {
  background-color: #fff;
  padding: 10px 20px 23px;
  /deep/.el-tabs__nav {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #c70009;
    line-height: 25px;
    letter-spacing: 2px;
  }
  /deep/.el-tabs__item:hover {
    color: #c70009 !important;
  }
  /deep/.el-tabs__item.is-active {
    color: #c70009 !important;
  }
  /deep/.el-tabs__active-bar {
    background-color: #c70009 !important;
  }

  .bankCard_for {
    padding: 0 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .wtj_bank {
      box-shadow: none;
      border: 1px dashed #979797;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > img {
        margin-bottom: 19px;
        border: 1px dashed #979797;
      }
    }

    .bindBank {
      padding: 30px 20px;
      box-sizing: border-box;
      width: 400px;
      height: 240px;
      background: #fafafa;
      box-shadow: 0px 2px 4px 0px #dbdbdb;
      border-radius: 25px;
      position: relative;
      margin-bottom: 20px;
      .yhk_img {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > img {
          width: 220px;
        }
        > span {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
          letter-spacing: 1px;
        }
      }
      .bank_num {
        display: flex;
        justify-content: space-around;
        margin-top: 39px;
        span {
          font-size: 24px;
          font-family: "D-DINExp", " D";
          font-weight: normal;
          color: #333333;
          line-height: 22px;
        }
      }
      .bank_btn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 48px;
        background: rgba(207, 224, 255, 0.29);
        border-radius: 0px 0px 25px 25px;
        backdrop-filter: blur(9px);
        display: flex;
        justify-content: space-around;
        align-items: center;

        font-size: 16px;
        font-weight: 400;
        color: #acacac;
        line-height: 22px;
        .el-button {
          font-size: 16px;
          font-weight: 400;
          color: #acacac;
          line-height: 22px;
        }
      }
    }
  }
}

.ncdialog {
  /deep/.el-dialog__header {
    border-bottom: 1px solid #c0c4cc;
    padding: 17px 20px;
  }
  /deep/.el-dialog__body {
    padding: 20px;
  }
  /deep/.el-dialog__footer {
    margin-top: 50px;
  }
  .dialog-footer {
    span {
      position: absolute;
      bottom: 20px;
      left: 10px;
      font-size: 12px;
      color: #aaa;
    }
  }
}
.jinggao {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  // background: #fffbe6;
  // box-shadow: 0px 2px 31px 0px rgba(234, 234, 234, 0.5);
  // border: 1px solid #fee58f;
  > div {
    display: flex;
    align-items: center;
    > img {
      width: 20px;
      margin-right: 10px;
    }
    .yz_top {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      letter-spacing: 1px;
    }
  }
  .yz_span {
    margin-top: 5px;
    padding-left: 30px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    letter-spacing: 1px;
  }
}
.yz_input {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.del_dialog {
  /deep/.el-dialog__body {
    padding: 12px 42px;
  }
}
</style>