<template>
  <div>
    <el-breadcrumb separator="/"
                   class="mbx">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>账户信息</el-breadcrumb-item>
      <el-breadcrumb-item>银行卡绑定</el-breadcrumb-item>
      <el-breadcrumb-item>添加</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="bank_card">
      <el-form class="cent_form"
               ref="bankFormDataRef"
               :rules="bankFornRule"
               :model="bankFormData"
               label-width="100px">
        <el-form-item label="账户名称："
                      prop="name">
          <el-input v-model="bankFormData.name"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="银行账号："
                      prop="bankNumber">
          <el-input v-model="bankFormData.bankNumber"
                    @input="BankNameGet"></el-input>
        </el-form-item>
        <el-form-item label="银行名称："
                      prop="bankBranch"
                      class="dis_form">
          <el-select class="cheng_bank"
                     filterable
                     clearable
                     @change="getBankBranch"
                     :filter-method='getBankAgain'
                     :disabled='bankFormData.bankBranch?true:false'
                     v-model="bankFormData.bankName"
                     placeholder="请选择银行总行">
            <el-option v-for="item,idx in BankNameListZh"
                       :key="idx"
                       :label="item.bankName"
                       :value="item.code"></el-option>

          </el-select>
          <el-select class="shi_bank"
                     filterable
                     clearable
                     :filter-method='getBankZhAgain'
                     v-model="bankFormData.bankBranch"
                     @change="getBankNumber"
                     placeholder="请选择银行支行">
            <el-option v-for="item,idx in BankBranchList"
                       :key="idx"
                       :label="item.bankName"
                       :value="item.id"></el-option>

          </el-select>
        </el-form-item>
        <el-form-item label="所在城市："
                      prop="areaId"
                      class="dis_form">
          <el-select class="chengshi"
                     @change="getCity"
                     v-model="bankFormData.provinceId">
            <el-option v-for="item,idx in provinceList"
                       :key="idx"
                       :label="item.name"
                       :value="item.id"></el-option>

          </el-select>
          <el-select class="chengshi"
                     @change="getArea"
                     v-model="bankFormData.cityId">
            <el-option v-for="item,idx in cityList"
                       :key="idx"
                       :label="item.name"
                       :value="item.id"></el-option>

          </el-select>
          <el-select class="chengshi"
                     v-model="bankFormData.areaId">
            <el-option v-for="item,idx in areaList"
                       :key="idx"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联行号："
                      prop="clearingNumber">
          <el-input v-model="bankFormData.clearingNumber"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="addSubmit"
                     @click="submit">提交</el-button>
        </el-form-item>

      </el-form>
    </div>
    <el-dialog @close='close'
               :close-on-click-modal='false'
               :visible.sync="outerVisible"
               width="30%">
      <div slot="title"
           class="header-title">
        <span class="title-name"><i class="el-icon-circle-check"
             style="color: #63d18a;margin-right:20px"></i></span>
        <span class="title-age">账户添加成功</span>
      </div>
      <div>您已成功添加企业对公账({{num}})，请尽快进行银行卡小额验证，确保您的账户可正常收款</div>
      <el-dialog :close-on-click-modal='false'
                 class="ncdialog"
                 width="568px"
                 title="小额打款验证"
                 :visible.sync="innerVisible"
                 append-to-body>
        <div>
          <div class="jinggao">
            <!-- <div>
              <img src="../../../../assets/img/hk_01@2x.png"
                   alt=""> -->
            <!-- class="yz_top" -->
            <span>我们已向您尾号({{num}})的银行账号转入验证小款,请尽快查收验证！</span>

            <!-- </div> -->

            <!-- <div class="yz_span">请尽快查收验证！</div> -->
          </div>
          <div class="yz_input">
            <el-form :model="ruleForm"
                     :rules="rules"
                     ref="ruleForm"
                     label-width="85px"
                     class="demo-ruleForm">
              <el-form-item label="验证金额:"
                            prop="amount">
                <el-input v-model="ruleForm.amount">
                  <i slot="suffix">元</i>
                </el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div slot="footer"
             class="dialog-footer">
          <span>*一张银行卡一天只能验证三次小额打款</span>

          <el-button size="mini"
                     :disabled='checkCount==3'
                     @click="subAgain">未收到小额，重新提交</el-button>
          <el-button type="primary"
                     size="mini"
                     @click="xeyz">确定</el-button>
        </div>
      </el-dialog>
      <div slot="footer"
           class="dialog-footer">
        <el-button size="mini"
                   @click="Byz">暂不验证</el-button>
        <el-button type="primary"
                   size="mini"
                   @click="YzStatus">去验证</el-button>
        <!-- innerVisible = true -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: "", //弹框展示银行卡号后四位
      id: "",
      ruleForm: {
        amount: "", //金额
      },
      outerVisible: false,
      innerVisible: false,
      bankFormData: {
        name: "",
      },
      BankNameListZh: [], //银行总行
      BankBranchList: [], //支行
      provinceList: [], //省
      cityList: [], //市
      areaList: [], //区
      rules: {
        amount: [
          { required: true, message: "请输入验证金额", trigger: "blur" },
        ],
      },
      checkCount: 0,
      bankFornRule: {
        bankNumber: [
          { required: true, message: "请输入银行账号", trigger: "blur" },
        ],
        bankBranch: [
          { required: true, message: "请选择银行", trigger: "blur" },
        ],
        areaId: [{ required: true, message: "请选择城市", trigger: "blur" }],
        clearingNumber: [
          { required: true, message: "请输入联行号", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getBankName();
    this.getName();
    this.getProvinceId();
  },
  methods: {
    // 银行卡格式
    BankNameGet(el) {
      if (el) {
        // let value = el.replace(/\D/g, "").substr(0, 19); // 不允许输入非数字字符，超过16位数字截取前11位
        // this.bankFormData.bankNumber = value.replace(/(.{4})/g, "$1 ");
        this.bankFormData.bankNumber = el
          .replace(/[^\d|'']/g, "")
          .replace(/(\d{4})(?=\d)/g, "$1 ");
      }
    },
    // 获取企业名称
    async getName() {
      const { data: res } = await this.$auth.get(
        "/cpiaoju-auth/lxgw/front/company/get"
      );
      this.bankFormData.name = res.data.companyName;
    },
    // 获取银行总行
    async getBankName() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/lxgw/front/bank-account/getBanksByName"
      );
      this.BankNameListZh = res.data.records;
    },
    // 搜索总行
    async getBankAgain(val) {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/lxgw/front/bank-account/getBanksByName?bankName=" + val
      );
      this.BankNameListZh = res.data.records;
    },
    // 搜索支行
    async getBankZhAgain(val) {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/lxgw/front/bank-account/getBankBranchByName",
        this.$qs.stringify({
          bankName: val,
          bankCode: this.bankFormData.bankName,
        })
      );
      this.BankBranchList = res.data.records;
    },
    // 选择支行
    async getBankBranch(val) {
      if (val) {
        let obj = this.BankNameListZh.find((item) => {
          return item.code == val;
        });
        const { data: res } = await this.$auth.post(
          "/cpiaoju-auth/lxgw/front/bank-account/getBankBranchByName",
          this.$qs.stringify({
            // bankName: obj.bankName,
            bankCode: obj.code,
          })
        );
        this.BankBranchList = res.data.records;
      }
    },
    // 获取联行号
    getBankNumber(val) {
      if (val) {
        this.BankBranchList.forEach((item) => {
          if (item.id == val) {
            this.bankFormData.clearingNumber = item.bankNumber;
          }
        });
      }
    },
    // 省
    async getProvinceId() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/areaCode/getProvList"
      );
      this.provinceList = res.data;
    },
    async getCity(val) {
      if (this.bankFormData.cityId) {
        this.bankFormData.cityId = "";
        this.cityList = [];
      }
      if (this.bankFormData.areaId) {
        this.bankFormData.areaId = "";
        this.areaList = [];
      }

      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/areaCode/getCityByProvinceId?pid=" + val
      );
      this.cityList = res.data;
    },
    async getArea(val) {
      if (this.bankFormData.areaId) {
        this.bankFormData.areaId = "";
        this.areaList = [];
      }
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/areaCode/getCityByProvinceId?pid=" + val
      );
      this.areaList = res.data;
    },
    // 提交
    submit() {
      this.$refs["bankFormDataRef"].validate(async (v) => {
        if (!v) return;
        let zhName = "";
        let zhId = "";
        this.BankNameListZh.forEach((item) => {
          if (item.code == this.bankFormData.bankName) {
            zhName = item.bankName;
            zhId = item.id;
          }
        });
        let branchName = "";
        this.BankBranchList.forEach((item) => {
          if (item.id == this.bankFormData.bankBranch) {
            branchName = item.bankName;
          }
        });
        let bankNumber = this.bankFormData.bankNumber.replace(/\s*/g, "");
        this.num = bankNumber.substr(bankNumber.length - 4, 4);
        const { data: res } = await this.$auth.post(
          "/cpiaoju-auth/lxgw/front/bank-account/saveOrUpdateBank",
          this.$qs.stringify({
            bankNumber: bankNumber,
            bankName: zhName,
            accountBank: zhId,
            bankBranch: branchName,
            provinceId: this.bankFormData.provinceId,
            cityId: this.bankFormData.cityId,
            areaId: this.bankFormData.areaId,
            clearingNumber: this.bankFormData.clearingNumber,
          })
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.$message.success(res.msg);
        this.id = res.id;
        this.outerVisible = true;
      });
    },
    // 关闭弹窗跳转
    close() {
      this.outerVisible = false;
      this.$router.push("/aside/bankCardBind");
    },
    // 暂不验证
    Byz() {
      this.outerVisible = false;
      this.$router.push("/aside/bankCardBind");
    },
    // 确定验证
    xeyz() {
      this.$refs["ruleForm"].validate(async (v) => {
        if (!v) return;
        const { data: res } = await this.$auth.post(
          "/cpiaoju-auth/lxgw/front/bank-account/smallAuthentication",
          this.$qs.stringify({
            id: this.id,
            amount: this.ruleForm.amount,
          })
        );

        if (res.code == 200) {
          sessionStorage.setItem("bankStatus", 1);
          this.$store.commit("getbankStatus", 1);
          this.$message.success(res.msg);
          this.$router.push("/aside/bankCardBind");
        } else {
          this.$message.warning(res.msg);
          this.innerVisible = false;
        }
      });
    },
    subAgain() {
      // this.innerVisible = false;
      // this.outerVisible = false;
      this.reginXe();
    },
    // 验证小额打款
    async YzStatus() {
      this.innerVisible = true;
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/lxgw/front/bank-account/updateStatus",
        this.$qs.stringify({
          id: this.id,
          status: 3,
        })
      );
    },
    // 获取小额信息
    async reginXe() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/lxgw/front/bank-account/resSmallAuthentication",
        this.$qs.stringify({
          id: this.id,
        })
      );
      if (res.code == 200) {
        this.checkCount = res.checkCount;
        this.$message.success(res.data);
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.aside_right {
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
.mbx {
  height: 56px;
  background-color: #fff;
  line-height: 56px;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.bank_card {
  flex: 1;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .cent_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .dis_form {
      .cheng_bank {
        width: 180px;
        /deep/ .el-input {
          width: 100%;
        }
      }
      .shi_bank {
        width: 260px;
        /deep/ .el-input {
          width: 100%;
        }
      }
      .chengshi {
        width: 32%;
        /deep/ .el-input {
          width: 100%;
        }
      }
      /deep/ .el-form-item__content {
        width: 450px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .addSubmit {
      width: 160px;
      height: 48px;
      background: #c70009;
      border-radius: 4px;
      border: none;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      margin-top: 18px;
    }
  }
  /deep/.el-form-item__content::after,
  /deep/.el-form-item__content::before {
    content: none;
  }
  /deep/.el-input {
    width: 450px;
  }
}
/deep/.el-dialog__header {
  padding: 0 20px;
  .header-title {
    line-height: 60px;
    font-size: 18px;
  }
}
/deep/.el-dialog__body {
  padding: 10px 60px;
  line-height: 1.5;
}
.ncdialog {
  /deep/.el-dialog__header {
    border-bottom: 1px solid #c0c4cc;
    padding: 17px 20px;
  }
  /deep/.el-dialog__body {
    padding: 20px;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  /deep/.el-dialog__footer {
    margin-top: 50px;
  }
  .dialog-footer {
    span {
      position: absolute;
      bottom: 20px;
      left: 10px;
      font-size: 12px;
      color: #aaa;
    }
  }
}
.jinggao {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  // background: #fffbe6;
  // box-shadow: 0px 2px 31px 0px rgba(234, 234, 234, 0.5);
  // border: 1px solid #fee58f;
  > div {
    display: flex;
    align-items: center;
    > img {
      width: 20px;
      margin-right: 10px;
    }
    .yz_top {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      letter-spacing: 1px;
    }
  }
  .yz_span {
    margin-top: 5px;
    padding-left: 30px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    letter-spacing: 1px;
  }
}
.yz_input {
  margin-top: 20px;
  display: flex;
  align-items: center;
  .je {
    // width: 100px;
  }
  .el-input {
    width: 200px;
    margin: 0 5px;
  }
}
</style>