<template>
  <div class="workABS">
    <div class="block bannerImg">
      <img width="100%"
           height="600"
           src="@/assets/img/yw2.png"
           alt="">
      <div class="bannerText"
           v-if="activeName=='first'">
        <h1>供应链ABS | ABN</h1>
        <p>
          乐享数科与保理公司合作的资产证券化产品，为核心企业提供一站式融资服务，通过供应链ABS系统、票据ABS系统、资管ABS系统、保理系统、附票保理系统、电子债权凭证系统、供应链票据系统、标债云系统等，与200多家银行、券商、保险资管、保理、租赁等金融机构达成战略合作，为评级AAA\AA+央国企、地产、城投、优质上市公司开展供应链ABS、ABN等业务，同时为银行、证券公司、保险资管公司提供资产服务+系统服务。</p>
        <!-- <p class="mt20"
           style="display: block">
          <!==          <router-link to="/login">==>
          <el-button class="submit btn_jrcp"
                     @click="jump()"
                     round>进 入 产 品</el-button>
          <!==          </router-link>==>
        </p> -->
      </div>
      <div class="bannerText"
           v-else>
        <h1>关于ABN</h1>
        <p>
          乐享ABN是大型企业、金融机构或多个中小企业把自身拥有的、将来能够产生稳定现金流的资产出售给受托机构，由受托机构以这些资产作为支持基础发行的商业票据。主要是资产出售方作为发起人用既有的或者新发放的债权作为基础资产去发行商业票据进行融资。发起人将基础资产转让给受托人，受托人通过发行商业票据募集资金以购买这些资产。</p>
        <!-- <p class="mt20"
           style="display: block">
          <!==          <router-link to="/login">==>
          <el-button class="submit btn_jrcp"
                     @click="jump()"
                     round>进 入 产 品</el-button>
          <!==          </router-link>==>
        </p> -->
      </div>
    </div>
    <el-tabs class="newsTab"
             v-model="activeName"
             @tab-click="handleClick">
      <el-tab-pane label="供应链ABS | ABN"
                   name="first">
        <div class="mainBox ">
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>产品优势</h1>
              </div>
              <div class="flex2 absGood mt20">
                <div class="flex2Item">
                  <img src="@/assets/img/abs_01.png"
                       alt="">
                  <p>核心企业评级AA+以上</p>
                </div>
                <div class="flex2Item">
                  <img src="@/assets/img/abs_02.png"
                       alt="">
                  <p>原始权益人/保理商灵活加入</p>
                </div>
                <div class="flex2Item">
                  <img src="@/assets/img/abs_03.png"
                       alt="">
                  <p>资产类型覆盖广而全</p>
                </div>
                <div class="flex2Item">
                  <img src="@/assets/img/abs_04.png"
                       alt="">
                  <p>简化确权流程提升供应商配合度</p>
                </div>
                <div class="flex2Item">
                  <img src="@/assets/img/abs_05.png"
                       alt="">
                  <p>提升信用，降低成本</p>
                </div>
                <div class="flex2Item">
                  <img src="@/assets/img/abs_06.png"
                       alt="">
                  <p>改善现金流，降低负债</p>
                </div>
                <div class="flex2Item">
                  <img src="@/assets/img/abs_07.png"
                       alt="">
                  <p>提升效率，灵活发行</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>产品流程</h1>
              </div>
              <div class="flex2 absFlow mt40">
                <div class="flex2Item">
                  <img src="@/assets/img/abs_08.png"
                       alt="">
                  <h1>确认资产准入</h1>
                  <p>
                    <img src="@/assets/img/abs_10.png"
                         alt="">
                    <span>核心企业准入（评级AA+以上），登陆供应链ABS|ABN系统上传资料</span>
                  </p>
                  <p>
                    <img src="@/assets/img/abs_10.png"
                         alt="">
                    <span>核心企业挑选应付账款资产，确定应付金额</span>
                  </p>
                  <p>
                    <img src="@/assets/img/abs_10.png"
                         alt="">
                    <span>供应商登录系统，提交合同发票、履约结算凭证等，申请应收账款融资，待系统审核</span>
                  </p>
                </div>
                <div class="flex2Item">
                  <img src="@/assets/img/abs_09.png"
                       alt="">
                  <h1>合同签署</h1>
                  <p>
                    <img src="@/assets/img/abs_10.png"
                         alt="">
                    <span>审核通过，线上签署《应收账款转让合同》（无追/有追）；由核心企业作为加入债务人出具《付款确认书》，或第三方担保机构提供担增信，并出具《差额补足承诺函》</span>
                  </p>
                  
                  <p>
                    <img src="@/assets/img/abs_10.png"
                         alt="">
                    <span>线上签署融资合同（无追索/有追索）</span>
                  </p>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="乐享ABN"
                   name="second">
        <div class="mainBox">
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>资产要求</h1>
              </div>
              <div class="flex1 ask mt20">
                <div class="flex1Item">
                  <p>发行人拥有的已建成的、有稳定现金流的项目，或者项目收益权，只要其现金流能够覆盖资产支持票据的
                    本息支付要求，均可进入遴选范围</p>
                  <img src="@/assets/img/abn_01.png"
                       alt="">
                </div>
                <div class="flex1Item">
                  <p>基础资产的价值取决于其产生稳定现金流的能力，而非根据建造成本估算的资产价值</p>
                  <img src="@/assets/img/abn_02.png"
                       alt="">
                </div>
                <div class="flex1Item">
                  <p>基础资产既可由资产支持票据的发行人直接拥有，也可由发行人间接控制（即基础资产在其下属子公司名
                    下）</p>
                  <img src="@/assets/img/abn_03.png"
                       alt="">
                </div>
                <div class="flex1Item">
                  <p>既有债权类：项目回收款、和企业已经形成的应收账款</p>
                  <img src="@/assets/img/abn_04.png"
                       alt="">
                </div>
                <div class="flex1Item">
                  <p>未来收益权类：高速公路收费收入、桥梁和隧道收费收入、地铁收入、港口和机场跑道收入等</p>
                  <img src="@/assets/img/abn_05.png"
                       alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>业务场景</h1>
              </div>
              <div class="flex3 work mt40">
                <div class="flex3Item">
                  <img src="@/assets/img/abn_06.png"
                       alt="">
                  <p>发起人范围广</p>
                </div>
                <div class="flex3Item">
                  <img src="@/assets/img/abn_07.png"
                       alt="">
                  <p>票面利率低</p>
                </div>
                <div class="flex3Item">
                  <img src="@/assets/img/abn_08.png"
                       alt="">
                  <p>标准化票据管理</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane> -->
    </el-tabs>
    <div class="apply"
         v-if="activeName=='first'">
      <h1>专属客服1v1服务，为您的需求保驾护航</h1>
      <router-link :to="{path:'/contactus',query:{type:'3'}}">
        <el-button>立即联系</el-button>
      </router-link>
    </div>
    <div class="apply"
         v-if="activeName=='second'">
      <h1>专属客服1v1服务，为您的需求保驾护航</h1>
      <router-link :to="{path:'/contactus',query:{type:'4'}}">
        <el-button>立即联系</el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkABS",
  data() {
    return {
      activeName: "first", //切签
    };
  },
  created() {
    this.tab();
  },
  methods: {
    tab() {
      let active = this.$route.query.activeName; //切签
      if (active) {
        this.activeName = active;
      } else {
        this.activeName = "first";
      }
    },
    handleClick(tab, event) {
      //切签
      // console.log(tab, event);
    },
    jump() {
      let token = sessionStorage.getItem("token");
      if (token) {
        this.$router.push("/auth");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.flex2.absGood {
  flex-wrap: wrap;

  .flex2Item {
    display: flex;
    width: 49%;
    background: #ffffff;
    padding: 28px;
    box-sizing: border-box;
    margin-top: 20px;

    > img {
      width: 44px;
      height: 44px;
      margin-right: 30px;
    }

    p {
      color: #333333;
      font-size: 16px;
      line-height: 44px;
    }
  }

  .flex2Item:last-of-type {
    flex: 1;
  }
}

.flex2.absFlow {
  flex-wrap: wrap;

  .flex2Item {
    width: 49%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px 118px 40px;
    box-sizing: border-box;
    background: #f4f5f7;

    > img {
      width: 79px;
      height: 79px;
      margin-bottom: 28px;
    }

    > h1 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    p {
      display: flex;
      align-items: baseline;
      width: 100%;
      line-height: 20px;
      margin-top: 10px;

      > img {
        margin-right: 10px;
      }

      span {
        font-size: 16px;
        color: #999999;
        letter-spacing: 1px;
      }
    }
  }
}

.flex1.ask {
  display: flex;
  flex-direction: column;

  .flex1Item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    padding: 10px 40px;
    box-sizing: border-box;
    margin-top: 20px;

    > img {
      width: 100px;
      height: 100px;
      margin-left: 120px;
    }

    > p {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 3px;
    }
  }
}

.flex3.work {
  .flex3Item {
    width: 32%;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f4f5f7;

    > img {
      width: 80px;
      height: 80px;
      margin-bottom: 20px;
    }
  }
}
.apply {
  height: 200px;
  text-align: center;
  padding: 50px 20px 40px;
  box-sizing: border-box;
  background: url("~@/assets/img/jjfa_12@2x.png") no-repeat;

  h1 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 30px;
  }

  .el-button {
    width: 180px;
    height: 48px;
    border: 1px solid #ffffff;
    color: #ffffff;
    background: none;
  }

  .el-button:hover {
    background: #ffffff;
    color: #c70009;
  }
}
</style>