<template>
  <div class="home" ref="scroll">
    <div class="bannerImg" :style="{ 'height': documentClientHeight + 'px' }">
      <el-carousel height="100%">
        <el-carousel-item v-for="(item,index) in bannerList" :key="index">
          <!-- :class="!item.url?'jumps':''" -->
          <a class='jumps' :href="item.url==''||null?'':item.url" :target="item.url==''||null?'_self':'_blank'"
            rel="noopener">
            <img id="jumpUrl" :src="item.imgUrl" alt="">
          </a>
          <div>

          </div>
          <!-- <h1>{{item.sort}}</h1> -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="mainBox">
      <div class="cardBg" style="background:#fff;height: 1050px;">
        <div class="center">
          <div class="oneTitle">
            <h1 class="">业务介绍</h1>
            <h2 class="">用科技发挥自身优势 提升供应链服务水平</h2>
          </div>
          <div class="container">
            <!-- 旋转动画 -->
            <div class="animateYw">
              <div @mouseover="MouseOver" @mouseout="MOuseOut" @click="pushUrl(item.url)" class="ball "
                :class="['ball'+Number(index+1),stop?'stop':'']" v-for="item,index in businessList" :key="index">
                <img :src="item.img">
              </div>
            </div>
            <div class="animateYw1">
              <div @mouseover="MouseOver" @mouseout="MOuseOut" @click="pushUrl(item.url)" class="ball "
                :class="['ani'+Number(index+1),stop?'stop':'']" v-for="item,index in businessListOver" :key="index">
                <img :src="item.img">
              </div>

            </div>
            <img src="../assets/ywjs/lx.gif" alt="">
            <!-- 旋转动画结束 -->
          </div>
        </div>
      </div>
      <div class="cardBg" style="background:#f4f5f7;padding-bottom:0">
        <div class="center">
          <div class="oneTitle">
            <h1 class="">解决方案</h1>
            <h2 class="">针对各个领域不同场景中用户需求、为企业、政府平台、金融机构等提供供应链金融科技解决方案</h2>
          </div>
        </div>
        <div class="flexStart">
          <div class="box bg1">
            <div class="black"></div>
            <div class="maskShow">
              <div class="minBox">
                <img src="@/assets/img/sy_10@2x.png" alt="">
                <h3>中小微企业</h3>
                <div class="hide">
                  <p>基于核心企业应付账款及链属中小微企业应收账款的底层资产，为中小微企业提供多元化、低成本、高效率的定制化服务。</p>
                  <router-link to="/solution">
                    <el-button type="primary" size="mini" round>了解更多</el-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="box bg2">
            <div class="black"></div>
            <div class="maskShow">
              <div class="minBox">
                <img src="@/assets/img/sy_11@2x.png" alt="">
                <h3>核心企业</h3>
                <div class="hide">
                  <p>基于核心企业应付账款及链属中小微企业应收账款的底层资产，通过与券商、信托、担保、小贷、保理公司等众多知名金融机构战略合作，为中小微企业量身定制全类别、全周期的资金融通服务。</p>
                  <router-link to="/solution">
                    <el-button type="primary" size="mini" round>了解更多</el-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="box bg3">
            <div class="black"></div>
            <div class="maskShow">
              <div class="minBox">
                <img src="@/assets/img/sy_12@2x.png" alt="">
                <h3>政府平台</h3>
                <div class="hide">
                  <p>针对各地经济社会发展情况，制定多元化、差异化的解决方案，通过搭建“政金企”互通平台，将为地方发展凝聚更多金融资源，培育更多经济增长点。</p>
                  <router-link to="/solution">
                    <el-button type="primary" size="mini" round>了解更多</el-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="box bg4">
            <div class="black"></div>
            <div class="maskShow">
              <div class="minBox">
                <img src="@/assets/img/sy_13@2x.png" alt="">
                <h3>银行</h3>
                <div class="hide">
                  <p>以数字科技赋能银行业转型发展，借助票据综合一体化服务帮助银行快速获取精准客源，盘活已有授信用户，进而为银行开拓更为广阔的市场空间。</p>
                  <router-link to="/solution">
                    <el-button type="primary" size="mini" round>了解更多</el-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="box bg5">
            <div class="black"></div>
            <div class="maskShow">
              <div class="minBox">
                <img src="@/assets/img/sy_14@2x.png" alt="">
                <h3>金融机构</h3>
                <div class="hide">
                  <p>针对层级多极化、 主体多样化、业务集成化的行业特点，为各类金融机构提供可视化、智能化、定制化的供应链金融科技服务方案。</p>
                  <router-link to="/solution">
                    <el-button type="primary" size="mini" round>了解更多</el-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cardBg" style="background:#fff">
        <div class="center">
          <div class="oneTitle">
            <h1>新闻中心</h1>
          </div>
          <div class="flex2">
            <ul v-show="fadeInLeft" class="news" :class="{'fadeInLeft':fadeInLeft}">
              <li>
                <h3>
                  <router-link :to="{path:'/news',query:{activeName:'2'}}">公司动态 ></router-link>
                </h3>
              </li>
              <li>
                <div @click="openWIn(currentPageDataTop.id)">
                  <div class="imgBox">
                    <img v-if="currentPageDataTop.cover==''||null" src="@/assets/img/pcIndex.jpg" alt="">
                    <img v-else :src="currentPageDataTop.cover" alt="">
                    <div class="mask">
                      <p class="link">
                        {{currentPageDataTop.title}}
                      </p>
                      <h4>{{currentPageDataTop.years}}</h4>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul v-if="fadeInRight" class="news newsRight" :class="{'fadeInRight':fadeInRight}">
              <li>
                <h3>
                  <router-link :to="{path:'/news',query:{activeName:'1'}}">行业热点 ></router-link>
                </h3>
              </li>
              <li v-for="(item,index) in currentPageData" class="hotNews" :key="index">
                <div @click="openWIn(item.id)" style="position:relative">
                  <div class="hotSpot">
                    <h4 class="hotTitle">
                      {{item.title}}</h4>
                    <p>{{item.years}}</p>
                  </div>
                </div>

              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="cardBg partnerBox">
        <div class="center">
          <div class="oneTitle">
            <h1 class="animate animate__fadeInLeft" style="color:#fff">合作伙伴</h1>
          </div>
          <div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="核心企业" name="first">
              </el-tab-pane>
              <el-tab-pane label="金融机构" name="second">

              </el-tab-pane>
            </el-tabs>
            <div class="block" v-show="activeName=='first'">
              <el-carousel indicator-position="outside" ref="hxqy" arrow="never" :interval='15000' height="338px">
                <el-carousel-item>
                  <div class="flex5" style="background-color:rgba(0,0,0,0)">
                    <div class="partner"><img src="@/assets/img/qy (1).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (2).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (3).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (4).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (5).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (6).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (7).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (9).jpg" alt=""></div>
                    <!-- <div class="partner"><img src="@/assets/img/qy (10).jpg" alt=""></div> -->
                    <div class="partner"><img src="@/assets/img/qy (11).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (12).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (13).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (14).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (15).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (16).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (17).jpg" alt=""></div>
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="flex5">
                    <div class="partner"><img src="@/assets/img/qy (18).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (19).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (20).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (21).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (22).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (23).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (24).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (25).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (25).png" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (27).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (28).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (29).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (30).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (31).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (32).jpg" alt=""></div>
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="flex5">
                    <div class="partner"><img src="@/assets/img/qy (33).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (34).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (35).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (36).jpg" alt=""></div>
                    <!-- <div class="partner"><img src="@/assets/img/qy (37).jpg"
                           alt=""></div> -->
                    <div class="partner"><img src="@/assets/img/qy (38).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (39).jpg" alt=""></div>
                    <!-- <div class="partner"><img src="@/assets/img/qy (40).jpg" alt=""></div>  -->
                    <div class="partner"><img src="@/assets/img/qy (41).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (42).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (43).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (44).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (45).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (46).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (47).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (48).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (49).jpg" alt=""></div>
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="flex5">

                    <div class="partner"><img src="@/assets/img/qy (50).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (51).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (52).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (53).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (54).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (55).png" alt=""></div>
                    <!-- <div class="partner"><img src="@/assets/img/qy (57).jpg" alt=""></div> -->
                    <!-- <div class="partner"><img src="@/assets/img/qy (58).jpg" alt=""></div> -->
                    <div class="partner"><img src="@/assets/img/qy (59).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (60).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (61).jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/qy (62).jpg" alt=""></div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="block" v-show="activeName=='second'">
              <el-carousel indicator-position="outside" ref="jrjg" arrow="never" :interval='15000' height="338px">
                <el-carousel-item>
                  <div class="flex5">
                    <div class="partner"><img src="@/assets/img/jr1.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr2.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr3.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr4.jpg" alt=""></div>
                    <!-- <div class="partner"><img src="@/assets/img/jr5.jpg" alt=""></div> -->
                    <div class="partner"><img src="@/assets/img/jr6.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr7.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr8.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr9.jpg" alt=""></div>
                    <!-- <div class="partner"><img src="@/assets/img/jr10.jpg" alt=""></div> -->
                    <div class="partner"><img src="@/assets/img/jr11.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr12.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr13.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr14.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr15.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr16.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr21.jpg" alt=""></div>
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="flex5">
                    <!-- <div class="partner"><img src="@/assets/img/jr17.jpg" alt=""></div> -->
                    <!-- <div class="partner"><img src="@/assets/img/jr18.jpg" alt=""></div> -->
                    <!-- <div class="partner"><img src="@/assets/img/jr19.jpg"
                           alt=""></div> -->
                    <!-- <div class="partner"><img src="@/assets/img/jr20.jpg" alt=""></div> -->
                    <div class="partner"><img src="@/assets/img/jr22.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr23.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr24.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr25.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr26.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr27.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr28.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr29.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr30.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr31.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr32.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr33.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr34.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr35.png" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr36.jpg" alt=""></div>
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="flex5">
                    <div class="partner"><img src="@/assets/img/jr37.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr38.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr39.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr40.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr41.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr42.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr43.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr44.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr45.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr46.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr47.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr48.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr49.jpg" alt=""></div>
                    <!-- <div class="partner"><img src="@/assets/img/jr50.jpg" alt=""></div> -->
                    <div class="partner"><img src="@/assets/img/jr51.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr52.jpg" alt=""></div>

                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="flex5">
                    <div class="partner"><img src="@/assets/img/jr53.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr54.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr55.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr56.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr57.png" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr58.png" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr59.jpg" alt=""></div>
                    <div class="partner"><img src="@/assets/img/jr60.jpg" alt=""></div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>

        </div>
      </div>
      <div>
        <img style="width:100%;vertical-align: middle" src="../assets/img_03/sy_14@2x.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      activeName: "first",
      bannerList: [],
      currentPageDataTop: "",
      currentPageData: "",
      scroll: "",

      fadeInLeft: false,
      fadeInRight: false,
      documentClientHeight: "",
      stop: false, //停止旋转
      // 业务介绍数据集合外圈
      businessList: [
        {
          img: require("../assets/ywjs/xhd.png"),
          url: "/work/workwzg?activeName=second",
        },
        {
          img: require("../assets/ywjs/lxabs.png"),
          url: "/work/workabs?activeName=first",
        },
        {
          img: require("../assets/ywjs/lxqyxd.png"),
          url: "/work/workwzg?activeName=third",
        },
        {
          img: require("../assets/ywjs/lxztt.png"),
          url: "/work/workztt",
        },
      ],
      businessListOver: [
        //内圈
        {
          img: require("../assets/ywjs/wzg.png"),
          url: "/work/workwzg?activeName=first",
        },
        {
          img: require("../assets/ywjs/xydpjxt.png"),
          url: "/work/workkj?activeName=second",
        },
        {
          img: require("../assets/ywjs/yszkpjh.png"),
          url: "/work/workcsy",
        },
        {
          img: require("../assets/ywjs/znfkxt.png"),
          url: "/work/workrisk",
        },
      ],
    };
  },
  created() {
    // this.xz();
    // 设置banner高度为电脑一屏高度
    this.documentClientHeight = document.body.clientHeight;
    this.getBannerList();
    this.articleListTop();
    this.articleList();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    MouseOver() {
      //鼠标移入
      this.stop = true;
    },
    MOuseOut() {
      //鼠标移出
      this.stop = false;
    },
    pushUrl(url) {
      //业务介绍跳转
      this.$router.push(url);
    },
    // // 滑动事件
    handleScroll() {
      this.scroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 控制新闻中心的左右滑入效果
      if (this.scroll > 2000) {
        this.fadeInLeft = true;
        this.fadeInRight = true;
      }
    },

    // handleScroll() {
    //   // this.$refs.scroll.getBoundingClientRect().y指的是当前元素的y轴的位置
    //   // window.innerHeight是当前浏览器窗口的可视高度
    //   if (this.$refs.scroll.getBoundingClientRect().y < window.innerHeight) {
    //     this.$refs.scroll.classList.add('animated' + this.direction)
    //   } else {
    //     this.$refs.scroll.classList.remove('animated' + this.direction)
    //   }
    // },

    //banner
    async getBannerList() {
      const { data: res } = await this.$http.get("/indexBanner");
      if (res.code !== 200) return this.$message.error(res.message);
      this.bannerList = res.data;
    },
    //新闻中心--公司动态
    async articleListTop() {
      const obj = {
        catalogId: 2,
      };
      const { data: res } = await this.$http.post(
        "/indexNewsTop",
        this.$qs.stringify(obj)
      );
      if (res.code == 200) {
        this.currentPageDataTop = res.data.records[0];
      } else {
        return this.$message.error(res.msg);
      }
    },
    //新闻中心-行业热点
    async articleList() {
      const obj = {
        page: 1,
        limit: 3,
        catalogId: 1,
      };
      const { data: res } = await this.$http.post(
        "/indexNews",
        this.$qs.stringify(obj)
      );
      if (res.code == 200) {
        this.currentPageData = res.data.records;
      } else {
        return this.$message.error(res.msg);
      }
    },
    handleClick(tab, event) {
      this.$refs["hxqy"].setActiveItem(0);
      this.$refs["jrjg"].setActiveItem(0);
    },
    // 跳转详情页
    async openWIn(id) {
      let { data: res } = await this.$http.get("/pressDetail?id=" + id);

      window.location.href = res.url;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
.el-carousel__item h3 {
  color: #debdaa;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
</style>
<style lang="less" scoped>
.stop {
  animation-play-state: paused !important;
  // -webkit-animation-play-state: paused;
}

// 业务介绍特效
.container {
  position: relative;
  margin: 54px auto;
}

.container>img {
  width: 285px;
  // height: 396px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 340px;
  z-index: 1;
}

/* 旋转动画 */
.animateYw {
  width: 1317px;
  height: 705px;
  border-radius: 100%;
  border: 1px solid #eb242d;
  position: absolute;
  left: -59.5px;
  top: 0px;
}

.animateYw1 {
  width: 803px;
  height: 364px;
  border-radius: 100%;
  border: 1px solid #eb242d;
  position: absolute;
  left: 206px;
  top: 164px;
  z-index: 10;
}

@keyframes animX {
  0% {
    left: -80px;
  }

  100% {
    left: 1240px;
  }
}

@keyframes animY {
  0% {
    top: -70px;
  }

  100% {
    top: 640px;
  }
}

@keyframes animX1 {
  0% {
    left: 730px;
  }

  100% {
    left: -70px;
  }
}

@keyframes animY1 {
  0% {
    top: -60px;
  }

  100% {
    top: 300px;
  }
}

@keyframes scale {
  /* 0% {
            transform: scale(0.7)
          }
          50% {
            transform: scale(1.2)
          }
          100% {
            transform: scale(0.7)
         } */
}

.ball {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.ball img:hover {
  transform: scale(1.2);
}

.ball img {
  // width: 285px;
  // 			height: 340px;
  margin-bottom: 10px;
}

/* 6个图x和y轴动画加起来是18s , 18s/6 等于 3s  18/4   4.5
        每个球y轴动画延迟 从0递减3s  4.5,x轴与y轴相差动画时长的一半（9s/2） 2.25 */
.ball1 {
  animation: animX 9s cubic-bezier(0.36, 0, 0.64, 1) -4.5s infinite alternate,
    animY 9s cubic-bezier(0.36, 0, 0.64, 1) 0s infinite alternate,
    scale 18s cubic-bezier(0.36, 0, 0.64, 1) 0s infinite alternate;
}

.ball2 {
  animation: animX 9s cubic-bezier(0.36, 0, 0.64, 1) -9s infinite alternate,
    animY 9s cubic-bezier(0.36, 0, 0.64, 1) -4.5s infinite alternate,
    scale 18s cubic-bezier(0.36, 0, 0.64, 1) -4.5s infinite alternate;
}

.ball3 {
  animation: animX 9s cubic-bezier(0.36, 0, 0.64, 1) -13.5s infinite alternate,
    animY 9s cubic-bezier(0.36, 0, 0.64, 1) -9s infinite alternate,
    scale 18s cubic-bezier(0.36, 0, 0.64, 1) -9s infinite alternate;
}

.ball4 {
  animation: animX 9s cubic-bezier(0.36, 0, 0.64, 1) -18s infinite alternate,
    animY 9s cubic-bezier(0.36, 0, 0.64, 1) -13.5s infinite alternate,
    scale 18s cubic-bezier(0.36, 0, 0.64, 1) -13.5s infinite alternate;
}

/* .ball5 {
				animation: animX 9s cubic-bezier(0.36, 0, 0.64, 1) -16.5s infinite alternate,
					animY 9s cubic-bezier(0.36, 0, 0.64, 1) -12s infinite alternate,
					scale 18s cubic-bezier(0.36, 0, 0.64, 1) -12s infinite alternate;
			}

			.ball6 {
				animation: animX 9s cubic-bezier(0.36, 0, 0.64, 1) -19.5s infinite alternate,
					animY 9s cubic-bezier(0.36, 0, 0.64, 1) -15s infinite alternate,
					scale 18s cubic-bezier(0.36, 0, 0.64, 1) -15s infinite alternate;
			} */
.ani1 {
  animation: animX1 9s cubic-bezier(0.36, 0, 0.64, 1) -4.5s infinite alternate,
    animY1 9s cubic-bezier(0.36, 0, 0.64, 1) 0s infinite alternate,
    scale 18s cubic-bezier(0.36, 0, 0.64, 1) 0s infinite alternate;
}

.ani2 {
  animation: animX1 9s cubic-bezier(0.36, 0, 0.64, 1) -9s infinite alternate,
    animY1 9s cubic-bezier(0.36, 0, 0.64, 1) -4.5s infinite alternate,
    scale 18s cubic-bezier(0.36, 0, 0.64, 1) -4.5s infinite alternate;
}

.ani3 {
  animation: animX1 9s cubic-bezier(0.36, 0, 0.64, 1) -13.5s infinite alternate,
    animY1 9s cubic-bezier(0.36, 0, 0.64, 1) -9s infinite alternate,
    scale 18s cubic-bezier(0.36, 0, 0.64, 1) -9s infinite alternate;
}

.ani4 {
  animation: animX1 9s cubic-bezier(0.36, 0, 0.64, 1) -18s infinite alternate,
    animY1 9s cubic-bezier(0.36, 0, 0.64, 1) -13.5s infinite alternate,
    scale 18s cubic-bezier(0.36, 0, 0.64, 1) -13.5s infinite alternate;
}

// 新闻中心划入效果
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    -o-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
  -webkit-animation-name: fadeInLeft;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
}

.fadeInRight {
  animation-name: fadeInRight;
  -webkit-animation-name: fadeInRight;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
}

// 、、、、、、、、、、、、ending、、、、、、、、、、、、、、、、、、
.oneTitle {
  h1 {
    letter-spacing: 3px;
  }

  h2 {
    letter-spacing: 1.5px;
  }
}

.minBox {
  h3 {
    letter-spacing: 1.5px;
  }
}

/deep/.el-carousel {
  height: 100%;
}

.home {
  width: 100%;
}

.bannerImg {
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .text {
    position: absolute;
    top: 244px;
    left: 370px;
    color: #ffffff;

    h1 {
      font-size: 42px;
      margin-bottom: 20px;
      letter-spacing: 1.5px;
    }

    p {
      font-size: 24px;
      line-height: 33px;
      letter-spacing: 1.5px;
    }
  }
}

.cardBg {
  width: 100%;

  .flex4 {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: flex-start;
    width: 1180px;
    height: 906px;
    margin: 0 auto;
    background-size: 100%;
    margin-top: 40px;
    position: relative;

    // 业务介绍部分 8个业务的位置  使用鼠标划入直接替换背景图片
    .position1 {
      position: absolute;
      top: 24px;
      font-size: 16px;
      right: 376px;
      cursor: pointer;

      .zczjh {
        width: 120px;
        height: 120px;
      }

      p {
        text-align: center;
      }
    }

    .position1:hover .zczjh {
      background: url("../assets/img/ywjs/zczjh.jpg") no-repeat center center;
      background-size: 100%;
    }

    .position2 {
      position: absolute;
      top: 155px;
      font-size: 16px;
      left: 336px;
      cursor: pointer;

      .kjfw {
        width: 120px;
        height: 120px;
      }

      p {
        text-align: center;
      }
    }

    .position2:hover .kjfw {
      background: url("../assets/img/ywjs/kjfw.jpg") no-repeat center center;
      background-size: 100%;
    }

    .position3 {
      position: absolute;
      top: 201px;
      right: 240px;
      cursor: pointer;
      font-size: 16px;

      .lxcx {
        width: 120px;
        height: 120px;
      }

      p {
        text-align: center;
      }
    }

    .position3:hover .lxcx {
      background: url("../assets/img/ywjs/lxcx.jpg") no-repeat center center;
      background-size: 100%;
    }

    .position4 {
      position: absolute;
      top: 278px;
      left: 35px;
      cursor: pointer;
      font-size: 16px;

      .lxkd {
        width: 120px;
        height: 120px;
      }

      p {
        text-align: center;
      }
    }

    .position4:hover .lxkd {
      background: url("../assets/img/ywjs/lxkd.jpg") no-repeat center center;
      background-size: 100%;
    }

    .position5 {
      position: absolute;
      top: 327px;
      right: 39px;
      cursor: pointer;
      font-size: 16px;

      .csy {
        width: 120px;
        height: 120px;
      }

      p {
        text-align: center;
      }
    }

    .position5:hover .csy {
      background: url("../assets/img/ywjs/csy.jpg") no-repeat center center;
      background-size: 100%;
    }

    .position6 {
      position: absolute;
      top: 402px;
      left: 304px;
      cursor: pointer;
      font-size: 16px;

      .pjfw {
        width: 120px;
        height: 120px;
      }

      p {
        text-align: center;
      }
    }

    .position6:hover .pjfw {
      background: url("../assets/img/ywjs/pjfw.jpg") no-repeat center center;
      background-size: 100%;
    }

    .position7 {
      position: absolute;
      top: 393px;
      right: 306px;
      cursor: pointer;
      font-size: 16px;

      .znfkxt {
        width: 120px;
        height: 120px;
      }

      p {
        text-align: center;
      }
    }

    .position7:hover .znfkxt {
      background: url("../assets/img/ywjs/znfkxt.jpg") no-repeat center center;
      background-size: 100%;
    }

    .position8 {
      position: absolute;
      bottom: -11px;
      right: 534px;
      cursor: pointer;
      font-size: 16px;

      .blfw {
        width: 120px;
        height: 120px;
      }

      p {
        text-align: center;
      }
    }

    .position8:hover .blfw {
      background: url("../assets/img/ywjs/blfw.jpg") no-repeat center center;
      background-size: 100%;
    }

    // 、、、、、、、、、、、ending、、、、、、、、、、、、、、
    .position {
      position: relative;
      margin-top: 20px;
      margin-right: 20px;

      .more {
        display: none;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 48px;
        font-weight: 500;
        font-size: 16px;
        background: #c70009;
        color: #ffffff;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
      }

      .flex4Item {
        position: relative;
        width: 285px;
        height: 170px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .icon {
          >img {
            width: 60px;
            height: 60px;
            margin-bottom: 20px;
          }
        }

        .text {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
        }
      }
    }

    .position:hover .more {
      display: block;
    }

    .position:hover .text {
      opacity: 0;
    }

    .position:nth-child(4n) {
      margin-right: 0;
    }
  }
}

.flexStart {
  display: flex;
  margin-top: 40px;

  .box {
    position: relative;
    width: 25%;
    height: 600px;
    background: #42b983;
    display: flex;
    justify-items: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 2%;
    box-sizing: border-box;
    color: #ffffff;

    .minBox {
      height: 112px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .hide {
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: translateY(0);
      transition: 0.6s all linear;
    }

    img {
      margin-bottom: 20px;
      height: 60px;
    }

    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    p {
      width: 200px;
      // height: 146px;
      word-break: break-all;
      margin-bottom: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      letter-spacing: 1.31px;
      font-size: 14px;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      text-align: center;
    }

    .el-button {
      width: 110px;
      height: 40px;
      background: #c70009;
      border-radius: 20px;
      border: none;
      font-size: 14px !important;
    }
  }

  .box:hover .minBox {
    overflow: inherit;
    transform: translateY(-50%);
    transition: 0.6s all linear;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .maskShow {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .black {
    position: absolute;
    bottom: 0;
    height: 0;
    background: rgba(20, 22, 33, 0.8);
    width: 100%;
    opacity: 0;
  }

  .box:hover .black {
    position: absolute;
    bottom: 0;
    height: 600px;
    opacity: 1;
    transform: translateY(0);
    transition: 0.6s all linear;
  }

  .box.bg1 {
    background: url("~@/assets/img/sy_16@2x.png") no-repeat;
    background-size: 100% 100%;
  }

  .box.bg2 {
    background: url("~@/assets/img/sy_17@2x.png") no-repeat;
  }

  .box.bg3 {
    background: url("~@/assets/img/sy_18@2x.png") no-repeat;
  }

  .box.bg4 {
    background: url("~@/assets/img/sy_19@2x.png") no-repeat;
  }

  .box.bg5 {
    background: url("~@/assets/img/sy_20@2x.png") no-repeat;
  }
}

.flex5 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .partner {
    width: 224px;
    height: 90px;
    margin-right: 20px;
    margin-top: 20px;
    overflow: hidden;
    // border: 1px solid #bdbcbc;
    padding: 1px;
    box-sizing: border-box;

    img {
      transition: all 0.5s;
    }
  }

  .partner:hover {
    // border: 1px solid #c70009;
  }

  .partner img:hover {
    // transform: scale(1.03);
  }

  .partner:nth-child(5n) {
    margin-right: 0;
  }
}

.partnerBox {
  background: url("../assets/img_03/hzhb.png") no-repeat center center !important;
  background-size: 100% 100%;

  /deep/ .el-tabs__header {
    margin: 0 0 6px 0;
  }

  /deep/ .el-tabs__active-bar {
    background-color: #c70009;
  }

  /deep/ .el-tabs__item.is-active {
    color: #fff;
    font-size: 18px !important;
    font-weight: bold;
  }

  /deep/ .el-tabs__nav-wrap::after {
    background: none;
  }

  /deep/ .el-tabs__item:hover {
    color: #fff;
  }

  /deep/.el-tabs__nav-scroll {
    border-bottom: 1px solid rgba(255, 255, 255, 0.68);
    box-sizing: border-box;
  }

  /deep/ .el-tabs__nav {
    float: none;
    width: max-content;
    margin: auto;
  }

  /deep/ .el-tabs__item {
    padding: 0 120px 0 0;
    margin-top: 38px;
    color: #fff;
    letter-spacing: 1.5px;
  }

  /deep/ .el-tabs__item:nth-child(2) {
    /*margin-right: 120px;*/
  }

  /deep/ .el-tabs__item.is-top {
    font-size: 16px;
  }

  /deep/ .el-carousel__button {
    width: 10px;
    height: 10px;
    border-radius: 100%;

    background: rgba(255, 255, 255, 0.5);
  }

  /deep/ .el-carousel__indicators--outside .el-carousel__indicator--horizontal.is-active button {
    background-color: #fff;
  }

  /deep/ .el-carousel__indicator--horizontal {
    padding: 12px 4px 2px;
  }
}

.flex2 {
  display: flex;
  justify-content: space-between;

  .news {
    width: 48%;

    li h3 {
      width: max-content;
      font-weight: 400;
      font-size: 16px;
      color: #262626;
      margin-bottom: 20px;
      margin-top: 36px;
    }

    li h3 a {
      color: #262626;
      letter-spacing: 1.5px;
    }

    li h3:hover a {
      color: #c70009;
      font-weight: 600;
    }

    .imgBox {
      position: relative;
      height: 350px;
      overflow: hidden;

      >img {
        width: 100%;
        height: 100%;
      }

      >img:hover {
        transform: scale(1.1);
        transition: 0.5s all linear;
      }

      .mask {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 80px;
        background: rgba(20, 22, 33, 0.6);
        padding: 10px 20px;
        box-sizing: border-box;
        color: #ffffff;

        .link {
          letter-spacing: 1.5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        h4 {
          color: rgba(255, 255, 255, 0.7);
          font-weight: 400;
          margin-top: 10px;
          letter-spacing: 1.31px;
        }

        .link a {
          font-size: 16px;
          color: #ffffff;
        }
      }
    }
  }

  .hotSpot {
    padding: 24px 20px;

    background: #f4f5f7;
    box-sizing: border-box;

    h4 {
      color: #333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 400;
      line-height: 28px;
      cursor: pointer;
      font-size: 16px;
      letter-spacing: 1.5px;
    }

    p {
      color: #8c8c8c;
      line-height: 28px;
      letter-spacing: 1.5px;
    }
  }

  .hotSpot:hover .hotTitle {
    color: #fff;
  }

  .hotSpot:hover p {
    color: #fff;
  }

  // 控制新闻背景色过渡显示
  .hotSpot:hover {
    color: #fff;
    background: #c70009;
    transition: background-color 2s;
  }
}

.news.newsRight {
  li {
    margin-bottom: 20px;
  }

  li:first-of-type {
    margin-bottom: 0;
  }

  li:last-of-type {
    margin-bottom: 0;
  }
}

/deep/ .el-carousel__container {
  // background: #ffffff;
  height: 100%;
}

/deep/ .el-carousel__item.is-animating {
  // background: #ffffff;
}

.jumps {

  // pointer-events: none;
  >img {
    object-fit: cover;
  }
}

.solution {
  display: flex;
  align-items: flex-end;
  margin: 0 auto;
  margin-top: 40px;

  .solu_left {
    width: 48.5%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .jjfa {
      width: 285px;
      height: 210px;
      margin-bottom: 10px;
      padding: 20px;
      box-sizing: border-box;
      background: #ffffff;

      .title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        div {
          width: 44px;
          height: 44px;
          margin-right: 14px;
        }

        .zxwqy {
          background: url(../assets/img_03/sy_02@2x.png) no-repeat center center;
          background-size: 100%;
        }

        .hxqy {
          background: url(../assets/img_03/sy_03@2x.png) no-repeat center center;
          background-size: 100%;
        }

        .zfpt {
          background: url(../assets/img_03/sy_04@2x.png) no-repeat center center;
          background-size: 100%;
        }

        .jrjg {
          background: url(../assets/img_03/sy_05@2x.png) no-repeat center center;
          background-size: 100%;
        }

        .yh {
          background: url(../assets/img_03/sy_06@2x.png) no-repeat center center;
          background-size: 100%;
        }

        span {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: normal;
          color: #000000;
          line-height: 25px;
          letter-spacing: 1px;
        }
      }

      .context {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        letter-spacing: 1px;
      }
    }

    .jjfa1:hover .zxwqy {
      background: url(../assets/img_03/sy_07@2x.png) no-repeat center center;
      background-size: 100%;
    }

    .jjfa2:hover .hxqy {
      background: url(../assets/img_03/sy_08@2x.png) no-repeat center center;
      background-size: 100%;
    }

    .jjfa3:hover .zfpt {
      background: url(../assets/img_03/sy_09@2x.png) no-repeat center center;
      background-size: 100%;
    }

    .jjfa4:hover .jrjg {
      background: url(../assets/img_03/sy_10@2x.png) no-repeat center center;
      background-size: 100%;
    }

    .jjfa5:hover .yh {
      background: url(../assets/img_03/sy_11@2x.png) no-repeat center center;
      background-size: 100%;
    }

    .jjfa:hover {
      background: #c80009;
    }

    .jjfa:hover span {
      color: #fff !important;
    }

    .jjfa:hover div {
      color: #fff !important;
    }
  }

  .solu_right {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    >img {
      width: 80%;
    }
  }
}
</style>