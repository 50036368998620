<template>
  <!--    厂商银-->
  <div class="ybl">
    <div class="block bannerImg">
      <img width="100%" height="600" src="@/assets/img/yw8.png" alt="">
      <div class="bannerText">
        <h1>关于应收账款票据化</h1>
        <p>
          应收账款票据化明确企业间债权债务关系、固定还款期限，规范企业商业信用，提高链上资金周转效率，缓解供应链“长尾”端小微企业融资难、融资贵问题。</p>
        <!-- <p class="mt20" style="display: block">
          <!==          <router-link to="/login">==>
          <el-button  class="submit btn_jrcp" @click="jump()" round>进 入 产 品</el-button>
          <!==          </router-link>==>
        </p> -->
      </div>
    </div>

    <div class="mainBox">
      <div class="cardBg">
        <div class="center">
          <div class="oneTitle">
            <h1>产品优势</h1>
          </div>
          <div class="csyBox mt40">
            <img src="@/assets/img/csy_01.png" alt="">
            <div class="text">
              <!-- <h1>对于商品生产商</h1> -->
              <p><i></i><span>降低有息负债</span></p>
              <p><i></i><span>压降应收账款和存货</span></p>
              <p><i></i><span>降低三角债，优化财报</span></p>
              <p><i></i><span>解决持票金额与支付金额不匹配的痛点</span></p>
            </div>
          </div>

          <!-- <div class="csyBox reset mt40">
            <div class="text">
              <h1>对于商品贸易商</h1>
              <p><i></i><span>依托真实商品交易结算，贸易商借助厂商资信获得银行的定向融资支持</span></p>
              <p><i></i><span>可以从厂家争取更大的返利</span></p>
            </div>
            <img src="@/assets/img/csy_02.png" alt="">
          </div> -->
        </div>
      </div>
    </div>
    <div class="apply">
      <h1>专属客服1v1服务，为您的需求保驾护航</h1>
      <router-link :to="{path:'/contactus',query:{type:'12'}}">
        <el-button>立即联系</el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: "WorkCSY",
    data() {
      return {}
    },
    methods:{
      jump(){
        let token = sessionStorage.getItem('token')
        if (token){
          this.$router.push('/auth')
        }else {
          this.$router.push('/login')
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .csyBox {
    position: relative;

    > img {
      width: 580px;
      height: 387px;

    }

    .text {
      position: absolute;
      top: 40px;
      right: 0;
      width: 655px;
      height: 307px;
      background: #ffffff;
      border-right: 20px solid #C70009;
      padding: 57px 40px;
      box-sizing: border-box;

      display: flex;
      flex-direction: column;
      justify-content: center;

      > h1 {
        font-size: 18px;
        color: #262626;
        line-height: 25px;
        letter-spacing: 1px;

      }

      p {
        display: flex;
        align-items: flex-start;
        margin-top: 20px;

        i {
          width: 5px;
          height: 5px;
          background: #C70009;
          margin-top: 8px;
          margin-right: 10px;
        }

        span {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 1px;
        }
      }
    }
  }

  .csyBox.reset {
    height: 387px;

    > img {
      position: absolute;
      top: 0;
      right: 0;
    }

    .text {
      position: absolute;
      top: 40px;
      left: 0;
      border-left: 20px solid #C70009;
      border-right: 0;
      z-index: 3;
    }
  }
  .apply {
  height: 200px;
  text-align: center;
  padding: 50px 20px 40px;
  box-sizing: border-box;
  background: url("~@/assets/img/jjfa_12@2x.png") no-repeat;

  h1 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 30px;
  }

  .el-button {
    width: 180px;
    height: 48px;
    border: 1px solid #ffffff;
    color: #ffffff;
    background: none;
  }

  .el-button:hover {
    background: #ffffff;
    color: #c70009;
  }
}
</style>