<template>
  <div id="app">
    <Header @aa='aaa' />
    <router-view ref="aa"></router-view>
    <Footer />
    <!--    <div id="nav">-->
    <!--      <router-link to="/">Home</router-link> |-->
    <!--      <router-link to="/about">666</router-link>-->
    <!--    </div>-->
    <!--    <router-view/>-->
  </div>
</template>

<style lang="less" >
#app {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*}*/

/*#nav {*/
/*  padding: 30px;*/

/*  a {*/
/*    font-weight: bold;*/
/*    color: #2c3e50;*/

/*    &.router-link-exact-active {*/
/*      color: #42b983;*/
/*    }*/
/*  }*/
/*}*/
</style>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  components: {
    Header,
    Footer,
  },
  methods: {
    aaa() {
      this.$nextTick(() => {
        this.$refs["aa"].openBz();
      });
    },
  },
};
</script>
