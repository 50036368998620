import { render, staticRenderFns } from "./Yszc.vue?vue&type=template&id=4e062b30&scoped=true&"
import script from "./Yszc.vue?vue&type=script&lang=js&"
export * from "./Yszc.vue?vue&type=script&lang=js&"
import style0 from "./Yszc.vue?vue&type=style&index=0&id=4e062b30&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e062b30",
  null
  
)

export default component.exports