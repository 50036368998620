var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"caseDetail"},[_c('div',{staticClass:"bannerImg position"},[(_vm.detailIndex == 0)?_c('img',{attrs:{"width":"100%","height":"600","src":require("@/assets/img/nm_01.png"),"alt":""}}):_vm._e(),(_vm.detailIndex == 1)?_c('img',{attrs:{"width":"100%","height":"600","src":require("@/assets/img/nm_2.png"),"alt":""}}):_vm._e(),(_vm.detailIndex == 0)?_c('div',{staticClass:"bannerTip",staticStyle:{"width":"480px"}},[_c('img',{attrs:{"src":require("@/assets/img/yitai_logo.png"),"alt":""}}),_vm._m(0),_vm._m(1),_vm._m(2)]):_vm._e(),(_vm.detailIndex == 1)?_c('div',{staticClass:"bannerTip",staticStyle:{"width":"480px"}},[_c('img',{attrs:{"src":require("@/assets/img/nm_3.png"),"alt":""}}),_vm._m(3),_vm._m(4),_vm._m(5)]):_vm._e()]),_c('div',{staticClass:"center detail"},[_c('h1',[_vm._v("客户名称")]),_c('h4',[_vm._v(_vm._s(_vm.caseDetail.case[_vm.detailIndex].title))]),_c('h1',[_vm._v("合作类型")]),_c('h4',[_vm._v(_vm._s(_vm.caseDetail.case[_vm.detailIndex].type))]),_c('h1',[_vm._v("解决方案")]),_c('h4',[_c('span',[_vm._v(_vm._s(_vm.caseDetail.case[_vm.detailIndex].solution))])]),_c('h1',{domProps:{"textContent":_vm._s(_vm.detailIndex==0?'实现价值':'实现流程')}}),_c('h4',[_c('span',[_vm._v(_vm._s(_vm.caseDetail.case[_vm.detailIndex].value))])]),_c('img',{staticClass:"mt60",attrs:{"src":_vm.caseDetail.case[_vm.detailIndex].img1,"alt":""}}),_c('img',{staticClass:"mt60",attrs:{"src":_vm.caseDetail.case[_vm.detailIndex].img2,"alt":""}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mt40"},[_c('span',[_vm._v("行业类型")]),_c('em',[_vm._v("煤炭")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("企业类型")]),_c('em',[_vm._v("股份有限公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("注册资本")]),_c('em',[_vm._v("1000000万元人民币")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mt40"},[_c('span',[_vm._v("行业类型")]),_c('em',[_vm._v("医疗")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("企业类型")]),_c('em',[_vm._v("股份有限公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("注册资本")]),_c('em',[_vm._v("1000000万元人民币")])])
}]

export { render, staticRenderFns }