<template>
  <div>
    <el-breadcrumb separator="/"
                   class="mbx">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>账户信息</el-breadcrumb-item>
      <el-breadcrumb-item>电子账簿管理</el-breadcrumb-item>
      <el-breadcrumb-item>开户详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <div class="spwa">
      <img src="../../../../assets/img/sq_01@2x.png"
           alt="">
      <span>失败提示的文案</span>
    </div> -->
    <el-form :rules="rules"
             ref="openFormDataRef"
             :model="openFormData">
      <div class="message_box">
        <div class="message_title">
          基础信息
        </div>
        <div class="form_box">

          <el-form-item label="企业名称："
                        prop="companyName">
            <el-input v-model="openFormData.companyName"
                      disabled></el-input>
          </el-form-item>
          <el-form-item label="开户许可证："
                        class="up_img"
                        prop="approvalFile">
            <el-upload action="#"
                       :disabled='disable'
                       class="upload-demo"
                       :class="{ uploadShow }"
                       list-type="picture-card"
                       :auto-upload="false"
                       :on-change="handleAvatarSuccess"
                       :on-remove="handleRemove"
                       :file-list="actionUrl"
                       :limit="1">
              <i v-if="!openFormData.approvalFile"
                 class="el-icon-plus avatar-uploader-icon">
                <img src="../../../../assets/img/tianjia_icon@2x.png"
                     alt="">
                <i>上传开户许可证
                </i></i>
            </el-upload>
            <el-dialog :visible.sync="imgDialogVisible">
              <img width="100%"
                   :src="backIdUrl"
                   alt="" />
            </el-dialog>
            <span @click="lookOpenAccount=true">查看示例</span>

          </el-form-item>
          <el-form-item label="开户许可证编号："
                        prop="approvalNumber">
            <el-input :disabled='disable'
                      v-model="openFormData.approvalNumber"
                      placeholder="请输入开户许可证号"></el-input>
          </el-form-item>
          <el-form-item label="营业期限："
                        :prop="openFormData.beginTerm?!openFormData.longTerm?'endTerm':'beginTerm':'beginTerm'">
            <el-date-picker :disabled='disable'
                            v-model="openFormData.beginTerm"
                            value-format='yyyy-MM-dd'
                            type="date"
                            placeholder="开始日期">
            </el-date-picker>
            <el-date-picker :disabled="!disable?openFormData.longTerm:disable"
                            v-model="openFormData.endTerm"
                            value-format='yyyy-MM-dd'
                            type="date"
                            placeholder="结束日期">
            </el-date-picker>
            <el-checkbox v-if="!openFormData.endTerm"
                         :disabled='disable'
                         v-model="openFormData.longTerm">证件长期有效</el-checkbox>

          </el-form-item>
        </div>

      </div>
      <div class="message_box">
        <div class="message_title">
          法人信息
        </div>
        <div class="form_box">

          <el-form-item label="法人证件："
                        class="up_img up_img_btn">
            <el-upload :disabled='disable'
                       :class="{ uploadShow1 }"
                       action="#"
                       list-type="picture-card"
                       :auto-upload="false"
                       :limit="1"
                       class="upload-demo"
                       :file-list="actionUrl1"
                       :on-change="handleAvatarSuccess1"
                       :on-remove="handleRemove1">

              <i v-if="!openFormData.legalFrontUrl"
                 class="el-icon-plus avatar-uploader-icon">
                <img src="../../../../assets/img/tianjia_icon@2x.png"
                     alt="">
                <i>身份证头像页
                </i></i>
            </el-upload>
            <el-upload :disabled='disable'
                       :class="{ uploadShow2 }"
                       class="upload-demo"
                       :file-list="actionUrl2"
                       action="#"
                       list-type="picture-card"
                       :auto-upload="false"
                       :limit="1"
                       :on-change="handleAvatarSuccess2"
                       :on-remove="handleRemove2">
              <i v-if="!openFormData.legalBackUrl"
                 class="el-icon-plus avatar-uploader-icon">
                <img src="../../../../assets/img/tianjia_icon@2x.png"
                     alt="">
                <i>身份证国徽页
                </i></i>
            </el-upload>
            <span @click="lookLegalPerson=true">查看示例</span>

          </el-form-item>
          <div class="tishi">（请上传清晰的证照，系统会自动识别照片内信息，如信息有误可手动修改）</div>
          <el-form-item label="有效期："
                        :prop="openFormData.legalStartDate?!openFormData.legalIdLong?'legalEndDate':'legalStartDate':'legalStartDate'">
            <el-date-picker :disabled='disable'
                            v-model="openFormData.legalStartDate"
                            value-format='yyyy-MM-dd'
                            type="date"
                            placeholder="开始日期">
            </el-date-picker>
            <el-date-picker :disabled='!disable?openFormData.legalIdLong:disable'
                            v-model="openFormData.legalEndDate"
                            value-format='yyyy-MM-dd'
                            type="date"
                            placeholder="结束日期">
            </el-date-picker>
            <el-checkbox v-if="!openFormData.legalEndDate"
                         :disabled='disable'
                         v-model="openFormData.legalIdLong">证件长期有效</el-checkbox>

          </el-form-item>
        </div>
      </div>
      <div class="message_box">
        <div class="message_title">
          联系人信息
        </div>
        <div class="form_box">

          <el-form-item label="联系人信息："
                        :prop="openFormData.agentFrontUrl?'agentBackUrl':'agentFrontUrl'"
                        class="up_img up_img_btn">
            <el-upload :disabled='disable'
                       :class="{ uploadShow3 }"
                       class="upload-demo"
                       :file-list="actionUrl3"
                       action="#"
                       list-type="picture-card"
                       :auto-upload="false"
                       :limit="1"
                       :on-change="handleAvatarSuccess3"
                       :on-remove="handleRemove3">
              <i v-if="!openFormData.agentFrontUrl"
                 class="el-icon-plus avatar-uploader-icon">
                <img src="../../../../assets/img/tianjia_icon@2x.png"
                     alt="">
                <i>身份证头像页
                </i></i>
            </el-upload>
            <el-upload :disabled='disable'
                       :class="{ uploadShow4 }"
                       class="upload-demo"
                       :file-list="actionUrl4"
                       action="#"
                       list-type="picture-card"
                       :auto-upload="false"
                       :limit="1"
                       :on-change="handleAvatarSuccess4"
                       :on-remove="handleRemove4">
              <i v-if="!openFormData.agentBackUrl"
                 class="el-icon-plus avatar-uploader-icon">
                <img src="../../../../assets/img/tianjia_icon@2x.png"
                     alt="">
                <i>身份证国徽页
                </i></i>
            </el-upload>
            <span @click="lookLegalPerson=true">查看示例</span>

          </el-form-item>
          <div class="tishi">（请上传清晰的证照，系统会自动识别照片内信息，如信息有误可手动修改）</div>

          <el-form-item label="联系手机："
                        prop="agentTel">
            <el-input :disabled='disable'
                      v-model="openFormData.agentTel"
                      placeholder="请输入联系手机"></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱："
                        prop="agentEmail">
            <el-autocomplete :disabled='disable'
                             class="inline-input"
                             v-model="openFormData.agentEmail"
                             :fetch-suggestions="querySearch"
                             placeholder="请输入电子邮箱"
                             :trigger-on-focus="false">

            </el-autocomplete>
          </el-form-item>

        </div>

      </div>
      <div class="message_box">
        <div class="message_title">
          绑定账户信息
        </div>
        <div class="form_box">

          <el-form-item label="开户行名称："
                        prop="bankAccountName">
            <el-select :disabled='disable'
                       style="width:100%"
                       @change="getBankMessage"
                       v-model="openFormData.bankAccountName"
                       placeholder="请选择银行名称">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.bankYhName"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="message_box">
      <div class="message_title">
        协议信息
        <span>提示：请阅读协议内容，虚户开通成功后银行将为您自动签署并盖章！</span>
      </div>
      <div class="form_box">
        <!-- dialogVisible=true -->
        <el-button style="color:#C70009"
                   @click="open"
                   type="text">《武汉众邦银行E账通系统开户三方协议》</el-button>

      </div>
    </div>
    <div class="tj_btn"
         v-if="!disable">
      <el-button @click="addSubmit">提交</el-button>

    </div>
    <!--查看示例-->
    <el-dialog :show-close="false"
               :visible.sync="lookOpenAccount"
               :destroy-on-close="true"
               width="410px"
               class="noHeader">
      <div class="contentTip"
           style="text-align: center;line-height: 30px">
        <div class="imgBoxTip">
          <img src="../../../../assets/img/ticket/bank-01.png"
               width="100%"
               height="100%"
               class="tipIcon"
               alt="">
        </div>
        <h4>开户许可证复印件（基本账户）</h4>
        <p>（加盖公章）</p>
      </div>
    </el-dialog>
    <el-dialog :show-close="false"
               :visible.sync="lookLegalPerson"
               :destroy-on-close="true"
               width="740px"
               class="noHeader">
      <div class="contentTip"
           style="text-align: center;line-height: 30px;display: flex;justify-content: space-between">
        <div class="sfz">
          <div class="imgBoxTip">
            <img width="340px"
                 height="240px"
                 class="tipIcon"
                 src="../../../../assets/img/auth/frsfz1.png"
                 alt="">
          </div>
          <h4>身份证头像页</h4>
          <p>（请上传清晰的证照）</p>
        </div>
        <div class="sfz">
          <div class="imgBoxTip">
            <img width="340px"
                 height="240px"
                 class="tipIcon"
                 src="../../../../assets/img/auth/frsfz2.png"
                 alt="">
          </div>
          <h4>身份证国徽页</h4>
          <p>（请上传清晰的证照）</p>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="武汉众邦银行E账通系统开户三方协议"
               :visible.sync="dialogVisible"
               width="30%">
      <span>武汉众邦银行E账通系统开户三方协议</span>
      <span slot="footer"
            class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button class="continue_btn"
                   @click="dialogVisible=false">同意并继续</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lookOpenAccount: false, //开户许可
      lookLegalPerson: false, //法人
      dialogVisible: false, //协议弹框
      disable: false,
      emailArry: [
        //邮箱联想
        "@qq.com",
        "@sina.com",
        "@163.com",
        "@126.com",
        "@yahoo.com.cn",
        "@gmail.com",
        "@sohu.com",
      ],
      uploadShow: false,
      uploadShow1: false,
      uploadShow2: false,
      uploadShow3: false,
      uploadShow4: false,

      imgDialogVisible: false, //图片查看器显示隐藏
      backIdUrl: "",
      openFormData: {
        //详情信息
        companyName: "",
        bankAccountName: "",
        approvalFile: "",
        approvalNumber: "",
        agentTel: "",
        agentEmail: "",
      },
      options: [],
      actionUrl: [], //续上传的图片列表
      actionUrl1: [],
      actionUrl2: [],
      actionUrl3: [],
      actionUrl4: [],
      name: "",
      rules: {
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        approvalFile: [
          { required: true, message: "请上传开户许可证", trigger: "change" },
        ],
        approvalNumber: [
          { required: true, message: "请输入开户许可证编号", trigger: "blur" },
        ],
        longTerm: [
          { required: true, message: "请选择是否长期有效", trigger: "change" },
        ],
        legalIdLong: [
          { required: true, message: "请选择是否长期有效", trigger: "change" },
        ],
        beginTerm: [
          { required: true, message: "请选择营业期限", trigger: "change" },
        ],
        endTerm: [
          { required: true, message: "请选择结束日期", trigger: "change" },
        ],
        legalStartDate: [
          { required: true, message: "请选择有效期", trigger: "change" },
        ],
        legalEndDate: [
          { required: true, message: "请选择结束日期", trigger: "change" },
        ],
        agentFrontUrl: [
          {
            required: true,
            message: "请上传联系人身份证正面",
            trigger: "change",
          },
        ],
        agentBackUrl: [
          {
            required: true,
            message: "请上传联系人身份证反面",
            trigger: "change",
          },
        ],
        agentTel: [
          { required: true, message: "请输入联系手机", trigger: "blur" },
        ],
        agentEmail: [
          { required: true, message: "请输入电子邮箱", trigger: "blur" },
        ],
        bankAccountName: [
          { required: true, message: "请选择银行名称", trigger: "change" },
        ],
      },
    };
  },
  created() {
    if (this.$route.query.num == 1) {
      this.disable = true; //控制文本框能否输入
    } else {
      this.disable = false; //控制文本框能否输入
    }
    this.khhSelect();
    this.getXhDetail();
  },
  methods: {
    // 另页打开协议
    open() {
      window.open("/static/xd_xy.pdf", "_blank");
    },
    // 获取开户详情
    async getXhDetail() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/zxlrOpenAccount/deatilAccount"
      );
      this.openFormData = res.data.zbAccount;
      this.openFormData.id = res.data.zbAccount.id;
      this.openFormData.enterpriseId = res.data.zbAccount.enterpriseId;
      this.openFormData.companyName = res.data.zbAccount.companyName;
      this.openFormData.approvalFile = res.data.zbAccount.approvalFile;

      this.openFormData.approvalNumber = res.data.zbAccount.approvalNumber;
      this.openFormData.beginTerm = res.data.zbAccount.beginTerm;
      this.openFormData.endTerm = res.data.zbAccount.endTerm;
      this.openFormData.longTerm =
        this.openFormData.longTerm == 1 ? true : false;
      this.openFormData.legalFrontUrl = res.data.zbAccount.legalFrontUrl;
      this.openFormData.legalBackUrl = res.data.zbAccount.legalBackUrl;
      this.openFormData.legalStartDate = res.data.zbAccount.legalStartDate;

      this.openFormData.legalEndDate = res.data.zbAccount.legalEndDate;
      this.openFormData.legalIdLong =
        this.openFormData.legalIdLong == 1 ? true : false;
      this.openFormData.agentFrontUrl = res.data.zbAccount.agentFrontUrl;
      this.openFormData.agentBackUrl = res.data.zbAccount.agentBackUrl;
      this.openFormData.agentTel = res.data.zbAccount.agentTel;

      this.openFormData.agentEmail = res.data.zbAccount.agentEmail;
      if (res.data.zbAccount.bankAccountName) {
        this.options.forEach((item) => {
          if (item.id == res.data.zbAccount.bankAccountId) {
            this.openFormData.bankAccountName = item.bankYhName;
          }
        });
      }

      if (res.data.zbAccount.approvalFile) {
        this.actionUrl.push({ url: res.data.zbAccount.approvalFile });
        this.uploadShow = true;
      }
      if (res.data.zbAccount.legalFrontUrl) {
        this.actionUrl1.push({ url: res.data.zbAccount.legalFrontUrl });
        this.uploadShow1 = true;
      }
      if (res.data.zbAccount.legalBackUrl) {
        this.actionUrl2.push({ url: res.data.zbAccount.legalBackUrl });
        this.uploadShow2 = true;
      }
      if (res.data.zbAccount.agentFrontUrl) {
        this.actionUrl3.push({ url: res.data.zbAccount.agentFrontUrl });
        this.uploadShow3 = true;
      }
      if (res.data.zbAccount.agentBackUrl) {
        this.actionUrl4.push({ url: res.data.zbAccount.agentBackUrl });
        this.uploadShow4 = true;
      }
    },

    // 调取ocr 营业执照
    async handleAvatarSuccess(file, fileList) {
      this.uploadShow = true;
      let param = new FormData();
      param.append("file", file.raw, file.name);
      param.append("type", 1);
      let { data: res } = await this.$auth.post(
        "/cpiaoju-auth/ocr/upload",
        param
      );
      this.openFormData.approvalFile = res.fileUrl;
      console.log(res);
    },
    // 监听删除图片
    handleRemove(file, fileList) {
      this.uploadShow = false;
      this.openFormData.approvalFile = "";
    },
    // 监听查看图片
    handlePreview(file) {
      this.backIdUrl = file.url;
      this.imgDialogVisible = true;
    },
    // 调取ocr 法人正面
    async handleAvatarSuccess1(file, fileList) {
      this.uploadShow1 = true;
      let param = new FormData();
      param.append("file", file.raw, file.name);
      param.append("type", 2);
      param.append("idCardSide", "front");
      let { data: res } = await this.$auth.post(
        "/cpiaoju-auth/ocr/upload",
        param
      );
      this.openFormData.legalFrontUrl = res.fileUrl;
      if (res.code == 200) {
        this.openFormData.legalName = res.ocr.full_name;
        this.openFormData.legalIdNo = res.ocr.citizenship_number;
      }
    },
    // 监听删除图片
    handleRemove1(file, fileList) {
      this.uploadShow1 = false;
      this.openFormData.legalFrontUrl = "";
    },
    // 监听查看图片
    handlePreview1(file) {
      this.backIdUrl = file.url;
      this.imgDialogVisible = true;
    },
    // 调取ocr 法人反面
    async handleAvatarSuccess2(file, fileList) {
      this.uploadShow2 = true;
      let param = new FormData();
      param.append("file", file.raw, file.name);
      param.append("type", 2);
      param.append("idCardSide", "back");
      let { data: res } = await this.$auth.post(
        "/cpiaoju-auth/ocr/upload",
        param
      );
      this.openFormData.legalBackUrl = res.fileUrl;
    },
    // 监听删除图片
    handleRemove2(file, fileList) {
      this.uploadShow2 = false;
      this.openFormData.legalBackUrl = "";
    },
    // 监听查看图片
    handlePreview2(file) {
      this.backIdUrl = file.url;
      this.imgDialogVisible = true;
    },
    // 调取ocr 联系人正面
    async handleAvatarSuccess3(file, fileList) {
      this.uploadShow3 = true;
      let param = new FormData();
      param.append("file", file.raw, file.name);
      param.append("type", 2);
      param.append("idCardSide", "front");
      let { data: res } = await this.$auth.post(
        "/cpiaoju-auth/ocr/upload",
        param
      );
      this.openFormData.agentFrontUrl = res.fileUrl;
      this.openFormData.agentName = res.ocr.full_name;
      this.openFormData.agentIdNo = res.ocr.citizenship_number;
      console.log(res);
    },
    // 监听删除图片
    handleRemove3(file, fileList) {
      this.uploadShow3 = false;
      this.openFormData.agentFrontUrl = "";
    },
    // 监听查看图片
    handlePreview3(file) {
      this.backIdUrl = file.url;
      this.imgDialogVisible = true;
    },
    // 调取ocr 联系人反面
    async handleAvatarSuccess4(file, fileList) {
      this.uploadShow4 = true;
      let param = new FormData();
      param.append("file", file.raw, file.name);
      param.append("type", 2);
      param.append("idCardSide", "back");
      let { data: res } = await this.$auth.post(
        "/cpiaoju-auth/ocr/upload",
        param
      );
      this.openFormData.agentBackUrl = res.fileUrl;
      let stT = res.ocr.issue_date.split("");
      stT.splice(4, 0, "-");
      stT.splice(7, 0, "-");

      this.openFormData.beginEffective = stT.join("");
      console.log(this.openFormData.beginEffective);

      let stT1 = res.ocr.expiring_date.split("");
      stT1.splice(4, 0, "-");
      stT1.splice(7, 0, "-");

      this.openFormData.endEffective = stT1.join("");
      console.log(this.openFormData.endEffective);

      if (res.ocr.expiring_date == "长期") {
        this.openFormData.longEffective = 1;
      } else {
        this.openFormData.longEffective = 2;
      }
    },
    // 监听删除图片
    handleRemove4(file, fileList) {
      this.uploadShow4 = false;
      this.openFormData.agentBackUrl = "";
    },
    // 监听查看图片
    handlePreview4(file) {
      this.backIdUrl = file.url;
      this.imgDialogVisible = true;
    },
    querySearch(queryString, cb) {
      console.log(queryString);
      let results = [];
      this.emailArry.forEach((item) => {
        // (item = queryString + item)
        results.push({
          value: queryString + item,
        });
      });
      console.log(results);
      // var restaurants = this.restaurants;
      // var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // // 调用 callback 返回建议列表的数据
      cb(results);
    },
    // 获取银行名称下拉
    async khhSelect() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/zxlrOpenAccount/bankName"
      );
      this.options = res.data;
    },
    getBankMessage(val) {
      this.options.forEach((item) => {
        if (item.id == val) {
          this.openFormData.bankAccountName = item.bankBranch;
          this.openFormData.bankAccountNo = item.clearingNumber;
          this.openFormData.bankAccountNumber = item.bankNumber;
          this.openFormData.bankAccountId = item.id;
        }
      });
    },
    // 编辑
    addSubmit() {
      if (this.openFormData.longTerm) {
        this.openFormData.longTerm = 1;
      } else {
        this.openFormData.longTerm = 2;
      }
      if (this.openFormData.legalIdLong) {
        this.openFormData.legalIdLong = 1;
      } else {
        this.openFormData.legalIdLong = 2;
      }
      if (!Number(this.openFormData.bankAccountName)) {
        this.options.forEach((item) => {
          if (item.bankYhName == this.openFormData.bankAccountName) {
            this.openFormData.bankAccountName = item.bankBranch;
            this.openFormData.bankAccountNo = item.clearingNumber;
            this.openFormData.bankAccountNumber = item.bankNumber;
            this.openFormData.bankAccountId = item.id;
          }
        });
      }

      this.$refs["openFormDataRef"].validate(async (v) => {
        if (!v) return;
        const { data: res } = await this.$auth.post(
          "/cpiaoju-auth/zxlrOpenAccount/updateAccount",
          this.$qs.stringify(this.openFormData)
        );
        if (res.code != 200) return this.$message.warning(res.msg);
        this.$message.success(res.msg);
        this.$router.push("/aside/virtualBnakList");
      });
    },
  },
};
</script>


<style lang="less" scoped>
.mbx {
  height: 56px;
  background-color: #fff;
  line-height: 56px;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.spwa {
  height: 40px;
  background: #ffe6e5;
  border: 1px solid #c70009;
  padding-left: 22px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  > img {
    margin-right: 10px;
  }
}
.message_box {
  background: #ffffff;
  margin-bottom: 20px;
  .message_title {
    height: 56px;
    background: #ffffff;
    padding: 16px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f3ef;
    font-size: 18px;
    > span {
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      line-height: 22px;
      letter-spacing: 1px;
      margin-left: 20px;
    }
  }
  .form_box {
    padding: 20px;
    box-sizing: border-box;
    /deep/.el-form-item__label {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      padding: 0;
    }
  }
  /deep/.el-form-item {
    display: flex;
    width: 535px;
  }
  /deep/.el-form-item__content {
    flex: 1 !important;
    display: flex;
  }
  /deep/ .el-date-editor.el-input {
    margin-right: 10px;
    width: none !important;
  }
  /deep/.el-checkbox {
    border-radius: 4px;
    border: 1px solid #dedede;
    flex: 1;
    padding: 10px 15px;
    box-sizing: border-box;
  }
  /deep/.el-form-item__content {
    line-height: 1;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
    // border: 1px dashed #d8d8d8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > img {
      width: 36px;
      border: 1px dashed #d8d8d8;
      color: #d8d8d8;
      margin-bottom: 18px;
    }
    > i {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      letter-spacing: 1px;
    }
  }
  .avatar {
    width: 100%;
    height: 100%;
    display: block;
  }
  .el-icon-plus:before {
    content: none;
  }
  // .el-icon-plus:after {
  //   content: "上传开户许可证";
  //   font-size: 14px;
  //   font-weight: 400;
  //   color: #999999;
  //   line-height: 20px;
  //   letter-spacing: 1px;
  // }
  .up_img_btn {
    margin-bottom: 0;
  }
  .up_img {
    /deep/.el-form-item__content {
      display: flex;
      align-items: flex-end;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #c70009;
      line-height: 20px;
      letter-spacing: 1px;
      margin-left: 15px;
      cursor: pointer;
    }
  }
  .tishi {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    letter-spacing: 1px;
    padding-left: 92px;
    margin: 15px 0 20px;
  }
}
.tj_btn {
  width: 100%;
  text-align: center;
  padding-top: 10px;
  /deep/.el-button--default {
    width: 160px;
    height: 48px;
    border-radius: 4px;

    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    border: none;
    background: #c70009;
    color: #ffffff;
  }
}
/deep/.el-date-editor.el-input {
  width: 33%;
}
/deep/.el-upload--picture-card {
  margin-right: 10px;
}
/deep/.el-upload-list__item {
  margin-bottom: 0 !important;
}
/*控制图片按钮是否显示的样式*/
/deep/.uploadShow .el-upload--picture-card {
  display: none;
}
/deep/.uploadShow1 .el-upload--picture-card {
  display: none;
}
/deep/.uploadShow2 .el-upload--picture-card {
  display: none;
}
/deep/.uploadShow3 .el-upload--picture-card {
  display: none;
}
/deep/.uploadShow4 .el-upload--picture-card {
  display: none;
}
.continue_btn {
  color: #ffffff;
  background: #c70009;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.continue_btn:hover {
  color: #ffffff;
  background: #c70009;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.continue_btn:focus {
  color: #ffffff;
  background: #c70009;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
</style>
