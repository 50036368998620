<template>
  <div>
    <el-breadcrumb separator="/"
                   class="mbx">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>账户信息</el-breadcrumb-item>
      <el-breadcrumb-item>出入金管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="bank_card">
      <el-tabs v-model="activeName"
               @tab-click="handleClick">
        <el-tab-pane label="提现明细"
                     name="first">
          <el-select class="select_css"
                     v-model="fictitiousAccount"
                     @change='searchTx'
                     clearable
                     placeholder="请选择虚户Id">
            <el-option v-for="item in options"
                       :key="item.id"
                       :label="item.fictitiousAccount"
                       :value="item.fictitiousAccount">
            </el-option>
          </el-select>
          <el-table :data="recordData"
                    style="width: 100%">
            <el-table-column type="index"
                             label="序号"
                             width="50">
            </el-table-column>
            <el-table-column prop="fictitiousAccount"
                             width="110"
                             label="付款虚户ID">
            </el-table-column>
            <el-table-column prop="source"
                             label="产品类型">
              <template slot-scope="scope">
                {{scope.row.source==1?'微掌柜':'微账户'}}
              </template>
            </el-table-column>
            <el-table-column prop="useStatus"
                             label="账户类型">
              <template slot-scope="scope">
                {{scope.row.useStatus==1?'放款账户':'还款账户'}}
              </template>
            </el-table-column>
            <el-table-column prop="cashOutAmount"
                             :formatter="thousandth"
                             label="提现金额(元)"
                             width="115">
            </el-table-column>
            <el-table-column prop="cashOutLeftAmount"
                             :formatter="thousandth"
                             label="提现后余额(元)"
                             width="125">
            </el-table-column>
            <el-table-column prop="accountNo"
                             label="收款账号"
                             width="105">
            </el-table-column>
            <el-table-column prop="createTime"
                             label="提现时间">
            </el-table-column>
            <el-table-column prop="status"
                             align="right"
                             label="状态">
              <template slot-scope="scope">
                {{scope.row.status==0?'待审核':scope.row.status==1?'处理中':scope.row.status==2?'提现成功':'提现失败'}}
              </template>
            </el-table-column>
            <el-table-column align="right"
                             label="操作">
              <template slot-scope="scope">
                <el-button type="text"
                           size="small"
                           @click="viewTx(scope.row)">查看</el-button>
              </template>
            </el-table-column>

          </el-table>
          <!-- 分页 -->
          <el-pagination style="margin-top:10px"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="page"
                         :page-sizes="[10, 20, 30, 40]"
                         :page-size="limit"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="入金明细"
                     name="second">
          <el-select v-model="fctId"
                     @change='searchRj'
                     clearable
                     placeholder="请选择虚户Id">
            <el-option v-for="item in options"
                       :key="item.id"
                       :label="item.fictitiousAccount"
                       :value="item.fictitiousAccount">
            </el-option>
          </el-select>
          <el-table :data="RjData"
                    style="width: 100%">
            <el-table-column type="index"
                             label="序号"
                             width="50">
            </el-table-column>
            <el-table-column prop="bnkTrnSeqNo"
                             label="流水号">
            </el-table-column>
            <el-table-column prop="fctId"
                             label="虚户ID">
            </el-table-column>
            <el-table-column prop="amt"
                             :formatter="thousandth"
                             label="金额(元)">
            </el-table-column>
            <el-table-column prop="swtchOutAcctNo"
                             label="转出方账户">
            </el-table-column>
            <el-table-column prop="time"
                             label="时间"
                             width="140">
            </el-table-column>
            <el-table-column prop="txnSt"
                             label="状态"
                             width="60">
              <template slot-scope="scope">
                {{scope.row.txnSt=='processing'?'处理中':scope.row.txnSt=='succeeded'?'提现成功':'失败'}}
              </template>
            </el-table-column>

            <el-table-column align="right"
                             width="100"
                             label="操作">
              <template slot-scope="scope">
                <el-button type="text"
                           size="small"
                           @click="viewTx(scope.row)">查看</el-button>
                <el-button type="text"
                           size="small"
                           @click="down(scope.row.bankCashInZipUrl)">回单下载</el-button>
              </template>
            </el-table-column>

          </el-table>
          <!-- 分页 -->
          <el-pagination style="margin-top:10px"
                         @size-change="handleSizeChange1"
                         @current-change="handleCurrentChange1"
                         :current-page="page1"
                         :page-sizes="[10, 20, 30, 40]"
                         :page-size="limit1"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total1">
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
      <!-- <el-button class="retur_router"
                 @click="$router.go(-1);">返回</el-button> -->
    </div>
    <el-dialog title="查看提现明细"
               :visible.sync="dialogVisible"
               width="30%">
      <div v-if="txRow.status==3"
           class="spwa">
        <img src="../../../../assets/img/sq_01@2x.png"
             alt="">
        <span>{{txRow.failMsg}}</span>
      </div>
      <div class="ck_cont">
        <div class="bg_cont"
             v-if="activeName=='first'">
          <ul>
            <li><span>付款账号：</span><span class="co_font">{{txRow.fictitiousAccount}}</span></li>
            <li><span>状态：</span><span class="co_font"> {{txRow.status==0?'待审核':txRow.status==1?'处理中':txRow.status==2?'提现成功':'提现失败'}}</span></li>
            <li><span>提现金额：</span><span class="co_font">{{thousandth1(txRow.cashOutAmount)}}</span></li>
            <li><span>法人姓名：</span><span class="co_font">{{txRow.legalName}}</span></li>
            <li><span>收款账户：</span><span class="co_font">{{txRow.accountNo}}</span></li>
            <li><span>大额行号：</span><span class="co_font">{{txRow.clearingNumber}}</span></li>
            <li><span>提现时间：</span><span class="co_font">{{txRow.createTime}}</span></li>
            <li><span>手机号：</span><span class="co_font">{{txRow.legalTel}}</span></li>
            <li style="margin-bottom:0"><span>提现后余额：</span><span class="co_font">{{thousandth1(txRow.cashOutLeftAmount)}}</span></li>
          </ul>
        </div>
        <div class="bg_cont"
             v-else>
          <ul>
            <li><span>流水号：</span><span class="co_font">{{txRow.bnkTrnSeqNo}}</span></li>
            <li><span>订单号：</span><span class="co_font"> {{txRow.sysOrdNo}}</span></li>
            <li><span>交易状态：</span><span class="co_font">{{txRow.txnSt=='processing'?'处理中':txRow.txnSt=='succeeded'?'提现成功':'失败'}}</span></li>
            <li><span>转出方账号：</span><span class="co_font">{{txRow.swtchOutAcctNo}}</span></li>
            <li><span>转出方银行编号：</span><span class="co_font">{{txRow.payBnkCd}}</span></li>
            <li><span>转出方银行账户名称：</span><span class="co_font">{{txRow.payBnkActNm}}</span></li>
            <li><span>金额（元）：</span><span class="co_font">{{thousandth1(txRow.amt)}}</span></li>
            <li><span>转出方银行联行号：</span><span class="co_font">{{txRow.payBnkBrnNo}}</span></li>
            <li><span>行名：</span><span class="co_font">{{txRow.bnkNm}}</span></li>
            <li><span>时间：</span><span class="co_font">{{txRow.time}}</span></li>
            <li><span>虚户ID：</span><span class="co_font">{{txRow.fctId}}</span></li>
            <li style="margin-bottom:0"><span>附言信息：</span><span class="co_font">{{txRow.cmntsInf}}</span></li>
          </ul>
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false"
                   class="btn_right">取消</el-button>
        <el-button class="btn_right btn_sub"
                   @click="dialogVisible = false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      activeName: "first",
      RjData: [], //入金明细list
      txRow: {}, //详情信息
      recordData: [], //提现明细
      page: 1, //提现分页参数
      limit: 10,
      total: 0,
      limit1: 10, //入金分页参数
      page1: 1,
      total1: 0,
      fictitiousAccount: "", //提现虚户id查询条件
      fctId: "", //入金明细查询条件
      options: [], //虚户id下拉
    };
  },
  created() {
    this.getVirList();
    this.getRjmxList();
    this.getSelectList();
  },
  methods: {
    async getSelectList() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/zxlrCashOutLog/findFictitiousAccount"
      );
      this.options = res.data;
    },
    handleClick() {},
    // 提现明细
    async getVirList() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/zxlrCashOutLog/listByPage",
        this.$qs.stringify({
          page: this.page,
          limit: this.limit,
          fictitiousAccount: this.fictitiousAccount,
        })
      );
      if (res.code != 0) return this.$message.warning(res.msg);
      this.total = res.data.total;
      this.recordData = res.data.records;
    },
    // 查询提现明细
    searchTx() {
      this.page = 1;
      this.getVirList();
    },
    //监听 页面条数 改变的事件
    handleSizeChange(newSize) {
      this.limit = newSize;
      this.getVirList();
    },
    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.page = newPage;
      this.getVirList();
    },
    // 入金
    async getRjmxList() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/zxlrCashInLog/selectCashInLog",
        this.$qs.stringify({
          page: this.page1,
          limit: this.limit1,
          fctId: this.fctId,
        })
      );
      if (res.code != 200) return this.$message.warning(res.msg);
      this.total1 = res.data.total;

      this.RjData = res.data.records;
    },
    // 入金查询
    searchRj() {
      this.page1 = 1;
      this.getRjmxList();
    },
    //监听 页面条数 改变的事件
    handleSizeChange1(newSize) {
      this.limit1 = newSize;
      this.getRjmxList();
    },
    //监听 页码值 改变的事件
    handleCurrentChange1(newPage) {
      this.page1 = newPage;
      this.getRjmxList();
    },
    viewTx(row) {
      this.dialogVisible = true;
      this.txRow = row;
      if (row.legalName) {
        this.txRow.legalName =
          row.legalName.substr(0, 1) +
          new Array(row.legalName.length).join("*");
      }
    },
    // 回单下载
    down(url) {
      if (!url) return this.$message.warning("回单为空");
      this.$auth({
        url: "/cpiaoju-auth/ocr/downloadUrlFile",
        method: "get",
        responseType: "blob",
        params: {
          url,
        },
      }).then((res) => {
        const blob = new Blob([res.data], { type: "application/zip" });
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        // a.download = row.companyName;
        a.click();
        URL.revokeObjectURL(url); //释放内存
      });
    },
    //列表数据-千分位-过滤
    thousandth(row, column) {
      const property = column["property"];
      if (row[property] && row[property] !== "-") {
        return Number(row[property])
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      } else if (
        row[property] === "-" ||
        row[property] === "" ||
        row[property] === null
      ) {
        return "-";
      }
    },
    //千分位
    thousandth1: function (value) {
      if (value) {
        return Number(value)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.mbx {
  height: 56px;
  background-color: #fff;
  line-height: 56px;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.aside_right {
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    .bank_card {
      flex: 1;
    }
  }
}
.bank_card {
  position: relative;
  background-color: #fff;
  box-sizing: border-box;
  padding: 10px 20px 23px;
  /deep/.el-tabs__nav {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #c70009;
    line-height: 25px;
    letter-spacing: 2px;
  }
  /deep/.el-tabs__item:hover {
    color: #c70009 !important;
  }
  /deep/.el-tabs__item.is-active {
    color: #c70009 !important;
  }
  /deep/.el-tabs__active-bar {
    background-color: #c70009 !important;
  }

  .retur_router {
    width: 86px;
    height: 34px;
    background: #c70009;
    border-radius: 4px;
    position: absolute;
    right: 19px;
    top: 20px;
    padding: 7px 29px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
  }
  /deep/.el-table thead {
    height: 54px;
    padding-top: 17px;
    padding-bottom: 15px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    letter-spacing: 1px;
  }
  /deep/.el-table .el-table__cell {
    padding: 0;
  }
  /deep/.el-table .cell {
    padding: 0;
  }
  /deep/.el-table th.el-table__cell > .cell {
    padding: 0;
  }
  /deep/.el-table th.el-table__cell {
    background: #fafafa;
  }
  .el-button--text {
    color: #c70009;
  }
}
/deep/.el-dialog__header {
  border-bottom: 1px solid #f0f3ef;
}
/deep/.el-dialog__body {
  padding: 5px 20px;
}
.spwa {
  height: 40px;
  background: #ffe6e5;
  border: 1px solid #c70009;
  padding-left: 22px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  > img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}
.ck_cont {
  border: 1px solid #f1f1f1;
  padding: 0 20px;
  .bg_cont {
    background: #fafafa;
    padding: 0 20px;
    li {
      margin-bottom: 20px;
      .co_font {
        color: #999999;
      }
    }
  }
}
/deep/.el-dialog__footer {
  padding-top: 0;
}
.dialog-footer {
  .btn_right {
    padding: 0;
    width: 86px;
    height: 34px;
    border-radius: 4px;
    border: 1px solid #c70009;

    font-size: 14px;
    font-weight: 400;
    color: #c70009;
    line-height: 20px;
  }
  .btn_sub {
    background: #c70009;

    color: #ffffff;
  }
}
.select_css {
  /deep/.el-input__inner {
    height: 35px;
    margin-bottom: 10px;
  }
  /deep/.el-input__icon {
    height: 44px; //设置高度，icon元素恢复原来的高度，这时arror才能垂直居中
    position: relative;
    top: -5px; // 元素整体上移，让箭头也居中
  }
}
</style>