<template>
  <div>
    <!--    新闻详情-->
    <div class="newsDetail">
      <div class="block bannerImg">
        <img width="100%" height="600" src="@/assets/img/news.jpg" alt="">
      </div>
      <div class="breadcrumb">
        <div class="center">
          <p><span><router-link :to="{path:'/news'}">新闻中心 > </router-link> </span>
            <router-link :to="{path:'/news',query:{activeName:activeDetailTab}}"><span
                    v-text="activeDetailTab=='1'?'行业热点 >':'公司动态 >'"></span></router-link>
            <em>{{acticleDetailList.title}}</em></p>
        </div>
      </div>
      <div class="contentText center ql-editor">
        <h1>{{acticleDetailList.title}}</h1>
        <h5>{{acticleDetailList.createTime}}</h5>
        <div class="contentNormal" style="line-height: 28px;letter-spacing: 2px;font-size: 16px" v-html="acticleDetailList.content">{{acticleDetailList.content}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "NewsDetail",
    data() {
      return {
        articleDetailId: this.$route.query.articleId,
        activeDetailTab: this.$route.query.activeTab,
        acticleDetailList: ''
      }
    },
    created() {
      this.articleDetail()
    },
    methods: {
      //文章
      async articleDetail() {
        console.log(this.$route.query)
        const obj = {
          id: this.articleDetailId,
        }
        const {data: res} = await this.$http.post('/indexNewsDetails', this.$qs.stringify(obj));
        if (res.code == 200) {
          this.acticleDetailList = res.data;
          this.total = res.data.total;
        } else {
          return this.$message.error(res.msg);
        }
      },
    }
  }
</script>

<style lang="less" scoped>
  .breadcrumb {
    height: 60px;
    background: #FFFFFF;
    border: 2px solid #F3F5FB;

    p {
      line-height: 60px;

      em {
        color: #999999;
      }
    }
  }

  .contentText {
    padding: 60px 0;
    box-sizing: border-box;
    white-space: pre-wrap;
    word-break: break-all;

    h1 {
      font-size: 32px;
      line-height: 45px;
      letter-spacing: 4px;
    }

    h5 {
      font-size: 16px;
      font-weight: 400;
      color: #262626;
      line-height: 22px;
      letter-spacing: 2px;
      margin: 20px 0 40px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      color: #262626;
      line-height: 36px;
      letter-spacing: 2px;

    }

    p.red {
      color: #C70009;
    }
  }
  .contentNormal{
    img{
      width: 1200px!important;
    }
  }

</style>
<style>
  .contentNormal img{
    width: 600px!important;
    height: auto!important;
    margin-bottom: 20px!important;
    margin-top: 20px!important;
  }

  .contentText .ql-syntax {
    white-space: pre-wrap;
  }

  .contentText em {
    font-style: italic !important;
  }
  .ql-size-12px{
    font-size: 12px;
  }
  .ql-size-14px{
    font-size: 14px;
  }
  .ql-size-16px{
    font-size: 16px;
  }
  .ql-size-18px{
    font-size: 18px;
  }
  .ql-size-20px{
    font-size: 20px;
  }
  .ql-size-22px{
    font-size: 22px;
  }
  .ql-size-24px{
    font-size: 24px;
  }
  .ql-size-26px{
    font-size: 26px;
  }
  .ql-size-28px{
    font-size: 28px;
  }
</style>