import Vue from 'vue'
import {
  Alert,
  Container,
  Header,
  Footer,
  Main,
  Menu,
  MenuItem,
  Submenu,
  Aside,
  Form,
  FormItem,
  Input,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Row,
  Image,
  Col,
  Table,
  TableColumn,
  Pagination,
  Tree,
  Link,
  Dialog,
  Select,
  Option,
  Tooltip,
  Switch,
  Message,
  MessageBox,
  Tabs,
  TabPane,
  Upload,
  RadioGroup,
  Radio,
  Empty,
  Timeline,
  TimelineItem,
  DatePicker,
  Tag,
  // Drawer,
  Popover,
  CheckboxGroup,
  Checkbox,
  // Image,
  // Cascader,
  // CascaderPanel,
  // CarouselItem,
  Loading,
  Descriptions,
  DescriptionsItem,
  // TimePicker,
  Steps,
  Step,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Autocomplete
} from 'element-ui'

Vue.use(Alert)
Vue.use(Autocomplete)
Vue.use(Container);
Vue.use(Header);
Vue.use(Footer)
Vue.use(Main);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Aside);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Button);
Vue.use(Card);
Vue.use(Row);
Vue.use(Image);
Vue.use(Col);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Tree);
Vue.use(Link);
Vue.use(Dialog);
Vue.use(Select);
Vue.use(Option);
Vue.use(Tooltip);
Vue.use(Switch);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Upload);
Vue.use(RadioGroup);
Vue.use(Radio);
// Vue.use(Empty);
Vue.use(Timeline);
Vue.use(TimelineItem)
Vue.use(DatePicker)
Vue.use(Tag)
// Vue.use(Tag)
// Vue.use(Drawer)
Vue.use(Popover)
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
// Vue.use(Image);
// Vue.use(Cascader);
// Vue.use(CascaderPanel);
// Vue.use(CarouselItem);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
// Vue.use(TimePicker)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Autocomplete)
Vue.use(Loading.directive);

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$loading = Loading.service;
Dialog.props.lockScroll.default = false;

let messageInstance = null;
const resetMessage = (options) => {
      if (messageInstance) {
        messageInstance.close()
      }
      messageInstance = Message(options)
    }
;['error', 'success', 'info', 'warning'].forEach(type => {
  resetMessage[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    return resetMessage(options)
  }
})
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

Vue.component(CollapseTransition.name, CollapseTransition)
Vue.prototype.$message = resetMessage

