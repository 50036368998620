<template>
  <div class="ybl">
    <div class="block bannerImg">
      <img width="100%"
           height="600"
           src="@/assets/img/yw6.png"
           alt="">
      <div class="bannerText">
        <h1>关于乐享云保理</h1>
        <p>乐享云保理为核心企业提供基于应收账款的融资服务，帮助产业解决资金端和资产端的需求匹配问题，支持保理业务全流程管控，包含贷前、贷中、贷后的各种业务信息管理及额度、业务、放款、还款等流程审批。规范保理业务办理流程，降低操作风险，高效、便捷地进行融资。实现底层资产透明化，降低操作风险和运营人工成本，提升供应链运转效率。</p>
        <!-- <p class="mt20"
           style="display: block">
          <!==          <router-link to="/login">==>
          <el-button class="submit btn_jrcp"
                     @click="jump"
                     round>进 入 产 品</el-button>
          <!==          </router-link>==>
        </p> -->
      </div>
    </div>

    <div class="mainBox">
      <div class="cardBg">
        <div class="center">
          <div class="oneTitle">
            <h1>产品优势</h1>
          </div>
          <div class="flexImg mt60">
            <div class="imgBox box1">
              <img src="@/assets/img/bl_01.png"
                   alt="">
              <p>应收账款自助融资，一键申请，全程线上操作</p>
            </div>
            <div class="imgBox box2">
              <img src="@/assets/img/bl_02.png"
                   alt="">
              <p>多维度风险检测预警，交易融资可追溯</p>
            </div>
            <div class="imgBox box3">
              <img src="@/assets/img/bl_03.png"
                   alt="">
              <p>不受地域限制，方便快捷</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="apply">
      <h1>专属客服1v1服务，为您的需求保驾护航</h1>
      <router-link :to="{path:'/contactus',query:{type:'8'}}">
        <el-button>立即联系</el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkYBL",
  data() {
    return {};
  },
  methods: {
    jump() {
      let token = sessionStorage.getItem("token");
      if (token) {
        this.$router.push("/auth");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.flexImg {
  display: flex;
  margin-bottom: 40px;

  .imgBox {
    flex: 3;
    background: #ffffff;
    box-shadow: 2px 2px 6px 0 #dedede;

    > p {
      padding: 50px 105px;
      box-sizing: border-box;
      text-align: center;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 2px;
    }

    > img {
      width: 100%;
      height: 260px;
    }
  }

  .imgBox:hover {
    transform: scale(1.2);
    transition: 0.3s all linear;
    background: #c70009;
    color: #ffffff;
    z-index: 2;
  }
}
.apply {
  height: 200px;
  text-align: center;
  padding: 50px 20px 40px;
  box-sizing: border-box;
  background: url("~@/assets/img/jjfa_12@2x.png") no-repeat;

  h1 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 30px;
  }

  .el-button {
    width: 180px;
    height: 48px;
    border: 1px solid #ffffff;
    color: #ffffff;
    background: none;
  }

  .el-button:hover {
    background: #ffffff;
    color: #c70009;
  }
}
</style>