<template>
  <!--  解决方案-->
  <div class="solution">
    <div class=" block bannerImg">
      <img width="100%"
           height="600"
           src="@/assets/img/solution.jpg"
           alt="">
    </div>
    <div class="mainBox">
      <div class="cardBg">
        <div class="center">
          <div class="oneTitle">
            <h1>方案介绍</h1>
          </div>
          <div class="soluteBox">
            <div :class="activeIndex===index? 'isActive': 'hide'"
                 v-for="(item, index) in caseArr"
                 :key="index">
              <div class="titleRed">{{item.title}}</div>
              <div class="text text1">
                {{item.text}}
              </div>
            </div>
            <div class="flex5">
              <div class="flex5Item item1"
                   v-for="(item,index) in caseAll"
                   :key="index"
                   @mouseenter="isShow(index)">
                <img :src="item.url"
                     alt="">
                <p>{{item.title}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cardBg">
        <div class="center">
          <div class="oneTitle">
            <h1>方案优势</h1>
          </div>
          <div class="container">
            <div class="list"
                 :class="currentIndex===index? 'eleactive': ''"
                 v-for="(item, index) in project"
                 :key="index"
                 @mouseenter="handlerEnter(index)">
              <div class="flexBox">
                <img :src="item.url"
                     alt="">
                <p>{{item.title}}</p>
                <div class="hideBox">
                  {{item.text}}
                </div>
              </div>
              <div class="hideEl">

              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="cardBg jdal">
        <div class="center">
          <div class="oneTitle">
            <h1>经典案例</h1>
          </div>
          <div class="flex2">
            <div class="flex2Item jdalL">
              <div class="itemBox">
                <h1>国安大数据</h1>
                <p><span>客户名称：</span><em>鄂尔多斯市伊泰投资控股有限责任公司</em></p>
                <p><span>合作类型：</span><em>合资公司</em></p>
                <p><span>解决方案：</span><em>2018年8月，为了顺应煤炭交易的庞大体量与能源化工的飞速发展，助力核心企业建立票据生态体系，解决中小供应商企业融资难题。乐享数科携手鄂尔多斯市伊泰投资控股有限责任公司、内蒙古天眼股权投资中心共同设立了国安大数据科技有限公司。历经2年多的布局开发与深耕合作，针对伊泰集团与链属企业的核心需求，设计全流程线上化服务，打造国安供应链金融服务平台，为企业用户提供一站式金融服务体系；目前平台已实现与平安、建设等多家银行的系统对接及多元服务，助力核心企业创新融合、布局未来。平台的票据服务数据从2018年底的8.6亿元，发展至2021年底的71.1亿元；截止2021年底，平台共计合作的核心企业15家，注册的企业会员近600家，合作的金融机构20余家，累计服务各类供应链金融业务共计约123亿元。</em>
                </p>
                <p><span>实现价值：</span><em>平台先后运营了资产服务中心与商业保理公司，为了更好的服务中小融资客户，不断提升伊泰商票的市场认可度与融资竞争力，打破利率壁垒，助力流转融通；综合贴现利率已从2019年的年化8%，逐步降低至2021年的年化3.58%，在增加业务签发的同时不断降低利率。</em>
                </p>
                <router-link to="/case"><span class="goDetail">了解更多 →</span></router-link>
              </div>
            </div>
            <div class="flex2Item jdalR">
              <img class="img1"
                   src="@/assets/img/jdal_01.png"
                   alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="apply">
        <h1>专属客服1v1服务，为您的需求保驾护航</h1>
        <router-link to="/contactus">
          <el-button>立即申请</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Solution",
  data() {
    return {
      activeIndex: 0, //业务介绍-默认显示
      currentIndex: 0, //方案优势-默认显示
      caseArr: [
        //方案介绍-显隐内容
        {
          title: "小微企业 ｜ 解决方案",
          text: "基于核心企业应付账款及链属中小微企业应收账款的底层资产，通过与券商、信托、担保、小贷、保理公司等众多知名金融机构战略合作，为中小微企业量身定制全类别、全周期的资金融通服务。同时，依托人工智能、大数据、区块链、云计算等新兴互联网技术，改善小微企业信用环境，进一步提高金融资源配置效率和融资便利化水平。",
        },
        {
          title: "核心企业 ｜ 解决方案",
          text: "借助公司现有票据管理系统与上海票交所供应链票据平台的互联互通，为核心企业提供票据托管、票据质押融资、票据贴现、票据代理查询等服务。同时，依托信息化、高效率的全流程管理，不断畅通信用流转环节 ，以数字化赋能缓解企业资金流压力，以供业链协同重塑企业核心竞争力，通过与各类金融机构的密切合作，已成功发行储架ABS超200亿元。\n",
        },
        {
          title: "政府平台 ｜ 解决方案",
          text: "针对各地经济社会发展情况，为平台制定多元化、差异化的解决方案，借助云计算、大数据等数字科技手段，弥合“政金企”之间信息鸿沟，助力三方共建地方经济高发展命运共同体。通过搭建“政金企”互通平台，将充分发挥政府资源整合和调动能力，有效释放财政资金乘数效应，进而为地方发展凝聚更多金融资源，培育更多经济增长点。\n",
        },
        {
          title: "银行 ｜ 解决方案",
          text: "以科技赋能提升金融服务效能，助力票据市场实现供需两端的良性循环。通过与银行内部进行系统对接，赋能银行业数字化转型发展。同时，依托现有“官、产、学、研、金”为一体的智慧信用生态平台整合票据综合服务业务链，以票据综合一体化服务帮助银行快速获取精准客源，盘活已有授信用户，为银行开拓更为广阔的市场空间。\n",
        },
        {
          title: "金融机构 ｜ 解决方案",
          text: "以“互联网+”技术为基础，提升存托机构资产管理效率，推动传统金融与数字科技深度融合。针对层级多极化、 主体多样化、业务集成化的行业特点，为各类金融机构提供可视化、智能化、定制化的供应链金融科技服务方案。同时，通过引入大数据、云计算、人工智能等新兴技术实现产业链数据交互与共享，彻底打破了传统围绕核心企业开展供应链金融的局限性。",
        },
      ],
      caseAll: [
        //方案介绍
        {
          url: require("@/assets/img/jjfa_01@2x.png"),
          title: "小微企业",
        },
        {
          url: require("@/assets/img/jjfa_02@2x.png"),
          title: "核心企业",
        },
        {
          url: require("@/assets/img/jjfa_03@2x.png"),
          title: "政府平台",
        },
        {
          url: require("@/assets/img/jjfa_04@2x.png"),
          title: "银行",
        },
        {
          url: require("@/assets/img/jjfa_05@2x.png"),
          title: "金融机构",
        },
      ],
      project: [
        //方案优势
        {
          // url: require('@/assets/img/jjfa_01@2x.png'),
          title: "提升供应链整体协同效能",
          text: "乐享数科基于自身技术优势和科技实力，推动全产业链数字化变革，运用前沿科技融合物流、仓储、金融、交易等综合数据，以信用大数据为载体打破传统局限，有效提升核心企业产业链一体化运作效率",
        },
        {
          // url: require('@/assets/img/jjfa_02@2x.png'),
          title: "优化供应链改善财务状况 ",
          text: "乐享数科供应链平台依托云计算、大数据、区块链等先进技术，开创数字经济增长新范式，利用金融科技手段搭建数字信用桥梁，以核心企业信用助力链属中小微企业拓宽融资渠道、加速资金流转、降低融资成本，从而为小微企业生产经营良性循环奠定坚实基础",
        },
        {
          // url: require('@/assets/img/jjfa_03@2x.png'),
          title: "助力核心企业金融服务能力",
          text: "乐享数科通过构建产融服务平台，加速供应链金融数字化转型，以用户思维聚焦企业真实需求，为核心企业提供一站式的金融科技解决方案，依托智能风控系统保证数据互联互通，减少沟通成本，高效传递价值，进而增强企业资金的流动性和灵活性",
        },
        {
          // url: require('@/assets/img/jjfa_04@2x.png'),
          title: "践行普惠金融助力企业发展",
          text: "乐享数科始终坚持以创新思维践行普惠金融梦想，通过云计算、大数据、区块链等先进技术赋能普惠金融，围绕金融科技生态新场景，加大普惠性、持续性资金对实体经济的支持力度，以此满足中小微企业多元化、全周期的融资需求",
        },
      ],
    };
  },
  methods: {
    isShow(index) {
      this.activeIndex = index;
    },
    handlerEnter(index) {
      this.currentIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
.soluteBox {
  padding: 30px 30px 50px;
  background: url("~@/assets/img/jjfa_06@2x.png") no-repeat;
  background-size: 100% !important;
  text-align: justify;
  box-sizing: border-box;
  margin-top: 36px;

  .titleRed {
    color: #c70009;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 18px;
  }

  .text {
    min-height: 130px;
    word-break: break-all;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 38px;
  }

  .flex5Item {
    width: 208px;
    height: 127px;
    background: #ffffff;
    box-shadow: 2px 2px 4px 0 #dedede;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    box-sizing: border-box;

    img {
      width: 44px;
      height: 44px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
  }

  .flex5Item:hover p {
    color: #c70009;
  }

  .flex5Item:nth-child(5n) {
    margin-right: 0;
  }

  .flex5Item:hover {
    border: 1px solid #c70009;
  }
}

.show {
  display: block;
}

.hide {
  display: none;
}

.container {
  height: 460px;
  display: flex;
  border: 1px solid #dedede;
  border-left: none;
  margin: 36px auto 0;
  box-sizing: border-box;

  .list {
    position: relative;
    flex: 1;
    height: 100%;
    border-left: 1px solid #dedede;
    transition: all 0.5s;
    /*padding: 0;*/
    box-sizing: border-box;
    width: 100%;
    /*background-size: 657px 100%;*/
    background-size: 657px 458px !important;
    background-color: #000000;
    opacity: 0.9;

    .hideEl {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(33, 33, 33, 0.6);
      top: 0;
      display: none;
      z-index: 2;
    }
  }

  .list:hover {
    .hideEl {
      display: block;
    }
  }

  .list:nth-child(1) {
    background: url("~@/assets/img/ys_01.png") no-repeat;
  }

  .list:nth-child(2) {
    background: url("~@/assets/img/ys_02.png") no-repeat;
  }

  .list:nth-child(3) {
    background: url("~@/assets/img/ys_03.png") no-repeat;
  }

  .list:nth-child(4) {
    background: url("~@/assets/img/ys_04.png") no-repeat;
  }

  .eleactive {
    flex: 1 1 40%;
  }

  .list.eleactive .hideBox {
    display: block;
    transition: 1s all linear;
  }

  .flexBox {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    p {
      margin: 28px 20px 20px;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      z-index: 999;
    }

    .hideBox {
      display: none;
      width: 360px;
      height: 130px;
      font-size: 16px;
      color: #ffffff;
      z-index: 999;
      font-weight: normal;
      line-height: 24px;
      letter-spacing: 1px;
    }
  }
}

.jdal {
  .flex2 {
    height: 400px;
    margin-top: 36px;
    overflow: hidden;
  }

  .flex2Item {
    width: 50%;

    h1 {
      font-size: 18px;
    }
  }

  .jdalL {
    flex: 1;
    padding: 40px 20px 30px;
    box-sizing: border-box;
    background: #ffffff;
  }

  .jdalR {
    width: 580px;
    overflow: hidden;

    .img1 {
      width: 100%;
      height: 100%;
    }

    .img1:hover {
      transform: scale(1.1);
      transition: 0.5s all linear;
    }
  }

  p {
    margin-top: 16px;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    span {
      font-size: 16px;
    }

    em {
      font-size: 16px;
      color: #999999;
    }
  }

  .goDetail {
    display: inline-block;
    font-size: 16px;
    color: #c70009;
    margin-top: 20px;
  }
}

.apply {
  height: 200px;
  text-align: center;
  padding: 50px 20px 40px;
  box-sizing: border-box;
  background: url("~@/assets/img/jjfa_12@2x.png") no-repeat;

  h1 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 30px;
  }

  .el-button {
    width: 180px;
    height: 48px;
    border: 1px solid #ffffff;
    color: #ffffff;
    background: none;
  }

  .el-button:hover {
    background: #ffffff;
    color: #c70009;
  }
}
</style>