import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import '@/assets/style/global.css'
import '@/plugins/element.js'
import animate from 'animate.css'
import VueQuillEditor from "vue-quill-editor/src";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import qs from 'qs'
//剪切板
import VueClipboard from 'vue-clipboard2'
import moment from "moment";//时间格式化
import MetaInfo from 'vue-meta-info'

import animated from 'animate.css' // npm install animate.css --save安装，在引入

Vue.use(animated)
Vue.use(MetaInfo)
Vue.use(VueClipboard)
Vue.use(animate)
Vue.use(VueQuillEditor)
// 打印
import Print from 'vue-print-nb'
Vue.use(Print); //注册

Vue.config.productionTip = false

//引入swiper样式
import "swiper/css/swiper.css";
import el from "element-ui/src/locale/lang/el";
import {Loading} from "element-ui";

//接口配置
const httpInstance = axios.create({baseURL: '/api'})
const authInstance = axios.create({baseURL: '/bill'})
authInstance.interceptors.request.use(config => {
  // console.log(config.url,'11')
  const token = window.sessionStorage.getItem("token")
  if (token) {
    config.headers.token = token
  }
  if (config.url==='/cpiaoju-auth/login/register'||config.url==='/cpiaoju-auth/login/phoneLogin'||config.url==='/cpiaoju-auth/login/updatePassWord'||config.url==='/cpiaoju-auth/login/otherphoneLogin'||config.url==='/cpiaoju-auth/accountSecurity/updatePhone' ){
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
  }else {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  }
  // 响应loading
  window.loadingInstance1 = Loading.service({
    background: 'rgba(0, 0, 0, 0.9)'
  })

  return config
}, error => {
  // router.push("/login")
  console.log(error, 'error')
})
router.afterEach((to, from, next) => {
  document.documentElement.scrollTop = document.body.scrollTop = 0;
  window.scrollTo(0, 0);
})
//接口响应拦截
authInstance.interceptors.response.use(config => {
  // console.log(config)
  if (config.data.code === 2001) {//20001用户未登录 2010token失效
    Vue.prototype.$message({type: "error", message: config.data.msg});
    window.sessionStorage.clear();
    router.push("/login");
  }
  if (config.data.code === 2010) {//20001用户未登录 2010token失效
    Vue.prototype.$message({type: "error", message: '登录状态已失效，请重新登录'});
    window.sessionStorage.clear();
    router.push("/login");
  }
  // 关闭loading
  window.loadingInstance1.close()
  return config
}, error => {
  // router.push("/login")
  console.log(error, 'error')
})

Vue.prototype.$http = httpInstance
Vue.prototype.$auth = authInstance
Vue.prototype.$qs = qs
Vue.prototype.$moment = moment;


new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    setTimeout(() => {
      document.dispatchEvent(new Event('render-event'))
    }, 5000)
  }
}).$mount('#app')

// 在线咨询代码
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?76741dfc665ea238b58300b1f7c6009a";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();


var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?18a395837a8695ef07a7df91107d7742";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
