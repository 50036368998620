<template>
	<el-container class="ticket-container">
		<el-container>
			<!--侧导航-->
			<el-aside width="200px">
				<Aside/>
			</el-aside>
			<el-container>
				<!--主体内容-->
				<el-main class="ticket-main">
					<router-view></router-view>
				</el-main>
			</el-container>
		</el-container>
	</el-container>
</template>

<script>
	import Aside from "./childrenCpn/Aside";
  export default {
    name: "Ticket",
	  components: {
      Aside
	  }
  }
</script>

<style scoped>
	.el-aside{
		overflow: visible !important;
	}
	/*.ticket-container{
		min-height: 100%;
	}*/
	.ticket-main{
		padding: 10px !important;
	}
</style>