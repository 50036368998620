<template>
  <!-- 小微企业信用贷详情 -->
  <div style="flex:1;background: #F6F7FB;">
    <div class="product_img">
      <img src="@/assets/img/banner.jpg"
           alt="">
    </div>
    <div class="center">
      <el-tabs style="background:#fff"
               v-model="activeName"
               type="card"
               @tab-click="handleClick">
        <el-tab-pane label="票据"
                     name="first"
                     class="first_tab">
          <div class="upload_tab">
            <el-upload class="avatar-uploader upImg"
                       action="#"
                       :auto-upload="false"
                       :on-change="handleAvatarSuccess"
                       :show-file-list="false">
              <!-- :on-remove="handleRemove" -->
              <!-- :on-preview="handlePreview" -->
              <!-- :before-upload="beforeAvatarUpload" -->
              <img v-if="bill"
                   :src="bill"
                   class="avatar">
              <!-- <i v-else
                 class="el-icon-plus avatar-uploader-icon icon">
                <img src="@/assets/img/tianjia_icon@2x.png"
                     alt="">
                <i>上传商票
                </i></i> -->
              <div v-else
                   class="icon">
                <em></em>
                <i>上传商票</i>
              </div>
            </el-upload>
          </div>
          <div class="form_tab">
            <p>请先录入产品信息，确认无误后可以点击“确认”按钮进行产品匹配</p>
            <el-form inline
                     label-position='right'
                     label-width='108px'
                     :model="billForm"
                     :rules="billRules"
                     ref="billRefForm"
                     class="demo-form-inline">
              <el-form-item label="票号："
                            prop="ticketNumber">
                <el-input v-model="billForm.ticketNumber"
                          placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="到期日期："
                            prop="ticketDueDate">
                <!-- <el-input v-model="billForm.ticketDueDate"
                          placeholder=""></el-input> -->
                <el-date-picker v-model="billForm.ticketDueDate"
                                type="date"
                                placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="承兑人名称："
                            prop="acceptorName">
                <el-input v-model="billForm.acceptorName"
                          placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="票面金额："
                            prop="ticketAmount">
                <el-input v-model="billForm.ticketAmount"
                          placeholder="">
                  <template slot="suffix">元</template>
                </el-input>
              </el-form-item>

            </el-form>
          </div>
          <el-button class="btn"
                     type="text"
                     @click="BillSubmit">确认</el-button>

        </el-tab-pane>
        <el-tab-pane label="应收账款"
                     name="second"
                     class="first_tab">
          <div class="form_tab">
            <p>请先录入产品信息，确认无误后可以点击“确认”按钮进行产品匹配</p>
            <el-form inline
                     label-position='right'
                     label-width='140px'
                     :model="receivableForm"
                     :rules="receivableRules"
                     ref="receivaRefForm"
                     class="demo-form-inline">
              <el-form-item label="债务人名称："
                            prop="acceptorName">
                <el-input v-model="receivableForm.acceptorName"
                          placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="应收账款到期日："
                            style="margin-left:70px"
                            prop="ticketDueDate">
                <el-date-picker v-model="receivableForm.ticketDueDate"
                                type="date"
                                placeholder="选择日期">
                </el-date-picker>
                <!-- <el-input v-model="receivableForm.ticketDueDate"
                          placeholder=""></el-input> -->
              </el-form-item>
              <el-form-item label="应收账款金额："
                            prop="ticketAmount">
                <el-input v-model="receivableForm.ticketAmount"
                          placeholder=""><template slot="suffix">元</template></el-input>
              </el-form-item>
            </el-form>
          </div>
          <el-button class="btn"
                     @click="receivableSubmit">确认</el-button>
        </el-tab-pane>
        <el-tab-pane label="税务"
                     name="third">

          <el-form inline
                   :model="taxFrom"
                   :rules="taxRules"
                   ref="taxRefForm"
                   style="text-align:center"
                   class="demo-form-inline">
            <el-form-item label="纳税金额："
                          prop="num">
              <el-input v-model="taxFrom.num"
                        placeholder="">
                <template slot="suffix">元/年</template>
              </el-input>
            </el-form-item>
          </el-form>
          <div style="text-align:center;margin-top:18px">
            <el-button class="btn"
                       @click="taxSubmit">确认</el-button>
          </div>

        </el-tab-pane>
        <el-tab-pane label="发票"
                     name="fourth">
          <el-form inline
                   :model="invoiceForm"
                   :rules="invoiceRules"
                   ref="invoiceRefForm"
                   style="text-align:center"
                   class="demo-form-inline">
            <el-form-item label="开票金额："
                          prop="num">
              <el-input v-model="invoiceForm.num"
                        placeholder="">
                <template slot="suffix">元/年</template>
              </el-input>
            </el-form-item>
          </el-form>
          <div style="text-align:center;margin-top:18px">
            <el-button class="btn"
                       @click="invoiceSubmit">确认</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="card_bank_all">
        <div class="bank_card"
             v-for="item,index in bankList"
             :key="index">
          <div v-if="showYY&&item.id==6 ||  showYY&&item.id==9 || show1"
               class="yy">
            <span class="sx">该产品暂不匹配预录入信息</span>
          </div>
          <img v-if="index==0"
               class="tj"
               src="@/assets/img/cpxq_02.png"
               alt="">

          <div class="card_1">
            <img :src="item.img"
                 alt="">
            <el-button class="btm"
                       @click="openUrl(item.id)">立即进入</el-button>
          </div>
          <div class="card_2">
            <p>{{item.text1}}</p>
            <p v-show="item.text2">{{item.text2}}</p>
          </div>
          <div class="card_3">
            <div>
              <div class="num">{{item.limit}}</div>
              <div class="dw">最高额度(万元)</div>
            </div>
            <div>
              <div class="num"
                   style="color:#D6000F">{{item.smallRate}}{{item.highRate?'-'+item.highRate:''}}</div>
              <div class="dw">年化利率(%)</div>
            </div>
            <div v-if="item.time">
              <div class="num">{{item.time}}</div>
              <div class="dw">贷款期限(月)</div>
            </div>
            <div v-else
                 style="width:80px">

            </div>
          </div>

        </div>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      activeName: "first", //tab
      showYY: false, //控制蒙版
      show1: false, //控制蒙版
      bill: "", //上传票面
      billForm: { //票据信息
        type: "1",
        ticketNumber: "",
        ticketDueDate: "",
        acceptorName: "",
        ticketAmount: "",
      },
      receivableForm: { //应收账款信息
        type: "2",
        acceptorName: "",
        ticketDueDate: "",
        ticketAmount: "",
      },
      billRules: {
        ticketNumber: [
          { required: true, message: "请输入票号", trigger: "blur" },
        ],
        ticketDueDate: [
          { required: true, message: "请输入到期日期", trigger: "blur" },
        ],
        acceptorName: [
          { required: true, message: "请输入承兑人名称", trigger: "blur" },
        ],
        ticketAmount: [
          { required: true, message: "请输入票面金额", trigger: "blur" },
        ],
      },
      invoiceRules: {
        // num: [{ validator: validatePass, trigger: "blur" }],
      },
      taxRules: {
        // num: [{ validator: validatePass1, trigger: "blur" }],
      },
      receivableRules: {
        ticketDueDate: [
          { required: true, message: "请输入到期日期", trigger: "blur" },
        ],
        acceptorName: [
          { required: true, message: "请输入债务人名称", trigger: "blur" },
        ],
        ticketAmount: [
          { required: true, message: "请输入应收账款金额", trigger: "blur" },
        ],
      }, //应收账款
      taxFrom: {
        num: "",
      }, //税务
      invoiceForm: {
        num: "",
      }, //发票
      bankList: [
        //产品信息
        {
          id: 1,
          img: require("@/assets/img/cp_01.png"),
          text1: "额度高，利率低，放款快;",
          text2: "全流程系统审核，支持随借随还;",
          limit: 50,
          smallRate: 12,
          highRate: 24,
          time: "3-12",
        },
        {
          id: 2,
          img: require("@/assets/img/cp_02.png"),
          text1:
            "高额度、免抵押、速审批、低利率，专注为中小微企业提供免抵押贷款服务",
          text2: "",
          limit: 300,
          smallRate: 6.18,
          highRate: 9.79,
          time: "6-24",
        },
        {
          id: 3,
          img: require("@/assets/img/cp_03.png"),
          text1: "线上申请，秒批秒到，全国多地可做",
          text2: "",
          limit: 50,
          smallRate: 16,
          highRate: 27,
          time: "12",
        },

        {
          id: 4,
          img: require("@/assets/img/cp_04.png"),
          text1: "利率低，随借随还，用款方便快捷，流程简单",
          text2: "",
          limit: 50,
          smallRate: 12,
          highRate: 24,
          time: "3-12",
        },
        {
          id: 5,
          img: require("@/assets/img/cp_05.png"),
          text1: "全国大部分区域可做，不受地域限制；",
          text2: "纯线上贷款，流程简单快捷，额度高最高500万；",
          limit: 500,
          smallRate: 8,
          highRate: 12,
          time: "3-12",
        },
        {
          id: 6,
          img: require("@/assets/img/cp_06.png"),
          text1: "操作便捷，利率透明安全，放款金额最快10分钟即可到账;",
          text2: "",
          limit: 300,
          smallRate: "-",
          highRate: "",
          time: "",
        },
        {
          id: 7,
          img: require("@/assets/img/cp_07.png"),
          text1: "无抵押纯信用贷款，线上申请最快10分钟完成审批，1小时内放款;",
          text2: "",
          limit: 100,
          smallRate: 22,
          highRate: 24,
          time: "12-24",
        },
        {
          id: 8,
          img: require("@/assets/img/cp_08.png"),
          text1: "操作简单，批款率高，放款快",
          text2: "",
          limit: 50,
          smallRate: 12.6,
          highRate: 18,
          time: "3-12",
        },
        {
          id: 9,
          img: require("@/assets/img/cp_09.png"),
          text1: "最高300w额度支持循环使用，放款快，利率低，不受区域限制",
          text2: "",
          limit: 300,
          smallRate: "-",
          highRate: "",
          time: "",
        },
        {
          id: 10,
          img: require("@/assets/img/cp_10.png"),
          text1: "实时出额，要求松，一年纳税一万以上即可；",
          text2: "还款方式灵活，可随借随还;",
          limit: 100,
          smallRate: 12,
          highRate: 18,
          time: "6-12",
        },
        {
          id: 11,
          img: require("@/assets/img/cp_11.png"),
          text1: "线上申请，秒批秒到，全国多地可做",
          text2: " ",
          limit: 50,
          smallRate: 18,
          highRate: "",
          time: "12",
        },
        {
          id: 12,
          img: require("@/assets/img/cp_12.png"),
          text1:
            "额度高、利息低、无需开卡，专注为中小微企业提供纯信用贷款服务；额度最高50万，利息最低9.8厘，期限最长24期",
          text2: "",
          limit: 50,
          smallRate: 15,
          highRate: 24,
          time: "6-24",
        },
        {
          id: 13,
          img: require("@/assets/img/cp_13.png"),
          text1:
            "小微企业股东凭借企业开票数据线上申请，全流程方便快捷，无抵押，数据即信用，最高可贷50万",
          text2: "",
          limit: 50,
          smallRate: 18.04,
          highRate: 23.95,
          time: "12-24",
        },
        {
          id: 14,
          img: require("@/assets/img/cp_14.png"),
          text1: "操作简单，全线上纯信用；",
          text2: "用款方便，200万额度，先息后本压力小；",
          limit: 200,
          smallRate: 10,
          highRate: 12,
          time: "3-12",
        },
        {
          id: 15,
          img: require("@/assets/img/cp_15.png"),
          text1: "无需抵押，依据企业数据授信；",
          text2: "授信成功，一个月有效，随借随还;",
          limit: 100,
          smallRate: 15,
          highRate: 24,
          time: "12",
        },
        {
          id: 16,
          img: require("@/assets/img/cp_16.png"),
          text1:
            "资金靠谱，无抵押全信用，额度可循环支用，无电核、无面签、不下户",
          text2: "",
          limit: 200,
          smallRate: 12,
          highRate: 18,
          time: "3-12",
        },
        {
          id: 17,
          img: require("@/assets/img/cp_17.png"),
          text1: "额度最高50万，一次授信，按日计息，最快当天放款，最长2年",
          text2: "",
          limit: 50,
          smallRate: 15,
          highRate: 24,
          time: "12-24",
        },
      ],
    };
  },
  created() {
    // 对产品根据利率从小到大排序
    this.bankList.sort(this.createComprisonFunction("smallRate"));
  },
  methods: {
    // 控制微掌柜、医疗及其他产品跳转
    openUrl(id) {
      if (id == 6 || id == 9) {
        window.open("http://gyl.cpiaoju.com");
      } else {
        window.open("http://gyl.cpiaoju.com/cjt/index.html");
      }
    },
    // 切换tab
    handleClick(tab) {
      this.show1 = false;
      this.$refs["taxRefForm"].resetFields();
      this.$refs["invoiceRefForm"].resetFields();
      if (tab.index == 2 || tab.index == 3) {
        this.showYY = true;
        this.bankList.sort((a, b) => ([6, 9].includes(b.id) ? -1 : 0));
      } else {
        this.showYY = false;
      }
    },
    // 上传
    async handleAvatarSuccess(file, fileList) {
      let param = new FormData();
      param.append("file", file.raw, file.name);
      let { data: res } = await this.$auth.post(
        "/cpiaoju-auth/ocr/uploadInvoice",
        param
      );
      this.bill = res.fileUrl;
      this.billForm.ticketNumber = res.ocr.invoiceNo;
      this.billForm.ticketDueDate = res.ocr.billDueDateOf;
      this.billForm.ticketAmount = res.ocr.invoice_amount;
      this.billForm.acceptorName = res.ocr.acceptorName;
    },
    // 票据
    BillSubmit() {
      this.$refs["billRefForm"].validate(async (v) => {
        if (!v) return;
        const { data: res } = await this.$auth.post(
          "/cpiaoju-auth/whiteList/selectWhite",
          this.$qs.stringify(this.billForm)
        );
        if (res.rate) {
          this.bankList.forEach((item) => {
            if (res.rate) {
              if (item.id == 9) {
                item.smallRate = res.rate;
              } else {
                item.smallRate = "-";
              }
              this.bankList.sort(this.createComprisonFunction("smallRate"));
            } else {
              item.smallRate = "-";
            }
          });
        }
      });
    },
    // 应收账款
    receivableSubmit() {
      this.$refs["receivaRefForm"].validate(async (v) => {
        if (!v) return;
        const { data: res } = await this.$auth.post(
          "/cpiaoju-auth/whiteList/selectWhite",
          this.$qs.stringify(this.receivableForm)
        );
        this.bankList.forEach((item) => {
          if (res.rate) {
            if (item.id == 6) {
              item.smallRate = res.rate;
            } else if (item.id == 9) {
              item.smallRate = "-";
            }
            this.bankList.sort(this.createComprisonFunction("smallRate"));
          } else {
            item.smallRate = "-";
          }
        });
      });
    },
    // 查询税务
    taxSubmit() {
      // this.$refs["taxRefForm"].validate((v) => {
      //   if (!v) return;
      //   this.createComprisonFunction();
      // });
      if (this.taxFrom.num < 50000) {
        this.show1 = true;
      } else {
        this.show1 = false;
        this.createComprisonFunction();
      }
    },
    // 发票
    invoiceSubmit() {
      // this.$refs["invoiceRefForm"].validate((v) => {
      //   if (!v) return;
      //   this.createComprisonFunction();
      // });
      if (this.invoiceForm.num < 500000) {
        this.show1 = true;
      } else {
        this.show1 = false;
        this.createComprisonFunction();
      }
    },
    // 排序
    createComprisonFunction(propertyName) {
      return function (object1, object2) {
        var value1 = object1[propertyName];
        var value2 = object2[propertyName];
        if (value1 < value2 || isNaN(value2)) {
          return -1;
        } else if (value1 > value2 || isNaN(value1)) {
          return 1;
        } else {
          return 0;
        }
      };
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.uploadShow .el-upload--picture-card {
  display: none;
}
.product_img {
  width: 100%;
  // height: 400px;
  margin-bottom: 20px;
  > img {
    width: 100%;
    // height: 400px;
  }
}
/deep/.el-tabs__header {
  border: none !important;
  box-shadow: 0px 2px 4px 0px rgba(255, 69, 69, 0.1) !important;
  margin: 0;
}
/deep/.el-tabs__nav {
  width: 100%;
  border: none !important;
}
/deep/.el-tabs__item {
  height: 50px;
  width: 25% !important;
  border: none !important;
  box-shadow: 0px 2px 4px 0px rgba(255, 69, 69, 0.1) !important;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 50px;
}
/deep/.el-tabs__item:hover {
  color: #333333;
}

/deep/.is-active {
  color: #fff !important;
  background-color: #d6000f;
}
/deep/.el-tabs__content {
  height: 230px;
  margin-bottom: 23px;
  padding: 40px 44px 40px 40px;
  box-sizing: border-box;
}
.el-icon-plus:before {
  content: none;
}
/deep/.el-upload--picture-card {
  height: 158px !important;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 158px;
  line-height: 150px;
  text-align: center;
  // border: 1px dashed #d8d8d8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > img {
    width: 36px;
    border: 1px dashed #d8d8d8;
    color: #d8d8d8;
    margin-bottom: 18px;
  }
  > i {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
.first_tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .upload_tab {
    margin-right: 30px;
  }
  .form_tab {
    flex: 1;
    > p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin-bottom: 20px;
    }
    /deep/.el-input__inner {
      width: 250px;
    }
    /deep/.el-form-item {
      margin-right: 30px;
    }
    /deep/.el-form-item__label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
}
.btn {
  width: 148px;
  height: 40px;
  background-color: #d6000f !important;
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff !important;
  letter-spacing: 1px;
  border: none;
}
.btn:hover {
  background: #d6000f;
  color: #ffffff !important;
}
.card_bank_all {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.btm:hover + .bank_card {
  box-shadow: 0px 2px 5px 0px rgba(214, 0, 15, 0.3) !important;
}
.bank_card {
  width: 580px;
  height: 300px;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px #dbdbdb;
  border-radius: 25px;
  padding: 40px 40px 60px;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;

  .tj {
    position: absolute;
    top: -9px;
    right: -10px;
  }
  .card_1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .btm {
      width: 100px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid #d6000f;

      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #d6000f;
      line-height: 32px;
      padding: 0;
    }
    .btm:hover {
      background: #d6000f;
      color: #ffffff;
    }
  }
  .card_2 {
    flex: 1;
    margin-bottom: 35px;
    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666 !important;
      line-height: 20px !important;
      letter-spacing: 1px !important;
    }
  }
  .card_3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .dw {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
    .num {
      font-size: 30px;
      font-family: D-DINExp, D;
      font-weight: normal;
      color: #333333;
      line-height: 33px;
    }
  }
}
.yy {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(33, 33, 33, 0.7);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  .sx {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 33px;
  }
}
/deep/ .avatar-uploader .el-upload {
  position: relative;
  width: 150px;
  height: 150px;
  border: 1px dashed #d8d8d8;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }
}

.upImg {
  display: inline-block;

  .icon {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    em {
      width: 36px;
      height: 36px;
      border: 1px dashed #d8d8d8;
      background: url("../../assets/img/tianjia_icon@2x.png") no-repeat;
      margin-bottom: 15px;
    }
    i {
      color: #999999;
      letter-spacing: 1px;
    }
  }
}
</style>