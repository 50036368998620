<template>
  <div class="workABS">
    <div class="block bannerImg">
      <img width="100%"
           height="600"
           src="@/assets/img/yw7.png"
           alt="">
      <div class="bannerText"
           v-if="activeName=='first'">
        <h1>关于极速贴</h1>
        <p>极速贴（银企直贴）是为持票企业、财务公司、银行等金融机构提供的票据快速贴现服务，持票企业通过平台自主完成跨行贴现申请，通过银行快速完成票据跨银行贴现。</p>
        <!-- <p class="mt20"
           style="display: block">
          <!==          <router-link to="/login">==>
          <el-button class="submit btn_jrcp"
                     @click="jump"
                     round>进 入 产 品</el-button>
          <!==          </router-link>==>
        </p> -->
      </div>
      <div class="bannerText"
           v-if="activeName=='second'">
        <h1>第二代央行评价系统</h1>
        <p>
          通过向金融机构采集关于信贷企业评级所需的相关信息，如企业基本信息、股权信息、投资信息、财务信息、负面信息、贷款信息等，再结合央行获取的企业的审计报告、评估报告、其他政府/税务/诉讼等外部信息等，对企业进行打分评级。其中评级结果为优秀、很好、好、正常和可接受的企业相关优质信贷资产可纳入央行再贷款的合格质押品范围。
        </p>
        <!-- <p class="mt20"
           style="display: block">
          <!==          <router-link to="/login">==>
          <el-button class="submit btn_jrcp"
                     @click="jump"
                     round>进 入 产 品</el-button>
          <!==          </router-link>==>
        </p> -->
      </div>
      <div class="bannerText"
           v-if="activeName=='three'">
        <h1>关于票驿站</h1>
        <p>票驿站是为持有承兑汇票的中小微企业提供票据贴现、融资解决方案的系列产品，资金方全部为各大商业银行，目前已经系统直连20余家。<br />渠道多元，各种金融机构选择：银行、信托、券商、担保、基金、小贷、保理……<br />产品全面，多种产品组合：银票贴现，商票直融，附票保理、微掌柜、极速贴……</p>
        <!-- <p class="mt20"
           style="display: block">
          <!==          <router-link to="/login">==>
          <el-button class="submit btn_jrcp"
                     @click="jump"
                     round>进 入 产 品</el-button>
          <!==          </router-link>==>
        </p> -->
      </div>
    </div>
    <el-tabs class="newsTab"
             v-model="activeName"
             @tab-click="handleClick">
      <el-tab-pane label="极速贴"
                   name="first">
        <div class="mainBox">
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>系统优势</h1>
              </div>
              <div class="flex3 kj mt20">
                <div class="flex3Item">
                  <img src="@/assets/img/jst_01.png"
                       alt="">
                  <p>多家贴现银行选择，系统匹配最优利率</p>
                </div>
                <div class="flex3Item">
                  <img src="@/assets/img/jst_02.png"
                       alt="">
                  <p>全程线上操作，简化开户流程，方便快捷</p>
                </div>
                <div class="flex3Item">
                  <img src="@/assets/img/jst_03.png"
                       alt="">
                  <p>贴现成功后，贴现资金最快一分钟到账</p>
                </div>
                <div class="flex3Item">
                  <img src="@/assets/img/jst_04.png"
                       alt="">
                  <p>银行与企业直接贴现，贴现利率透明</p>
                </div>
                <div class="flex3Item">
                  <img src="@/assets/img/jst_05.png"
                       alt="">
                  <p>白名单匹配、票据核验、确保交易安全</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="二代央评系统"
                   name="second">
        <div class="mainBox">
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>系统模块</h1>
              </div>
              <div class="kjGood mt40">
                <div class="item imgBox">
                  <img src="@/assets/img/xyd_01.png"
                       alt="">
                </div>
                <div class="item text">
                  <h1>二代央评报送系统</h1>
                  <p>系统按照二代央评数据采集规范，加工处理行方需要的报文，实现数据的自动报送，对于反馈报文，自动解析，明确提示出报送报文的错误解释和错误数据修改方式。</p>
                </div>
              </div>
              <div class="kjGood mt20">
                <div class="item text">
                  <h1>二代央评预验收</h1>
                  <p>提供央评接口预验收模块，对开发的接口程序进行验收测试，保证快速、准确无误的对接联调，以最快的方式对接二代央评系统。</p>
                </div>
                <div class="item imgBox">
                  <img src="@/assets/img/xyd_02.png"
                       alt="">
                </div>
              </div>
              <div class="kjGood mt20">
                <div class="item imgBox">
                  <img src="@/assets/img/xyd_03.png"
                       alt="">
                </div>
                <div class="item text">
                  <h1>央评预评级系统</h1>
                  <p>帮助金融机构提前了解企业在二代央行评级系统评级模型中的大致定位、缩短评级周期，提高评级效率，增加企业评级数量。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="票驿站"
                   name="three">
        <div class="mainBox">
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>资产要求</h1>
              </div>
              <div class="flex3 product mt20">
                <div class="flex3Item">
                  <img src="@/assets/img/pyz_01.png"
                       alt="">
                  <p>多家银行选择，系统匹配最优利率</p>
                </div>
                <div class="flex3Item">
                  <img src="@/assets/img/pyz_02.png"
                       alt="">
                  <p>一站式全流程线上操作，方便快捷</p>
                </div>
                <div class="flex3Item">
                  <img src="@/assets/img/pyz_03.png"
                       alt="">
                  <p>融资成功后，资金最快1分钟到账</p>
                </div>
                <div class="flex3Item">
                  <img src="@/assets/img/pyz_04.png"
                       alt="">
                  <p>与银行系统直连，利率透明</p>
                </div>
                <div class="flex3Item">
                  <img src="@/assets/img/pyz_04.png"
                       alt="">
                  <p>白名单匹配，票据核验，交易安全</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cardBg">
            <div class="center">
              <div class="oneTitle">
                <h1>系统优势</h1>
              </div>
              <div class="flex2 wzgAsk mt20">
                <div class="flex2Item">
                  <img src="@/assets/img/pyz_06.png"
                       alt="">
                  <p>
                    <em>自主经营</em>
                    <span>自身LOGO，企业独立经营管理</span>
                  </p>
                </div>
                <div class="flex2Item">
                  <img src="@/assets/img/pyz_07.png"
                       alt="">
                  <p>
                    <em>营销支持</em>
                    <span>制作宣传海报，大客户营销方案</span>
                  </p>
                </div>
                <div class="flex2Item">
                  <img src="@/assets/img/pyz_08.png"
                       alt="">
                  <p>
                    <em>利润共享</em>
                    <span>交易成功后自主平台方享受利润分成</span>
                  </p>
                </div>
                <div class="flex2Item">
                  <img src="@/assets/img/pyz_09.png"
                       alt="">
                  <p>
                    <em>产品全面</em>
                    <span>拥有解决商票、银票不同融资需求的产品类型</span>
                  </p>
                </div>
                <div class="flex2Item">
                  <img src="@/assets/img/pyz_10.png"
                       alt="">
                  <p>
                    <em>渠道多元</em>
                    <span>银行等各类金融机构合作，多种渠道选择</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="apply" v-if="activeName=='first'">
      <h1>专属客服1v1服务，为您的需求保驾护航</h1>
      <router-link :to="{path:'/contactus',query:{type:'9'}}">
        <el-button>立即联系</el-button>
      </router-link>
    </div>
    <div class="apply" v-if="activeName=='second'">
      <h1>专属客服1v1服务，为您的需求保驾护航</h1>
      <router-link :to="{path:'/contactus',query:{type:'10'}}">
        <el-button>立即联系</el-button>
      </router-link>
    </div>
    <div class="apply" v-if="activeName=='three'">
      <h1>专属客服1v1服务，为您的需求保驾护航</h1>
      <router-link :to="{path:'/contactus',query:{type:'11'}}">
        <el-button>立即联系</el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkKJ",
  data() {
    return {
      activeName: "first", //切签
    };
  },
  created() {
    this.tab();
  },
  methods: {
    tab() {
      let active = this.$route.query.activeName; //切签
      if (active) {
        this.activeName = active;
      } else {
        this.activeName = "first";
      }
    },
    handleClick(tab, event) {
      //切签
      console.log(tab, event);
    },
    jump() {
      let token = sessionStorage.getItem("token");
      if (token) {
        this.$router.push("/auth");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.flex3.kj {
  flex-wrap: wrap;

  .flex3Item {
    width: 32.2%;
    height: 256px;
    background: #ffffff;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      width: 191px;
      margin-top: 20px;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 3px;
      text-align: center;
    }
  }

  .flex3Item:nth-child(4) {
    width: 49%;
  }

  .flex3Item:nth-child(5) {
    width: 49%;
  }
}

/*  新一代票据*/
.kjGood {
  display: flex;
  padding: 37px 55px;
  box-sizing: border-box;
  background: #ffffff;

  .item {
    width: 50%;

    > img {
      margin-left: 100px;
      width: 300px;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: 18px;
      color: #262626;
      line-height: 25px;
      letter-spacing: 3px;
    }

    p {
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 2px;
      margin-top: 20px;
    }
  }
}

/*  票驿站*/
.flex3.product {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .flex3Item {
    width: 386px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
    box-sizing: border-box;
    margin-top: 20px;
    margin-right: 20px;

    > img {
      width: 60px;
      height: 60px;
      margin-bottom: 20px;
    }

    > p {
      width: 169px;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 1px;
      text-align: center;
    }
  }

  .flex3Item:nth-child(3n) {
    margin-right: 0;
  }
}

.flex2.wzgAsk {
  flex-wrap: wrap;

  .flex2Item {
    width: 49%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    height: 120px;
    background: #f4f5f7;
    padding: 30px 20px;
    box-sizing: border-box;

    > img {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }

    > p {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 2px;

      em {
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 1px;
      }

      span {
        font-size: 16px;
        color: #999999;
        line-height: 22px;
        letter-spacing: 1px;
      }
    }
  }
}
.apply {
  height: 200px;
  text-align: center;
  padding: 50px 20px 40px;
  box-sizing: border-box;
  background: url("~@/assets/img/jjfa_12@2x.png") no-repeat;

  h1 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 30px;
  }

  .el-button {
    width: 180px;
    height: 48px;
    border: 1px solid #ffffff;
    color: #ffffff;
    background: none;
  }

  .el-button:hover {
    background: #ffffff;
    color: #c70009;
  }
}
</style>