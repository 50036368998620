<template>
  <!--  关于乐享-->
  <div class="aboutus">
    <div class="block bannerImg">
      <img width="100%" height="600" src="@/assets/img/aboutus.jpg" alt="">
    </div>
    <div class="mainBox">
      <div class="cardBg jdal">
        <div class="center">
          <div class="oneTitle">
            <h1>公司简介</h1>
          </div>
          <div class="flex2 mt40">
            <div class="flex2Item jdalR">
              <img class="img1" src="@/assets/img/gywm_01.jpg" alt="">
            </div>
            <div class="flex2Item jdalL">
              <div class="itemBox">
                <p>
                  乐享数科有限公司系国内头部数字普惠、供应链金融科技公司，2014年成立于北京，注册资本5000万元。先后荣获“国家（中关村）双高新技术企业”、“双软认证企业”、“北京市专精特新中小企业”等荣誉，并通过国家信息安全等级保护三级认证。同时担任中央财经大学云票据研究中心主任单位及商务部中国服务贸易协会供应链金融委员会、票据经纪委员会主任单位。公司致力于打造产、学、研、金为一体的智慧信用生态平台，围绕全产业链、全生命周期为客户提供产品设计、科技研发和场景化的金融服务方案。
                </p>
                <p>
                  乐享数科运维的医疗行业供应链平台、能源行业供应链平台、物流运输供应链平台，农业供应链平台、钢铁供应链平台、云票据服务平台，
                  已合作金融机构200余家，央国企上市公司及大型核心企业400多家，累计成交金额9100亿元，服务小微企业50000多家，并在积极筹建汽车、烟酒流通、化工行业等供应链平台。公司以供应链金融产业驱动金融数字化变革，致力于打造商业信用流转的首席服务官，积极响应国家货币政策，依托互联网人工智能、云计算、大数据、区块链等先进技术，重塑供应链金融科技服务模式，为中小微企业融资服务提供科技金融支持，与合作伙伴一起共创数字经济增长新范式。
                </p>
                <p><span style="font-weight: bold">乐享数科控股及参股公司6家</span><br>
                  人银金融信息服务（北京）有限公司、全联征信有限公司、河北信投数科供应链管理有限公司、国安大数据科技有限公司、乐享国医（北京）医疗科技有限公司、乐享中新（上海）智能数字科技有限公司。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="cardBg" style="height:760px">
        <!-- 发展历程做成竖向手风琴 -->
        <div class="">
          <div class="oneTitle">
            <h1>发展历程</h1>
          </div>
          <div class="or-container">
            <div class="box" :class="eleindex ==i?'eleactive':''" v-for="(ele,i) in piclist" :key="i"
              @mouseenter="enter(i)" @mouseleave="out(i)">
              <span v-if="eleindex !=i" style="transform: rotate(90deg)">{{ele.year}}</span>
              <div v-else class="el_zk">
                <img :src="ele.img" alt="">
                <div>
                  <div class="tit">{{ele.year}}</div>
                  <span>大事件</span>
                  <div class="nr">
                    <div v-for="ite,i in ele.thing" :key="i">
                      <div class="icon_month">
                        <span class="icon_dd"></span><span class="month">{{ite.month}}</span>
                      </div>
                      <p>{{ite.content}}</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <!-- <div class="history mt40">
            <div class="flex2 mb34">
              <p class="year">{{value}}年</p>
              <el-select popper-class="hight160" v-model="value" placeholder="请选择">
                <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="hostoryMain">
              <div class="block">
                <ul class="itemWrappe">
                  <li class="eachItem" v-for="(item,index) in list[value]" :key="index"
                      :class="index == activeIndex ? 'active' : ''">
                    <span class="month">{{item.month}}</span>
                    <span class="progres">
                      <span class="dot"></span>
                      <i class="line">
                        <i class="process"></i>
                      </i>
                    </span>
                    <span class="content">{{item.content}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="cardBg jdal" style="height:700px">
        <div class="center">
          <div class="oneTitle">
            <h1>行业与社会组织任职</h1>
          </div>
          <div class="mt40 goodBox">
            <div class="goodItem">
              <div class="itemBox pos1">
                <i class="icon1"></i>
                <span>票据研究中心中国互联网金融研究院主任单位</span>
              </div>
              <div class="itemBox pos2">
                <i class="icon1"></i>
                <span>商务部中国服务贸易协会票据经济委员会主任单位</span>
              </div>
              <div class="itemBox pos3">
                <i class="icon1"></i>
                <span>中国票据联盟主任单位</span>
              </div>
              <div class="itemBox pos4">
                <i class="icon1"></i>
                <span>中央财经大学云票据研究中心主任单位</span>
              </div>
              <div class="itemBox pos5">
                <i class="icon1"></i>
                <span>中国国际经济合作协会供应链金融专委会副主任单位</span>
              </div>
              <div class="itemBox pos6">
                <i class="icon1"></i>
                <span>中国交通运输协会共享出行分会第一届理事单位</span>
              </div>
              <div class="itemBox pos7">
                <i class="icon1"></i>
                <span>商务部中国服务贸易协会供应链金融委员会主任单位</span>
              </div>
              <div class="itemBox pos8">
                <i class="icon1"></i>
                <span>中国保理行业专业委员会副主任</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="cardBg jdal">
        <div class="center">
          <div class="oneTitle">
            <h1>企业荣誉</h1>
          </div>
          <el-carousel :initial-index="1" class="mt40" :interval="4000" :autoplay="true" type="card"
            indicator-position="none" arrow="always" height="200px">
            <el-carousel-item class="pos" style="background:#fff" v-for="(item,index) in partnerImg" :key="index">
              <img :src="item.url" alt="">
              <h3 class="medium">{{ item.text }}</h3>
            </el-carousel-item>
          </el-carousel>
          <img src="@/assets/img/gywm_03@2x.png" alt="">
        </div>
      </div>
    </div>

    <div class="main_li" v-for="(item,index) in list" :key="index" :class="index == activeIndex ? 'active' : ''">
      {{item.index}}
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {
      eleindex: 0,
      piclist: [
        {
          year: 2014,
          img: require("../assets/year/2014.png"),

          thing: [
            {
              month: "11月",
              content: "公司成立。曾用名：乐享投融（北京）网络科技有限公司。",
            },
          ],
        },
        {
          year: 2015,
          img: require("../assets/year/2015.png"),

          thing: [
            {
              month: "11月",
              content:
                "北京市海淀区国资委旗下基金与中国创投战略A轮投资。乐享数科与民生银行总行清算中心共同研发金融服务系统并上市。",
            },
            {
              month: "09月",
              content: "平台首月用户量突破3000人次。",
            },
            {
              month: "08月",
              content: "第一个运营平台“云票据”正式投产。",
            },
          ],
        },
        {
          year: 2016,
          img: require("../assets/year/2016.png"),

          thing: [
            {
              month: "12月",
              content:
                "乐享数科与兴业银行子公司兴业数金战略合作，打造供应链金融服务系统。",
            },
            {
              month: "06月",
              content: "自主研发独家互联网在线保理融资服务平台正式投产。",
            },
            {
              month: "03月",
              content:
                "联合京津冀三地大型国有企业共同设立京津冀协同票据交易中心股份有限公司。",
            },
          ],
        },
        {
          year: 2017,
          img: require("../assets/year/2017.png"),

          thing: [
            {
              month: "09月",
              content:
                "战略入股人银金融信息服务（北京）有限公司（隶属人民银行机关事务管理局)。",
            },
            {
              month: "03月",
              content:
                "联合中央财经大学、北京市京师律师事务所、兴业数字金融服务(上海)股份有限公司联合设立“中央财经大学·云票据研究中心”。",
            },
          ],
        },
        {
          year: 2018,
          img: require("../assets/year/2018.png"),
          thing: [
            {
              month: "11月",
              content: "成立商务部中国服务贸易协会票据经纪委员会。",
            },
            {
              month: "11月",
              content: "与伊泰集团成立万禄资产交易中心。",
            },

            {
              month: "08月",
              content:
                "与内蒙古伊泰集团成立国安大数据科技有限公司，打造煤炭行业供应链金融平台。",
            },
            {
              month: "06月",
              content: "与富民银行进行系统直联并上线“极速贴”系统。",
            },
          ],
        },
        {
          year: 2019,
          img: require("../assets/year/2019.png"),

          thing: [
            {
              month: "07月",
              content:
                "联合中国服务贸易协会、北京工商大学商业经济研究所、中国农业大学县域经济研究中心、复旦大学国际供应链金融研究中心等单位成立商务部中国服务贸易协会供应链金融委员会。",
            },
            {
              month: "04月",
              content:
                "与中国建材协会成立中国建材协会票据服务中心，与北京房地产商会成立票据服务中心。",
            },
          ],
        },
        {
          year: 2020,
          img: require("../assets/year/2020.png"),

          thing: [
            {
              month: "09月",
              content:
                "与河北信产投集团共同设立子公司，河北信投数科供应链管理有限公司，深耕河北市场。",
            },

            {
              month: "05月",
              content:
                "市场首单N+N+N的集合保理ABS发行成功。乐享数科联合前海交易所和天风证券等机构，共同发行集合保理区块链资产支持专项计划，发行规模3.22亿元，整体ABS储架规模超200亿元。",
            },
          ],
        },
        {
          year: 2021,
          img: require("../assets/year/2021.png"),

          thing: [
            {
              month: "12月",
              content:
                "与安徽中技国医医疗科技有限公司合资成立的乐享国医（北京）医疗科技有限公司，积极响应“中国制造2025”，在供应链金融领域深化合作。",
            },
            {
              month: "10月",
              content:
                "深化银企合作，联合建设银行创新上线乐享建行平台云贷，强力助推产业发展，解决中小微企业融资难题。",
            },
            {
              month: "03月",
              content:
                "与北京金融安全产业园签署战略合作协议，共同成立供应链管理公司，双方将充分发挥各自资源优势，服务商业信用，实现共赢发展。",
            },
          ],
        },
        {
          year: 2022,
          img: require("../assets/year/2022.png"),
          thing: [
            {
              month: "7月",
              content:
                "乐享中新(上海)智能数字科技有限公司正式成立。依托央行再贷款业务和央行企业（企业）内部评级系统，以央评资产作为核心资产，通过自身独立供应链科技系统为金融机构导流符合标准的资产，积极服务金融机构和中小微企业金融服务。",
            },
            {
              month: "7月",
              content:
                "“乐享企业信贷平台”的正式上线。聚焦普惠金融堵点，满足小微融资需求。企业信贷平台通过“金融+ 数据增信”为小微企业提供低成本、低门槛、高额度的纯信用融资服务。",
            },
            {
              month: "10月",
              content:
                "“百年资管- 乐享数科2022国风1号供应链资产支持计划”成功落地发行。储架金额30亿，发行方式为分期发行，每期时间不超过12月。首期ABS发行金额3.82亿元，其中优先级3.8095亿元。",
            },
          ],
        },
        {
          year: 2023,
          img: require("../assets/year/2023.png"),
          thing: [
            {
              month: "4月",
              content:
                "乐享数科联手中信银行总行共同打造首款医疗行业供应链金融产品，推动医疗行业普惠金融的资金可得性，便利性，降低中小微企业的融资成本。",
            },
            {
              month: "9月",
              content:
                "乐享数科积极贯彻落实人民银行总行二代央评系统实施，推出服务该系统的中小微企业预评级产品线，解决中小金融机构合格抵押品相对不足的问题，为中小微企业获得人行支小再贷款提供专业支持。",
            },
            {
              month: "12月",
              content:
                "乐享数科战略投资全国性企业征信机构全联征信有限公司，为金融交易和贸易交易提供全方位数据服务，为数据安全保驾护航。",
            },
            {
              month: "12月",
              content:
                "乐享数科积极响应人民银行总行企业资金流水信用信息共享平台建设，联手商业银行和地方政府平台共建中小微企业支付信用信息评估模型，推动中小微企业信用评价，解决征信白户获取金融服务，同时助力中小微企业融资。",
            },
          ],
        },
      ],
      list: {
        //发展历程
        2023: [
          {
            month: "4月",
            content:
              "乐享数科联手中信银行总行共同打造首款医疗行业供应链金融产品，推动医疗行业普惠金融的资金可得性，便利性，降低中小微企业的融资成本。",
          },
          {
            month: "9月",
            content:
              "乐享数科积极贯彻落实人民银行总行二代央评系统实施，推出服务该系统的中小微企业预评级产品线，解决中小金融机构合格抵押品相对不足的问题，为中小微企业获得人行支小再贷款提供专业支持。",
          },
          {
            month: "12月",
            content:
              "乐享数科战略投资全国性企业征信机构全联征信有限公司，为金融交易和贸易交易提供全方位数据服务，为数据安全保驾护航。",
          },
          {
            month: "12月",
            content:
              "乐享数科积极响应人民银行总行企业资金流水信用信息共享平台建设，联手商业银行和地方政府平台共建中小微企业支付信用信息评估模型，推动中小微企业信用评价，解决征信白户获取金融服务，同时助力中小微企业融资。",
          },
        ],
        2022: [
          {
            month: "7月",
            content:
              "乐享中新(上海)智能数字科技有限公司正式成立。依托央行再贷款业务和央行企业（企业）内部评级系统，以央评资产作为核心资产，通过自身独立供应链科技系统为金融机构导流符合标准的资产，积极服务金融机构和中小微企业金融服务。",
          },
          {
            month: "7月",
            content:
              "“乐享企业信贷平台”的正式上线。聚焦普惠金融堵点，满足小微融资需求。企业信贷平台通过“金融+ 数据增信”为小微企业提供低成本、低门槛、高额度的纯信用融资服务。",
          },
          {
            month: "10月",
            content:
              "“百年资管- 乐享数科2022国风1号供应链资产支持计划”成功落地发行。储架金额30亿，发行方式为分期发行，每期时间不超过12月。首期ABS发行金额3.82亿元，其中优先级3.8095亿元。",
          },
        ],
        2021: [
          {
            month: "12月",
            content:
              "与安徽中技国医医疗科技有限公司合资成立的乐享国医（北京）医疗科技有限公司， 积极响应“中国制造2025”，在供应链金融领域深化合作。",
          },
          {
            month: "10月",
            content:
              "深化银企合作，联合建设银行创新上线乐享建行平台云贷，强力助推产业发展，解决中小微企业融资难题。",
          },
          {
            month: "03月",
            content:
              "与北京金融安全产业园签署战略合作协议，共同成立供应链管理公司，双方将充分发挥各自资源优势，服务商业信用，实现共赢发展。",
          },
        ],
        2020: [
          {
            month: "09月",
            content:
              "与河北信产投集团共同设立子公司，河北信投数科供应链管理有限公司，深耕河北市场。",
          },
          {
            month: "07月",
            content:
              "央行《标准化票据管理办法》落地，乐享数科研发全国首款RPA+AI供应链票据+标准化票据全生命周期管理系统“标票云”。",
          },
          {
            month: "05月",
            content:
              "市场首单N+N+N的集合保理ABS发行成功。乐享数科联合前海交易所和天风证券等机构，共同发行集合保理区块链资产支持专项计划，发行规模3.22亿元，整体ABS储架规模超200亿元。",
          },
          {
            month: "04月",
            content:
              "乐享数科成功对接上海票据交易所运营的供应链票据系统，打造数字票据新时代实现票据全生命周期管理。",
          },
        ],
        2019: [
          {
            month: "07月",
            content:
              "联合中国服务贸易协会、北京工商大学商业经济研究所、中国农业大学县域经济研究中心、复旦大学国际供应链金融研究中心等单位成立商务部中国服务贸易协会供应链金融委员会 。",
          },
          {
            month: "04月",
            content:
              "与中国建材协会成立中国建材协会票据服务中心，与北京房地产商会成立票据服务中心，助推企业商业信用发展，服务商业票据流转。",
          },
        ],
        2018: [
          {
            month: "11月",
            content:
              "成立商务部中国服务贸易协会票据经纪委员会，系票据经纪领域唯一全国性行业组织，会员单位110家。",
          },
          {
            month: "11月",
            content:
              "与内蒙古伊泰集团等多家煤炭企业共同设立万禄资产交易中心，打造垂直产业链资产交易平台，服务煤炭行业供应链，优化行业支付方式，拓宽企业融资渠道。",
          },
          {
            month: "10月",
            content:
              "成立天津金融资产交易所票据运营中心，布局票据非银市场流转，取得登记凭证。",
          },
          {
            month: "08月",
            content:
              "与内蒙古伊泰集团成立国安大数据科技有限公司，打造煤炭交易结算票据化科技平台。",
          },
          {
            month: "06月",
            content:
              "与富民银行进行系统直连并上线“极速贴”系统，实现持票企业跨行、跨地域，免开户快速贴现。",
          },
        ],
        2017: [
          {
            month: "09月",
            content:
              "战略入股人银金融信息服务（北京）有限公司（隶属人民银行机关事务管理局)。",
          },
          {
            month: "03月",
            content:
              "联合中央财经大学、北京市京师律师事务所、兴业数字金融服务(上海)股份有限公司共同发起设立“中央财经大学•云票据研究中心”。",
          },
        ],
        2016: [
          {
            month: "12月",
            content:
              "乐享数科与兴业银行子公司兴业数金战略合作，打造首家商票流转标准化服务系统。",
          },
          {
            month: "09月",
            content:
              "自主研发独家互联网在线保理+票据融资服务平台“附票保理”正式投产，电子商业承兑汇票线上融资突破10亿元，电子银行承兑汇票流转金额破1000亿。",
          },
          {
            month: "03月",
            content:
              "联合京津冀三地大型国有企业共同设立京津冀协同票据交易中心股份有限公司（全国首家票据交易中心）。",
          },
        ],
        2015: [
          {
            month: "11月",
            content:
              "乐享数科与民生银行总行清算中心共同研发票据鉴证系统并上线（此系统的推出标志着我国票据市场进入到电子化时代，对促进中国票据市场发展产生深远影响）。2016年3月 联合京津冀三地大型国有企业共同设立京津冀协同票据交易中心股份有限公司（全国首家票据交易中心）。",
          },
          {
            month: "11月",
            content: "北京市海淀区国资委旗下基金与中国创投战略投资（A轮）。",
          },
          {
            month: "09月",
            content: "平台首月用户量突破3000 人次。",
          },
          {
            month: "08月",
            content: "第一个运营平台“云票据”正式投产。",
          },
        ],
        2014: [
          {
            month: "11月",
            content:
              "乐享数科有限公司成立。曾用名：乐享投融（北京）网络科技有限公司。",
          },
        ],
      },
      activeIndex: "0",
      partnerImg: [
        //企业荣誉
        {
          url: require("@/assets/img/ry1.jpeg"),
          text: "高新技术企业证书",
        },

        {
          url: require("@/assets/img/ry2.jpeg"),
          text: "软件企业证书",
        },
        {
          url: require("@/assets/img/ry3.jpeg"),
          text: "中关村高新证书",
        },
        {
          url: require("@/assets/img/ry4.jpg"),
          text: "增值电信业务经营许可证",
        },
        {
          url: require("@/assets/img/ry5.jpg"),
          text: "质量管理体系认证证书",
        },
        {
          url: require("@/assets/img/ry6.jpeg"),
          text: "第十届理事会会员单位",
        },
        {
          url: require("@/assets/img/ry7.jpg"),
          text: "中国国际经济合作学会供应链金融委员会副主任单位",
        },
        {
          url: require("@/assets/img/ry8.jpeg"),
          text: "北京市工商业联合会会员",
        },
        {
          url: require("@/assets/img/ry9.jpeg"),
          text: "中国物流与采购联合会物流与供应链金融分会会员单位",
        },
        {
          url: require("@/assets/img/ry10.jpg"),
          text: "中国互联网金融研究院主任单位",
        },
        {
          url: require("@/assets/img/ry11.jpg"),
          text: "中国服务贸易协会副主任单位",
        },
        {
          url: require("@/assets/img/ry12.jpg"),
          text: "中国服务贸易协会票据经纪委员会发起单位",
        },
        {
          url: require("@/assets/img/ry13.jpg"),
          text: "第三届中国商业保理行业贡献奖",
        },
        // {
        //   url: require('@/assets/img/ry1.jpeg'),
        //   text: '高新技术企业证书'
        // },
      ],
      options: [
        //选择年份
        {
          value: "2021",
          label: "2021年",
        },
        {
          value: "2020",
          label: "2020年",
        },
        {
          value: "2019",
          label: "2019年",
        },
        {
          value: "2018",
          label: "2018年",
        },
        {
          value: "2017",
          label: "2017年",
        },
        {
          value: "2016",
          label: "2016年",
        },
        {
          value: "2015",
          label: "2015年",
        },
        {
          value: "2014",
          label: "2014年",
        },
      ],
      value: "",
    };
  },
  created() {
    this.value = this.options[0].value;
    const self = this;
    this.interval = setInterval(function () {
      if (self.activeIndex < 3) {
        self.activeIndex++;
      } else {
        self.activeIndex = 0;
      }
    }, 3000);
  },
  methods: {
    enter(i) {
      console.log(i);
      this.eleindex = i;
      // if (this.eleindex === i) {
      //   this.eleindex = -1
      // } else {
      //   this.eleindex = i
      // }
    },
    out(i) {
      this.imgindex = -1;
    },
  },
};
</script>

<style lang="less" scoped>
/*手风琴样式*/
.or-container {
  display: flex;
  width: 100%;
  padding: 40px 80px;
  box-sizing: border-box;
  height: 550px;
}

.or-container:before {
  background-color: rgba(0, 0, 0, 0.4);
}

.box {
  height: 550px;
  flex: 1;
  overflow: hidden;
  transition: 0.5s;
  line-height: 0;
  border-left: 1px solid #c70009;
}

.box:last-child {
  border-right: 1px solid #c70009;
}

// .box > img {
//   width: 100%;
//   // height: calc(100% - 10vh);
//   -o-object-fit: cover;
//   object-fit: cover;
//   transition: 0.5s;
// }

.box>span {
  display: block;
  text-align: center;

  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #262626;
  line-height: 44px;
  letter-spacing: 1.5px;
  margin-top: 43px;
}

.eleactive {
  flex: 1 1 65%;

  background: rgba(199, 0, 9, 0.02);
}

.el_zk {
  background: url("../assets/img/bjfzlc.png") no-repeat;
  background-position: 100% 100%;
  height: 100%;
  padding: 24px 35px 43px 35px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;

  img {
    width: 76px;
    height: 271px;
    margin-right: 36px;
  }

  >div {
    font-size: 32px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    line-height: 40px;
    flex: 1;

    .tit {
      color: #c70009;
      font-size: 32px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #c70009;
      line-height: 47px;
    }

    >span {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 25px;
    }

    .nr {
      border-top: 2px solid #c70009;
      margin-top: 18px;
      padding-top: 20px;
      box-sizing: border-box;

      >div {
        display: flex;
        // justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 20px;

        p {
          flex: 1;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          letter-spacing: 1.78px;
        }

        .icon_month {
          // width: 8%;
          display: flex;
          align-items: center;
          margin-right: 10px;

          .icon_dd {
            width: 5px;
            height: 5px;
            border-radius: 2.5px;
            background-color: #c70009;
            margin-right: 10px;
          }

          .month {
            margin-top: 2px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            letter-spacing: 1.75px;
          }
        }
      }
    }
  }
}

/////////////手风琴ending/////////////////////////

.eleactive>img {
  width: 100%;
  height: 100%;
}

/*end*/

.jdal {
  .flex2 {
    height: 400px;
    margin-top: 36px;
    overflow: hidden;
  }

  .flex2Item {
    width: 50%;

    h1 {
      font-size: 18px;
    }
  }

  .jdalL {
    flex: 1;
    padding: 0 0 10px 50px;
    box-sizing: border-box;

    .itemBox {
      height: 400px;
      overflow-y: auto;
    }
  }

  .jdalR {
    width: 550px;
    overflow: hidden;

    .img1 {
      width: 550px;
      height: 400px;
    }

    .img1:hover {
      transform: scale(1.1);
      transition: 0.5s all linear;
    }
  }

  p {
    font-size: 16px;
    margin-bottom: 12px;
    line-height: 29px;

    span {
      font-size: 16px;
    }

    em {
      font-size: 16px;
      color: #999999;
    }
  }

  .goDetail {
    display: inline-block;
    font-size: 16px;
    color: #c70009;
    margin-top: 30px;
  }
}

.pos {
  .medium {
    font-size: 8px;
  }
}

.el-carousel--horizontal {
  /*width: 780px;*/
  width: 1000px;
  height: 220px;
  margin: 40px auto 0;
  background: #ffffff;
}

.el-carousel__item--card {
  transition: transform;
}

.el-carousel__item {
  width: 230px;
  height: 220px;
  /*border-left: 50px solid #ffffff;*/
  /*border-right: 50px solid #ffffff;*/
  z-index: 99;
  margin: 0 132px;
  background: #ffffff;
}

.el-carousel__item img {
  width: 230px;
  height: 160px;
}

.el-carousel__item h3 {
  color: #333333;
  opacity: 0.75;
  line-height: 24px;
  text-align: center;
  width: 100%;
  margin-top: 5px;
  font-size: 8px;
  letter-spacing: 1px;
}

.goodBox {
  margin-left: 92px;

  .goodItem {
    position: relative;
    height: 700px;
    background: url("~@/assets/img/gylx_06@2x.gif") no-repeat;
    background-size: 60%;

    .itemBox {
      position: absolute;
      width: auto;
      height: 36px;
      line-height: 36px;
      border-radius: 25px;
      padding-right: 20px;
      box-sizing: border-box;

      .icon1 {
        float: left;
        width: 36px;
        height: 36px;
        display: inline-block;
        background: url("~@/assets/img/gywm_02@2x.png") no-repeat;
        background-size: 100%;
        margin-right: 10px;
      }

      >span {
        font-size: 16px;
        color: #999999;
      }
    }

    .itemBox:hover {
      background: linear-gradient(180deg, #e50015 0%, #c70009 100%);
    }

    .itemBox:hover span {
      color: #ffffff;
    }

    .itemBox:hover .icon1 {
      background: url("~@/assets/img/gywm_05@2x.png") no-repeat;
      background-size: 100%;
    }

    .itemBox.pos1 {
      left: 473px;
      top: 46px;
    }

    .itemBox.pos2 {
      left: 529px;
      top: 97px;
    }

    .itemBox.pos3 {
      left: 559px;
      top: 148px;
    }

    .itemBox.pos4 {
      left: 574px;
      top: 199px;
    }

    .itemBox.pos5 {
      left: 576px;
      bottom: 412px;
    }

    .itemBox.pos6 {
      left: 561px;
      bottom: 352px;
    }

    .itemBox.pos7 {
      left: 525px;
      bottom: 293px;
    }

    .itemBox.pos8 {
      left: 468px;
      bottom: 242px;
    }
  }
}

/deep/ .el-timeline-item__tail {
  border-left: 2px solid #d6000f;
}

/deep/ li:nth-last-child(2) {
  .el-timeline-item__tail {
    border-left: 2px solid #cccccc;
  }
}

/deep/ .el-timeline-item__node {
  background-color: #d6000f;
}

/deep/ li:nth-last-child(1) {
  .el-timeline-item__node {
    background-color: #999999;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 16px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(54, 52, 52, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(99, 97, 97, 0.3);
  background-color: #b4afaf;
}

/* 发展历程*/
.history {
  .mb34 {
    margin-bottom: 34px;
  }

  .year {
    font-size: 18px;
    color: #c70009;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .itemWrappe {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .eachItem {
      color: #555;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 4px;

      .month {
        display: inline-block;
        text-align: right;
        width: 40px;
        margin-right: 24px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #000;
      }

      .progres {
        display: inline-block;

        .dot {
          display: block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #ccc;
          margin: 10px auto 0;
        }

        .line {
          display: block;
          width: 1px;
          height: 45px;
          background-color: #eee;
          margin: 10px auto 0;
        }
      }

      .content {
        display: inline-block;
        margin-left: 30px;
        flex: 1;
        color: #999999;
        white-space: pre-wrap;
        overflow: hidden;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }

    .eachItem:nth-last-child(1) {
      .line {
        height: 0;
      }
    }
  }

  .active {
    color: #c70009;

    .content {
      color: #c70009 !important;
    }

    .dot {
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      margin: 10px auto 0;
      background-color: #b63f3f !important;
    }

    .line {
      background-color: #b63f3f !important;
      transform: translateY(0) !important;
      transition: 0.8s all linear !important;
    }
  }

  /deep/ .el-input--suffix .el-input__inner {
    width: 100px;
    height: 22px;
    border: none;
    line-height: 24px;
  }

  /deep/ .el-input__suffix {
    top: 10px;
  }

  /deep/ .el-select-dropdown__item:hover {
    background-color: #c70009;
  }

  /deep/ .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: #c70009;
  }
}

.flex2 {
  /deep/ .el-select .el-select-dropdown__wrap.el-scrollbar__wrap {
    max-height: 160px;
  }
}

/deep/ .history .el-input__suffix {
  top: 1px !important;
}

/deep/ .el-select .el-input .el-select__caret {
  line-height: 12px;
}

.el-scrollbar__wrap {
  height: 160px !important;
}
</style>
<style>
.hight160 .el-scrollbar__wrap {
  height: 160px !important;
}

.hight160 .el-select-dropdown__item.selected {
  color: #ffffff;
  background: #c70009;
}

.el-carousel__item .el-carousel__mask {
  opacity: 0;
  background: none;
}

.fzlc {
  transform: rotate(90deg);
  /* transform-origin: 100px; */
  width: 550px;
}
</style>