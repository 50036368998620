<template>
  <!--  新闻中心-->
  <div class="news">
    <div class="block bannerImg">
      <img width="100%"
           height="600"
           src="@/assets/img/news.jpg"
           alt="">
    </div>
    <el-tabs class="newsTab"
             v-model="activeName"
             @tab-click="handleClick">
      <el-tab-pane label="公司动态"
                   name="2">
        <div class="center mt20">
          <div class="flex2 isTop">
            <div class="flex2Item"
                 v-for="(item,index) in articleIsTop"
                 :key="index">
              <div @click="openWIn(item.id)">
                <div class="imgBox">
                  <img v-if="item.cover"
                       :src="item.cover"
                       alt="">
                  <img v-else
                       :src="defaultCover1"
                       alt="">
                </div>
                <div class="text">
                  <h1 v-html="item.title"></h1>
                  <p><i>置顶</i> <img src="@/assets/img/xwzx_03@2x.png"
                         alt=""> <span>{{item.years}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="article mt30 isOne"
               v-for="(item,index) in currentPageData"
               :key="index">
            <div class="articleRow">
              <span>{{item.days}}日</span>
              <div class="content">
                <h2>{{item.years}}</h2>
                <div class="link"
                     @click="openWIn(item.id)">
                  <h1>{{item.title}}</h1>
                  <!--                  <div v-html="item.content"-->
                  <!--                       class="newsMaxHight"></div>-->

                  <div class="newsMaxHight">{{item.description}}</div>
                  <div class="fr red">
                    <div>阅读全文</div>
                    <!-- <router-link :to="{path:'/news/newsdetail',query:{articleId:item.id,activeTab:'2'}}">阅读全文→</router-link> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="block page">
            <el-pagination hide-on-single-page
                           @current-change="handleCurrentChange"
                           layout="prev, pager, next"
                           :total="total"
                           prev-text="上一页"
                           next-text="下一页"
                           :page-size="pageSize"
                           :current-page="currentPage">
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="行业热点"
                   name="1">
        <div class="center mt20">
          <div class="flex2 isTop">
            <div class="flex2Item"
                 v-for="(item,index) in articleIsTop1"
                 :key="index">
              <div @click="openWIn(item.id)">
                <div class="imgBox">
                  <img v-if="item.cover"
                       :src="item.cover"
                       alt="">
                  <img v-else
                       :src="defaultCover"
                       alt="">
                </div>
                <div class="text">
                  <h1 v-html="item.title"></h1>
                  <p><i>置顶</i> <img src="@/assets/img/xwzx_03@2x.png"
                         alt=""> <span>{{item.years}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="article mt30 isOne"
               v-for="(item,index) in currentPageData1"
               :key="index">
            <div class="articleRow">
              <span>{{item.days}}日</span>
              <div class="content">
                <h2>{{item.years}}</h2>
                <div class="link"
                     @click="openWIn(item.id)">
                  <h1>{{item.title}}</h1>
                  <!--                  <div v-html="item.content"-->
                  <!--                       class="newsMaxHight"></div>-->
                  <div class="newsMaxHight">{{item.description}}</div>
                  <div class="fr red">
                    <div>阅读全文</div>
                    <!-- <router-link :to="{path:'/news/newsdetail',query:{articleId:item.id,activeTab:'1'}}">阅读全文→
                    </router-link> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="block page">
            <el-pagination hide-on-single-page
                           @current-change="handleCurrentChange1"
                           layout="prev, pager, next"
                           :total="total1"
                           prev-text="上一页"
                           next-text="下一页"
                           :page-size="pageSize1"
                           :current-page="currentPage1">
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "News",
  data() {
    return {
      value: false,
      value1: false,
      activeName: "2", //切签
      total: 1, //公司动态
      currentPage: 1,
      pageSize: 5,
      currentPageData: [],
      articleIsTop: [], //置顶文章
      total1: 1, //行业热点
      currentPage1: 1,
      pageSize1: 5,
      currentPageData1: [],
      articleIsTop1: [], //置顶文章
      defaultCover: require("@/assets/img/pcTopRD.jpg"),
      defaultCover1: require("@/assets/img/pcTop.jpg"),
    };
  },
  created() {
    this.articleTop();
    this.articleTop1();
    this.articleList();
    this.articleList1();
  },
  methods: {
    //置顶文章 动态2
    async articleTop() {
      let active = this.$route.query.activeName; //切签
      if (active) {
        this.activeName = active;
      }
      const obj = {
        catalogId: 2,
        limit: 2,
      };
      const { data: res } = await this.$http.post(
        "/indexNewsTop",
        this.$qs.stringify(obj)
      );
      if (res.code == 200) {
        this.articleIsTop = res.data.records;
      } else {
        return this.$message.error(res.msg);
      }
    },
    //置顶文章-热点1
    async articleTop1() {
      let active = this.$route.query.activeName; //切签
      if (active) {
        this.activeName = active;
      }
      const obj = {
        catalogId: 1,
        limit: 2,
      };
      const { data: res } = await this.$http.post(
        "/indexNewsTop",
        this.$qs.stringify(obj)
      );
      if (res.code == 200) {
        this.articleIsTop1 = res.data.records;
      } else {
        return this.$message.error(res.msg);
      }
    },
    //文章-动态2
    async articleList() {
      let active = this.$route.query.activeName; //切签
      if (active) {
        this.activeName = active;
      }
      const obj = {
        page: this.currentPage,
        limit: this.pageSize,
        catalogId: 2,
      };
      const { data: res } = await this.$http.post(
        "/indexNews",
        this.$qs.stringify(obj)
      );
      if (res.code == 200) {
        this.currentPageData = res.data.records;
        this.total = res.data.total;
      } else {
        return this.$message.error(res.msg);
      }
    },
    //文章-热点1
    async articleList1() {
      const obj = {
        page: this.currentPage1,
        limit: this.pageSize1,
        catalogId: 1,
      };
      const { data: res } = await this.$http.post(
        "/indexNews",
        this.$qs.stringify(obj)
      );
      if (res.code == 200) {
        this.currentPageData1 = res.data.records;
        this.total1 = res.data.total;
      } else {
        return this.$message.error(res.msg);
      }
    },
    handleClick(tab, event) {
      //切签
      // console.log(tab, event);
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.articleList();
    },
    handleCurrentChange1(currentPage) {
      this.currentPage1 = currentPage;
      this.articleList1();
    },
    // 跳转详情页
    async openWIn(id) {
      let { data: res } = await this.$http.get("/pressDetail?id=" + id);

      window.location.href = res.url;
    },
  },
};
</script>

<style lang="less" scoped>
.news {
  padding-bottom: 60px;
  box-sizing: border-box;

  .isTop {
    /*padding-bottom: 5px;*/
    padding-bottom: 60px;
    box-sizing: border-box;

    .flex2Item {
      width: 49%;
      background: #ffffff;
      box-shadow: 0 2px 4px 0 #dedede;

      .imgBox {
        width: 100%;
        height: 340px;
        overflow: hidden;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      .imgBox:hover img {
        transform: scale(1.1);
        transition: 0.5s all linear;
      }

      .text {
        padding: 20px;
        box-sizing: border-box;

        h1 {
          font-size: 18px;
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        p {
          margin-top: 16px;

          i {
            display: inline-block;
            color: #c70009;
            width: 40px;
            height: 20px;
            text-align: center;
            border-radius: 6px;
            border: 1px solid #c70009;
            margin-right: 12px;
          }

          img {
            vertical-align: -1px;
            margin-right: 2px;
          }

          span {
            color: #999999;
          }
        }
      }
    }
  }

  .article {
    .articleRow {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      > span {
        display: inline-block;
        /*width: 90px;*/
        width: 7%;
        min-width: 90px;
        max-width: 90px;
        font-size: 32px;
        color: #c70009;
      }

      .content {
        /*flex: 1;*/
        width: 92%;
        word-break: break-all;
        p {
          word-break: break-all;
        }
        span {
          word-break: break-all;
        }

        h2 {
          font-size: 16px;
          font-weight: 400;
          color: #999999;
          margin-bottom: 18px;
          border-bottom: 1px solid #dedede;
          padding-bottom: 4px;
          box-sizing: border-box;
        }

        h1 {
          color: #333333;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 12px;
          letter-spacing: 1.2px;
        }

        .link p {
          font-size: 16px;
          color: #333333;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        .red {
          margin-top: 20px;

          a {
            color: #c70009;
          }
        }

        .link:hover h1 {
          color: #c70009;
        }
      }
    }
  }
}

.page {
  /deep/ .el-pagination {
    text-align: center;
  }

  /deep/ .el-pager li.active {
    color: #c70009;
  }

  /deep/ .el-pager li:hover {
    color: #c70009;
  }

  /deep/ .el-pagination {
    .btn-next:hover {
      color: #c70009;
    }

    .btn-prev:hover {
      color: #c70009;
    }
  }
}

.newsMaxHight {
  /*height: 60px;*/
  height: auto;
  /*max-height: 60px;*/
  font-size: 16px;
  color: #999999;
  line-height: 30px;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /deep/ img {
    margin-top: 80px;
  }
}
</style>