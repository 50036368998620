<template>
  <div class="work">
    <div class="block bannerImg ">
      <img width="100%"
           height="600"
           src="@/assets/img/work.jpg"
           alt="">
    </div>
    <div class="center pad60">
      <div class="card">
        <div class="oneTitle">
          <h1 class="">乐享承信</h1>
          <h2>依托优质核心企业信用，通过区块链分布式记账方式建立数字商票系统，实现各个供应商在产业链条上的多级流转</h2>
        </div>
        <div class="flex2">
          <div class="item flex2Item left">
            <router-link :to="{path:'/work/worklxcx',query:{activeName:'first'}}">
              <img src="@/assets/img/ywjs_01.png"
                   alt="">
              <div class="mask"></div>
              <div class="slide">
                <h1 class="title">乐享承信</h1>
                <img class="Icon"
                     width="20"
                     height="20"
                     src="@/assets/img/ywjs_14@2x.png"
                     alt="">
              </div>
            </router-link>
          </div>

          <div class="item flex2Item right">
            <router-link :to="{path:'/work/worklxcx',query:{activeName:'second'}}">
              <img src="@/assets/img/ywjs_02.png"
                   alt="">
              <div class="mask"></div>
              <div class="slide">
                <h1 class="title">供应链票据系统</h1>
                <img class="jumpIcon"
                     width="20"
                     height="20"
                     src="@/assets/img/ywjs_14@2x.png"
                     alt="">
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="card mt60">
        <div class="oneTitle">
          <h1>资产证券化</h1>
          <h2>以票据等结算工具的应收账款为底层基础资产，通过与券商合作，围绕“3N”模式开展的资产证券化业务</h2>
        </div>
        <div class="flex2">
          <div class="item flex2Item left">
            <router-link :to="{path:'/work/workabs',query:{activeName:'first'}}">
              <img src="@/assets/img/ywjs_03.png"
                   alt="">
              <div class="mask"></div>
              <div class="slide">
                <h1 class="title">乐享ABS</h1>
                <img class="jumpIcon"
                     width="20"
                     height="20"
                     src="@/assets/img/ywjs_14@2x.png"
                     alt="">
              </div>
            </router-link>
          </div>
          <div class="item flex2Item right">
            <router-link :to="{path:'/work/workabs',query:{activeName:'first'}}">
            <!-- 2023.1.29改为跳转到abs|abn -->
              <img src="@/assets/img/ywjs_04.png"
                   alt="">
              <div class="mask"></div>
              <div class="slide">
                <h1 class="title">乐享ABN</h1>
                <img class="jumpIcon"
                     width="20"
                     height="20"
                     src="@/assets/img/ywjs_14@2x.png"
                     alt="">
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="card mt60">
        <div class="oneTitle">
          <h1>乐享云贷</h1>
          <h2>依托核心企业信用，与各类金融机构合作，为持票企业提供多元化、低成本、高效率的定制化服务</h2>
        </div>
        <div class="flex3">
          <div class="item flex3Item ">
            <router-link :to="{path:'/work/workwzg',query:{activeName:'first'}}">
              <img src="@/assets/img/ywjs_05.png"
                   alt="">
              <div class="mask"></div>
              <div class="slide">
                <h1 class="title">微掌柜</h1>
                <img class="jumpIcon"
                     width="20"
                     height="20"
                     src="@/assets/img/ywjs_14@2x.png"
                     alt="">
              </div>
            </router-link>
          </div>
          <div class="item flex3Item ">
            <router-link :to="{path:'/work/workwzg',query:{activeName:'second'}}">
              <img src="@/assets/img/ywjs_06.png"
                   alt="">
              <div class="mask"></div>
              <div class="slide">
                <h1 class="title">兴惠贷</h1>
                <img class="jumpIcon"
                     width="20"
                     height="20"
                     src="@/assets/img/ywjs_14@2x.png"
                     alt="">
              </div>
            </router-link>
          </div>
          <div class="item flex3Item ">
            <router-link :to="{path:'/work/workwzg',query:{activeName:'third'}}">
              <img src="@/assets/img/ywjs_18.png"
                   alt="">
              <div class="mask"></div>
              <div class="slide">
                <h1 class="title">小微企业信用贷</h1>
                <img class="jumpIcon"
                     width="20"
                     height="20"
                     src="@/assets/img/ywjs_14@2x.png"
                     alt="">
              </div>
            </router-link>
          </div>

        </div>
      </div>

      <div class="card mt60">
        <div class="oneTitle">
          <h1>智能风控</h1>
          <h2>通过嵌入业务场景和流程设计打造出多维度、全方位的智能风控体系，降低数据获取成本，有效防范履约风险</h2>
        </div>
        <div class="flex1">
          <div class="item flex1Item">
            <router-link to="/work/workrisk">
              <img src="@/assets/img/ywjs_07.png"
                   alt="">
              <div class="mask"></div>
              <div class="slide">
                <h1 class="title">智能风控系统</h1>
                <img class="jumpIcon"
                     width="20"
                     height="20"
                     src="@/assets/img/ywjs_14@2x.png"
                     alt="">
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="card mt60">
        <div class="oneTitle">
          <h1>乐享直贴通</h1>
          <h2>以票据资产现金流为偿付支持创设的受益证券，通过线上智能化撮合，助力中小微企业实现资金边际效率的最优化</h2>
        </div>
        <div class="flex1">
          <div class="item flex1Item">
            <router-link to="/work/workztt">
              <img src="@/assets/img/ywjs_08.png"
                   alt="">
              <div class="mask"></div>
              <div class="slide">
                <h1 class="title">乐享直贴通</h1>
                <img class="jumpIcon"
                     width="20"
                     height="20"
                     src="@/assets/img/ywjs_14@2x.png"
                     alt="">
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="card mt60">
        <div class="oneTitle">
          <h1>保理服务</h1>
          <h2>通过多方在线交互、协同,实现保理业务全流程管控，降低操作风险和运营人工成本，提升供应链运转效率，规避各方业务风险</h2>
        </div>
        <div class="flex1">
          <div class="item flex1Item">
            <router-link to="/work/workybl">
              <img src="@/assets/img/ywjs_09.png"
                   alt="">
              <div class="mask"></div>
              <div class="slide">
                <h1 class="title">乐享云保理</h1>
                <img class="jumpIcon"
                     width="20"
                     height="20"
                     src="@/assets/img/ywjs_14@2x.png"
                     alt="">
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="card mt60">
        <div class="oneTitle">
          <h1>科技服务</h1>
          <h2>针对各个领域不同场景中用户的需求，为政府、企业、金融机构等提供数字化金融科技解决方案</h2>
        </div>
        <div class="flex3">
          <div class="item flex3Item">
            <router-link :to="{path:'/work/workkj',query:{activeName:'first'}}">
              <img src="@/assets/img/ywjs_10.png"
                   alt="">
              <div class="mask"></div>
              <div class="slide">
                <h1 class="title">极速贴</h1>
                <img class="jumpIcon"
                     width="20"
                     height="20"
                     src="@/assets/img/ywjs_14@2x.png"
                     alt="">
              </div>
            </router-link>
          </div>
          <div class="item flex3Item">
            <router-link :to="{path:'/work/workkj',query:{activeName:'second'}}">
              <img src="@/assets/img/ywjs_11.png"
                   alt="">
              <div class="mask"></div>
              <div class="slide">
                <h1 class="title">二代央评系统</h1>
                <img class="jumpIcon"
                     width="20"
                     height="20"
                     src="@/assets/img/ywjs_14@2x.png"
                     alt="">
              </div>
            </router-link>
          </div>
          <div class="item flex3Item">
            <router-link :to="{path:'/work/workkj',query:{activeName:'three'}}">
              <img src="@/assets/img/yejs_12.png"
                   alt="">
              <div class="mask"></div>
              <div class="slide">
                <h1 class="title">票驿站</h1>
                <img class="jumpIcon"
                     width="20"
                     height="20"
                     src="@/assets/img/ywjs_14@2x.png"
                     alt="">
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="card mt60">
        <div class="oneTitle">
          <h1>应收账款票据化</h1>
          <h2>全流程线上完成等分化签收转让，提升企业融资能力，降低全链条融资成本</h2>
        </div>
        <div class="flex1">
          <div class="item flex1Item">
            <router-link to="/work/workcsy">
              <img src="@/assets/img/ywjs_13.png"
                   alt="">
              <div class="mask"></div>
              <div class="slide">
                <h1 class="title">应收账款票据化</h1>
                <img class="jumpIcon"
                     width="20"
                     height="20"
                     src="@/assets/img/ywjs_14@2x.png"
                     alt="">
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Work",
};
</script>

<style lang="less" scoped>
.work {
  background: #ffffff;

  .flex2 {
    .flex2Item {
      position: relative;
      width: 49%;
      height: 320px;
      background: #999999;

      a > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .mask {
    position: absolute;
    bottom: 0;
    height: 0;
    background: rgba(199, 0, 9, 0.2);
    width: 100%;
    opacity: 0;
  }

  .slide {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .title {
      color: #ffffff;
    }

    .jumpIcon {
      opacity: 0;
      margin-top: 30px;
    }
  }

  .item:hover .slide {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -120%);
    transition: 0.3s all linear;
  }

  .item:hover .jumpIcon {
    opacity: 1;
  }

  .item:hover .mask {
    position: absolute;
    bottom: 0;
    height: 100%;
    opacity: 1;
    transform: translateY(0);
    transition: 0.3s all linear;
  }

  .flex1 {
    position: relative;

    .flex1Item {
      position: relative;
      width: 100%;
      height: 320px;

      a > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .flex3 {
    position: relative;

    .flex3Item {
      position: relative;
      width: 384px;
      height: 320px;

      a > img {
        width: 384px;
        height: 100%;
      }
    }
  }
}
</style>