var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case"},[_vm._m(0),_c('div',{staticClass:"cardBg jdal"},[_c('div',{staticClass:"center"},[_c('router-view'),_c('div',{staticClass:"flex2"},[_vm._m(1),_c('div',{staticClass:"flex2Item jdalL"},[_c('div',{staticClass:"itemBox"},[_c('h1',[_vm._v("国安大数据")]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('router-link',{attrs:{"to":{path:'/case/casedetail',query:{detailIndex:0}}}},[_c('span',{staticClass:"goDetail"},[_vm._v("了解更多 →")])])],1)])]),_c('div',{staticClass:"flex2 mb60"},[_vm._m(6),_c('div',{staticClass:"flex2Item jdalL"},[_c('div',{staticClass:"itemBox"},[_c('h1',[_vm._v("安徽中技国医SPD系统平台")]),_vm._m(7),_vm._m(8),_vm._m(9),_c('h2'),_vm._m(10),_c('h2'),_c('router-link',{attrs:{"to":{path:'/case/casedetail',query:{detailIndex:1}}}},[_c('span',{staticClass:"goDetail"},[_vm._v("了解更多 →")])])],1)])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block bannerImg"},[_c('img',{attrs:{"width":"100%","height":"600","src":require("@/assets/img/case.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex2Item jdalR"},[_c('img',{staticClass:"img1",attrs:{"width":"100%","height":"100%","src":require("@/assets/img/jdal_01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("客户名称：")]),_c('em',[_vm._v("鄂尔多斯市伊泰投资控股有限责任公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("合作类型：")]),_c('em',[_vm._v("合资公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("解决方案：")]),_c('em',[_vm._v("2018年8月，为了顺应煤炭交易的庞大体量与能源化工的飞速发展，助力核心企业建立票据生态体系，解决中小供应商企业融资难题。乐享数科携手鄂尔多斯市伊泰投资控股有限责任公司、内蒙古天眼股权投资中心共同设立了国安大数据科技有限公司。 历经2年多的布局开发与深耕合作，针对伊泰集团与链属企业的核心需求，设计全流程线上化服务，打造国安供应链金融服务平台，为企业用户提供一站式金融服务体系；目前平台已实现与平安、建设等多家银行的系统对接及多元服务，助力核心企业创新融合、布局未来。平台的票据服务数据从2018年底的8.6亿元，发展至2021年底的71.1亿元；截止2021年底，平台共计合作的核心企业15家，注册的企业会员近600家，合作的金融机构20余家，累计服务各类供应链金融业务共计约123亿元。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("实现价值：")]),_c('em',[_vm._v("平台先后运营了资产服务中心与商业保理公司，为了更好的服务中小融资客户，不断提升伊泰商票的市场认可度与融资竞争力，打破利率壁垒，助力流转融通；综合贴现利率已从2019年的年化8%，逐步降低至2021年的年化3.58%，在增加业务签发的同时不断降低利率。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex2Item jdalR"},[_c('img',{staticClass:"img1",attrs:{"src":require("@/assets/img/02@2x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("客户名称：")]),_c('em',[_vm._v("安徽中技国医医疗科技有限公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("合作类型：")]),_c('em',[_vm._v("合资公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("解决方案：")]),_c('em',[_vm._v("近年来医用耗材市场以每年20%的速度高速增长，但医院医用耗材整体管理水平不高，目前仍处于进销存管理、线下采购、纸质对账的半自动化管理阶段。同时，“两票制”政策的全面执行，也使得流通企业的资金压力越来越大。在此背景下，SPD+供应链金融的服务模式应运而生。 SPD+供应链金融是基于供应商与医院之间真实贸易背景，以应收账款作为参考的金融创新模式。借助先进的大数据、区块链、云计算、智能风控等技术，为供应链各环节客户提供全方位可定制的金融科技解决方案，将有效解决链上成员的融资难题，提高供应商资金周转速度；并且链上企业无需提供抵押和保证担保，即可享有轻松快捷的线上融资。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("实现流程：")]),_c('em',[_vm._v("1.银行为医院批复总额度，在总额度项下，医院向银行推荐优质耗材供应商； 2.银行为单个耗材供应商批复直接授信额度； 3.基于SPD系统的结算单、医院、供应商三方进行平台在线确认； 4.供应商通过服务平台、提供结算单、明细单、发票提交融资申请； 5.银行对结算单、发票进行核验、进行放款； 6.到期后医院进行还款，银行锁定还款账户、到期自动扣划；")])])
}]

export { render, staticRenderFns }