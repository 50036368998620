<template>
  <div class="product">
    <el-breadcrumb class="breadCrumb"
                   separator="/">
      <el-breadcrumb-item>工作台</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>产品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="cardProduct mt20 flex2">
      <div class="flex2Item flex2">
        <div class="itemL">
          <h1>供应链票据</h1>
          <p>解决传统票据无法拆分流转的难题</p>
          <a href="javascript:;"
             @click="jumpGYL(companyRole,companyStatus,bankStatus)">立即前往</a>
        </div>
        <div class="itemR">
          <img src="@/assets/img/auth/gzt_01.png"
               alt="">
        </div>
      </div>
      <div class="flex2Item flex2">
        <div class="itemL">
          <h1>微掌柜</h1>
          <p>解决账期长、回款慢、门槛高的融资问题</p>
          <a href="javascript:;"
             @click="jumpMicro">了解产品</a>
        </div>
        <div class="itemR">
          <img src="@/assets/img/auth/gzt_02.png"
               alt="">
        </div>
      </div>
      <div class="flex2Item flex2">
        <div class="itemL">
          <h1>振兴发票贷</h1>
          <p>基于商票和担保公司增信并行的信用流转产品</p>
          <router-link to="/work/workwzg?activeName=second"
                       target="_blank">了解产品</router-link>
        </div>
        <div class="itemR">
          <img src="@/assets/img/auth/gzt_03.png"
               alt="">
        </div>
      </div>
      <div class="flex2Item flex2">
        <div class="itemL">
          <h1>ABS</h1>
          <p>帮助持票中小企业实现快速的低成本融资</p>
          <router-link to="/work/workabs?activeName=first"
                       target="_blank">了解产品</router-link>
        </div>
        <div class="itemR">
          <img src="@/assets/img/auth/gzt_04.png"
               alt="">
        </div>
      </div>
      <div class="flex2Item flex2">
        <div class="itemL">
          <h1>云保理</h1>
          <p>实现底层资产透明化，提升供应链运转效率</p>
          <router-link to="/work/workybl"
                       target="_blank">了解产品</router-link>
        </div>
        <div class="itemR">
          <img src="@/assets/img/auth/gzt_05.png"
               alt="">
        </div>
      </div>
      <!-- 1.3增加小微企业信用贷 -->
      <div class="flex2Item flex2">
        <div class="itemL">
          <h1>小微企业信用贷</h1>
          <p>为中小微企业打造数字化融资服务中心</p>
          <a href="javascript:;"
             @click="jumpMicro">了解产品</a>
        </div>
        <div class="itemR">
          <img src="@/assets/img/auth/gzt_06.png"
               alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Product",
  data() {
    return {};
  },
  computed: {
    ...mapState([
      "companyStatus", //企业状态
      "bankStatus", //银行卡状态
      "companyRole", //企业角色
    ]),
  },
  methods: {
    //跳转供应链
    jumpGYL(role, val, bank) {
      if (role != "0") return this.$message.error("该账户暂无权限");
      if (role == "0" && val != "3")
        return this.$message.error("企业未完成认证");
      if (val == "3" && bank != "1")
        return this.$message.error("银行卡验证未通过");
      this.$router.push("/ticket");
    },
    jumpMicro() {
      // this.$router.push("/workbench/microCreditLoans");
      let routeData = this.$router.resolve({
        path: "/productDetails/microCreditLoans",
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.product {
  .cardProduct.flex2 {
    flex-wrap: wrap;
  }

  .cardProduct {
    min-height: calc(100vh - 522px);
    background: #ffffff;
    padding: 40px 80px 20px;
    box-sizing: border-box;

    .flex2Item {
      width: 400px;
      height: 240px;
      padding: 40px 50px 30px;
      box-sizing: border-box;
      background: #fafafa;
      box-shadow: 0 2px 4px 0 #dbdbdb;
      border-radius: 25px;
      margin-bottom: 20px;

      .itemL {
        position: relative;

        h1 {
          font-size: 20px;
          margin-bottom: 20px;
        }

        p {
          font-size: 16px;
          color: #666666;
          line-height: 24px;
          letter-spacing: 1px;
        }

        > a {
          position: absolute;
          left: 0;
          bottom: 0;
          display: inline-block;
          width: 100px;
          height: 34px;
          background: #ffffff;
          line-height: 34px;
          text-align: center;
          color: #c70009;
          border-radius: 17px;
          border: 1px solid #c70009;
        }
      }

      .itemL > a:hover {
        background: rgba(199, 0, 9, 0.12);
      }

      .itemR {
        img {
          width: 70px;
          height: 70px;
          margin-left: 60px;
        }
      }
    }
  }
}
</style>