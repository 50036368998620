<template>
  <!-- 首页header样式 变量控制css -->
  <div class="header" :class="{'header1':$route.path=='/','header2':header2Tf}">
    <!-- :class="[$route.path=='/'?'header1':'']" -->
    <div class="center">
      <div class="nav">
        <div style="width: 212px;margin-right: 170px;height:70px">
          <div v-if="$route.path!='/'" class="logo logoQt" @click="$router.push('/')"></div>
          <!-- 变量配置class 替换背景图片 -->
          <div v-else class="logo" :class="{'logo1':logo2Bg1,'logo2':logo2Bg}"
            @click="$route.path=='/'?$router.go(0):$router.push('/')">
            <!-- 点击图标进行刷新 -->
          </div>
        </div>

        <!--一期头部导航-->
        <!--        <el-menu-->
        <!--                v-if="isLogin!=='login'&&isLogin!=='loginqt'&&isLogin!=='auth'&&isLogin!=='ticket'&&isLogin!=='aside'&&isLogin!=='workbench'"-->
        <!--                router-->
        <!--                :default-active="activeRoute"-->
        <!--                class="el-menu-demo row navFlex"-->
        <!--                mode="horizontal"-->
        <!--                @select="handleSelect"-->
        <!--                text-color="#333333"-->
        <!--                active-text-color="#C70009">-->
        <!--          <el-menu-item v-for="(item,index) in navRouter" :index="item.route" :key="index">-->
        <!--            <span class="routerLink">{{item.name}}</span>-->
        <!--          </el-menu-item>-->
        <!--        </el-menu>-->
        <!--二期头部导航-->
        <div class="headerMemu" style="flex:1;height:100%">
          <div class="mhNav" style="height:100%">
            <el-menu v-if="showUser==true" class="el-menu-demo row navFlex" text-color="#333333"
              active-text-color="#C70009">
              <el-menu-item style="padding-left:0;position:relative" v-for="(item,index) in navRouter" :key="index">
                <span class="routerLink"><a href="/">{{item.name}}</a></span>
              </el-menu-item>
            </el-menu>

            <el-menu v-if="showUser==false" router :default-active="activeRoute" class="el-menu-demo row navFlex"
              mode="horizontal" @select="handleSelect" :text-color="$route.path=='/'?'#fff':'#333333'"
              :active-text-color="$route.path=='/'?'#fff':'#C70009'">
              <el-menu-item v-for="(item,index) in navRouter" :index="item.route" :key="index">
                <span class="routerLink">{{item.name}}</span>
              </el-menu-item>
              <el-menu-item index="/">
                <span>
                  <a href="https://en.lxdt.com/" target="_blank" rel=""><em class="red_he">简</em><em>/EN</em></a>
                </span>
                <!-- <span class="routerLink">简/EN</span> -->
              </el-menu-item>
            </el-menu>
            <div class="user" v-if="showUser==true&&activeRoute!='/login'&&activeRoute!='/loginqt'">
              <el-button v-if="activeRoute=='/auth'" type="text" style="color:#D80915;margin-right:10px"
                @click="xszd">新手指导</el-button>
              <!-- 1.3增加新手指导 -->

              <img v-if="(companyStatus=='3'&&companyRole=='0')||companyRole!='0'||companyStatus=='6'"
                src="@/assets/img/auth/sy_05.png" alt="" style="width: 30px;height: 30px;">
              <img v-else src="@/assets/img/auth/dl_01.png" alt="" style="width: 30px;height: 30px;">
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  <em
                    v-text="(companyStatus=='3'&&companyRole=='0')||companyRole!='0'||companyStatus=='6'?companyName:getUser"></em><i
                    class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="userDown">
                  <el-dropdown-item command="a" class="flex2">
                    <span>{{getUser}}</span>
                    <!--                  <span>{{isUserPhone}}</span>-->
                    <!--                  <div class="openId">管理员</div>-->
                  </el-dropdown-item>
                  <el-dropdown-item command="b">账户中心</el-dropdown-item>
                  <el-dropdown-item command="c">业务中心</el-dropdown-item>
                  <el-dropdown-item command="d">
                    <a href="javascript:;">退出</a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--二次确认-退出-->
    <el-dialog :show-close="false" :visible.sync="goOutVisible" :close-on-click-modal="false" :destroy-on-close="true"
      width="410px">
      <div class="contentTip flex5">
        <img class="tipIcon" src="@/assets/img/auth/tishi1.png" alt="">
        <div>
          <h1>退出提示</h1>
          <p>请确认是否要退出登录？</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer formBtn">
        <el-button class="btn normal" @click="goOutVisible=false">取 消</el-button>
        <el-button class="btn submit" type="primary" @click="goOutVisibleVisibleSave()">确 认</el-button>
      </span>
    </el-dialog>

    <!--gyl/cpiaoju信息授权带回-->
    <el-dialog :show-close="false" :visible.sync="FromVisible" :close-on-click-modal="false" :destroy-on-close="true"
      width="410px">
      <div class="contentTip flex5">
        <img class="tipIcon" src="@/assets/img/auth/tishi1.png" alt="">
        <div>
          <h1>提示</h1>
          <p>已检测您的账号在云票据平台上存在企业信息，是否需要将数据带回？</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer formBtn">
        <el-button class="btn normal" @click="FromVisibleCancal()">取 消</el-button>
        <el-button class="btn submit" type="primary" @click="FromeVisibleSave()">授 权 带 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "index",
  props: {
    // attest:{
    //   type:String,
    //   default:window.sessionStorage.getItem('getUserPhone'),
    // }
  },
  data() {
    return {
      qyzt: this.$store.state.getStatus,
      getbusinessRole: sessionStorage.getItem("businessRole"), //0小微企业 1核心企业 2保理公司 3担保公司 4律所
      businessRole: sessionStorage.getItem("businessRole"), //0小微企业 1核心企业 2保理公司 3担保公司 4律所
      getStatus: window.sessionStorage.getItem("getStatus"), //1.未认证完成时显示手机号  2.认证通过后显示企业名称---3
      // companyName: window.sessionStorage.getItem('companyName'),//公司名
      isToken: window.sessionStorage.getItem("token"), //token
      isUserPhone: window.sessionStorage.getItem("getUserPhone"), //手机号
      isLogin: this.$route.path, //是否显示二期导航
      activeIndex: "/", //默认路径
      activeRoute: this.$route.path, //高亮路径
      // navRouter: [//一期导航
      //   {route: '/', name: '首页'},
      //   {route: '/work', name: '业务介绍'},
      //   {route: '/solution', name: '解决方案'},
      //   {route: '/case', name: '经典案例'},
      //   {route: '/news', name: '新闻中心'},
      //   {route: '/aboutus', name: '关于乐享'},
      //   {route: '/contactus', name: '联系我们'}
      // ],
      // navMHRouter: [//二期导航
      //   {route: '/', name: '门户中心'},
      // ]
      navRouter: [], //导航
      showUser: false, //一期、二期导航
      goOutVisible: false, //退出弹窗
      FromVisible: false, //gyl来源弹窗
      scroll: "",
      header2Tf: false,
      logo2Bg: false,
      logo2Bg1: true,
    };
  },
  computed: {
    ...mapState([
      "companyStatus",
      "bankStatus",
      "getUser",
      "companyName",
      "companyRole",
      "userType", //管理员/操作员
      "backStatus", //企业来源-授权
    ]),
  },
  beforeCreate() { },
  created() {
    this.getRoute();
  },
  mounted() {
    // 滚动距离控制 头部样式
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 从别的页面点击进入新手指导 可去掉
    xszd() {
      if (this.$route.path == "/auth") {
        this.$emit("aa");
      } else {
        this.$router.push("/auth");
      }
    },
    // 滑动事件
    handleScroll() {
      this.scroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.scroll == 120 || this.scroll > 120) {
        this.header2Tf = true;
        this.logo2Bg = true;
        this.logo2Bg1 = false;
      } else {
        this.header2Tf = false;
        this.logo2Bg = false;
        this.logo2Bg1 = true;
      }
    },
    //获取路由
    getRoute() {
      let oldPath = this.$route.path.split("/");
      let hasOldPath = oldPath.slice(1, oldPath.length - 1).join("/"); //截取地址栏路径
      let hasOldPath1 = oldPath.slice(1, 2).join("/"); //截取地址栏路径
      this.isLogin = hasOldPath1; //显示二期导航
      if (oldPath.length > 2) {
        let reCurrentRoute = "/" + hasOldPath;
        if ((this.activeRoute = reCurrentRoute)) {
          this.activeRoute = reCurrentRoute;
        }
      } else {
        this.activeRoute = this.$route.path;
      }
      if (
        hasOldPath1 == "login" ||
        hasOldPath1 == "loginqt" ||
        hasOldPath1 == "auth" ||
        hasOldPath1 == "ticket" ||
        hasOldPath1 == "aside" ||
        hasOldPath1 == "workbench" ||
        hasOldPath1 == "productDetails"
      ) {
        this.showUser = true;
        this.navRouter = [
          //二期导航
          { route: "/", name: "首页" }, //门户中心
        ];
      } else {
        this.showUser = false;
        this.navRouter = [
          //一期导航
          { route: "/", name: "首页" },
          { route: "/work", name: "业务介绍" },
          { route: "/solution", name: "解决方案" },
          { route: "/case", name: "经典案例" },
          { route: "/news", name: "新闻中心" },
          { route: "/aboutus", name: "关于乐享" },
          { route: "/contactus", name: "联系我们" },
        ];
      }
    },
    //查询资料
    async getRole() {
      // console.log(sessionStorage.getItem('getUserPhone'), '获取')
      const { data: res } = await this.$auth.post("/cpiaoju-auth/index/home");
      if (res.code == 200) {
        this.formList = res.date;
        this.tableList = res.agent;
        if (res.date.status == "4") {
          this.stateSet = "未认证";
        } else if (res.date.status == "3") {
          this.stateSet = "认证通过";
        } else if (res.date.status == "2") {
          this.stateSet = "认证失败";
        } else if (res.date.status == "1") {
          this.stateSet = "审核中";
        } else if (res.date.status == "0") {
          this.stateSet = "未认证";
        } else if (res.date.status == "6") {
          this.stateSet = "待补充";
        }
        this.$store.commit("getuserType", res.date.userType); //用户角色-操作员|管理员
        this.$store.state.userType = res.date.userType; //用户角色-操作员|管理员
        window.sessionStorage.setItem("userType", res.date.userType); //用户角色-操作员|管理员
        this.$store.commit("getcompanyStatus", res.date.status); //登录后企业审核状态--0.保存 1.待认证 2.认证失败 3.认证通过4未认证
        this.$store.state.companyStatus = res.date.status;
        window.sessionStorage.setItem("getStatus", res.date.status); //登录后企业审核状态--0.保存 1.待认证 2.认证失败 3.认证通过4未认证
        window.sessionStorage.setItem("companyName", res.date.companyName); //公司
        this.$store.commit("getcompanyName", res.date.companyName); //

        this.$store.commit("getcompanyRole", res.date.businessRole); //银行状态--0 处理中 1 审核成功 2 审核失败 3 待验证 4未添加
        window.sessionStorage.setItem("businessRole", res.date.businessRole); //登录后0小微企业 1核心企业 2保理公司 3担保公司 4律所
      }
    },
    //登录账户来源-gyl/cpiaoju-数据带回
    async FromeVisibleSave() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/login/updateCompany",
        this.$qs.stringify({
          // userId: this.updateId,
          backStatus: 2,
        })
      );
      if (res.code == 200) {
        this.FromVisible = false;
        this.$store.state.backStatus = 2;
        this.$store.commit("getbackStatus", 2);
        this.getRole();
      } else {
        this.FromVisible = false;
        this.$message.error(res.msg);
      }
    },
    async FromVisibleCancal() {
      if (this.$route.path == "/aside/companyinfo") {
        this.FromVisible = false;
      } else {
        this.$router.push("/aside/companyinfo");
        this.FromVisible = false;
      }
    },
    //导航条
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    //导航跳转
    // handleSelectGoTo() {
    //   // this.$router.push('/')
    // },
    //导航右侧下拉
    handleCommand(command) {
      if (command == "a") {
      } else if (command == "b") {
        if (this.backStatus == "1") return (this.FromVisible = true);
        if (this.userType == "1") {
          this.$router.push("/aside/companyinfo");
        } else {
          this.$router.push("/aside/password");
        }
      } else if (command == "c") {
        this.$router.push("/workbench/product");
      } else if (command == "d") {
        //退出登录
        this.goOutVisible = true;
      } else {
        this.$router.push("/login");
      }
    },
    //退出登录
    goOutVisibleVisibleSave() {
      this.$auth.post("/cpiaoju-auth/login/logout").then((res) => {
        if (res.data.code == 200) {
          this.goOutVisible = false;
          window.sessionStorage.clear();
          localStorage.removeItem("userPhoneKj");
          if (this.companyRole == "0") {
            this.$router.push("/login");
          } else {
            this.$router.push("/loginqt");
          }
        } else if (res.data.code == 2001) {
          this.$message.error("登录状态已失效，请重新登录");
          this.goOutVisible = false;
        } else if (res.data.code == 2010) {
          this.$message.error("登录状态已失效，请重新登录");
          this.goOutVisible = false;
        } else {
          this.$message.error(res.data.message);
          this.goOutVisible = false;
        }
      });
    },
  },
  destroyed() {
    // 销毁 滚动距离
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    $route() {
      this.getRoute();
    },
  },
};
</script>
<style lang="less" scoped>
.routerLink:after {
  position: absolute;
  bottom: -6px;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 10;
  transition: all 0.3s;
}

/* 头部导航 每个按钮下边框的动画 */
.routerLink:after {
  height: 4px;
  left: 50%;
  width: 0;
  background: #c7000a;
  transform: translateX(-50%);
}

.routerLink:hover:after {
  width: 100%;
}

.header {
  width: 100%;
  height: 70px;
  background: #ffffff;
}

// 2022.7.7更改
.header1 {
  // 首页设置fixed 会算上滚动条的宽度 logo会移动距离 所以剪掉滚动条宽度
  width: calc(100% - 5px) !important;
  position: fixed !important;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

.header1:hover {
  background-color: #fff !important;
  box-shadow: 0px 0px 5px #979797;

  .el-menu-item {
    color: #333333 !important;
  }
}

// 更换背景图片
.header1:hover .logo1 {
  width: 100%;
  // height: 70px;
  background: url("../../assets/img/logo.png") no-repeat;
  background-size: 100% auto;
  background-position: 0 50%;
}

.logoQt {
  width: 100%;
  background: url("../../assets/img/logo.png") no-repeat;
  background-size: 100% auto;
  background-position: 0 50%;
}

.header2 {
  background-color: #fff !important;
  box-shadow: 0px 0px 5px #979797;

  .el-menu-item {
    color: #333333 !important;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;

  .routerLink {
    cursor: pointer;
  }

  .routerLink:hover {
    color: #c70009;
  }

  .logo {
    width: 100%;
    height: 100%;
    display: flex;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .logo1 {
    background: url("../../assets/img/indexlogo.png") no-repeat;
    background-size: 100% auto;
    background-position: 0 50%;
  }

  .logo2 {
    background: url("../../assets/img/logo.png") no-repeat;
    background-size: 100% auto;
    background-position: 0 50%;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .el-menu {
    background: none;
    border-bottom: none;

    li.el-menu-item {
      width: 100%;
      height: auto;
      padding: 0 0 4px;
      line-height: normal;
      margin-left: 56px;
      font-size: 16px;
      cursor: inherit;
      border-bottom: none;

      .is-active {
        border-bottom: none !important;
        // background-color: rgba(0, 0, 0, 0);

        border-top: 4px;
        width: calc(100% - 14px);
      }
    }

    .el-menu-item:hover {
      background-color: rgba(0, 0, 0, 0);
    }

    .is-active:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.el-menu-item.is-active {
  background-color: rgba(0, 0, 0, 0) !important;
  // border-bottom: 2px solid #c7000a !important;
}

.el-submenu__title.is-active {
  background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu-item {
  // height: 100% !important;
  // display: flex;
  // align-items: center;
}

.mhNav {
  flex: 1;
  display: flex;
  justify-content: space-between;
  /*justify-content: flex-end;*/

  .el-menu {
    border-right: none;
  }

  .el-menu li a:hover {
    color: #c70009;
  }

  .nav .el-menu.mhWidth {
    /*width: 75%;*/
    flex: 1;
    /*margin-left: 20%;*/
  }

  .nav .el-menu.mhWidth li.el-menu-item {
    margin-left: 130px;
  }

  .user {
    display: flex;
    align-items: center;

    >img {
      vertical-align: middle;
      margin-right: 10px;
    }

    /deep/ .el-dropdown-menu .el-popper {
      width: 165px;
      margin-top: -15px;
    }
  }
}

ul.userDown.el-dropdown-menu.el-popper {
  /*width: 165px;*/
  width: 125px;
  position: absolute;
  top: 46px !important;
  padding: 0;

  li {
    font-size: 12px;
  }

  .el-dropdown-menu__item {
    padding: 0 18px;
  }

  .flex2 {
    align-items: center;
  }

  .openId {
    display: inline-block;
    width: 44px;
    height: 16px;
    background: linear-gradient(135deg, #e4443d 0%, #c70009 100%);
    border-radius: 10px;
    line-height: 16px;
    text-align: center;
    font-size: 8px;
    color: #ffffff;
  }

  .el-dropdown-menu__item:focus,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: rgba(199, 0, 9, 0.05);
    color: #333333;
  }
}

.contentTip {
  .tipIcon {
    width: 46px;
    height: 46px;
    margin-right: 20px;
  }

  h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    word-break: break-all;
    color: #666666;
  }
}

.header2 {
  .red_he {
    color: rgb(199, 0, 9);
  }
}
</style>
<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #333333;
}

.el-dropdown-link:hover {
  color: #c70009;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>