<template>
  <div class="center1">
    <div class="center">
      <!-- 侧导航 -->
      <div class="aside_left">
        <router-link to="/auth">
          <el-menu class="home">个人中心</el-menu>
        </router-link>
        <el-menu :default-active="activePath"
                 class="el-menu-vertical-demo"
                 :default-openeds='openeds'
                 router
                 @open="handleOpen"
                 @close="handleClose">
          <el-submenu :index="idx+''"
                      v-for="item,idx in routerData"
                      :key="idx">
            <template slot="title">{{item.name}}</template>
            <el-menu-item :index="ite.path"
                          v-for="(ite,i) in item.children"
                          :key="i">{{ite.name}}
            </el-menu-item>
          </el-submenu>
        </el-menu>

      </div>
      <div class="aside_right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    data() {
      return {
        isbusinessRole: window.sessionStorage.getItem("businessRole"),
        // activePath: this.$route.path,
        openeds: ["0", "1", "2", "3", "4"],
        routerData: [
          {
            path: "",
            name: "认证管理",
            children: [
              {
                path: "/aside/companyinfo",
                name: "企业信息",
              },
            ],
          },
          {
            path: "",
            name: "账户信息",
            children: [
              {
                path: "/aside/bankCardBind",
                name: "银行卡绑定",
              },
              {
                path: "/aside/virtualBnakList",
                name: "电子账簿管理",
              },
              {
                path: "/aside/inOutGoldList",
                name: "出入金管理",
              },
            ],
          },
          {
            path: "",
            name: "用户管理",
            children: [
              {
                path: "/aside/subcompany",
                name: "子公司管理",
              },
              {
                path: "/aside/operator",
                name: "操作员管理",
              },
            ],
          },
          {
            path: "",
            name: "账号安全",
            children: [
              {
                path: "/aside/password",
                name: "密码管理",
              },
            ],
          },
        ],
      };
    },
    created() {
      //1核心企业-存在---用户管理-子操作管理
      let asideList = this.routerData
      if (this.companyRole != '0') {
        if (this.companyRole == '1') {//核心企业
          asideList.splice(1, 1)//没有账户中心
        } else {
          asideList.splice(1, 1)
          asideList[1].children.splice(0, 1)
        }
      } else {
        if (this.userType == '1') {
          asideList[2].children.splice(0, 1)//小微没有子用户
        } else {
          this.routerData.splice(0, 3)
        }
      }
      // if (this.userType != '1'){
      //   this.routerData.splice(0, 3)
      // }
      // if(this.userType == '1' && this.companyRole != "1"){
      //   this.routerData[2].children.splice(0, 1);
      // }
      // console.log(this.routerData.splice(1, 1))
      // console.log(this.routerData.splice(0, 3))
      // console.log(this.routerData)
      // console.log(this.routerData[2].children.splice(0, 1))
//小微没有子公司模块  管理员有银行卡  管理员并且不是核心企业--没有银行卡和子公司
//       if (this.companyRole != '0') return this.routerData.splice(1, 1)
//       if (this.userType != '1') return this.routerData.splice(0, 3)
//
//       if (this.userType == '1' && this.companyRole != "1") return this.routerData[2].children.splice(0, 1);

      // if (this.isbusinessRole != '0') return this.routerData.splice(1, 1)
      // if (this.userType != '1') return this.routerData.splice(0, 3)
      // if (this.userType == '1' && this.isbusinessRole != "1") return this.routerData[2].children.splice(0, 1);

    },
    computed: {
      ...mapState([
        // 'companyStatus',//企业状态
        // 'getUser',//用户手机号
        'userType',//管理员/操作员
        'companyRole'//企业角色
      ]),
      activePath: {
        get() {
          return this.$route.meta.GuidePath ? this.$route.meta.ChildrenActiveMenu : this.$route.path
        },
        set(v) {
        }
      },
    },
    watch: {
      $route: {
        handler(to, from) {
          let pathret = to.path;
          // console.log("这个是企业状态：", sessionStorage.getItem("getStatus"));
          if (sessionStorage.getItem("getStatus") != 3) {
            // debugger
            pathret = "/aside/companyinfo";
            this.activePath = "/aside/companyinfo";
            // this.$router.push("/aside/companyinfo");
            // this.activePath = "/aside/companyinfo";
            // let oldPath = this.$route.path.split('/');
            // let hasOldPath = oldPath.slice(1, oldPath.length - 1).join('/');//截取地址栏路径
            // let hasOldPath1 = oldPath.slice(1, 2).join('/');//截取地址栏路径
            // console.log(hasOldPath1,'旧-',oldPath)
          } else {
            if (this.$route.path.indexOf("/bankCardBind") != -1) {
              this.activePath = "/aside/bankCardBind";
            } else if (this.$route.path.indexOf("/virtualBnakList") != -1) {
              console.log(this.$route.path);

              this.activePath = "/aside/virtualBnakList";
            }
          }
        },
      },
    },
    methods: {
      handleOpen(key, keyPath) {
        // console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        // console.log(key, keyPath);
      },
    },
  };
</script>

<style lang="less" scoped>
  .center1 {
    background-color: #f6f7fb !important;

    .center {
      padding: 20px 0 30px 0;
      box-sizing: border-box;
      display: flex;
    }
  }

  .aside_left {
    width: 180px;
    background-color: #fff;
    margin-right: 20px;
    height: fit-content;
  }

  .aside_right {
    flex: 1;
  }

  /deep/ .el-icon-arrow-down:before {
    content: none;
  }

  /deep/ .el-submenu {
    border-bottom: 1px solid #f0f3ef;
    min-width: 180px;
  }

  /deep/ .el-menu-item {
    height: 42px;
    min-width: 180px !important;
    padding: 0 0 0 20px !important;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    letter-spacing: 1px;
  }

  /deep/ .el-menu-item.is-active {
    color: #c70009 !important;
  }

  /deep/ .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #fff;
  }

  /deep/ .el-submenu__title {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 25px;
    letter-spacing: 1px;
    padding: 20px;
    box-sizing: border-box;
    height: 65px;
  }

  /deep/ .el-menu {
    border-right: none;
  }

  .home {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 25px;
    letter-spacing: 1px;
    padding: 20px;
    box-sizing: border-box;
    height: 65px;
  }

  .home:hover {
    background-color: #ecf5ff;
  }
</style>
<style lang='less'>
  /deep/ .el-submenu .el-menu-item {
    min-width: none !important;
  }

</style>
